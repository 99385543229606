import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Associative Property of Addition"} />
        <div className="p-4">
          <p>Write the missing number and/or the missing sum.</p>
        </div>
        <div className="p-4">
          <p className="text-2xl text-center">
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" + ( 3 + 1 ) = "}
            <input
              type="text"
              className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
              maxLength="2"
            />
            {" = 3 + ( 2 + 1 )"}
          </p>
        </div>
        <div className="p-4">
          <p className="text-2xl text-center">
            {"3 + ( "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" + 1 ) = "}
            <input
              type="text"
              className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
              maxLength="2"
            />
            {" = 4 + ( 3 + 1 )"}
          </p>
        </div>
        <div className="p-4">
          <p className="text-2xl text-center">
            {"4 + ( 2 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" ) = "}
            <input
              type="text"
              className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
              value={"9"}
              readOnly
              maxLength="2"
            />
            {" = 3 + ( 2 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" )"}
          </p>
        </div>
        <div className="p-4">
          <p className="text-2xl text-center">
            {"5 + ( 3 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" ) = "}
            <input
              type="text"
              className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
              value={"10"}
              readOnly
              maxLength="2"
            />
            {" = 2 + ( 3 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" )"}
          </p>
        </div>
        <div className="p-4">
          <p className="text-2xl text-center">
            {"6 + ( 4 + 1 ) = "}
            <input
              type="text"
              className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
              maxLength="2"
            />
            {" = 1 + ( "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" )"}
          </p>
        </div>
        <div className="p-4">
          <p className="text-2xl text-center">
            {"7 + ( 3 + 2 ) = "}
            <input
              type="text"
              className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
              maxLength="2"
            />
            {" = 2 + ( "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" )"}
          </p>
        </div>
        <div className="p-4">
          <p className="text-2xl text-center">
            {"8 + ( 5 + 3 ) = "}
            <input
              type="text"
              className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
              maxLength="2"
            />
            {" = 5 + ( "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
            {" )"}
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;