import React from "react";
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Choose the correct answer"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. What is the lesson or moral of the story?{" "}
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="Get a little bit of sunshine and fresh air. "
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="All about protecting yourself from getting germs. "
              />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Chemicals in our bodies." />
            </div>
            <div className="flex-row">
              <Radio id="QA4" name="Q1" label="Germs are everywhere." />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              What did the author suggest in the story?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Wash your hands." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="Cover your mouth when you cough and sneeze."
              />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Do not share food or drinks." />
            </div>
            <div className="flex-row">
              <Radio id="QA4" name="Q1" label="All of the above." />
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. How can germs get into your body?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Cuts in the skin. " />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Squeezing " />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Greeting" />
            </div>
            <div className="flex-row">
              <Radio id="QA4" name="Q1" label="All of the above" />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/3">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/One/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E4;