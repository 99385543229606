import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA12 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />

        <div className="p-4">
          <img src={IMAGES.L4.MA11.Bar2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <div>
            <p>1) How many pets were sold in July and April combined ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>2) How many more pets were sold in June than in March ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>3) How many pets were sold in March, January, and June ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>4) In Auguest, twice the number of pets were sold than in May. How many pets were sold in August ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>5) Were more pets sold in January and April ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="5"
            />
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/11">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Four/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA12;