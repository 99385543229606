import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS2 = () => {
  
  return (
    <div>
      <div>
        <BannerTitle title={"All about maps"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SS2.AllAboutMap}
            alt="Map"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/1A">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Four/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
