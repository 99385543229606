import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  const normalStyle = "w-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded";
  const correctStyle =
    "w-1/2 bg-green-500 hover:bg-green-600 text-white rounded";
  const errorStyle = "w-1/2 bg-red-600 hover:bg-red-700 text-white rounded";
  const showCorrectSytle =
    "w-1/2 opacity-50 bg-green-500 hover:bg-green-600 text-white rounded";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("5").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("6").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("10").className = showCorrectSytle;
        document.getElementById("9").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("10").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      default:
      // code block
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div>
          <div className="p-4">
            <p>
              Choose the correct adjective or adverbs provided for the
              sentences.
            </p>
          </div>
          <div className="p-4">
            <div>
              <p>1. The boy ran.</p>
            </div>
            <div className="flex mt-4">
              <div className="w-1/2 flex justify-end px-2">
                <button onClick={checkAnswer} id="1" className={normalStyle}>
                  happy
                </button>
              </div>
              <div className="w-1/2 flex justify-start px-2">
                <button onClick={checkAnswer} id="2" className={normalStyle}>
                  quickly
                </button>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div>
              <p>2. The wind blew.</p>
            </div>
            <div className="flex mt-4">
              <div className="w-1/2 flex justify-end px-2">
                <button onClick={checkAnswer} id="3" className={normalStyle}>
                  howling
                </button>
              </div>
              <div className="w-1/2 flex justify-start px-2">
                <button onClick={checkAnswer} id="4" className={normalStyle}>
                  furiously
                </button>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div>
              <p>3. The students sang the songs.</p>
            </div>
            <div className="flex mt-4">
              <div className="w-1/2 flex justify-end px-2">
                <button onClick={checkAnswer} id="5" className={normalStyle}>
                  talented
                </button>
              </div>
              <div className="w-1/2 flex justify-start px-2">
                <button onClick={checkAnswer} id="6" className={normalStyle}>
                  loudly
                </button>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div>
              <p>4. The game was finished.</p>
            </div>
            <div className="flex mt-4">
              <div className="w-1/2 flex justify-end px-2">
                <button onClick={checkAnswer} id="7" className={normalStyle}>
                  exciting
                </button>
              </div>
              <div className="w-1/2 flex justify-start px-2">
                <button onClick={checkAnswer} id="8" className={normalStyle}>
                  early
                </button>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div>
              <p>5. John, the boy in the class, was late.</p>
            </div>
            <div className="flex mt-4">
              <div className="w-1/2 flex justify-end px-2">
                <button onClick={checkAnswer} id="9" className={normalStyle}>
                  tallest
                </button>
              </div>
              <div className="w-1/2 flex justify-start px-2">
                <button onClick={checkAnswer} id="10" className={normalStyle}>
                  again
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Four/5">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;
