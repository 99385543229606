export const IMAGES = {
  L1: {
    SS1: {
      C0: require("./L1/C0.png"),
      C1: require("./L1/C1.png"),
      C2: require("./L1/C2.png"),
      C3: require("./L1/C3.png"),
      C4: require("./L1/C4.png"),
      C5: require("./L1/C5.png"),
      C6: require("./L1/C6.png"),
    },
    SS4: {
      QEG: require("./L1/QEG.png"),
      Q1: require("./L1/Q1.png"),
      Q2: require("./L1/Q2.png"),
      Q3: require("./L1/Q3.png"),
      Q4: require("./L1/Q4.png"),
    },
    SS5: {
      RC1: require("./L1/RC1.png"),
    },
    SS7: {
      Quiz1: require("./L1/Quiz1.png"),
      Quiz2: require("./L1/Quiz2.png"),
      Quiz3: require("./L1/Quiz3.png"),
      Quiz4: require("./L1/Quiz4.png"),
      Quiz5: require("./L1/Quiz5.png"),
      Quiz6: require("./L1/Quiz6.png"),
      Quiz7: require("./L1/Quiz7.png"),
      Quiz8: require("./L1/Quiz8.png"),
    },
  },
  L2: {
    SS1: {
      Fast1: require("./L2/Fast1.png"),
      Fast2: require("./L2/Fast2.png"),
      Fast3: require("./L2/Fast3.png"),
      Fast4: require("./L2/Fast4.png"),
      Fast5: require("./L2/Fast5.png"),
      Fast6: require("./L2/Fast6.png"),
    },
    SS8: {
      EatingOut: require("./L2/EatingOut.png"),
    },
  },
  L3: {
    LandForms: require("./L3/LandForms.png"),
    LF1: require("./L3/LF1.png"),
    LF2: require("./L3/LF2.png"),
    LF3: require("./L3/LF3.png"),
    LF4: require("./L3/LF4.png"),
    LF5: require("./L3/LF5.png"),
    LF6: require("./L3/LF6.png"),
    LF7: require("./L3/LF7.png"),
    LF8: require("./L3/LF8.png"),
    LF9: require("./L3/LF9.png"),
    LF10: require("./L3/LF10.png"),
    LF11: require("./L3/LF11.png"),
    LF12: require("./L3/LF12.png"),
    LandFromScene: require("./L3/LandFromScene.png"),
    LFQ1: require("./L3/LFQ1.png"),
    LFQ2: require("./L3/LFQ2.png"),
    LFQ3: require("./L3/LFQ3.png"),
    LFQ4: require("./L3/LFQ4.png"),
    LFQ5: require("./L3/LFQ5.png"),
  },
  L4: {
    Com1: require("./L4/Com1.png"),
    Com2: require("./L4/Com2.png"),
    CavePainting: require("./L4/CavePainting.png"),
    SmokeSignal: require("./L4/SmokeSignal.png"),
    CarrierPigeons: require("./L4/CarrierPigeons.png"),
    TribalDance: require("./L4/TribalDance.png"),
    Post: require("./L4/Post.png"),
    ElectricalTelegraph: require("./L4/ElectricalTelegraph.png"),
    PrintingPressMachine: require("./L4/PrintingPressMachine.png"),
    Radio: require("./L4/Radio.png"),
    Television: require("./L4/Television.png"),
    Tools1: require("./L4/Tools1.png"),
    Tools2: require("./L4/Tools2.png"),
    Tools3: require("./L4/Tools3.png"),
    Tools4: require("./L4/Tools4.png"),
    Tools5: require("./L4/Tools5.png"),
  },
};