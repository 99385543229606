import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA10 = () => {
  const speakData =
    "Use place value understanding and properties of operations to add and subtract. Add the ones. seven. plus. six. equal. thirteen. Regroup 13 ones as 1 ten 3 ones. Add the tens. One. Plus. Six. Plus. Eight. Equal. Fifteen. Regroup 15tens as 1 hundreds 5 tens. Always start with the largest place value first.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "Use place value understanding and properties of operations to add and subtract"
          }
        />
        <div className="p-4">
          <p>
            Use place value understanding and properties of operations to add
            and subtract
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA10.PLV1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA10.PLV2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA10.PLV3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA10.PLV4} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA10.PLV6} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA10.PLV7} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Two/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Two/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA10;