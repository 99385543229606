import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS14 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Use the words in the box to label the communication tools."}
        />
        <div className="px-4 border border-green-500 m-2">
          <div className="flex justify-between">
            <div>Sharpen Stones</div>
            <div>The Reed Pen</div>
          </div>
          <div className="flex justify-between">
            <div>Quill Pen</div>
            <div>Pencil</div>
            <div>Fountain Pen</div>
          </div>
        </div>
        <div>
          <div className="flex mx-2 px-2">
            <div className="w-1/2 px-2">
              <div className="flex p-4 md:p-12 lg:p-12">
                <img src={IMAGES.L4.Tools4} alt="" className="w-full" />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-blue-500 border-b-2"
                />
              </div>
            </div>
            <div className="w-1/2 px-2">
              <div className="flex p-4 md:p-12 lg:p-12">
                <img src={IMAGES.L4.Tools3} alt="" className="w-full" />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-blue-500 border-b-2"
                />
              </div>
            </div>
          </div>
          <div className="flex mx-2 px-2">
            <div className="w-1/2 px-2">
              <div className="flex p-4 md:p-12 lg:p-12">
                <img src={IMAGES.L4.Tools2} alt="" className="w-full" />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-blue-500 border-b-2"
                />
              </div>
            </div>
            <div className="w-1/2 px-2">
              <div className="flex p-4 md:p-12 lg:p-12">
                <img src={IMAGES.L4.Tools5} alt="" className="w-full" />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-blue-500 border-b-2"
                />
              </div>
            </div>
          </div>
          <div className="flex mx-2 px-2">
            <div className="w-1/2 px-2">
              <div className="flex p-4 md:p-12 lg:p-12">
                <img src={IMAGES.L4.Tools1} alt="" className="w-full" />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-blue-500 border-b-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Four/13">
            <BackButton />
          </Link>
          <Link to="/G1/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS14;