import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA5 = () => {
  const speakData = "Circle. Square. Hexagon. Rectangle. Oval. Triangle. Trapeziod. Pentagon. Semicircle. Octagon. Star. Rhombus. Heart.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"GEOMETRY"} />
        <div className="p-4">
          <p className="text-2xl font-bold">Shapes</p>          
        </div>
        <div className="w-full flex p-4">
          <div className="justify-center text-center w-full">
            <img src={IMAGES.L4.MA5.ShapesBanner} alt="" className="w-full" />
          </div>
        </div>
        <div className="w-full flex p-4">
          <div className="justify-center text-center w-full">
            <img src={IMAGES.L4.MA5.Shapes} alt="" className="w-full" />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Four/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Math/Units/Four/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA5;
