import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import CareIMG from "../../assets/L1/Care.png";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS6 = () => {
  const speakData = "Family members share a special bond. Families show care for each other by showing regard for each other’s thoughts and feelings. Sick family members, babies and elderly family members can be the most vulnerable and they might need special care.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Care"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={CareIMG} alt="Care" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            Family members share a special bond. Families show care for each
            other by showing regard for each other’s thoughts and feelings. Sick
            family members, babies and elderly family members can be the most
            vulnerable and they might need special care.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/5">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS6;
