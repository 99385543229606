import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Solving everyday problems using the engineering design cycle"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC1} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC2} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC3} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC4} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC5} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC6} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC7} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC8} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC9} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC10} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC11} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC12} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SC3.EDC13} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-24">
          <img src={IMAGES.L2.SC3.EDC14} alt="" className="w-full" />
        </div>
        
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Two/6">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units/Two/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC7;