import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA8 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Associative Property of Addition"} />
        <div className="p-4">
          <p>
            Use the Associative Property of Addition to rewrite each addition
            fact.
          </p>
        </div>
        <div className="p-4">
          <div className="mb-4 grid grid-cols-2 gap-1">
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <p className="text-2xl">(12</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">3)</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">5</p>
              <p className="text-2xl">=</p>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              <p className="text-2xl">+(</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">)</p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-2 gap-1">
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <p className="text-2xl">(10</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">3)</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">7</p>
              <p className="text-2xl">=</p>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              <p className="text-2xl">+(</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">)</p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-2 gap-1">
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <p className="text-2xl">(14</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">6)</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">2</p>
              <p className="text-2xl">=</p>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              <p className="text-2xl">+(</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">)</p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-2 gap-1">
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <p className="text-2xl">(5</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">8)</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">6</p>
              <p className="text-2xl">=</p>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+(</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">)</p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-2 gap-1">
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <p className="text-2xl">(12</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">5)</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">2</p>
              <p className="text-2xl">=</p>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              <p className="text-2xl">+(</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">)</p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-2 gap-1">
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <p className="text-2xl">(11</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">4)</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">2</p>
              <p className="text-2xl">=</p>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              <p className="text-2xl">+(</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">)</p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-2 gap-1">
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <p className="text-2xl">(9</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">3)</p>
              <p className="text-2xl">+</p>
              <p className="text-2xl">2</p>
              <p className="text-2xl">=</p>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center justify-items-center">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              <p className="text-2xl">+(</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">+</p>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-b-2"
                maxLength="1"
              />
              <p className="text-2xl">)</p>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Two/7">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Two/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA8;
