import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS8 = () => {
  const speakData =
    "The last night my friends and I went to a fast food restaurant. We were celebrating the birthday of one of my friends. When we arrived, the place smelled delicious. There were hamburgers, pizzas, hotdogs and all the fast food you could imagine. My best friend Tina had a big hamburger with extra cheese. Christ, the birthday girl, had a hotdog with extra French fries and I had a big pizza with extra cheese and pepperoni. While we enjoyed our food, we told stories, jokes and shared our food among us. Finally, we chose a drink, my best friend and I had a chocolate milkshake for each one and the Birthday girl had a coke. We spent a really nice time together. By Mandy.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Eating Out"} />

        <div className="p-4">
          <p>
            The last night my friends and I went to a fast food restaurant. We
            were celebrating the birthday of one of my friends. When we arrived,
            the place smelled delicious. There were hamburgers, pizzas, hotdogs
            and all the fast food you could imagine. My best friend Tina had a
            big hamburger with extra cheese. Christ, the birthday girl, had a
            hotdog with extra French fries and I had a big pizza with extra
            cheese and pepperoni. While we enjoyed our food, we told stories,
            jokes and shared our food among us. Finally, we chose a drink, my
            best friend and I had a chocolate milkshake for each one and the
            Birthday girl had a coke. We spent a really nice time together.
          </p>
        </div>
        <div className="flex p-4">
          <p className="justify-end">By Mandy</p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.SS8.EatingOut} alt="EatingOut" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Two/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS8;
