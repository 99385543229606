import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const SS1 = () => {
  const speakData =
    "People started wearing clothes about 100,000 years ago. Their clothes were made of animal skin. People also used to weave grass together to make rough fabric. Clothes were probably the same for men and women. Making Fabric – About 8,000 years ago, people learned to weave threads together to make pieces of fabric. They used threads of wool, cotton, or linen. They sewed the pieces of fabric together to make simple tunics. These early clothes were like our T-shirts today, but they were longer. Later, people used looms for weaving.The linen tunic from ancient Egypt is now in a museum. It’s about 1,500 years old. We don’t have many clothes as old as this tunic. Fabric doesn’t usually last long, so people throw old clothes away. If we want to know about clothes in the past, we can look at old paintings. The spinning wheel was invented about 2,500 years ago in India. With spinning wheels, people could make thread more quickly, so making fabric was easier. Fabrics became more comfortable. If you were rich, you could buy lots of different clothes.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"The Evolution of Clothes "} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SS1.Clothes} alt="Clothes" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            People started wearing clothes about 100,000 years ago. Their
            clothes were made of animal skin. People also used to weave grass
            together to make rough fabric. Clothes were probably the same for
            men and women.
          </p>
        </div>
        <div className="p-4">
          <p>
            Making Fabric – About 8,000 years ago, people learned to weave
            threads together to make pieces of fabric. They used threads of
            wool, cotton, or linen. They sewed the pieces of fabric together to
            make simple tunics. These early clothes were like our T-shirts
            today, but they were longer. Later, people used looms for weaving.
          </p>
        </div>
        <div className="p-4">
          <p>
            The linen tunic from ancient Egypt is now in a museum. It’s about
            1,500 years old. We don’t have many clothes as old as this tunic.
            Fabric doesn’t usually last long, so people throw old clothes away.
            If we want to know about clothes in the past, we can look at old
            paintings.
          </p>
        </div>
        <div className="p-4">
          <p>
            The spinning wheel was invented about 2,500 years ago in India. With
            spinning wheels, people could make thread more quickly, so making
            fabric was easier. Fabrics became more comfortable. If you were
            rich, you could buy lots of different clothes.
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/SocialStudies/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1;
