import React from "react";

const BannerTitle = ({title}) => {
  return (
    <div>
      <div className="flex bg-zmh-lightblue-600 text-white text-xl font-bold justify-center items-center p-6">
        {title}
      </div>
    </div>
  );
};

export default BannerTitle;
