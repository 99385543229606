import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA5 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Quiz"}
        />

        <div className="p-4">
          <p>Order the three object from the shortest to the longest. Write "1" under the shortest object and write "3" under the longest object.</p>
        </div>

        <div className="p-4">
          <div className="">
            <img src={IMAGES.L4.MA5.SL1} alt="" className="" />
          </div>
          <div className="">
            <div className="grid grid-cols-3">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"2"}
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"3"}
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"1"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          
        </div>

        <div className="p-4">
          <div className="">
            <img src={IMAGES.L4.MA5.SL2} alt="" className="border border-gray-700" />
          </div>
          <div className="">
            <div className="grid grid-cols-3">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"                  
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"                  
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          
        </div>

        <div className="p-4">
          <div className="">
            <img src={IMAGES.L4.MA5.SL3} alt="" className="border border-gray-700" />
          </div>
          <div className="">
            <div className="grid grid-cols-3">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"                  
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/4">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Four/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA5;
