import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS16 = () => {
  const speakData =
    "A compass is an important tool for finding direction. It helps you to find your way when you are using a map. There are four main points of the compass: north, east, south and west . These are called compass points.";

  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Compass Points"} />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            A <strong>compass</strong> is an important tool for finding
            direction. It helps you to find your way when you are using a
            map. There are <strong>four main points</strong> of the compass:
            <strong> north, east, south and west </strong>. These are called
            <strong>compass points</strong>.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img src={IMAGES.L4.SS16.Compass2} alt="Compass" className="w-full" />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/15">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Four/17">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS16;
