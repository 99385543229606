import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA11 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Taller or Shorter"} />
        <div className="p-4">
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <p>
              <b>
                <u>Look</u>
              </b>{" "}
              at the pictures in the boxes.{" "}
              <b>
                <u>Click on</u>
              </b>{" "}
              the correct option.
            </p>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full mb-2">
                <div>
                  <img src={IMAGES.L3.MA11.TS1} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-rows-2">
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-1"
                      type="radio"
                      value=""
                      name="default-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-1"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Building
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-2"
                      type="radio"
                      value=""
                      name="default-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-2"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      House
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full mb-2">
                <div>
                  <img src={IMAGES.L3.MA11.TS2} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-rows-2">
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-3"
                      type="radio"
                      value=""
                      name="default-radio1"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-3"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Bottle of water
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-4"
                      type="radio"
                      value=""
                      name="default-radio1"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-4"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Jar
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full mb-2">
                <div>
                  <img src={IMAGES.L3.MA11.TS3} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-rows-2">
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-5"
                      type="radio"
                      value=""
                      name="default-radio3"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-5"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                     Giraffe
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-6"
                      type="radio"
                      value=""
                      name="default-radio3"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-6"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Hippo
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full mb-2">
                <div>
                  <img src={IMAGES.L3.MA11.TS4} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-rows-2">
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-7"
                      type="radio"
                      value=""
                      name="default-radio4"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-7"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                     Bush
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-8"
                      type="radio"
                      value=""
                      name="default-radio4"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-8"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Tree
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full mb-2">
                <div>
                  <img src={IMAGES.L3.MA11.TS5} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-rows-2">
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-9"
                      type="radio"
                      value=""
                      name="default-radio5"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-9"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                     White Candle
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-10"
                      type="radio"
                      value=""
                      name="default-radio5"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-radio-10"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Blue Candle
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Three/10">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Three/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA11;
