import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Here are some other well- known superstitions. Can you match them
            with the beliefs?
          </p>
        </div>
        <div className="p-4">
          <div className="">
            <div className="w-full">
              <p className="text-center">
                <u>
                  <strong>Belief</strong>
                </u>
              </p>
              <p>
                <strong>1.</strong> black cat
              </p>
              <p>
                <strong>2.</strong> crack in the sidewalk
              </p>
              <p>
                <strong>3.</strong> bless you
              </p>
            </div>
            <div className="w-full">
              <p className="text-center">
                <u>
                  <strong>Belief</strong>
                </u>
              </p>
              <p>
                <strong>A.</strong> don't step on it
              </p>
              <p>
                <strong>B.</strong> say it after a person sneeze
              </p>
              <p>
                <strong>C.</strong> crossing your path brings bad luck
              </p>
            </div>
          </div>
          <div className="p-4 mt-20 mb-2">
            <p>Fill (A,B,C) of supersition that match with the beliefs</p>
          </div>
          <div className="flex">
            <div className="w-1/2">
              <p className="font-bold bg-blue-500 text-white w-20 mx-auto text-center text-2xl mb-4">
                1
              </p>
              <p className="font-bold bg-blue-500 text-white w-20 mx-auto text-center text-2xl mb-4">
                2
              </p>
              <p className="font-bold bg-blue-500 text-white w-20 mx-auto text-center text-2xl">
                3
              </p>
            </div>
            <div className="w-1/2">
              <div className="mb-4">
                <input
                  type="text"
                  className="outline-none w-20 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
                  maxLength={1}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  className="outline-none w-20 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-20 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Four/2">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;