import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { IMAGES } from "../../assets";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS4 = () => {
  const speakData =
    "How does Fast Food affect our Health? What makes food worthwhile to your body is the nutrition it provides. Nutrition is like fuel for your body. Things like fruits and vegetables, whole grains, dairy products, and meat give our bodies the fuel they need. Fast food is usually pretty high in calories, fat, salt, and sugar. Our bodies need some of these things, but it's very easy to eat too much of them. When we fill up on foods that are high in fat, sugar, salt, and calories, we don't have much room left for the foods our bodies need. Fast foods are usually processed. This means that they're not in their natural form. Think about the difference between a potato and French fries, or a piece of meat and a hot dog. These foods have been chopped up, pressed together, and in many cases filled with a lot of things our bodies don't recognize as food. Many people are allergic to the artificial colors, flavors, and preservatives in processed foods. The extra calories and unhealthy ingredients in many fast foods can make people gain too much weight or develop diabetes (a disease that makes it hard to digest sugar). Many fast food restaurants have started offering some healthier foods like salads, juice, milk, carrots, and fruit. This helps people to get the nutrition they need even when they're in a hurry.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Fast Food"} />
        <div className="p-4">
          <p className="text-center font-bold">
            How does Fast Food affect our Health?
          </p>
        </div>
        <div className="p-4">
          <p>
            What makes food worthwhile to your body is the nutrition it
            provides. Nutrition is like fuel for your body. Things like fruits
            and vegetables, whole grains, dairy products, and meat give our
            bodies the fuel they need.
          </p>
        </div>
        <div className="p-4">
          <p>
            Fast foods are usually <strong>processed</strong>. This means that
            they're not in their natural form. Think about the difference
            between a potato and French fries, or a piece of meat and a hot dog.
            These foods have been chopped up, pressed together, and in many
            cases filled with a lot of things our bodies don't recognize as
            food. Many people are allergic to the artificial colors, flavors,
            and preservatives in processed foods.
          </p>
        </div>
        <div className="p-4">
          <p>
            The extra calories and unhealthy ingredients in many fast foods can
            make people gain too much weight or develop diabetes (a disease that
            makes it hard to digest sugar). Many fast food restaurants have
            started offering some healthier foods like salads, juice, milk,
            carrots, and fruit. This helps people to get the nutrition they need
            even when they're in a hurry.
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.SS1.Fast6} alt="FastFood" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Two/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Two/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;
