import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import One from '../../assets/Three/One.jpg';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const E1 = () => {
  const speakData =
    "The sun was out. Amy wanted to go outside. She needed to water her plants. She put on her garden gloves. She put on her boots. She went outside. She got water. Amy went to the flower bed. She watered the roses. She pushed the soil around the flowers. Her gloves got dirty. She went to the herb garden. She watered the basil plants. She picked a few leaves. Amy brought the leaves inside. She showed her big sister. She will make diner. She will add to the sauce. It will taste great!";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle
          title={"Read the story. Look at the bold words. Choose the answers."}
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={One} alt="" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            The sun was out. Amy wanted to go outside. She needed to water her
            plants. She put on her garden gloves. She put on her boots. She went
            outside. She got water. Amy went to the flower bed. She watered the
            <strong> roses</strong>. She pushed the <strong> soil</strong>{" "}
            around the flowers. Her gloves got dirty. She went to the herb
            garden. She watered the <strong> basil</strong> plants. She picked a
            few leaves. Amy brought the leaves inside. She showed her big
            sister. She will make diner. She will add to the sauce. It will
            taste great!
          </Typography>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;