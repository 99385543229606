import React from "react";
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E1 = () => {
  const speakData =
    "“Will you play hide-and-seek?” Jack asked his mother. “I don’t have time. I have to find some tape,” Jack’s mother said. “I’ll help,” Jack said. He looked high and low. He found his mother’s tape in a pile on her desk. “Will you play hide-and-seek with me?” Jack asked his brother. “I don’t have time. I have to find my kite,” his brother said. “I’ll help,” Jack said. He looked high and low. He found his brother’s kite by the gate. “Will you play hide-and-seek with me?” Jack said to his father. “I don’t have time. I have to find my rope,” his father said. “I’ll help,” Jack said. He looked high and low. He found his father’s rope by the rake. “Will you play hide-and-seek with me?” Jack said to his sister. “I don’t have time. I have to find my dime,” his sister said. “I’ll help,” Jack said. He looked high and low. He found his sister’s dime behind the drapes. “Too bad no one has time to play hide-and-seek!” Jack laughed.";  
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Hide and Seek"} />
        <div className="p-4">
          <p>Read the passage and answer the questions.</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.E1.HideAndSeek}
            alt="HideAndSeek"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            “Will you play hide-and-seek?” Jack asked his mother. “I don’t have
            time. I have to find some tape,” Jack’s mother said. “I’ll help,”
            Jack said. He looked high and low. He found his mother’s tape in a
            pile on her desk.
          </p>
          <p>“Will you play hide-and-seek with me?”</p>
          <p>
            Jack asked his brother. “I don’t have time. I have to find my kite,”
            his brother said. “I’ll help,” Jack said. He looked high and low. He
            found his brother’s kite by the gate.
          </p>
          <p>
            “Will you play hide-and-seek with me?” Jack said to his father. “I
            don’t have time. I have to find my rope,” his father said. “I’ll
            help,” Jack said. He looked high and low. He found his father’s rope
            by the rake.
          </p>
          <p>
            “Will you play hide-and-seek with me?” Jack said to his sister. “I
            don’t have time. I have to find my dime,” his sister said. “I’ll
            help,” Jack said. He looked high and low. He found his sister’s dime
            behind the drapes.
          </p>
          <p>“Too bad no one has time to play hide-and-seek!” Jack laughed.</p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Three/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;