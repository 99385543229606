import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA14 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div className="mb-6">
            <img src={IMAGES.L4.MA14.Book} alt="" className="w-full" />
          </div>
          <div className="mb-6 bg-gray-300 shadow-xl rounded-xl py-4">
            <div className="text-center font-bold">
              <p>Week 1</p>
            </div>
            <div className="text-center">
              <p>Number of books</p>
            </div>
            <div className="w-1/2 mx-auto">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                readOnly
                value={"3"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6 bg-gray-300 shadow-xl rounded-xl py-4">
            <div className="text-center font-bold">
              <p>Week 2</p>
            </div>
            <div className="text-center">
              <p>Number of books</p>
            </div>
            <div className="w-1/2 mx-auto">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"                
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6 bg-gray-300 shadow-xl rounded-xl py-4">
            <div className="text-center font-bold">
              <p>Week 3</p>
            </div>
            <div className="text-center">
              <p>Number of books</p>
            </div>
            <div className="w-1/2 mx-auto">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"                
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6 bg-gray-300 shadow-xl rounded-xl py-4">
            <div className="text-center font-bold">
              <p>Week 4</p>
            </div>
            <div className="text-center">
              <p>Number of books</p>
            </div>
            <div className="w-1/2 mx-auto">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"                
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/13">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Four/15">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA14;