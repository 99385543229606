import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Write the next two numbers in sequence."} />
        <div className="p-4">
          <p className="text-2xl mb-6">
            {"1) 15 23 31 39 47 55 63 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"2) 81 79 77 75 73 71 69 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"3) 9 16 23 30 37 44 51 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"4) 85 78 71 64 57 50 43 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"5) 3 11 19 27 35 43 51 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"6) 67 62 57 52 47 42 37 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"7) 70 68 66 64 62 60 58 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"8) 17 24 31 38 45 52 59 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"9) 54 49 44 39 34 29 24 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
          <p className="text-2xl mb-6">
            {"10) 96 87 78 69 60 51 42 "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />{" "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-b-2"
              maxLength="2"
            />
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/8">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;