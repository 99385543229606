import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC3 = () => {
  const speakData =
    "Ask it. What is the problem and is there criteria the design should meet? Research it. Generate ideas for possible design solutions. Plan it. Choose one idea, draw an annotated diagram and make a list of what you need. Create it. Follow your plan to build your solution. Test it. Try it out. Does it solve the problem and meet the design criteria? Improve it. Can it be improved? Modify and test again.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Engineering Design Process"} />
        <div className="p-4">
          <img src={IMAGES.L4.Design2} alt="" />
        </div>
        <div className="p-4">
          <p className="font-bold"> Ask it</p>
        </div>
        <div className="p-4">
          <p>
            What is the problem and is there criteria the design should meet?
          </p>
        </div>
        <div className="p-4">
          <p className="font-bold">Research it</p>
        </div>
        <div className="p-4">
          <p>Generate ideas for possible design solutions.</p>
        </div>
        <div className="p-4">
          <p className="font-bold">Plan it</p>
        </div>
        <div className="p-4">
          <p>
            Choose one idea, draw an annotated diagram and make a list of what
            you need.
          </p>
        </div>
        <div className="p-4">
          <p className="font-bold">Create it</p>
        </div>
        <div className="p-4">
          <p>Follow your plan to build your solution.</p>
        </div>
        <div className="p-4">
          <p className="font-bold">Test it</p>
        </div>
        <div className="p-4">
          <p>
            Try it out. Does it solve the problem and meet the design criteria?
          </p>
        </div>
        <div className="p-4">
          <p className="font-bold">Improve it</p>
        </div>
        <div className="p-4">
          <p>Can it be improved? Modify and test again.</p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Four/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/Four/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;
