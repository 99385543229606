import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA1 = () => {  

  return (
    <div>
      <div>
        <BannerTitle title={"Grade 2 Overview"} />
        <div className="mt-10">
          <div className="mb-4">
            <div className="px-4">
              <p className="text-orange-700 font-bold">
                Operations and Algebraic Thinking
              </p>
            </div>
            <div className="px-8">
              <ul className="list-disc">
                <li>
                  Represent and solve problems involving addition and
                  subtraction.
                </li>
                <li>Add and subtract within 20.</li>
                <li>
                  Work with equal groups of objects to gain foundations for
                  multiplication.
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-4">
            <div className="px-4">
              <p className="text-orange-700 font-bold">
                Number and Operations in Base Ten
              </p>
            </div>
            <div className="px-8">
              <ul className="list-disc">
                <li>Understand place value.</li>
                <li>
                  Use place value understanding and properties of operations to
                  add and subtract.
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-4">
            <div className="px-4">
              <p className="text-orange-700 font-bold">Measurement and Data</p>
            </div>
            <div className="px-8">
              <ul className="list-disc">
                <li>Measure and estimate lengths in standard units.</li>
                <li>Relate addition and subtraction to length.</li>
                <li>Work with time and money.</li>
                <li>Represent and interpret data.</li>
              </ul>
            </div>
          </div>
          <div className="mb-4">
            <div className="px-4">
              <p className="text-orange-700 font-bold">Geometry</p>
            </div>
            <div className="px-8">
              <ul className="list-disc">
                <li>Reason with shapes and their attributes.</li>
              </ul>
            </div>
          </div>          
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/CommonCoreStandard">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA1;
