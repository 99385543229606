import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS16 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"The Bedroom"} />
        <div className="p-3">
          <p>
            What do we find in the bedroom that is important for us to be
            healthy?
          </p>
        </div>
        <div className="p-3">
          <strong>In the bedroom, we find</strong>
        </div>
        <div className="mt-3 p-3 justify-center text-center text-2xl">
          <strong>Bed</strong>
        </div>
        <div className="p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.SS16.Bed}
            alt="Bedroom"
            className=" w-6/12 mx-auto"
          />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/15">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/17">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS16;
