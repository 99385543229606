import React from 'react';
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Choose The Correct Answer"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">1. What is the main idea ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="The barn was dirty." />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Spot was grey and white." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="The barn was a good place to live."                
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. What did Spot like to do ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="Play in rain" />
            </div>
            <div className="flex-row">
              <Radio
                id="QA5"
                name="Q2"
                label="chase mice and cats"                
              />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="sleep in a barn" />
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. What does the “large” mean ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="big" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="happy" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="silly" />
            </div>
          </div>
        </div>
        
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/One/1">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/One/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;