import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA13 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>
            The children in Salamander Class had a vote on their best shape.
          </p>
          <p>Each shape on the chart stands for one vote.</p>
        </div>
        <div className="p-4">
          <div className="mb-6">
            <img src={IMAGES.L4.MA13.FavShape} alt="" className="w-full" />
          </div>
          <div className="mb-6">
            <div>
              <p>1. Which shape got the most votes?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                readOnly
                value={"Octagon"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>2. How many votes did the square get?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>3. How many votes did the triangle get?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>4. How many votes did the pentagon get?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>5. How many votes did the hexagon get?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>6. How many votes did the octagon get?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>7. Which shape got the fewest votes?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="9"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/12">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Four/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA13;