import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS5 = () => {
  const speakData =
    "This is a map of the world. The different colours show different areas of land, such as white for snowy places. The key on this map explains the different colours. This map shows where some animals live in the wild and also some famous landmarks.";

  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"All about map"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SS5.Map3} alt="Map" className="w-full" />
        </div>
        <div className="p-4 md:p-12 lg:p-12 mb-24">
          <p>
            This is a map of the world. The different colours show different
            areas of land, such as white for snowy places. The key on this map
            explains the different colours. This map shows where some animals
            live in the wild and also some famous landmarks.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Four/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS5;
