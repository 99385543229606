import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC32 = () => {
  const speakData =
    "What do you think is happening? What happens if you let the string go slack? What happens if you move the cup further from your ear? What happens if you move the cup further from your mouth when talking? How are we catching sound?";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Something to Think About…"} />
        <div className="p-4 text-center">
          <p>What do you think is happening?</p>
        </div>
        <div className="p-4 text-center">
          <p>What happens if you let the string go slack?</p>
        </div>
        <div className="p-4 text-center">
          <p>What happens if you move the cup further from your ear?</p>
        </div>
        <div className="p-4 text-center">
          <p>
            What happens if you move the cup further from your mouth when
            talking?
          </p>
        </div>
        <div className="p-4 text-center">
          <p>How are we catching sound?</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.SomethingToThinkAbout}
            alt="SomethingToThinkAbout"
            className="w-full"
          />
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/31">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/One/33">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC32;
