import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC2 = () => {
  const normalStyle =
    "w-full bg-gray-200 hover:bg-gray-200 px-10 py-2 rounded-full text-black m-2";
  const correctStyle =
    "w-full bg-green-500 hover:bg-green-600 px-10 py-2 rounded-full text-white m-2";
  const errorStyle =
    "w-full bg-red-500 hover:bg-red-600 px-10 py-2 rounded-full text-white m-2";
  const showCorrectSytle =
    "w-full opacity-50 bg-green-500 hover:bg-green-600 px-10 py-2 rounded-full text-white m-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("3").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "2":
        document.getElementById("3").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "3":        
        document.getElementById("3").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "4":
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("4").className = errorStyle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "5":
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("5").className = errorStyle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":        
        document.getElementById("6").className = correctStyle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "9":                
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("9").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "10":
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("10").className = errorStyle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "11":
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("11").className = errorStyle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":        
        document.getElementById("12").className = correctStyle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "13":
        document.getElementById("14").className = showCorrectSytle;
        document.getElementById("13").className = errorStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      case "14":
        document.getElementById("14").className = correctStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      case "15":                
        document.getElementById("14").className = showCorrectSytle;
        document.getElementById("15").className = errorStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div>
            <img src={IMAGES.L4.SC2.Q1} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div>
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              It's sunny.
            </button>
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              It's rainy.
            </button>
            <button onClick={checkAnswer} id="3" className={normalStyle}>
              It's cloudy.
            </button>
          </div>
        </div>
        <div className="p-4">
          <div>
            <img src={IMAGES.L4.SC2.Q2} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div>
            <button onClick={checkAnswer} id="4" className={normalStyle}>
              It's sunny.
            </button>
            <button onClick={checkAnswer} id="5" className={normalStyle}>
              It's windy.
            </button>
            <button onClick={checkAnswer} id="6" className={normalStyle}>
              It's snowy.
            </button>
          </div>
        </div>
        <div className="p-4">
          <div>
            <img src={IMAGES.L4.SC2.Q3} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div>
            <button onClick={checkAnswer} id="7" className={normalStyle}>
              It's snowy.
            </button>
            <button onClick={checkAnswer} id="8" className={normalStyle}>
              It's rainy.
            </button>
            <button onClick={checkAnswer} id="9" className={normalStyle}>
              It's sunny.
            </button>
          </div>
        </div>
        <div className="p-4">
          <div>
            <img src={IMAGES.L4.SC2.Q4} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div>
            <button onClick={checkAnswer} id="10" className={normalStyle}>
              It's rainy.
            </button>
            <button onClick={checkAnswer} id="11" className={normalStyle}>
              It's sunny.
            </button>
            <button onClick={checkAnswer} id="12" className={normalStyle}>
              It's windy.
            </button>
          </div>
        </div>
        <div className="p-4">
          <div>
            <img src={IMAGES.L4.SC2.Q5} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div>
            <button onClick={checkAnswer} id="13" className={normalStyle}>
              It's snowy.
            </button>
            <button onClick={checkAnswer} id="14" className={normalStyle}>
              It's sunny.
            </button>
            <button onClick={checkAnswer} id="15" className={normalStyle}>
              It's windy.
            </button>
          </div>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Four/1">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units/Four/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC2;
