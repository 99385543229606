import React from "react";
import { Link } from "react-router-dom";
import { Typography, Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS11 = () => {
  return (
    <div>
      <BannerTitle title={"Choose the correct answers."} />
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            1. What were Australia Aborigines used to communicate with other
            tribes who visited their site?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA1" name="Q1" label="Cave painting" />
          </div>
          <div className="flex-row">
            <Radio id="QA2" name="Q1" label="Smoke signals" />
          </div>
          <div className="flex-row">
            <Radio id="QA3" name="Q1" label="Carrier pigeon" />
          </div>
        </div>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            2. Why did Native American people use smoke signal?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio
              id="QA4"
              name="Q2"
              label="To send a message of warning, help and other important news."
            />
          </div>
          <div className="flex-row">
            <Radio id="QA5" name="Q2" label="To communicate with their gods." />
          </div>
          <div className="flex-row">
            <Radio
              id="QA6"
              name="Q2"
              label="To communicate with other tribes."
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            3. What did people use to deliver important messages through war
            zones?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Smoke Signals" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Carrier Pigeons" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Mail or Post System" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            4. What was the fastest way to communicate over long distances in
            the middle of 1800s ?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Mail or Post System" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Carrier Pigeon" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Electrical Telegraph" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            5. Why was printing press invented?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="To print more books quickly." />
          </div>
          <div className="flex-row">
            <Radio
              id="QA8"
              name="Q3"
              label="To make paper that would be last longer"
            />
          </div>
          <div className="flex-row">
            <Radio
              id="QA9"
              name="Q3"
              label="To print pictures in more books."
            />
          </div>
        </div>
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/Four/10">
          <BackButton />
        </Link>

        <Link to="/G1/SocialStudies/Units/Four/13">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default SS11;