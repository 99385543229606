import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q1} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q2} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q3} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q4} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q6} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q7} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q8} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L3.MA4.Q9} alt="QUIZ" className="w-full" />
          </div>
          <div className="flex mt-2">
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="2"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">tens</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <div className="w-2/3">
                  <div>
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full text-3xl text-center border-b-black border-2 outline-none"
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <p className="p-2">&nbsp;</p>
                  <p className="p-2 mt-2">ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Three/3">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Three/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA4;
