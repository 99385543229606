import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC7 = () => {
  const speakData =
    "Plants and animals live in habitats that suit them. They have special features that help them to survive in their habitat. This is why animals that live in cold places have thick fur, and whey animals that live in or near water are good swimmers.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"How Do I Survive?"} />
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>How Do I Survive?</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC7.HowDoISurvive}
            alt="HowDoISurvive"
            className="w-full"
          />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC7;