import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA1 = () => {
  const speakData =
    "ONE,TWO,THREE,FOUR,FIVE,SIX,SEVEN,EIGHT,NINE,TEN";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Number 1 to 10"} />
        <div className="w-full flex p-4">
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[1]} alt="Number" className="w-full" />
          </div>
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[2]} alt="Number" className="w-full" />
          </div>
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[3]} alt="Number" className="w-full" />
          </div>
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[4]} alt="Number" className="w-full" />
          </div>
        </div>
        <div className="w-full flex p-4 text-center">
          <div className="w-1/4">
            <p>ONE</p>
          </div>
          <div className="w-1/4">
            <p>TWO</p>
          </div>
          <div className="w-1/4">
            <p>THREE</p>
          </div>
          <div className="w-1/4">
            <p>FOUR</p>
          </div>
        </div>
        <div className="w-full flex p-4">
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[5]} alt="Number" className="w-full" />
          </div>
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[6]} alt="Number" className="w-full" />
          </div>
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[7]} alt="Number" className="w-full" />
          </div>
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[8]} alt="Number" className="w-full" />
          </div>
        </div>
        <div className="w-full flex p-4 text-center">
          <div className="w-1/4">
            <p>FIVE</p>
          </div>
          <div className="w-1/4">
            <p>SIX</p>
          </div>
          <div className="w-1/4">
            <p>SEVEN</p>
          </div>
          <div className="w-1/4">
            <p>EIGHT</p>
          </div>
        </div>
        <div className="w-full flex p-4">
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[9]} alt="Number" className="w-full" />
          </div>
          <div className="justify-center text-center w-1/4">
            <img src={IMAGES.L1.MA1[10]} alt="Number" className="w-full" />
          </div>
        </div>
        <div className="w-full flex p-4 text-center">
          <div className="w-1/4">
            <p>NINE</p>
          </div>
          <div className="w-1/4">
            <p>TEN</p>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Math/Units/One/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA1;
