import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC6 = () => {
  const speakData =
    "You have researched your chosen habitat and found out lots of information about it. Well done! Now you are going to ask your friends about the habitat that they researched. In your group, think some questions you can ask to find out about the other habitats. What are the conditions like in that habitat? What is the weather like? What plants and animals live there? What do they eat? Where do they live? How do they survive?";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Researching Habitats"} />
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Researching Habitats</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC6.ResearchingHabits}
            alt="ResearchingHabits"
            className="w-full"
          />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC6.OceansListen}
            alt="Ocean"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SC6.ArticListen} alt="Artic" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC6.TropicalRainforestListen}
            alt="TropicalRainforest"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SC6.DesertsListen}
            alt="Desert"
            className="w-full"
          />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/5">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC6;
