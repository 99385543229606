import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA5 = () => {
  const speakData = "Let’s practice subtraction using these two rulers. The first one is 15 cm long. The second one is 12 cm long. What is the different between the length of the two rulers. Remember in math that the word difference the result of subtracting one number from another number. So how would you write this subtraction sentence. 15 minus 12. That is correct. Let’s subtract them starting write the numbers in the one place 5 minus 2=3. Makes sure you write the answer under the ones column. The next column is 1 minus 1=0. So the different between two rulers is 3 cm.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Operations and Algebraic Thinking"} />
        <div className="p-4">
          <p>Represent and solve problems involving addition and subtraction</p>
        </div>
        <div>
          <img src={IMAGES.L1.MA5.Ruler} alt="" className="w-full" />
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg">
          <p>
            Let’s practice subtraction using these two rulers. The first one is
            15 cm long. The second one is 12 cm long. What is the different
            between the length of the two rulers. Remember in math that the word
            difference the result of subtracting one number from another number.
            So how would you write this subtraction sentence . 15 -12.That is
            correct. Let’s subtract them starting write the numbers in the one
            place 5-2=3.Makes sure you write the answer under the ones column.
            The next column is 1-1=0.So the different between two rulers is 3
            cm.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/One/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/One/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA5;
