import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"COMPLETE THE SENTENCES"} />
        <div className="p-4 border border-blue-500 m-2">
          <div className="flex justify-between">
            <div>arms</div>
            <div>axle</div>
            <div>grain</div>
            <div>flour</div>
          </div>
          <div className="flex justify-between">
            <div>sail</div>
            <div>water</div>
            <div>wheel</div>
            <div>wind</div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div className="mb-4">
              1. Watermills can make grain into
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
          <div>
            <div className="mb-4">
              2. Watermills use energy from flowing
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
          <div>
            <div className="mb-4">
              3. A watermill has a large
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
              on the outside.
            </div>
          </div>
          <div>
            <div className="mb-4">
              4. The wheel turns an
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
              inside the watermill.
            </div>
          </div>
          <div>
            <div className="mb-4">
              5. Windmill use energy from the
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
              .
            </div>
          </div>
          <div>
            <div className="mb-4">
              6. A windmill has long arms with big
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
              .
            </div>
          </div>
          <div>
            <div className="mb-4">
              7. The
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
              turn when the wind pushes the sails.
            </div>
          </div>
          <div>
            <div className="mb-4">
              8. A milestone breaks the
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={5}
              />
              .
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/One/2">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units/One/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;