import React from "react";
import { Checkbox, Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import {IMAGES} from '../../assets/index';
import { useSpeechSynthesis } from "react-speech-kit";
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const E1 = () => {
  const speakData =
    "Mom told it was time to go. Caleb put on his shoes. He put on his jacket. Then, he put on his coat. Caleb got into the car. Mom and Caleb went to the store. Caleb asked Mom where they were going. Mom said it was a surprise. She drove into the lot. She found a good spot for the car. She parked the car. Caleb took off his seat belt. He opened the door. It was the grocery store! Caleb held Mom's hand. He was happy! He wanted to buy his favorite kind of fruit. Caleb loves oranges!";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"The Grocery Store"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.GroceryStore}
            alt="GroceryStore"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Mom told it was time to go. Caleb put on his shoes. He put on his
            jacket. Then, he put on his coat. Caleb got into the car. Mom and
            Caleb went to the store. Caleb asked Mom where they were going. Mom
            said it was a surprise. She drove into the lot. She found a good
            spot for the car. She parked the car. Caleb took off his seat belt.
            He opened the door. It was the grocery store! Caleb held Mom's hand.
            He was happy! He wanted to buy his favorite kind of fruit. Caleb
            loves oranges!
          </Typography>
        </div>

        <div className="flex p-4 mb-20">
          <Checkbox color="blue" label="I can read a story." defaultChecked />
        </div>
        <br />
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/One/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/One/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;