import React, {useState} from 'react';
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import Container from './DragNDrop_Two/Container';
import { Link } from 'react-router-dom';
import { Checkbox } from '@material-tailwind/react';
import NextButton from '../../../../../components/NextButton';
import BackButton from '../../../../../components/BackButton';
import BannerTitle from '../../../../../components/BannerTitle';


const E14 = () => {
  const [isDis, setIsDis] = useState(true);
  const changeNext = () => {
    setIsDis(!isDis);
  };

  return (
    <div>
      <div>
        <BannerTitle
          title={
            "A noun is a word. It can be a person, a place, a thing, or an idea."
          }
        />
        <div className="flex p-6">Drag the words to the correct columns</div>
      </div>
      <div className="mb-20">
        <DndProvider backend={TouchBackend}>
          {/* Here, render a component that uses DND inside it */}
          <Container />
        </DndProvider>
      </div>
      <br />
      <br />
      <div className="w-full px-3">
        <div>
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can understand different kinds of nouns."
          />
        </div>
      </div>
      <br />
      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/KG/English/Units/One/13">
          <BackButton />
        </Link>
        <Link to="/KG/English/Units/One/15">
          <NextButton />
        </Link>
      </div>
    </div>
  );
}

export default E14;