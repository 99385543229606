import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS12 = () => {
  const speakData =
    "The first steam engine train was built over 200 years ago! Trains have come a long way since then.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle
          title={"Transportation: Cars, Trucks, Trains, and Buses"}
        />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            The first steam engine train was built over 200 years ago! Trains
            have come a long way since then.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SS12.Train1} alt="Train" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SS12.Train2} alt="Train" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SS12.Train3} alt="Train" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img src={IMAGES.L3.SS12.Train4} alt="Train" className="w-full" />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/11">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Three/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS12;
