import React from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS4 = () => {
  const normalStyle = "px-2 bg-gray-200 hover:bg-gray-200 rounded text-black";
  const correctStyle =
    "px-2 bg-green-500 hover:bg-green-600 rounded text-white";
  const errorStyle = "px-2 bg-red-500 hover:bg-red-600 rounded text-white";
  const showCorrectSytle =
    "px-2 opacity-50 bg-green-500 hover:bg-green-600 rounded text-white";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":                
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"DESSERTS"} />
        <div className="p-4">
          <p className="font-bold mb-4">Choose the correct words.</p>
          <p>
            1. Lucuma fruit is (
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              orange
            </button>
            /
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              green
            </button>
            ) on the inside.
          </p>
        </div>
        <div className="p-4">
          <p>
            Mazamorra morada is made with purple (
            <button onClick={checkAnswer} id="3" className={normalStyle}>
              cake
            </button>
            /
            <button onClick={checkAnswer} id="4" className={normalStyle}>
              corn
            </button>
            ).
          </p>
        </div>
        <div className="p-4">
          <p>
            3. Some people say lucuma fruit has a (
            <button onClick={checkAnswer} id="5" className={normalStyle}>
              sweet
            </button>
            /
            <button onClick={checkAnswer} id="6" className={normalStyle}>
              sour
            </button>
            ) nutty flavor.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Four/9">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;