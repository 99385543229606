import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS17 = () => {
  const speakData =
    "The needle on a compass moves freely around the dial, always searching for north. Once the compass points to north, this helps someone to see where east, south and west are located. All maps should have a compass rose";

  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Using a compass"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SS17.Compass3} alt="Compass" className="w-full" />
        </div>
        <div className="p-4 md:p-12 lg:p-12 mb-10">
          <p>
            The needle on a compass moves freely around the dial, always
            searching for north. Once the compass points to north, this helps
            someone to see where east, south and west are located.
          </p>
        </div>
        <div className="flex w-full mb-20">
          <div className="w-2/3 px-4 py-6 ml-4 bg-blue-700 text-white rounded-r-full">
            <p>All maps should have a compass rose</p>
          </div>
          <div className="w-1/3 px-3">
            <img src={IMAGES.L4.SS17.Compass4} alt="Compass" />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/16">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Four/18">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS17;
