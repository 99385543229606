import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Addition"} />
        <div className="p-4">
          <p>Count the objects, write the numbers and find the sum</p>
        </div>
        <div className="p-4">
          <div className="w-full mx-auto">
            <img src={IMAGES.L2.MA9.strawberry} alt="Addition" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-full mx-auto">
            <img src={IMAGES.L2.MA9.burger} alt="Addition" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-full mx-auto">
            <img src={IMAGES.L2.MA9.bee} alt="Addition" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-full mx-auto">
            <img src={IMAGES.L2.MA9.leaf} alt="Addition" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4 mb-20">
          <div className="w-full mx-auto">
            <img src={IMAGES.L2.MA9.bear} alt="Addition" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>        

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;