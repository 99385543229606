import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC33 = () => {
  const speakData =
    "When you speak into the cup, the sound or vibrations we make are being ‘caught’ in the first cup. These same sound or vibrations travel down the string and enter the second cup placed close to our ear. The sounds caught in this cup then travel into our ear and we can hear what our partner has said! When you let the string go slack, the vibrations don’t travel very well and we can’t hear the sounds at the other end. When we move the first cup further away from our mouth, the sound doesn’t get caught very well in the first cup, and not all of the vibrations then travel down the string. When we move the second cup further away from our ear, not all of the vibrations that travelled down the string get passed into our ear and we can’t hear the sounds very well. ";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Here’s What’s Happening…"} />
        <div className="p-4">
          <p>
            When you speak into the cup, the sound or vibrations we make are
            being ‘caught’ in the first cup.
          </p>
        </div>
        <div className="p-4 mx-4 mt-4 bg-blue-500 text-white">
          <p>
            These same sound or vibrations travel down the string and enter the
            second cup placed close to our ear. The sounds caught in this cup
            then travel into our ear and we can hear what our partner has said!
          </p>
        </div>
        <div className="p-4">
          <p>
            When you let the string go slack, the vibrations don’t travel very
            well and we can’t hear the sounds at the other end.
          </p>
        </div>
        <div className="p-4">
          <p>
            When we move the first cup further away from our mouth, the sound
            doesn’t get caught very well in the first cup, and not all of the
            vibrations then travel down the string.
          </p>
        </div>
        <div className="p-4">
          <p>
            When we move the second cup further away from our ear, not all of
            the vibrations that travelled down the string get passed into our
            ear and we can’t hear the sounds very well.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.Ear} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/32">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/One/34">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC33;
