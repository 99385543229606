import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC5 = () => {
  const speakData =
    "Introduction to Biogeology. Plants and animals need air, water, and land resources to live. Life interacts with the earth's enviroment to get the resources needed to survive and grow. These zebras drink water from a watering hole. They need water from the earth to live. Life and land are linked together. One can change the other. Plants and animals change their enviroment when they use the earth's resources. Prairie dogs dig burrows in the ground. Burrows provide shelter and safety. Prairie dogs change the land by digging burrows. Roots help hold soil in place. The roots affect the land by helping to keep it stable. People are animals. People also change the land to meet their needs. We build dams for water and energy. Dams change the flow of water on the earth's surface.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Biogeology"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SC5.Biogeology}
            alt="Biogeology"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC5.B1} alt="Biogeology" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC5.B2} alt="Biogeology" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC5.B3} alt="Biogeology" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC5.B4} alt="Biogeology" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC5.B5} alt="Biogeology" className="w-full" />
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Four/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Four/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC5;
