import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC10 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Lesson Objective"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Make observations to construct an evidence-based account that
            objects can be seen only when illuminated.
          </p>
        </div>
        <div className="p-4">
          <p>
            Plan and conduct an investigation to determine the effect of placing
            objects made with different materials in the path of a beam of
            light.
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/9">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/One/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC10;