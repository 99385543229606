import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Antonyms are Opposites!"} />
        <div className="p-4">
          <p>
            <strong>Directions:</strong> Look at the word list. Fill an
            antonym into the correct blank.
          </p>
        </div>
        <div className="p-4 border-2 border-blue-500 rounded-lg m-4">
          <div className="flex justify-between">
            <div className="">Wild</div>
            <div className="">Fast</div>
            <div className="">Ugly</div>
            <div className="">Hot</div>
          </div>
          <div className="flex justify-between">
            <div className="">Dry</div>
            <div className="">On</div>
            <div className="">Bad</div>
            <div className="">Small</div>
          </div>
        </div>
        <div className="p-4 mb-20">
          <div className="flex mb-2">
            <div className="w-1/4">
              <u>Opposite</u>
            </div>
            <div className="w-2/4"></div>
            <div className="w-1/4 mx-2"></div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>1. cold</p>
            </div>
            <div className="w-3/4">
              Summer in California are{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>2. slow</p>
            </div>
            <div className="w-3/4">
              Cheetahs run very{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>3. tame</p>
            </div>
            <div className="w-3/4">
              The gorillas in the jungle are{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>4. off</p>
            </div>
            <div className="w-3/4">
              If you are cold, turn the heat{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>5. good</p>
            </div>
            <div className="w-3/4">
              Screaming in class is{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>6. pretty</p>
            </div>
            <div className="w-3/4">
              The green troll was very{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>7. huge</p>
            </div>
            <div className="w-3/4">
              An ant is{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/4">
              <p>8. wet</p>
            </div>
            <div className="w-3/4">
              The desert is very{" "}
              <input
                type="text"
                className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Two/6">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E7;