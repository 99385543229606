import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS5 = () => {
  const speakData =
    "Fast food is the name for food we can buy already prepared and ready to eat. This kind of food usually doesn't offer much nutrition for our bodies, but many people love the way it tastes. Fast foods are often processed  and have a lot more fat, sugar, salt, and calories than our bodies need. Fast food is cheap and quick, but we should make the effort to cook our own foods so we know what is in them. Cooking at home can actually be cheaper and also healthier!";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Lesson Summary"} />
        <div className="p-4">
          <p>
            <strong>Fast food</strong> is the name for food we can buy already
            prepared and ready to eat. This kind of food usually doesn't offer
            much <strong>nutrition</strong> for our bodies, but many people love
            the way it tastes. Fast foods are often <strong>processed</strong>
             and have a lot more fat, sugar, salt, and calories than our bodies
            need.
          </p>
        </div>
        <div className="p-4">
          <p>
            <strong>Fast food</strong> is cheap and quick, but we should make
            the effort to cook our own foods so we know what is in them. Cooking
            at home can actually be cheaper and also healthier!
          </p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Two/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS5;