import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA14 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>2D Shape Attributes</p>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <p>Shape</p>
            </div>
            <div className="border border-black">
              <p>Name</p>
            </div>
            <div className="border border-black">
              <p>How many sides?</p>
            </div>
            <div className="border border-black">
              <p>How many corners?</p>
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S10} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Triangle"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"3"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"3"}
                maxLength="1"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S11} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S12} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S13} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S14} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S15} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S16} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S17} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 text-center">
            <div className="border border-black">
              <img src={IMAGES.L4.MA17.S18} alt="" className="" />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"                
                maxLength="1"
              />
            </div>
          </div>

        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/15">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA14;
