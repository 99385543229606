import React from "react";
import { Typography, Radio, Checkbox } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS15 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Read the passage and fill in the blanks."} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1 . My family live in a _____ .
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="house" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="apartment" defaultChecked />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2 . Our house is a _____ .
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA3" name="Q2" label="one story house" />
            </div>
            <div className="flex-row">
              <Radio
                id="QA4"
                name="Q2"
                label="two story house"
                defaultChecked
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3 . We cook and eat _____ .
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA5" name="Q3" label="in the kitchen" />
            </div>
            <div className="flex-row">
              <Radio
                id="QA6"
                name="Q3"
                label="in the living room"
                defaultChecked
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4.We watch TV in the _____ .
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q4" label="living room" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q4" label="bed room" defaultChecked />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              5 . My favorite room is _____ .
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q5" label="living room" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q5" label="my bed room" defaultChecked />
            </div>
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            color="blue"
            label="I can answer questions."
            defaultChecked
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/13">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/16">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS15;
