import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC1 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Enigneering design process"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Ask questions, make observations, and gather information about a
            situation people want to change to define a simple problem that can
            be solved through the development of a new or improved object or
            tool.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Design1} alt="" className="w-full" />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Four/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC1;
