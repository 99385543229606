import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";
import { IMAGES } from "../../assets";

const SS5 = () => {
  const speakData =
    "Mandy shares her crayons. Sam listens to the teacher. and Bob take turns. Mandy, Sam, and Bob follow rules. They treat the things in their classroom well. They are all good citizens. A citizen is a person who belongs to a group of people. These students are citizens of a class. They are citizens of a town, too! They help make the town a nice place to be. They do not litter. They help keep the town clean. They follow the laws. Good citizens can be great friends and neighbors.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <BannerTitle title={"Citizenship"} />
      <div className="p-4">
        <p className="text-center font-bold">Reading Comprehension</p>
      </div>
      <div className="p-4">
        <p>
          Mandy shares her crayons. Sam listens to the teacher. and Bob take
          turns. Mandy, Sam, and Bob follow rules. They treat the things in
          their classroom well. They are all good citizens. A citizen is a
          person who belongs to a group of people. These students are citizens
          of a class. They are citizens of a town, too! They help make the town
          a nice place to be. They do not litter. They help keep the town clean.
          They follow the laws. Good citizens can be great friends and
          neighbors.
        </p>
      </div>
      <div className="p-4">
        <img src={IMAGES.L1.SS5.RC1} alt="Reading" />
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/One/4">
          <BackButton />
        </Link>
        {speaking ? (
          <StopButton id="stop" type={cancel} />
        ) : (
          <PlayButton
            id="speak"
            type={() => speak({ text: speakData, rate: 0.8 })}
          />
        )}
        <Link to="/G1/SocialStudies/Units/One/6">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default SS5;
