import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Nouns are the names of things around us. Use a name form the box to
            solve each puzzle.
          </p>
        </div>
        <div>
          <img src={IMAGES.L2.E6.Quiz} alt="Quiz" className="w-full px-2" />
        </div>
        <div>
          <div className="p-4">
            <p>
              1. I have horns. I eat grass. I give milk. I am a{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}.
            </p>
          </div>
          <div className="p-4">
            <p>
              2. I have a shell. Birds lay me. I am yellow and white inside. I
              am a{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}.
            </p>
          </div>
          <div className="p-4">
            <p>
              3. I am high up in the sky. I twinkle at night. I am a{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}.
            </p>
          </div>
          <div className="p-4">
            <p>
              4. I have wheels and doors. Inside I have seats. I take people
              places. I am a{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/5">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Two/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;