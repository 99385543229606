import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC7 = () => {
  const speakData =
    "Leonardo Da Vinci is credited with discovering that sound travels in waves. Galileo Galilei discovered the relationship between frequency and pitch.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Sound Scientist"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.SoundScientist}
            alt="SoundScientist"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.SoundScientist2}
            alt="SoundScientist"
            className="w-full"
          />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/One/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC7;
