import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC18 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p className="text-center font-bold">Are the following objects opaque, translucent or transparent?</p>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.Thinking} alt="" className="w-full" />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/17">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/One/19">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC18;
