import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>
            1. During the first week, they received 12 boxes of canned soup and
            36 boxes of crackers. During the second week, they received 25 boxes
            of canned soup and 24 boxes of crackers. How many boxes of crackers
            did they receive in total?
          </p>
          <p className="font-bold text-center">32 + 24 = 60</p>
          <p className="font-bold text-center">60 boxes</p>
        </div>
        <div className="p-4">
          <p>
            2. They had 40 boxes of pasta sauce in storage and received 47 more
            boxes last month. How many boxes of pasta sauce are there?
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-48 text-center text-lg border-b-2 border-b-black"
            />
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-12 text-center text-lg border-b-2 border-b-black"
              maxLength="3"
            />{" "}
            boxes
          </p>
        </div>
        <div className="p-4">
          <p>
            3. In the storage room, there are 16 boxes of corn flakes. There
            were 19 more boxes of cheerious than corn flakes. How many boxes of
            cereal are there?
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-48 text-center text-lg border-b-2 border-b-black"
            />
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-12 text-center text-lg border-b-2 border-b-black"
              maxLength="3"
            />{" "}
            boxes
          </p>
        </div>
        <div className="w-1/3 mx-auto">
          <img src={IMAGES.L1.MA7.Q1} alt="" className="" />
        </div>
        <div className="p-4">
          <p>
            4. The food bank expected to recurit 60 volunteers. 34 men and 45
            women signed up. How many volunteers were recurited ?
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-48 text-center text-lg border-b-2 border-b-black"
            />
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-12 text-center text-lg border-b-2 border-b-black"
              maxLength="3"
            />{" "}
            volunteers
          </p>
        </div>
        <div className="p-4">
          <p>
            5. The local food bank opened 18 days in January and 21 days in
            February. If it will be opened for 15 days in March, how many is it
            open in these three months?
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-48 text-center text-lg border-b-2 border-b-black"
            />
          </p>
          <p className="font-bold text-center">
            <input
              type="text"
              className="outline-none w-12 text-center text-lg border-b-2 border-b-black"
              maxLength="3"
            />{" "}
            days
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/One/6">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/One/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA7;