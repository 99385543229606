import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E1 = () => {
  const speakData = "Spot was a large grey and white dog. Spot lived in a barn on the farm. Spot liked living in the barn. It was warm in the winter and cool in the summer. There were many mice and cats and Spot liked to chase them. Best of all, Spot had a soft place to sleep in the corner of the barn.";  
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Spot the Barn Dog"} />
        <div className="p-4">
          <p>Read the passage and answer the questions.</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.E1.SpotTheBarnDog}
            alt="SpotTheBarnDog"
            className="w-full"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Spot was a large grey and white dog. Spot lived in a barn on the
            farm. Spot liked living in the barn. It was warm in the winter and
            cool in the summer. There were many mice and cats and Spot liked to
            chase them. Best of all, Spot had a soft place to sleep in the
            corner of the barn.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/One/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/One/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;