import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ProtectIMG from "../../assets/L1/Protect.png";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS5 = () => {
  const speakData = "It is natural for family members to want to protect each other and keep each other safe. The adults in the family try to protect the children from harm. It is important for family members to protect each other’s feelings also.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Protect"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={ProtectIMG} alt="Protect" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            It is natural for family members to want to protect each other and
            keep each other safe. The adults in the family try to protect the
            children from harm. It is important for family members to protect
            each other’s feelings also.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS5;
