import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";
import { IMAGES } from "../../assets";

const SS2 = () => {
  const speakData =
    "Fast food has been around for a long time, and it seems to get more popular every day. Unfortunately, fast food often takes the place of nutritious food in our diets. In this lesson, you'll learn about fast food, why it came to be, and some of the ways it affects our health. Do you like fast food? How often do you eat fast food? What's your favorite type of fast food?  What do smiling clowns, kings and queens, golden arches, and dominos have in common? They're symbols for fast food! One well-known landmark in Georgia is called 'The Big Chicken.' It not only helps people find their way around, it's also the sign for a Kentucky Fried Chicken restaurant. A big bucket of Kentucky Fried Chicken can be a huge treat: it's fast, tasty, and doesn't cost a lot of money. This is what fast food is all about: cheap, ready-to-eat food that people enjoy eating. I bet you can think of dozens of fast foods. Do you have any favorites? Donuts? French fries? Milk shakes? Chicken nuggets? Pizza? Ready-made salad? These are the foods we turn to when we're hungry but don't have time to prepare a meal. Unfortunately, they're not always the best choices for our health.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Fast Food"} />
        <div className="p-4">
          <img src={IMAGES.L2.SS1.Fast2} alt="FastFood" />
        </div>
        <div className="p-4">
          <p>
            <strong>Fast food</strong> has been around for a long time, and it
            seems to get more popular every day. Unfortunately, fast food often
            takes the place of nutritious food in our diets. In this lesson,
            you'll learn about fast food, why it came to be, and some of the
            ways it affects our health.
          </p>
        </div>
        <div className="p-4 border border-green-500 mx-2">
          <p>
            Do you like fast food? How often do you eat fast food? What's your
            favorite type of fast food?
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.SS1.Fast3} alt="FastFood" />
        </div>
        <div className="p-4">
          <p>
            What do smiling clowns, kings and queens, golden arches, and dominos
            have in common? They're symbols for fast food! One well-known
            landmark in Georgia is called 'The Big Chicken.' It not only helps
            people find their way around, it's also the sign for a Kentucky
            Fried Chicken restaurant.
          </p>
        </div>
        <div className="p-4">
          <p>
            A big bucket of Kentucky Fried Chicken can be a huge treat: it's
            fast, tasty, and doesn't cost a lot of money. This is what 
            <strong>fast food </strong> is all about: cheap, ready-to-eat food
            that people enjoy eating.
          </p>
        </div>
        <div className="p-4">
          <p>
            I bet you can think of dozens of fast foods. Do you have any
            favorites? Donuts? French fries? Milk shakes? Chicken nuggets?
            Pizza? Ready-made salad? These are the foods we turn to when we're
            hungry but don't have time to prepare a meal. Unfortunately, they're
            not always the best choices for our health.
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Two/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Two/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
