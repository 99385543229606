import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ProvideIMG from "../../assets/L1/Provide.png";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS7 = () => {
  const speakData = "Providing for your family could mean providing your time, money, help or attention. The adults in a family earn money to provide for the family’s needs such as shelter, food and clothes. Providing also means giving your time and attention to your family. It could mean listening to each other or helping each other.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Provide"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={ProvideIMG} alt="Provide" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            Providing for your family could mean providing your time, money,
            help or attention. The adults in a family earn money to provide for
            the family’s needs such as shelter, food and clothes. Providing also
            means giving your time and attention to your family. It could mean
            listening to each other or helping each other.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/7_1">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS7;
