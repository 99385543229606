import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA10 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Subtraction"} />
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(example)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">12 - 1 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    value={"11"}
                    readOnly
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(a)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">16 - 8 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(b)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">9 - 3 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(c)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">14 - 6 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(d)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">18 - 10 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(e)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">5 - 4 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(f)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">7 - 3 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(g)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">15 - 5 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(h)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">4 - 4 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div className="mb-2">
              <p className="font-bold text-zmh-lightblue-700">(i)</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">20 - 15 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-28"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/One/9">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/One/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA10;