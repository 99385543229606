import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS18 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Around the town"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SS18.CompassQ} alt="Compass" className="w-full" />
        </div>

        <div className="p-10 mb-20">
          <div className="w-full mb-10">
            <p>
              1 . From the Fire Station to the Bakery go
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              .
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              2 . From the school to the shopping mall go
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              .
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              3. To go to school, Jack walks to
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              .
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              The Fire station is
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              of the police station.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              5 . Jack’s home is
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              of the police station.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/17">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Four/19">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS18;
