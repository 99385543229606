export const IMAGES = {
  L1: {
    MA3: {
      Solve1: require("./L1/Solve1.png"),
    },
    MA4: {
      Solve2: require("./L1/Solve2.png"),
    },
    MA5: {
      Example1: require("./L1/Example1.png"),
      Example2: require("./L1/Example2.png"),
    },
    MA7: {
      Q1: require("./L1/Q1.png"),
    },
    MA9: {
      Sub1: require("./L1/Sub1.png"),
    },
    MA10: {
      Sub2: require("./L1/Sub2.png"),
    },
    MA11: {
      Add1: require("./L1/Add1.png"),
    },
    MA14: {
      Times: require("./L1/Times.png"),
      EqualSized: require("./L1/EqualSized.png"),
      Multiply: require("./L1/Multiply.png"),
    },
    MA15: {
      HowMany: require("./L1/HowMany.png"),
      HowMany2: require("./L1/HowMany2.png"),
      HowMany3: require("./L1/HowMany3.png"),
      HowMany4: require("./L1/HowMany4.png"),
    },
    MA16: {
      QQ1: require("./L1/QQ1.png"),
      QQ2: require("./L1/QQ2.png"),
      QQ3: require("./L1/QQ3.png"),
      QQ4: require("./L1/QQ4.png"),
      QQ5: require("./L1/QQ5.png"),
      QQ6: require("./L1/QQ6.png"),
      QQ7: require("./L1/QQ7.png"),
      QQ8: require("./L1/QQ8.png"),
      QQ9: require("./L1/QQ9.png"),
      QQ10: require("./L1/QQ10.png"),
      QQ11: require("./L1/QQ11.png"),
      QQ12: require("./L1/QQ12.png"),
    },
    MA17: {
      QQQ1: require("./L1/QQQ1.png"),
      QQQ2: require("./L1/QQQ2.png"),
      QQQ3: require("./L1/QQQ3.png"),
    },
  },
  L2: {
    MA3: {
      PV1: require("./L2/PV1.png"),
      PV2: require("./L2/PV2.png"),
      PV3: require("./L2/PV3.png"),
    },
    MA4: {
      LC1: require("./L2/LC1.png"),
      LC2: require("./L2/LC2.png"),
      LC3: require("./L2/LC3.png"),
      LC5: require("./L2/LC5.png"),
      LC6: require("./L2/LC6.png"),
      LC7: require("./L2/LC7.png"),
      LC8: require("./L2/LC8.png"),
    },
    MA7: {
      Round1: require("./L2/Round1.png"),
      Round2: require("./L2/Round2.png"),
    },
    MA9: {
      QR1: require("./L2/QR1.png"),
      QR2: require("./L2/QR2.png"),
      QR3: require("./L2/QR3.png"),
    },
    MA10: {
      PLV1: require("./L2/PLV1.png"),
      PLV2: require("./L2/PLV2.png"),
      PLV3: require("./L2/PLV3.png"),
      PLV4: require("./L2/PLV4.png"),
      PLV6: require("./L2/PLV6.png"),
      PLV7: require("./L2/PLV7.png"),
    },
  },
  L3: {
    MA3: {
      GL1: require("./L3/GL1.png"),
      GL2: require("./L3/GL2.png"),
      GL3: require("./L3/GL3.png"),
    },
    MA5: {
      ME1: require("./L3/ME1.png"),
      ME2: require("./L3/ME2.png"),
      ME3: require("./L3/ME3.png"),
      ME4: require("./L3/ME4.png"),
      ME5: require("./L3/ME5.png"),
      ME6: require("./L3/ME6.png"),
    },
    MA6: {
      QQ1: require("./L3/QQ1.png"),
      QQ2: require("./L3/QQ2.png"),
      QQ3: require("./L3/QQ3.png"),
      QQ4: require("./L3/QQ4.png"),
      QQ5: require("./L3/QQ5.png"),
      QQ6: require("./L3/QQ6.png"),
      QQ7: require("./L3/QQ7.png"),
      QQ8: require("./L3/QQ8.png"),
      QQ9: require("./L3/QQ9.png"),
      QQ10: require("./L3/QQ10.png"),
      QQ11: require("./L3/QQ11.png"),
      QQ12: require("./L3/QQ12.png"),
      QQ13: require("./L3/QQ13.png"),
      QQ14: require("./L3/QQ14.png"),
      QQ15: require("./L3/QQ15.png"),
      QQ16: require("./L3/QQ16.png"),
      QQ17: require("./L3/QQ17.png"),
      QQ18: require("./L3/QQ18.png"),
      QQ19: require("./L3/QQ19.png"),
      QQ20: require("./L3/QQ20.png"),
      QQ21: require("./L3/QQ21.png"),
      QQ22: require("./L3/QQ22.png"),
      QQ23: require("./L3/QQ23.png"),
      QQ24: require("./L3/QQ24.png"),
      QQ25: require("./L3/QQ25.png"),
      QQ26: require("./L3/QQ26.png"),
      QQ27: require("./L3/QQ27.png"),
      QQ28: require("./L3/QQ28.png"),
      QQ29: require("./L3/QQ29.png"),
      QQ30: require("./L3/QQ30.png"),
    },
    MA9: {
      SL1: require("./L3/SL1.png"),
      SL2: require("./L3/SL2.png"),
      SL3: require("./L3/SL3.png"),
    },
    MA10: {
      QLQ1: require("./L3/QLQ1.png"),
      QLQ2: require("./L3/QLQ2.png"),
    },
    MA12: {
      QMQ1: require("./L3/QMQ1.png"),
      QMQ2: require("./L3/QMQ2.png"),
    },
  },
  L4: {
    MA3: {
      Analog: require("./L4/Analog.png"),
      Digital: require("./L4/Digital.png"),
      Time1: require("./L4/Time1.png"),
      Time2: require("./L4/Time2.png"),
      Time3: require("./L4/Time3.png"),
      Time4: require("./L4/Time4.png"),
      Time5: require("./L4/Time5.png"),
    },
    MA4: {
      C1: require("./L4/C1.png"),
      C2: require("./L4/C2.png"),
      C3: require("./L4/C3.png"),
      C4: require("./L4/C4.png"),
      C5: require("./L4/C5.png"),
      C6: require("./L4/C6.png"),
      C7: require("./L4/C7.png"),
      C8: require("./L4/C8.png"),
      C9: require("./L4/C9.png"),
      C10: require("./L4/C10.png"),
      C11: require("./L4/C11.png"),
      C12: require("./L4/C12.png"),
      C13: require("./L4/C13.png"),
      C14: require("./L4/C14.png"),
      C15: require("./L4/C15.png"),
      C16: require("./L4/C16.png"),
      C17: require("./L4/C17.png"),
      C18: require("./L4/C18.png"),
    },
    MA6: {
      Coin1: require("./L4/Coin1.png"),
      Coin2: require("./L4/Coin2.png"),
      Coin3: require("./L4/Coin3.png"),
      Coin4: require("./L4/Coin4.png"),
      Coin5: require("./L4/Coin5.png"),
      Coin6: require("./L4/Coin6.png"),
      Coin7: require("./L4/Coin7.png"),
      Coin8: require("./L4/Coin8.png"),
      Coin9: require("./L4/Coin9.png"),
      Coin10: require("./L4/Coin10.png"),
      Coin11: require("./L4/Coin11.png"),
      Coin12: require("./L4/Coin12.png"),
      Coin13: require("./L4/Coin13.png"),
      Coin14: require("./L4/Coin14.png"),
      Coin15: require("./L4/Coin15.png"),
      Coin16: require("./L4/Coin16.png"),
      Coin17: require("./L4/Coin17.png"),
      Coin18: require("./L4/Coin18.png"),
    },
    MA8: {
      MQ1: require("./L4/MQ1.png"),
      MQ2: require("./L4/MQ2.png"),
      MQ3: require("./L4/MQ3.png"),
      MQ4: require("./L4/MQ4.png"),
      MQ5: require("./L4/MQ5.png"),
      MQ6: require("./L4/MQ6.png"),
      MQ7: require("./L4/MQ7.png"),
    },
    MA9: {
      CanvasDraw1: require("./L4/CanvasDraw1.png"),
    },
    MA10: {
      ID1: require("./L4/ID1.png"),
      ID2: require("./L4/ID2.png"),
      ID3: require("./L4/ID3.png"),
      ID4: require("./L4/ID4.png"),
    },
    MA11: {
      Bar1: require("./L4/Bar1.png"),
      Bar2: require("./L4/Bar2.png"),
      Bar3: require("./L4/Bar3.png"),
    },
    MA14: {
      Geo1: require("./L4/Geo1.png"),
      Geo2: require("./L4/Geo2.png"),
      Geo3: require("./L4/Geo3.png"),
      Geo4: require("./L4/Geo4.png"),
      Geo5: require("./L4/Geo5.png"),
      Geo6: require("./L4/Geo6.png"),
    },
    MA15: {
      DrawCanvas2: require("./L4/DrawCanvas2.png"),
      DrawCanvas3: require("./L4/DrawCanvas3.png"),
    },
  },
};