import React, { useEffect, useRef } from "react";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import UndoButton from "../../../../../components/UndoButton";
import ResetButton from "../../../../../components/ResetButton";
import { Link } from "react-router-dom";
import CanvasDaw from "react-canvas-draw";
import BannerTitle from "../../../../../components/BannerTitle";

const SS4 = () => {
  const firstCanvasRef = useRef(null);

  useEffect(() => {
    const old_draw = JSON.parse(localStorage.getItem("draw_data"));
    if (old_draw) {
      firstCanvasRef.current.loadSaveData(old_draw);
    }
  }, []);

  const clear = () => {
    firstCanvasRef.current.clear();
  };

  const undo = () => {
    firstCanvasRef.current.undo();
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Draw and write about your own statue. "} />        
        <div className="m-3 border-2 border-black">
          <CanvasDaw
            canvasWidth={360}
            ref={firstCanvasRef}
            brushRadius={1}
            lazyRadius={0}
          />
        </div>
        
        <div className="flex p-3 justify-center mb-20">
          <UndoButton type={undo} />
          <ResetButton type={clear} />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Two/3">
            <BackButton />
          </Link>
          <Link to="/G2/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;