import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Inherit"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Make observations to construct an evidence-based account that young
            plants and animals are like, but not exactly like, their parents
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L31} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L32} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L33} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L34} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L35} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L36} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L37} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L38} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L39} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L310} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L311} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L312} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L313} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L314} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.L315} alt="" className="w-full" />
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Three/1">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC2;
