import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC28 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Lesson Objective"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Use tools and materials to design and build a device that uses light
            or sound to solve the problem of communicating over a distance.
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/27">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/One/29">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC28;