import React, { useState } from "react";
import { Checkbox, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import TheSkyIsFalling from "../../assets/Three/TheSkyIsFalling.png";
import { useSpeechSynthesis } from "react-speech-kit";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const E3 = () => {
  const speakData =
    "The nut falls from a tree. The nut hits Rabbit. Rabbit runs to tell Fox. “The sky is falling” says Rabbit. Fox runs to tell Bear. “The sky is falling” says Fox. Bear runs to tell Squirrel. “The sky is falling” says Bear. Squirrel runs to tell Deer. “The sky is falling” says Squirrel. Deer runs to tell Mouse. “The sky is falling” says Deer. Mouse runs to tell Wise Old Owl. “The sky is falling” says Mouse. “No, it is not” says Wise Old Owl. “A net fell,” says Wise Old Owl. “It hit rabbit on the head”.";  

  const [isDis, setIsDis] = useState(true);

  const { speak, speaking, cancel } = useSpeechSynthesis();

  const changeNext = () => {
    setIsDis(!isDis);
  };

  return (
    <div>
      <div>
        <BannerTitle title={"The Sky is Falling"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={TheSkyIsFalling}
            alt="TheSkyIsFalling"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            The nut falls from a tree. The nut hits Rabbit. Rabbit runs to tell
            Fox. “The sky is falling” says Rabbit. Fox runs to tell Bear. “The
            sky is falling” says Fox. Bear runs to tell Squirrel. “The sky is
            falling” says Bear. Squirrel runs to tell Deer. “The sky is falling”
            says Squirrel. Deer runs to tell Mouse. “The sky is falling” says
            Deer. Mouse runs to tell Wise Old Owl. “The sky is falling” says
            Mouse. “No, it is not” says Wise Old Owl. “A net fell,” says Wise
            Old Owl. “It hit rabbit on the head”.
          </Typography>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can understand what i read."
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;
