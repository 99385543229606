import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC1 = () => {
  const speakData =
    "Plan and conduct an investigation to determine if plants need sunlight and water LS2.A: Interdependent Relationships in Ecosystems";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"Ecosystems ; Interactions, Energy, and Dynamics"}
        />
        <div className="p-4">
          <p>
            Plan and conduct an investigation to determine if plants need
            sunlight and water LS2.A: Interdependent Relationships in Ecosystems
          </p>
        </div>
        <div className="m-2">
          <div className="flex">
            <img src={IMAGES.L2.SC1.Eco1} alt="" className="w-1/3" />
            <img src={IMAGES.L2.SC1.Eco2} alt="" className="w-1/3" />
            <img src={IMAGES.L2.SC1.Eco3} alt="" className="w-1/3" />
          </div>
          <div className="flex">
            <img src={IMAGES.L2.SC1.Eco4} alt="" className="w-1/3" />
            <img src={IMAGES.L2.SC1.Eco5} alt="" className="w-1/3" />
            <img src={IMAGES.L2.SC1.Eco6} alt="" className="w-1/3" />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Two/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC1;