import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E13 = () => {
  const normalStyle =
    "w-full px-4 py-5 mb-2 bg-gray-200 hover:bg-gray-300 text-gray-800 text-lg font-medium rounded-2xl";
  const correctStyle =
    "w-full px-4 py-5 mb-2 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";
  const errorStyle =
    "w-full px-4 py-5 mb-2 bg-red-600 hover:bg-red-700 text-white text-lg font-medium rounded-2xl";
  const showCorrectSytle =
    "w-full px-4 py-5 mb-2 opacity-50 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";

  const [isDis, setIsDis] = useState(true);

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").disabled = true;
        document.getElementById("1").className = correctStyle;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "2":
        document.getElementById("1").disabled = true;
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "3":
        document.getElementById("1").disabled = true;
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").disabled = true;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        break;
      default:
      // code block
    }
  };

  const changeNext = () => {
    setIsDis(!isDis);
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Read each groups of words."} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Choose the group that is a complete sentence.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">3.</Typography>
        </div>

        <div className="bg-white p-4">
          <button onClick={checkAnswer} id="1" className={normalStyle}>
            We go to the park
          </button>
          <button onClick={checkAnswer} id="2" className={normalStyle}>
            school go to I
          </button>
          <button onClick={checkAnswer} id="3" className={normalStyle}>
            apple I like
          </button>
        </div>

        <div className="flex p-4">
          <div className="flex items-center h-5">
            <input
              id="my-checkbox"
              aria-describedby="my-checkbox-text"
              type="checkbox"
              value=""
              onChange={changeNext}
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
          <div className="ml-2 text-sm">
            <label
              htmlFor="my-checkbox"
              className="font-medium text-gray-900 dark:text-gray-300"
            >
              I understand the basic features of print. I can follow from left
              to right. I can understand complete sentence.
            </label>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/12">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Two/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E13;
