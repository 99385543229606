import React from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Container from "./DragNDrop_Two/Container";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E7 = () => {
  return (
    <div>
      <BannerTitle title={"Drag the words on the cones in alphabetical order."} />
      <div className="mb-20">
        <DndProvider backend={TouchBackend}>
          {/* Here, render a component that uses DND inside it */}
          <Container />
        </DndProvider>
      </div>
      <div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/6">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Three/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E7;
