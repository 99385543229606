import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS4 = () => {
  const speakData = "Some people live in towns. Towns are bigger than villages. These have lots of different types of buildings including houses, shops and schools. Do you live in a town? What kind of buildings do you have in your town?";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Town"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SS4.Town} alt="Town" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            Some people live in towns. Towns are bigger than villages. These
            have lots of different types of buildings including houses, shops
            and schools. Do you live in a town? What kind of buildings do you
            have in your town?
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Two/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;
