import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const SS1 = () => {
  const speakData =    
    "Easter Island is in the Pacific Ocean far from anywhere. The island is famous for its 887 stone statues. They have big heads and little bodies. Why are the statues here? There is a story that 1,700 years ago, people were lost on the ocean. They arrives at this beautiful island. There were lots of plants and animals, so they stayed.These people made the big statues for their gods. They made the statues with stone from the middle of the island. Then, up to 250 people transported the statues across the island to the coast, where they stand today.After a few hundred years, there were too many people for this small island. They cut down many trees. This was bad for the ground, and bad for the other plants and animals, too. Soon there wasn’t much food. People started to fight and they pushes over many of the statues.Today, people come to see the statues and learn about Easter island.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Statues in Easter Island"} />
        <div className="p-4">
          <p>
            Easter Island is in the Pacific Ocean far from anywhere. The island
            is famous for its 887 stone statues. They have big heads and little
            bodies.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SS1.Statue} alt="Statue" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Why are the statues here? There is a story that 1,700 years ago,
            people were lost on the ocean. They arrives at this beautiful
            island. There were lots of plants and animals, so they stayed.{" "}
          </p>
        </div>
        <div className="p-4">
          <p>
            These people made the big statues for their gods. They made the
            statues with stone from the middle of the island. Then, up to 250
            people transported the statues across the island to the coast, where
            they stand today.
          </p>
        </div>
        <div className="p-4">
          <p>
            After a few hundred years, there were too many people for this small
            island. They cut down many trees. This was bad for the ground, and
            bad for the other plants and animals, too. Soon there wasn’t much
            food. People started to fight and they pushes over many of the
            statues.
          </p>
        </div>
        <div className="p-4">
          <p>
            Today, people come to see the statues and learn about Easter island.
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/SocialStudies/Units/Two/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1;
