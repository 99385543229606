import Home from "./pages/Home";
import KG from "./pages/KG";
import G1 from "./pages/G1";
import G2 from "./pages/G2";
import {
BrowserRouter as Router,
Route,
Routes,
} from "react-router-dom";
// English KG
import KGUnits from "./pages/KG/English/Units/Units";
import EnglishKGCC from "./pages/KG/English/Units/One/CCStandard";
import EnglishKGE1 from "./pages/KG/English/Units/One/E1";
import EnglishKGE2 from "./pages/KG/English/Units/One/E2";
import EnglishKGE3 from "./pages/KG/English/Units/One/E3";
import EnglishKGE4 from "./pages/KG/English/Units/One/E4";
import EnglishKGE5 from "./pages/KG/English/Units/One/E5";
import EnglishKGE6 from "./pages/KG/English/Units/One/E6";
import EnglishKGE7 from "./pages/KG/English/Units/One/E7";
import EnglishKGE8 from "./pages/KG/English/Units/One/E8";
import EnglishKGE9 from "./pages/KG/English/Units/One/E9";
import EnglishKGE10 from "./pages/KG/English/Units/One/E10";
import EnglishKGE11 from "./pages/KG/English/Units/One/E11";
import EnglishKGE12 from "./pages/KG/English/Units/One/E12";
import EnglishKGE13 from "./pages/KG/English/Units/One/E13";
import EnglishKGE14 from "./pages/KG/English/Units/One/E14";
import EnglishKGE15 from "./pages/KG/English/Units/One/E15";
import EnglishKG2CC from "./pages/KG/English/Units/Two/CCStandard";
import EnglishKG2E1 from "./pages/KG/English/Units/Two/E1";
import EnglishKG2E2 from "./pages/KG/English/Units/Two/E2";
import EnglishKG2E3 from "./pages/KG/English/Units/Two/E3";
import EnglishKG2E4 from "./pages/KG/English/Units/Two/E4";
import EnglishKG2E5 from "./pages/KG/English/Units/Two/E5";
import EnglishKG2E6 from "./pages/KG/English/Units/Two/E6";
import EnglishKG2E7 from "./pages/KG/English/Units/Two/E7";
import EnglishKG2E8 from "./pages/KG/English/Units/Two/E8";
import EnglishKG2E9 from "./pages/KG/English/Units/Two/E9";
import EnglishKG2E10 from "./pages/KG/English/Units/Two/E10";
import EnglishKG2E11 from "./pages/KG/English/Units/Two/E11";
import EnglishKG2E12 from "./pages/KG/English/Units/Two/E12";
import EnglishKG2E13 from "./pages/KG/English/Units/Two/E13";
import EnglishKG2E14 from "./pages/KG/English/Units/Two/E14";
import EnglishKG3CC from "./pages/KG/English/Units/Three/CCStandard";
import EnglishKG3E1 from "./pages/KG/English/Units/Three/E1";
import EnglishKG3E2 from "./pages/KG/English/Units/Three/E2";
import EnglishKG3E3 from "./pages/KG/English/Units/Three/E3";
import EnglishKG3E4 from "./pages/KG/English/Units/Three/E4";
import EnglishKG3E5 from "./pages/KG/English/Units/Three/E5";
import EnglishKG3E6 from "./pages/KG/English/Units/Three/E6";
import EnglishKG3E7 from "./pages/KG/English/Units/Three/E7";
import EnglishKG3E8 from "./pages/KG/English/Units/Three/E8";
import EnglishKG3E9 from "./pages/KG/English/Units/Three/E9";
import EnglishKG3E10 from "./pages/KG/English/Units/Three/E10";
import EnglishKG3E11 from "./pages/KG/English/Units/Three/E11";
import EnglishKG4CC from "./pages/KG/English/Units/Four/CCStandard";
import EnglishKG4E1 from "./pages/KG/English/Units/Four/E1";
import EnglishKG4E2 from "./pages/KG/English/Units/Four/E2";
import EnglishKG4E3 from "./pages/KG/English/Units/Four/E3";
import EnglishKG4E4 from "./pages/KG/English/Units/Four/E4";
import EnglishKG4E5 from "./pages/KG/English/Units/Four/E5";
import EnglishKG4E6 from "./pages/KG/English/Units/Four/E6";
import EnglishKG4E7 from "./pages/KG/English/Units/Four/E7";
import EnglishKG4E8 from "./pages/KG/English/Units/Four/E8";
import EnglishKG4E9 from "./pages/KG/English/Units/Four/E9";
import EnglishKG4E10 from "./pages/KG/English/Units/Four/E10";
import EnglishKG4E11 from "./pages/KG/English/Units/Four/E11";
// End of English KG
// Start of SocialStudies KG
import KGSocialStudiesUnits from "./pages/KG/SocialStudies/Units/Units";
import SocialStudiesKGSS1 from "./pages/KG/SocialStudies/Units/One/SS1";
import SocialStudiesKGSS2 from "./pages/KG/SocialStudies/Units/One/SS2";
import SocialStudiesKGSS3 from "./pages/KG/SocialStudies/Units/One/SS3";
import SocialStudiesKGSS4 from "./pages/KG/SocialStudies/Units/One/SS4";
import SocialStudiesKGSS5 from "./pages/KG/SocialStudies/Units/One/SS5";
import SocialStudiesKGSS6 from "./pages/KG/SocialStudies/Units/One/SS6";
import SocialStudiesKGSS7 from "./pages/KG/SocialStudies/Units/One/SS7";
import SocialStudiesKGSS71 from "./pages/KG/SocialStudies/Units/One/SS7_1";
import SocialStudiesKGSS8 from "./pages/KG/SocialStudies/Units/One/SS8";
import SocialStudiesKGSS9 from "./pages/KG/SocialStudies/Units/One/SS9";
import SocialStudiesKGSS10 from "./pages/KG/SocialStudies/Units/One/SS10";
import SocialStudiesKGSS11 from "./pages/KG/SocialStudies/Units/One/SS11";
import SocialStudiesKGSS12 from "./pages/KG/SocialStudies/Units/One/SS12";
import SocialStudiesKGSS13 from "./pages/KG/SocialStudies/Units/One/SS13";
import SocialStudiesKGSS14 from "./pages/KG/SocialStudies/Units/One/SS14";
import SocialStudiesKGSS15 from "./pages/KG/SocialStudies/Units/One/SS15";
import SocialStudiesKGSS16 from "./pages/KG/SocialStudies/Units/One/SS16";
import SocialStudiesKGSS17 from "./pages/KG/SocialStudies/Units/One/SS17";
import SocialStudiesKGSS18 from "./pages/KG/SocialStudies/Units/One/SS18";
import SocialStudiesKGSS19 from "./pages/KG/SocialStudies/Units/One/SS19";
import SocialStudiesKGSS20 from "./pages/KG/SocialStudies/Units/One/SS20";
import SocialStudiesKG2SS1 from "./pages/KG/SocialStudies/Units/Two/SS1";
import SocialStudiesKG2SS1A from "./pages/KG/SocialStudies/Units/Two/SS1A";
import SocialStudiesKG2SS1B from "./pages/KG/SocialStudies/Units/Two/SS1B";
import SocialStudiesKG2SS2 from "./pages/KG/SocialStudies/Units/Two/SS2";
import SocialStudiesKG2SS3 from "./pages/KG/SocialStudies/Units/Two/SS3";
import SocialStudiesKG2SS4 from "./pages/KG/SocialStudies/Units/Two/SS4";
import SocialStudiesKG2SS5 from "./pages/KG/SocialStudies/Units/Two/SS5";
import SocialStudiesKG2SS6 from "./pages/KG/SocialStudies/Units/Two/SS6";
import SocialStudiesKG2SS7 from "./pages/KG/SocialStudies/Units/Two/SS7";
import SocialStudiesKG2SS8 from "./pages/KG/SocialStudies/Units/Two/SS8";
import SocialStudiesKG2SS9 from "./pages/KG/SocialStudies/Units/Two/SS9";
import SocialStudiesKG2SS10 from "./pages/KG/SocialStudies/Units/Two/SS10";
import SocialStudiesKG2SS11 from "./pages/KG/SocialStudies/Units/Two/SS11";
import SocialStudiesKG2SS12 from "./pages/KG/SocialStudies/Units/Two/SS12";
import SocialStudiesKG2SS13 from "./pages/KG/SocialStudies/Units/Two/SS13";
import SocialStudiesKG2SS14 from "./pages/KG/SocialStudies/Units/Two/SS14";
import SocialStudiesKG2SS15 from "./pages/KG/SocialStudies/Units/Two/SS15";
import SocialStudiesKG2SS16 from "./pages/KG/SocialStudies/Units/Two/SS16";
import SocialStudiesKG2SS17 from "./pages/KG/SocialStudies/Units/Two/SS17";
import SocialStudiesKG2SS18 from "./pages/KG/SocialStudies/Units/Two/SS18";
import SocialStudiesKG2SS19 from "./pages/KG/SocialStudies/Units/Two/SS19";
import SocialStudiesKG2SS20 from "./pages/KG/SocialStudies/Units/Two/SS20";
import SocialStudiesKG2SS21 from "./pages/KG/SocialStudies/Units/Two/SS21";
import SocialStudiesKG2SS22 from "./pages/KG/SocialStudies/Units/Two/SS22";
import SocialStudiesKG2SS23 from "./pages/KG/SocialStudies/Units/Two/SS23";
import SocialStudiesKG2SS24 from "./pages/KG/SocialStudies/Units/Two/SS24";
import SocialStudiesKG2SS25 from "./pages/KG/SocialStudies/Units/Two/SS25";
import SocialStudiesKG2SS26 from "./pages/KG/SocialStudies/Units/Two/SS26";
import SocialStudiesKG2SS27 from "./pages/KG/SocialStudies/Units/Two/SS27";
import SocialStudiesKG2SS28 from "./pages/KG/SocialStudies/Units/Two/SS28";
import SocialStudiesKG3SS1 from "./pages/KG/SocialStudies/Units/Three/SS1";
import SocialStudiesKG3SS1A from "./pages/KG/SocialStudies/Units/Three/SS1A";
import SocialStudiesKG3SS2 from "./pages/KG/SocialStudies/Units/Three/SS2";
import SocialStudiesKG3SS3 from "./pages/KG/SocialStudies/Units/Three/SS3";
import SocialStudiesKG3SS4 from "./pages/KG/SocialStudies/Units/Three/SS4";
import SocialStudiesKG3SS5 from "./pages/KG/SocialStudies/Units/Three/SS5";
import SocialStudiesKG3SS6 from "./pages/KG/SocialStudies/Units/Three/SS6";
import SocialStudiesKG3SS7 from "./pages/KG/SocialStudies/Units/Three/SS7";
import SocialStudiesKG3SS8 from "./pages/KG/SocialStudies/Units/Three/SS8";
import SocialStudiesKG3SS9 from "./pages/KG/SocialStudies/Units/Three/SS9";
import SocialStudiesKG3SS10 from "./pages/KG/SocialStudies/Units/Three/SS10";
import SocialStudiesKG3SS11 from "./pages/KG/SocialStudies/Units/Three/SS11";
import SocialStudiesKG3SS12 from "./pages/KG/SocialStudies/Units/Three/SS12";
import SocialStudiesKG3SS13 from "./pages/KG/SocialStudies/Units/Three/SS13";
import SocialStudiesKG3SS14 from "./pages/KG/SocialStudies/Units/Three/SS14";
import SocialStudiesKG3SS15 from "./pages/KG/SocialStudies/Units/Three/SS15";
import SocialStudiesKG3SS16 from "./pages/KG/SocialStudies/Units/Three/SS16";
import SocialStudiesKG3SS17 from "./pages/KG/SocialStudies/Units/Three/SS17";
import SocialStudiesKG3SS18 from "./pages/KG/SocialStudies/Units/Three/SS18";
import SocialStudiesKG3SS19 from "./pages/KG/SocialStudies/Units/Three/SS19";
import SocialStudiesKG3SS20 from "./pages/KG/SocialStudies/Units/Three/SS20";
import SocialStudiesKG3SS21 from "./pages/KG/SocialStudies/Units/Three/SS21";
import SocialStudiesKG3SS22 from "./pages/KG/SocialStudies/Units/Three/SS22";
import SocialStudiesKG3SS23 from "./pages/KG/SocialStudies/Units/Three/SS23";
import SocialStudiesKG3SS24 from "./pages/KG/SocialStudies/Units/Three/SS24";
import SocialStudiesKG4SS1 from "./pages/KG/SocialStudies/Units/Four/SS1";
import SocialStudiesKG4SS1A from "./pages/KG/SocialStudies/Units/Four/SS1A";
import SocialStudiesKG4SS2 from "./pages/KG/SocialStudies/Units/Four/SS2";
import SocialStudiesKG4SS3 from "./pages/KG/SocialStudies/Units/Four/SS3";
import SocialStudiesKG4SS4 from "./pages/KG/SocialStudies/Units/Four/SS4";
import SocialStudiesKG4SS5 from "./pages/KG/SocialStudies/Units/Four/SS5";
import SocialStudiesKG4SS6 from "./pages/KG/SocialStudies/Units/Four/SS6";
import SocialStudiesKG4SS7 from "./pages/KG/SocialStudies/Units/Four/SS7";
import SocialStudiesKG4SS8 from "./pages/KG/SocialStudies/Units/Four/SS8";
import SocialStudiesKG4SS9 from "./pages/KG/SocialStudies/Units/Four/SS9";
import SocialStudiesKG4SS10 from "./pages/KG/SocialStudies/Units/Four/SS10";
import SocialStudiesKG4SS11 from "./pages/KG/SocialStudies/Units/Four/SS11";
import SocialStudiesKG4SS12 from "./pages/KG/SocialStudies/Units/Four/SS12";
import SocialStudiesKG4SS13 from "./pages/KG/SocialStudies/Units/Four/SS13";
import SocialStudiesKG4SS14 from "./pages/KG/SocialStudies/Units/Four/SS14";
import SocialStudiesKG4SS15 from "./pages/KG/SocialStudies/Units/Four/SS15";
import SocialStudiesKG4SS16 from "./pages/KG/SocialStudies/Units/Four/SS16";
import SocialStudiesKG4SS17 from "./pages/KG/SocialStudies/Units/Four/SS17";
import SocialStudiesKG4SS18 from "./pages/KG/SocialStudies/Units/Four/SS18";
import SocialStudiesKG4SS19 from "./pages/KG/SocialStudies/Units/Four/SS19";
import SocialStudiesKG4SS20 from "./pages/KG/SocialStudies/Units/Four/SS20";
import SocialStudiesKG4SS21 from "./pages/KG/SocialStudies/Units/Four/SS21";
import SocialStudiesKG4SS22 from "./pages/KG/SocialStudies/Units/Four/SS22";
import SocialStudiesKG4SS23 from "./pages/KG/SocialStudies/Units/Four/SS23";
import SocialStudiesKG4SS24 from "./pages/KG/SocialStudies/Units/Four/SS24";
// End of SocialStudies KG
// Start of Science KG
import KGScienceUnits from "./pages/KG/Science/Units/Units";
import ScienceKGSC1 from "./pages/KG/Science/Units/One/SC1";
import ScienceKGSC2 from "./pages/KG/Science/Units/One/SC2";
import ScienceKGSC3 from "./pages/KG/Science/Units/One/SC3";
import ScienceKGSC4 from "./pages/KG/Science/Units/One/SC4";
import ScienceKGSC5 from "./pages/KG/Science/Units/One/SC5";
import ScienceKGSC6 from "./pages/KG/Science/Units/One/SC6";
import ScienceKGSC7 from "./pages/KG/Science/Units/One/SC7";
import ScienceKGSC8 from "./pages/KG/Science/Units/One/SC8";
import ScienceKGSC9 from "./pages/KG/Science/Units/One/SC9";
import ScienceKGSC10 from "./pages/KG/Science/Units/One/SC10";
import ScienceKGSC11 from "./pages/KG/Science/Units/One/SC11";
import ScienceKGSC12 from "./pages/KG/Science/Units/One/SC12";
import ScienceKGSC13 from "./pages/KG/Science/Units/One/SC13";
import ScienceKG2SC1 from "./pages/KG/Science/Units/Two/SC1";
import ScienceKG2SC2 from "./pages/KG/Science/Units/Two/SC2";
import ScienceKG2SC3 from "./pages/KG/Science/Units/Two/SC3";
import ScienceKG2SC4 from "./pages/KG/Science/Units/Two/SC4";
import ScienceKG2SC5 from "./pages/KG/Science/Units/Two/SC5";
import ScienceKG2SC6 from "./pages/KG/Science/Units/Two/SC6";
import ScienceKG2SC7 from "./pages/KG/Science/Units/Two/SC7";
import ScienceKG2SC8 from "./pages/KG/Science/Units/Two/SC8";
import ScienceKG2SC9 from "./pages/KG/Science/Units/Two/SC9";
import ScienceKG2SC10 from "./pages/KG/Science/Units/Two/SC10";
import ScienceKG2SC11 from "./pages/KG/Science/Units/Two/SC11";
import ScienceKG2SC12 from "./pages/KG/Science/Units/Two/SC12";
import ScienceKG2SC13 from "./pages/KG/Science/Units/Two/SC13";
import ScienceKG2SC14 from "./pages/KG/Science/Units/Two/SC14";
import ScienceKG2SC15 from "./pages/KG/Science/Units/Two/SC15";
import ScienceKG2SC16 from "./pages/KG/Science/Units/Two/SC16";
import ScienceKG2SC17 from "./pages/KG/Science/Units/Two/SC17";
import ScienceKG2SC18 from "./pages/KG/Science/Units/Two/SC18";
import ScienceKG2SC19 from "./pages/KG/Science/Units/Two/SC19";
import ScienceKG3SC1 from "./pages/KG/Science/Units/Three/SC1";
import ScienceKG3SC2 from "./pages/KG/Science/Units/Three/SC2";
import ScienceKG3SC3 from "./pages/KG/Science/Units/Three/SC3";
import ScienceKG3SC4 from "./pages/KG/Science/Units/Three/SC4";
import ScienceKG3SC5 from "./pages/KG/Science/Units/Three/SC5";
import ScienceKG3SC6 from "./pages/KG/Science/Units/Three/SC6";
import ScienceKG3SC7 from "./pages/KG/Science/Units/Three/SC7";
import ScienceKG3SC8 from "./pages/KG/Science/Units/Three/SC8";
import ScienceKG3SC9 from "./pages/KG/Science/Units/Three/SC9";
import ScienceKG3SC10 from "./pages/KG/Science/Units/Three/SC10";
import ScienceKG3SC11 from "./pages/KG/Science/Units/Three/SC11";
import ScienceKG3SC12 from "./pages/KG/Science/Units/Three/SC12";
import ScienceKG4SC1 from "./pages/KG/Science/Units/Four/SC1";
import ScienceKG4SC2 from "./pages/KG/Science/Units/Four/SC2";
import ScienceKG4SC3 from "./pages/KG/Science/Units/Four/SC3";
import ScienceKG4SC4 from "./pages/KG/Science/Units/Four/SC4";
import ScienceKG4SC5 from "./pages/KG/Science/Units/Four/SC5";
import ScienceKG4SC6 from "./pages/KG/Science/Units/Four/SC6";
import ScienceKG4SC7 from "./pages/KG/Science/Units/Four/SC7";
import ScienceKG4SC8 from "./pages/KG/Science/Units/Four/SC8";
import ScienceKG4SC9 from "./pages/KG/Science/Units/Four/SC9";
import ScienceKG4SC10 from "./pages/KG/Science/Units/Four/SC10";
import ScienceKG4SC11 from "./pages/KG/Science/Units/Four/SC11";
import ScienceKG4SC12 from "./pages/KG/Science/Units/Four/SC12";
import ScienceKG4SC13 from "./pages/KG/Science/Units/Four/SC13";
import ScienceKG4SC14 from "./pages/KG/Science/Units/Four/SC14";
import ScienceKG4SC15 from "./pages/KG/Science/Units/Four/SC15";
import ScienceKG4SC16 from "./pages/KG/Science/Units/Four/SC16";
import ScienceKG4SC17 from "./pages/KG/Science/Units/Four/SC17";
import ScienceKG4SC18 from "./pages/KG/Science/Units/Four/SC18";
// End of Science KG
// Start of Math KG
import KGMathUnits from "./pages/KG/Math/Units/Units";
import MathKG1CC from "./pages/KG/Math/Units/One/CC";
import MathKG1MA1 from "./pages/KG/Math/Units/One/MA1";
import MathKG1MA2 from "./pages/KG/Math/Units/One/MA2";
import MathKG1MA3 from "./pages/KG/Math/Units/One/MA3";
import MathKG1MA4 from "./pages/KG/Math/Units/One/MA4";
import MathKG1MA5 from "./pages/KG/Math/Units/One/MA5";
import MathKG1MA6 from "./pages/KG/Math/Units/One/MA6";
import MathKG1MA7 from "./pages/KG/Math/Units/One/MA7";
import MathKG1MA8 from "./pages/KG/Math/Units/One/MA8";
import MathKG1MA9 from "./pages/KG/Math/Units/One/MA9";
import MathKG1MA10 from "./pages/KG/Math/Units/One/MA10";
import MathKG1MA11 from "./pages/KG/Math/Units/One/MA11";
import MathKG1MA12 from "./pages/KG/Math/Units/One/MA12";
import MathKG2CC from "./pages/KG/Math/Units/Two/CC";
import MathKG2MA1 from "./pages/KG/Math/Units/Two/MA1";
import MathKG2MA2 from "./pages/KG/Math/Units/Two/MA2";
import MathKG2MA3 from "./pages/KG/Math/Units/Two/MA3";
import MathKG2MA4 from "./pages/KG/Math/Units/Two/MA4";
import MathKG2MA5 from "./pages/KG/Math/Units/Two/MA5";
import MathKG2MA6 from "./pages/KG/Math/Units/Two/MA6";
import MathKG2MA7 from "./pages/KG/Math/Units/Two/MA7";
import MathKG2MA8 from "./pages/KG/Math/Units/Two/MA8";
import MathKG2MA9 from "./pages/KG/Math/Units/Two/MA9";
import MathKG2MA10 from "./pages/KG/Math/Units/Two/MA10";
import MathKG2MA11 from "./pages/KG/Math/Units/Two/MA11";
import MathKG3CC from "./pages/KG/Math/Units/Three/CC";
import MathKG3MA1 from "./pages/KG/Math/Units/Three/MA1";
import MathKG3MA2 from "./pages/KG/Math/Units/Three/MA2";
import MathKG3MA3 from "./pages/KG/Math/Units/Three/MA3";
import MathKG3MA4 from "./pages/KG/Math/Units/Three/MA4";
import MathKG3MA5 from "./pages/KG/Math/Units/Three/MA5";
import MathKG3MA6 from "./pages/KG/Math/Units/Three/MA6";
import MathKG3MA7 from "./pages/KG/Math/Units/Three/MA7";
import MathKG3MA8 from "./pages/KG/Math/Units/Three/MA8";
import MathKG3MA9 from "./pages/KG/Math/Units/Three/MA9";
import MathKG3MA10 from "./pages/KG/Math/Units/Three/MA10";
import MathKG3MA11 from "./pages/KG/Math/Units/Three/MA11";
import MathKG3MA12 from "./pages/KG/Math/Units/Three/MA12";
import MathKG3MA13 from "./pages/KG/Math/Units/Three/MA13";
import MathKG4CC from "./pages/KG/Math/Units/Four/CC";
import MathKG4MA1 from "./pages/KG/Math/Units/Four/MA1";
import MathKG4MA2 from "./pages/KG/Math/Units/Four/MA2";
import MathKG4MA3 from "./pages/KG/Math/Units/Four/MA3";
import MathKG4MA4 from "./pages/KG/Math/Units/Four/MA4";
import MathKG4MA5 from "./pages/KG/Math/Units/Four/MA5";
import MathKG4MA6 from "./pages/KG/Math/Units/Four/MA6";
import MathKG4MA7 from "./pages/KG/Math/Units/Four/MA7";
import MathKG4MA8 from "./pages/KG/Math/Units/Four/MA8";
import MathKG4MA9 from "./pages/KG/Math/Units/Four/MA9";
import MathKG4MA10 from "./pages/KG/Math/Units/Four/MA10";
import MathKG4MA11 from "./pages/KG/Math/Units/Four/MA11";
import MathKG4MA12 from "./pages/KG/Math/Units/Four/MA12";
import MathKG4MA13 from "./pages/KG/Math/Units/Four/MA13";
import MathKG4MA14 from "./pages/KG/Math/Units/Four/MA14";
// End of Math KG
// Start of Math G1
import G1MathUnits from "./pages/G1/Math/Units/Units";
import MathG11CC from "./pages/G1/Math/Units/One/CC";
import MathG11MA1 from "./pages/G1/Math/Units/One/MA1";
import MathG11MA2 from "./pages/G1/Math/Units/One/MA2";
import MathG11MA3 from "./pages/G1/Math/Units/One/MA3";
import MathG11MA4 from "./pages/G1/Math/Units/One/MA4";
import MathG11MA5 from "./pages/G1/Math/Units/One/MA5";
import MathG11MA6 from "./pages/G1/Math/Units/One/MA6";
import MathG11MA7 from "./pages/G1/Math/Units/One/MA7";
import MathG11MA8 from "./pages/G1/Math/Units/One/MA8";
import MathG11MA9 from "./pages/G1/Math/Units/One/MA9";
import MathG11MA10 from "./pages/G1/Math/Units/One/MA10";
import MathG11MA11 from "./pages/G1/Math/Units/One/MA11";
import MathG12CC from "./pages/G1/Math/Units/Two/CC";
import MathG12MA1 from "./pages/G1/Math/Units/Two/MA1";
import MathG12MA2 from "./pages/G1/Math/Units/Two/MA2";
import MathG12MA3 from "./pages/G1/Math/Units/Two/MA3";
import MathG12MA4 from "./pages/G1/Math/Units/Two/MA4";
import MathG12MA5 from "./pages/G1/Math/Units/Two/MA5";
import MathG12MA6 from "./pages/G1/Math/Units/Two/MA6";
import MathG12MA7 from "./pages/G1/Math/Units/Two/MA7";
import MathG12MA8 from "./pages/G1/Math/Units/Two/MA8";
import MathG12MA9 from "./pages/G1/Math/Units/Two/MA9";
import MathG12MA10 from "./pages/G1/Math/Units/Two/MA10";
import MathG12MA11 from "./pages/G1/Math/Units/Two/MA11";
import MathG13CC from "./pages/G1/Math/Units/Three/CC";
import MathG13MA1 from "./pages/G1/Math/Units/Three/MA1";
import MathG13MA2 from "./pages/G1/Math/Units/Three/MA2";
import MathG13MA3 from "./pages/G1/Math/Units/Three/MA3";
import MathG13MA4 from "./pages/G1/Math/Units/Three/MA4";
import MathG13MA5 from "./pages/G1/Math/Units/Three/MA5";
import MathG13MA6 from "./pages/G1/Math/Units/Three/MA6";
import MathG13MA7 from "./pages/G1/Math/Units/Three/MA7";
import MathG13MA8 from "./pages/G1/Math/Units/Three/MA8";
import MathG13MA9 from "./pages/G1/Math/Units/Three/MA9";
import MathG13MA10 from "./pages/G1/Math/Units/Three/MA10";
import MathG13MA11 from "./pages/G1/Math/Units/Three/MA11";
import MathG13MA12 from "./pages/G1/Math/Units/Three/MA12";
import MathG13MA13 from "./pages/G1/Math/Units/Three/MA13";
import MathG13MA14 from "./pages/G1/Math/Units/Three/MA14";
import MathG13MA15 from "./pages/G1/Math/Units/Three/MA15";
import MathG13MA16 from "./pages/G1/Math/Units/Three/MA16";
import MathG14CC from "./pages/G1/Math/Units/Four/CC";
import MathG14MA1 from "./pages/G1/Math/Units/Four/MA1";
import MathG14MA2 from "./pages/G1/Math/Units/Four/MA2";
import MathG14MA3 from "./pages/G1/Math/Units/Four/MA3";
import MathG14MA4 from "./pages/G1/Math/Units/Four/MA4";
import MathG14MA5 from "./pages/G1/Math/Units/Four/MA5";
import MathG14MA6 from "./pages/G1/Math/Units/Four/MA6";
import MathG14MA7 from "./pages/G1/Math/Units/Four/MA7";
import MathG14MA8 from "./pages/G1/Math/Units/Four/MA8";
import MathG14MA9 from "./pages/G1/Math/Units/Four/MA9";
import MathG14MA10 from "./pages/G1/Math/Units/Four/MA10";
import MathG14MA11 from "./pages/G1/Math/Units/Four/MA11";
import MathG14MA12 from "./pages/G1/Math/Units/Four/MA12";
import MathG14MA13 from "./pages/G1/Math/Units/Four/MA13";
import MathG14MA14 from "./pages/G1/Math/Units/Four/MA14";
import MathG14MA15 from "./pages/G1/Math/Units/Four/MA15";
import MathG14MA16 from "./pages/G1/Math/Units/Four/MA16";
import MathG14MA17 from "./pages/G1/Math/Units/Four/MA17";
// End of Math G1
// Start of Math G2
import G2MathUnits from "./pages/G2/Math/Units/Units";
import MathG21CC from "./pages/G2/Math/Units/One/CC";
import MathG21MA1 from "./pages/G2/Math/Units/One/MA1";
import MathG21MA2 from "./pages/G2/Math/Units/One/MA2";
import MathG21MA3 from "./pages/G2/Math/Units/One/MA3";
import MathG21MA4 from "./pages/G2/Math/Units/One/MA4";
import MathG21MA5 from "./pages/G2/Math/Units/One/MA5";
import MathG21MA6 from "./pages/G2/Math/Units/One/MA6";
import MathG21MA7 from "./pages/G2/Math/Units/One/MA7";
import MathG21MA8 from "./pages/G2/Math/Units/One/MA8";
import MathG21MA9 from "./pages/G2/Math/Units/One/MA9";
import MathG21MA10 from "./pages/G2/Math/Units/One/MA10";
import MathG21MA11 from "./pages/G2/Math/Units/One/MA11";
import MathG21MA12 from "./pages/G2/Math/Units/One/MA12";
import MathG21MA13 from "./pages/G2/Math/Units/One/MA13";
import MathG21MA14 from "./pages/G2/Math/Units/One/MA14";
import MathG21MA15 from "./pages/G2/Math/Units/One/MA15";
import MathG21MA16 from "./pages/G2/Math/Units/One/MA16";
import MathG21MA17 from "./pages/G2/Math/Units/One/MA17";
import MathG22CC from "./pages/G2/Math/Units/Two/CC";
import MathG22MA1 from "./pages/G2/Math/Units/Two/MA1";
import MathG22MA2 from "./pages/G2/Math/Units/Two/MA2";
import MathG22MA3 from "./pages/G2/Math/Units/Two/MA3";
import MathG22MA4 from "./pages/G2/Math/Units/Two/MA4";
import MathG22MA5 from "./pages/G2/Math/Units/Two/MA5";
import MathG22MA6 from "./pages/G2/Math/Units/Two/MA6";
import MathG22MA7 from "./pages/G2/Math/Units/Two/MA7";
import MathG22MA8 from "./pages/G2/Math/Units/Two/MA8";
import MathG22MA9 from "./pages/G2/Math/Units/Two/MA9";
import MathG22MA10 from "./pages/G2/Math/Units/Two/MA10";
import MathG22MA11 from "./pages/G2/Math/Units/Two/MA11";
import MathG22MA12 from "./pages/G2/Math/Units/Two/MA12";
import MathG23CC from "./pages/G2/Math/Units/Three/CC";
import MathG23MA1 from "./pages/G2/Math/Units/Three/MA1";
import MathG23MA2 from "./pages/G2/Math/Units/Three/MA2";
import MathG23MA3 from "./pages/G2/Math/Units/Three/MA3";
import MathG23MA4 from "./pages/G2/Math/Units/Three/MA4";
import MathG23MA5 from "./pages/G2/Math/Units/Three/MA5";
import MathG23MA6 from "./pages/G2/Math/Units/Three/MA6";
import MathG23MA7 from "./pages/G2/Math/Units/Three/MA7";
import MathG23MA8 from "./pages/G2/Math/Units/Three/MA8";
import MathG23MA9 from "./pages/G2/Math/Units/Three/MA9";
import MathG23MA10 from "./pages/G2/Math/Units/Three/MA10";
import MathG23MA11 from "./pages/G2/Math/Units/Three/MA11";
import MathG23MA12 from "./pages/G2/Math/Units/Three/MA12";
import MathG24CC from "./pages/G2/Math/Units/Four/CC";
import MathG24MA1 from "./pages/G2/Math/Units/Four/MA1";
import MathG24MA2 from "./pages/G2/Math/Units/Four/MA2";
import MathG24MA3 from "./pages/G2/Math/Units/Four/MA3";
import MathG24MA4 from "./pages/G2/Math/Units/Four/MA4";
import MathG24MA5 from "./pages/G2/Math/Units/Four/MA5";
import MathG24MA6 from "./pages/G2/Math/Units/Four/MA6";
import MathG24MA7 from "./pages/G2/Math/Units/Four/MA7";
import MathG24MA8 from "./pages/G2/Math/Units/Four/MA8";
import MathG24MA9 from "./pages/G2/Math/Units/Four/MA9";
import MathG24MA10 from "./pages/G2/Math/Units/Four/MA10";
import MathG24MA11 from "./pages/G2/Math/Units/Four/MA11";
import MathG24MA12 from "./pages/G2/Math/Units/Four/MA12";
import MathG24MA13 from "./pages/G2/Math/Units/Four/MA13";
import MathG24MA14 from "./pages/G2/Math/Units/Four/MA14";
import MathG24MA15 from "./pages/G2/Math/Units/Four/MA15";
import MathG24MA16 from "./pages/G2/Math/Units/Four/MA16";
import MathG24MA17 from "./pages/G2/Math/Units/Four/MA17";
// End of Math G2

// English G1
import G1Units from "./pages/G1/English/Units/Units";
import EnglishG1CC from "./pages/G1/English/Units/One/CCStandard";
import EnglishG1E1 from "./pages/G1/English/Units/One/E1";
import EnglishG1E2 from "./pages/G1/English/Units/One/E2";
import EnglishG1E3 from "./pages/G1/English/Units/One/E3";
import EnglishG1E4 from "./pages/G1/English/Units/One/E4";
import EnglishG1E5 from "./pages/G1/English/Units/One/E5";
import EnglishG1E6 from "./pages/G1/English/Units/One/E6";
import EnglishG1E7 from "./pages/G1/English/Units/One/E7";
import EnglishG1E8 from "./pages/G1/English/Units/One/E8";
import EnglishG12CC from "./pages/G1/English/Units/Two/CCStandard";
import EnglishG12E1 from "./pages/G1/English/Units/Two/E1";
import EnglishG12E2 from "./pages/G1/English/Units/Two/E2";
import EnglishG12E3 from "./pages/G1/English/Units/Two/E3";
import EnglishG12E4 from "./pages/G1/English/Units/Two/E4";
import EnglishG12E5 from "./pages/G1/English/Units/Two/E5";
import EnglishG12E6 from "./pages/G1/English/Units/Two/E6";
import EnglishG12E7 from "./pages/G1/English/Units/Two/E7";
import EnglishG12E8 from "./pages/G1/English/Units/Two/E8";
import EnglishG12E9 from "./pages/G1/English/Units/Two/E9";
import EnglishG12E10 from "./pages/G1/English/Units/Two/E10";
import EnglishG13CC from "./pages/G1/English/Units/Three/CCStandard";
import EnglishG13E1 from "./pages/G1/English/Units/Three/E1";
import EnglishG13E2 from "./pages/G1/English/Units/Three/E2";
import EnglishG13E3 from "./pages/G1/English/Units/Three/E3";
import EnglishG13E4 from "./pages/G1/English/Units/Three/E4";
import EnglishG13E5 from "./pages/G1/English/Units/Three/E5";
import EnglishG13E6 from "./pages/G1/English/Units/Three/E6";
import EnglishG13E7 from "./pages/G1/English/Units/Three/E7";
import EnglishG13E8 from "./pages/G1/English/Units/Three/E8";
import EnglishG13E9 from "./pages/G1/English/Units/Three/E9";
import EnglishG14CC from "./pages/G1/English/Units/Four/CCStandard";
import EnglishG14E1 from "./pages/G1/English/Units/Four/E1";
import EnglishG14E2 from "./pages/G1/English/Units/Four/E2";
import EnglishG14E3 from "./pages/G1/English/Units/Four/E3";
import EnglishG14E4 from "./pages/G1/English/Units/Four/E4";
import EnglishG14E5 from "./pages/G1/English/Units/Four/E5";
import EnglishG14E6 from "./pages/G1/English/Units/Four/E6";
import EnglishG14E7 from "./pages/G1/English/Units/Four/E7";
import EnglishG14E8 from "./pages/G1/English/Units/Four/E8";
import EnglishG14E9 from "./pages/G1/English/Units/Four/E9";
// End of English G1
// Start of Science G1
import G1ScienceUnits from "./pages/G1/Science/Units/Units";
import ScienceG1SC1 from "./pages/G1/Science/Units/One/SC1";
import ScienceG1SC2 from "./pages/G1/Science/Units/One/SC2";
import ScienceG1SC3 from "./pages/G1/Science/Units/One/SC3";
import ScienceG1SC4 from "./pages/G1/Science/Units/One/SC4";
import ScienceG1SC5 from "./pages/G1/Science/Units/One/SC5";
import ScienceG1SC6 from "./pages/G1/Science/Units/One/SC6";
import ScienceG1SC7 from "./pages/G1/Science/Units/One/SC7";
import ScienceG1SC8 from "./pages/G1/Science/Units/One/SC8";
import ScienceG1SC9 from "./pages/G1/Science/Units/One/SC9";
import ScienceG1SC10 from "./pages/G1/Science/Units/One/SC10";
import ScienceG1SC11 from "./pages/G1/Science/Units/One/SC11";
import ScienceG1SC12 from "./pages/G1/Science/Units/One/SC12";
import ScienceG1SC13 from "./pages/G1/Science/Units/One/SC13";
import ScienceG1SC14 from "./pages/G1/Science/Units/One/SC14";
import ScienceG1SC15 from "./pages/G1/Science/Units/One/SC15";
import ScienceG1SC16 from "./pages/G1/Science/Units/One/SC16";
import ScienceG1SC17 from "./pages/G1/Science/Units/One/SC17";
import ScienceG1SC18 from "./pages/G1/Science/Units/One/SC18";
import ScienceG1SC19 from "./pages/G1/Science/Units/One/SC19";
import ScienceG1SC20 from "./pages/G1/Science/Units/One/SC20";
import ScienceG1SC21 from "./pages/G1/Science/Units/One/SC21";
import ScienceG1SC22 from "./pages/G1/Science/Units/One/SC22";
import ScienceG1SC23 from "./pages/G1/Science/Units/One/SC23";
import ScienceG1SC24 from "./pages/G1/Science/Units/One/SC24";
import ScienceG1SC25 from "./pages/G1/Science/Units/One/SC25";
import ScienceG1SC26 from "./pages/G1/Science/Units/One/SC26";
import ScienceG1SC27 from "./pages/G1/Science/Units/One/SC27";
import ScienceG1SC28 from "./pages/G1/Science/Units/One/SC28";
import ScienceG1SC29 from "./pages/G1/Science/Units/One/SC29";
import ScienceG1SC30 from "./pages/G1/Science/Units/One/SC30";
import ScienceG1SC31 from "./pages/G1/Science/Units/One/SC31";
import ScienceG1SC32 from "./pages/G1/Science/Units/One/SC32";
import ScienceG1SC33 from "./pages/G1/Science/Units/One/SC33";
import ScienceG1SC34 from "./pages/G1/Science/Units/One/SC34";
import ScienceG12SC1 from "./pages/G1/Science/Units/Two/SC1";
import ScienceG12SC2 from "./pages/G1/Science/Units/Two/SC2";
import ScienceG12SC3 from "./pages/G1/Science/Units/Two/SC3";
import ScienceG12SC4 from "./pages/G1/Science/Units/Two/SC4";
import ScienceG12SC5 from "./pages/G1/Science/Units/Two/SC5";
import ScienceG12SC6 from "./pages/G1/Science/Units/Two/SC6";
import ScienceG12SC7 from "./pages/G1/Science/Units/Two/SC7";
import ScienceG12SC8 from "./pages/G1/Science/Units/Two/SC8";
import ScienceG12SC9 from "./pages/G1/Science/Units/Two/SC9";
import ScienceG13SC1 from "./pages/G1/Science/Units/Three/SC1";
import ScienceG13SC2 from "./pages/G1/Science/Units/Three/SC2";
import ScienceG13SC3 from "./pages/G1/Science/Units/Three/SC3";
import ScienceG13SC4 from "./pages/G1/Science/Units/Three/SC4";
import ScienceG13SC5 from "./pages/G1/Science/Units/Three/SC5";
import ScienceG13SC6 from "./pages/G1/Science/Units/Three/SC6";
import ScienceG13SC7 from "./pages/G1/Science/Units/Three/SC7";
import ScienceG13SC8 from "./pages/G1/Science/Units/Three/SC8";
import ScienceG13SC9 from "./pages/G1/Science/Units/Three/SC9";
import ScienceG14SC1 from "./pages/G1/Science/Units/Four/SC1";
import ScienceG14SC2 from "./pages/G1/Science/Units/Four/SC2";
import ScienceG14SC3 from "./pages/G1/Science/Units/Four/SC3";
import ScienceG14SC4 from "./pages/G1/Science/Units/Four/SC4";
import ScienceG14SC5 from "./pages/G1/Science/Units/Four/SC5";
import ScienceG14SC6 from "./pages/G1/Science/Units/Four/SC6";
// End of Science G1
// Start of Social Studies G1
import G1SocialStudiesUnits from "./pages/G1/SocialStudies/Units/Units";
import SocialStudiesG1SS1 from "./pages/G1/SocialStudies/Units/One/SS1";
import SocialStudiesG1SS2 from "./pages/G1/SocialStudies/Units/One/SS2";
import SocialStudiesG1SS3 from "./pages/G1/SocialStudies/Units/One/SS3";
import SocialStudiesG1SS4 from "./pages/G1/SocialStudies/Units/One/SS4";
import SocialStudiesG1SS5 from "./pages/G1/SocialStudies/Units/One/SS5";
import SocialStudiesG1SS6 from "./pages/G1/SocialStudies/Units/One/SS6";
import SocialStudiesG1SS7 from "./pages/G1/SocialStudies/Units/One/SS7";
import SocialStudiesG12SS1 from "./pages/G1/SocialStudies/Units/Two/SS1";
import SocialStudiesG12SS2 from "./pages/G1/SocialStudies/Units/Two/SS2";
import SocialStudiesG12SS3 from "./pages/G1/SocialStudies/Units/Two/SS3";
import SocialStudiesG12SS4 from "./pages/G1/SocialStudies/Units/Two/SS4";
import SocialStudiesG12SS5 from "./pages/G1/SocialStudies/Units/Two/SS5";
import SocialStudiesG12SS6 from "./pages/G1/SocialStudies/Units/Two/SS6";
import SocialStudiesG12SS7 from "./pages/G1/SocialStudies/Units/Two/SS7";
import SocialStudiesG12SS8 from "./pages/G1/SocialStudies/Units/Two/SS8";
import SocialStudiesG12SS9 from "./pages/G1/SocialStudies/Units/Two/SS9";
import SocialStudiesG12SS10 from "./pages/G1/SocialStudies/Units/Two/SS10";
import SocialStudiesG13SS1 from "./pages/G1/SocialStudies/Units/Three/SS1";
import SocialStudiesG13SS2 from "./pages/G1/SocialStudies/Units/Three/SS2";
import SocialStudiesG13SS3 from "./pages/G1/SocialStudies/Units/Three/SS3";
import SocialStudiesG13SS4 from "./pages/G1/SocialStudies/Units/Three/SS4";
import SocialStudiesG13SS5 from "./pages/G1/SocialStudies/Units/Three/SS5";
import SocialStudiesG13SS6 from "./pages/G1/SocialStudies/Units/Three/SS6";
import SocialStudiesG13SS7 from "./pages/G1/SocialStudies/Units/Three/SS7";
import SocialStudiesG13SS8 from "./pages/G1/SocialStudies/Units/Three/SS8";
import SocialStudiesG14SS1 from "./pages/G1/SocialStudies/Units/Four/SS1";
import SocialStudiesG14SS2 from "./pages/G1/SocialStudies/Units/Four/SS2";
import SocialStudiesG14SS3 from "./pages/G1/SocialStudies/Units/Four/SS3";
import SocialStudiesG14SS4 from "./pages/G1/SocialStudies/Units/Four/SS4";
import SocialStudiesG14SS5 from "./pages/G1/SocialStudies/Units/Four/SS5";
import SocialStudiesG14SS6 from "./pages/G1/SocialStudies/Units/Four/SS6";
import SocialStudiesG14SS7 from "./pages/G1/SocialStudies/Units/Four/SS7";
import SocialStudiesG14SS8 from "./pages/G1/SocialStudies/Units/Four/SS8";
import SocialStudiesG14SS9 from "./pages/G1/SocialStudies/Units/Four/SS9";
import SocialStudiesG14SS10 from "./pages/G1/SocialStudies/Units/Four/SS10";
import SocialStudiesG14SS11 from "./pages/G1/SocialStudies/Units/Four/SS11";
import SocialStudiesG14SS12 from "./pages/G1/SocialStudies/Units/Four/SS12";
import SocialStudiesG14SS13 from "./pages/G1/SocialStudies/Units/Four/SS13";
import SocialStudiesG14SS14 from "./pages/G1/SocialStudies/Units/Four/SS14";
//End of Social Studies G1
// English G2
import G2Units from "./pages/G2/English/Units/Units";
import EnglishG2CC from "./pages/G2/English/Units/One/CCStandard";
import EnglishG2E1 from "./pages/G2/English/Units/One/E1";
import EnglishG2E2 from "./pages/G2/English/Units/One/E2";
import EnglishG2E3 from "./pages/G2/English/Units/One/E3";
import EnglishG2E4 from "./pages/G2/English/Units/One/E4";
import EnglishG2E5 from "./pages/G2/English/Units/One/E5";
import EnglishG2E6 from "./pages/G2/English/Units/One/E6";
import EnglishG2E7 from "./pages/G2/English/Units/One/E7";
import EnglishG2E8 from "./pages/G2/English/Units/One/E8";
import EnglishG22CC from "./pages/G2/English/Units/Two/CCStandard";
import EnglishG22E1 from "./pages/G2/English/Units/Two/E1";
import EnglishG22E2 from "./pages/G2/English/Units/Two/E2";
import EnglishG22E3 from "./pages/G2/English/Units/Two/E3";
import EnglishG22E4 from "./pages/G2/English/Units/Two/E4";
import EnglishG22E5 from "./pages/G2/English/Units/Two/E5";
import EnglishG22E6 from "./pages/G2/English/Units/Two/E6";
import EnglishG22E7 from "./pages/G2/English/Units/Two/E7";
import EnglishG23CC from "./pages/G2/English/Units/Three/CCStandard";
import EnglishG23E1 from "./pages/G2/English/Units/Three/E1";
import EnglishG23E2 from "./pages/G2/English/Units/Three/E2";
import EnglishG23E3 from "./pages/G2/English/Units/Three/E3";
import EnglishG23E4 from "./pages/G2/English/Units/Three/E4";
import EnglishG23E5 from "./pages/G2/English/Units/Three/E5";
import EnglishG23E6 from "./pages/G2/English/Units/Three/E6";
import EnglishG23E7 from "./pages/G2/English/Units/Three/E7";
import EnglishG24CC from "./pages/G2/English/Units/Four/CCStandard";
import EnglishG24E1 from "./pages/G2/English/Units/Four/E1";
import EnglishG24E2 from "./pages/G2/English/Units/Four/E2";
import EnglishG24E3 from "./pages/G2/English/Units/Four/E3";
import EnglishG24E4 from "./pages/G2/English/Units/Four/E4";
import EnglishG24E5 from "./pages/G2/English/Units/Four/E5";
import EnglishG24E6 from "./pages/G2/English/Units/Four/E6";
import EnglishG24E7 from "./pages/G2/English/Units/Four/E7";
import EnglishG24E8 from "./pages/G2/English/Units/Four/E8";
import EnglishG24E9 from "./pages/G2/English/Units/Four/E9";
// End of English G2
// Start of Science G2
import G2ScienceUnits from "./pages/G2/Science/Units/Units";
import ScienceG2SC1 from "./pages/G2/Science/Units/One/SC1";
import ScienceG2SC2 from "./pages/G2/Science/Units/One/SC2";
import ScienceG2SC3 from "./pages/G2/Science/Units/One/SC3";
import ScienceG2SC4 from "./pages/G2/Science/Units/One/SC4";
import ScienceG2SC5 from "./pages/G2/Science/Units/One/SC5";
import ScienceG2SC6 from "./pages/G2/Science/Units/One/SC6";
import ScienceG2SC7 from "./pages/G2/Science/Units/One/SC7";
import ScienceG2SC8 from "./pages/G2/Science/Units/One/SC8";
import ScienceG2SC9 from "./pages/G2/Science/Units/One/SC9";
import ScienceG2SC10 from "./pages/G2/Science/Units/One/SC10";
import ScienceG2SC11 from "./pages/G2/Science/Units/One/SC11";
import ScienceG2SC12 from "./pages/G2/Science/Units/One/SC12";
import ScienceG2SC13 from "./pages/G2/Science/Units/One/SC13";
import ScienceG2SC14 from "./pages/G2/Science/Units/One/SC14";
import ScienceG2SC15 from "./pages/G2/Science/Units/One/SC15";
import ScienceG2SC16 from "./pages/G2/Science/Units/One/SC16";
import ScienceG2SC17 from "./pages/G2/Science/Units/One/SC17";
import ScienceG2SC18 from "./pages/G2/Science/Units/One/SC18";
import ScienceG2SC18A from "./pages/G2/Science/Units/One/SC18A";
import ScienceG2SC19 from "./pages/G2/Science/Units/One/SC19";
import ScienceG2SC20 from "./pages/G2/Science/Units/One/SC20";
import ScienceG2SC21 from "./pages/G2/Science/Units/One/SC21";
import ScienceG22SC1 from "./pages/G2/Science/Units/Two/SC1";
import ScienceG22SC2 from "./pages/G2/Science/Units/Two/SC2";
import ScienceG22SC3 from "./pages/G2/Science/Units/Two/SC3";
import ScienceG22SC4 from "./pages/G2/Science/Units/Two/SC4";
import ScienceG22SC5 from "./pages/G2/Science/Units/Two/SC5";
import ScienceG22SC6 from "./pages/G2/Science/Units/Two/SC6";
import ScienceG22SC7 from "./pages/G2/Science/Units/Two/SC7";
import ScienceG22SC8 from "./pages/G2/Science/Units/Two/SC8";
import ScienceG22SC9 from "./pages/G2/Science/Units/Two/SC9";
import ScienceG22SC10 from "./pages/G2/Science/Units/Two/SC10";
import ScienceG22SC11 from "./pages/G2/Science/Units/Two/SC11";
import ScienceG23SC1 from "./pages/G2/Science/Units/Three/SC1";
import ScienceG23SC2 from "./pages/G2/Science/Units/Three/SC2";
import ScienceG23SC3 from "./pages/G2/Science/Units/Three/SC3";
import ScienceG23SC4 from "./pages/G2/Science/Units/Three/SC4";
import ScienceG23SC5 from "./pages/G2/Science/Units/Three/SC5";
import ScienceG23SC6 from "./pages/G2/Science/Units/Three/SC6";
import ScienceG23SC7 from "./pages/G2/Science/Units/Three/SC7";
import ScienceG23SC8 from "./pages/G2/Science/Units/Three/SC8";
import ScienceG23SC8A from "./pages/G2/Science/Units/Three/SC8A";
import ScienceG23SC9 from "./pages/G2/Science/Units/Three/SC9";
import ScienceG23SC10 from "./pages/G2/Science/Units/Three/SC10";
import ScienceG23SC11 from "./pages/G2/Science/Units/Three/SC11";
import ScienceG23SC12 from "./pages/G2/Science/Units/Three/SC12";
import ScienceG23SC13 from "./pages/G2/Science/Units/Three/SC13";
import ScienceG23SC14 from "./pages/G2/Science/Units/Three/SC14";
import ScienceG23SC15 from "./pages/G2/Science/Units/Three/SC15";
import ScienceG24SC1 from "./pages/G2/Science/Units/Four/SC1";
import ScienceG24SC2 from "./pages/G2/Science/Units/Four/SC2";
import ScienceG24SC3 from "./pages/G2/Science/Units/Four/SC3";
import ScienceG24SC4 from "./pages/G2/Science/Units/Four/SC4";
import ScienceG24SC5 from "./pages/G2/Science/Units/Four/SC5";
import ScienceG24SC6 from "./pages/G2/Science/Units/Four/SC6";
import ScienceG24SC7 from "./pages/G2/Science/Units/Four/SC7";
import ScienceG24SC8 from "./pages/G2/Science/Units/Four/SC8";
// End of Science G2
// Start of SocialStudies G2
import G2SocialStudiesUnits from "./pages/G2/SocialStudies/Units/Units";
import SocialStudiesG2SS1 from "./pages/G2/SocialStudies/Units/One/SS1";
import SocialStudiesG2SS2 from "./pages/G2/SocialStudies/Units/One/SS2";
import SocialStudiesG2SS3 from "./pages/G2/SocialStudies/Units/One/SS3";
import SocialStudiesG2SS4 from "./pages/G2/SocialStudies/Units/One/SS4";
import SocialStudiesG22SS1 from "./pages/G2/SocialStudies/Units/Two/SS1";
import SocialStudiesG22SS2 from "./pages/G2/SocialStudies/Units/Two/SS2";
import SocialStudiesG22SS3 from "./pages/G2/SocialStudies/Units/Two/SS3";
import SocialStudiesG22SS4 from "./pages/G2/SocialStudies/Units/Two/SS4";
import SocialStudiesG23SS1 from "./pages/G2/SocialStudies/Units/Three/SS1";
import SocialStudiesG23SS2 from "./pages/G2/SocialStudies/Units/Three/SS2";
import SocialStudiesG23SS3 from "./pages/G2/SocialStudies/Units/Three/SS3";
import SocialStudiesG23SS4 from "./pages/G2/SocialStudies/Units/Three/SS4";
import SocialStudiesG24SS1 from "./pages/G2/SocialStudies/Units/Four/SS1";
import SocialStudiesG24SS2 from "./pages/G2/SocialStudies/Units/Four/SS2";
import SocialStudiesG24SS3 from "./pages/G2/SocialStudies/Units/Four/SS3";
import SocialStudiesG24SS4 from "./pages/G2/SocialStudies/Units/Four/SS4";
import SocialStudiesG24SS5 from "./pages/G2/SocialStudies/Units/Four/SS5";
import SocialStudiesG24SS6 from "./pages/G2/SocialStudies/Units/Four/SS6";
import SocialStudiesG24SS7 from "./pages/G2/SocialStudies/Units/Four/SS7";
import SocialStudiesG24SS8 from "./pages/G2/SocialStudies/Units/Four/SS8";
import SocialStudiesG24SS9 from "./pages/G2/SocialStudies/Units/Four/SS9";
import SocialStudiesG24SS10 from "./pages/G2/SocialStudies/Units/Four/SS10";
import SocialStudiesG24SS11 from "./pages/G2/SocialStudies/Units/Four/SS11";

export default function App() {
  return (
    <Router>
        <div>
            <Routes>
              <Route path="/" element={<Home />} />              
              <Route path="/KG" element={<KG />} />           
              <Route path="/KG/English/Units" element={<KGUnits />} />
              <Route path="/KG/English/Units/One/CommonCoreStandard" element={<EnglishKGCC />} />
              <Route path="/KG/English/Units/One/1" element={<EnglishKGE1 />} />              
              <Route path="/KG/English/Units/One/2" element={<EnglishKGE2 />} />           
              <Route path="/KG/English/Units/One/3" element={<EnglishKGE3 />} />           
              <Route path="/KG/English/Units/One/4" element={<EnglishKGE4 />} />           
              <Route path="/KG/English/Units/One/5" element={<EnglishKGE5 />} />           
              <Route path="/KG/English/Units/One/6" element={<EnglishKGE6 />} />           
              <Route path="/KG/English/Units/One/7" element={<EnglishKGE7 />} />           
              <Route path="/KG/English/Units/One/8" element={<EnglishKGE8 />} />           
              <Route path="/KG/English/Units/One/9" element={<EnglishKGE9 />} />           
              <Route path="/KG/English/Units/One/10" element={<EnglishKGE10 />} />           
              <Route path="/KG/English/Units/One/11" element={<EnglishKGE11 />} />           
              <Route path="/KG/English/Units/One/12" element={<EnglishKGE12 />} />              
              <Route path="/KG/English/Units/One/13" element={<EnglishKGE13 />} />              
              <Route path="/KG/English/Units/One/14" element={<EnglishKGE14 />} />              
              <Route path="/KG/English/Units/One/15" element={<EnglishKGE15 />} />              
              <Route path="/KG/English/Units/Two/CommonCoreStandard" element={<EnglishKG2CC />} />
              <Route path="/KG/English/Units/Two/1" element={<EnglishKG2E1 />} />              
              <Route path="/KG/English/Units/Two/2" element={<EnglishKG2E2 />} />           
              <Route path="/KG/English/Units/Two/3" element={<EnglishKG2E3 />} />           
              <Route path="/KG/English/Units/Two/4" element={<EnglishKG2E4 />} />           
              <Route path="/KG/English/Units/Two/5" element={<EnglishKG2E5 />} />           
              <Route path="/KG/English/Units/Two/6" element={<EnglishKG2E6 />} />           
              <Route path="/KG/English/Units/Two/7" element={<EnglishKG2E7 />} />           
              <Route path="/KG/English/Units/Two/8" element={<EnglishKG2E8 />} />           
              <Route path="/KG/English/Units/Two/9" element={<EnglishKG2E9 />} />           
              <Route path="/KG/English/Units/Two/10" element={<EnglishKG2E10 />} />           
              <Route path="/KG/English/Units/Two/11" element={<EnglishKG2E11 />} />           
              <Route path="/KG/English/Units/Two/12" element={<EnglishKG2E12 />} />              
              <Route path="/KG/English/Units/Two/13" element={<EnglishKG2E13 />} />              
              <Route path="/KG/English/Units/Two/14" element={<EnglishKG2E14 />} />              
              <Route path="/KG/English/Units/Three/CommonCoreStandard" element={<EnglishKG3CC />} />
              <Route path="/KG/English/Units/Three/1" element={<EnglishKG3E1 />} />              
              <Route path="/KG/English/Units/Three/2" element={<EnglishKG3E2 />} />           
              <Route path="/KG/English/Units/Three/3" element={<EnglishKG3E3 />} />           
              <Route path="/KG/English/Units/Three/4" element={<EnglishKG3E4 />} />           
              <Route path="/KG/English/Units/Three/5" element={<EnglishKG3E5 />} />           
              <Route path="/KG/English/Units/Three/6" element={<EnglishKG3E6 />} />           
              <Route path="/KG/English/Units/Three/7" element={<EnglishKG3E7 />} />           
              <Route path="/KG/English/Units/Three/8" element={<EnglishKG3E8 />} />           
              <Route path="/KG/English/Units/Three/9" element={<EnglishKG3E9 />} />           
              <Route path="/KG/English/Units/Three/10" element={<EnglishKG3E10 />} />           
              <Route path="/KG/English/Units/Three/11" element={<EnglishKG3E11 />} />         
              <Route path="/KG/English/Units/Four/CommonCoreStandard" element={<EnglishKG4CC />} />
              <Route path="/KG/English/Units/Four/1" element={<EnglishKG4E1 />} />              
              <Route path="/KG/English/Units/Four/2" element={<EnglishKG4E2 />} />           
              <Route path="/KG/English/Units/Four/3" element={<EnglishKG4E3 />} />           
              <Route path="/KG/English/Units/Four/4" element={<EnglishKG4E4 />} />           
              <Route path="/KG/English/Units/Four/5" element={<EnglishKG4E5 />} />           
              <Route path="/KG/English/Units/Four/6" element={<EnglishKG4E6 />} />           
              <Route path="/KG/English/Units/Four/7" element={<EnglishKG4E7 />} />           
              <Route path="/KG/English/Units/Four/8" element={<EnglishKG4E8 />} />           
              <Route path="/KG/English/Units/Four/9" element={<EnglishKG4E9 />} />           
              <Route path="/KG/English/Units/Four/10" element={<EnglishKG4E10 />} />           
              <Route path="/KG/English/Units/Four/11" element={<EnglishKG4E11 />} />       
              <Route path="/KG/SocialStudies/Units" element={<KGSocialStudiesUnits />} />               
              <Route path="/KG/SocialStudies/Units/One/1" element={<SocialStudiesKGSS1 />} />     
              <Route path="/KG/SocialStudies/Units/One/2" element={<SocialStudiesKGSS2 />} />     
              <Route path="/KG/SocialStudies/Units/One/3" element={<SocialStudiesKGSS3 />} />     
              <Route path="/KG/SocialStudies/Units/One/4" element={<SocialStudiesKGSS4 />} />     
              <Route path="/KG/SocialStudies/Units/One/5" element={<SocialStudiesKGSS5 />} />     
              <Route path="/KG/SocialStudies/Units/One/6" element={<SocialStudiesKGSS6 />} />     
              <Route path="/KG/SocialStudies/Units/One/7" element={<SocialStudiesKGSS7 />} />     
              <Route path="/KG/SocialStudies/Units/One/7_1" element={<SocialStudiesKGSS71 />} />     
              <Route path="/KG/SocialStudies/Units/One/8" element={<SocialStudiesKGSS8 />} />     
              <Route path="/KG/SocialStudies/Units/One/9" element={<SocialStudiesKGSS9 />} />     
              <Route path="/KG/SocialStudies/Units/One/10" element={<SocialStudiesKGSS10 />} />     
              <Route path="/KG/SocialStudies/Units/One/11" element={<SocialStudiesKGSS11 />} />     
              <Route path="/KG/SocialStudies/Units/One/12" element={<SocialStudiesKGSS12 />} />     
              <Route path="/KG/SocialStudies/Units/One/13" element={<SocialStudiesKGSS13 />} />     
              <Route path="/KG/SocialStudies/Units/One/14" element={<SocialStudiesKGSS14 />} />     
              <Route path="/KG/SocialStudies/Units/One/15" element={<SocialStudiesKGSS15 />} />     
              <Route path="/KG/SocialStudies/Units/One/16" element={<SocialStudiesKGSS16 />} />     
              <Route path="/KG/SocialStudies/Units/One/17" element={<SocialStudiesKGSS17 />} />     
              <Route path="/KG/SocialStudies/Units/One/18" element={<SocialStudiesKGSS18 />} />     
              <Route path="/KG/SocialStudies/Units/One/19" element={<SocialStudiesKGSS19 />} />     
              <Route path="/KG/SocialStudies/Units/One/20" element={<SocialStudiesKGSS20 />} />     
              <Route path="/KG/SocialStudies/Units/Two/1" element={<SocialStudiesKG2SS1 />} />     
              <Route path="/KG/SocialStudies/Units/Two/1A" element={<SocialStudiesKG2SS1A />} />     
              <Route path="/KG/SocialStudies/Units/Two/1B" element={<SocialStudiesKG2SS1B />} />     
              <Route path="/KG/SocialStudies/Units/Two/2" element={<SocialStudiesKG2SS2 />} />     
              <Route path="/KG/SocialStudies/Units/Two/3" element={<SocialStudiesKG2SS3 />} />     
              <Route path="/KG/SocialStudies/Units/Two/4" element={<SocialStudiesKG2SS4 />} />     
              <Route path="/KG/SocialStudies/Units/Two/5" element={<SocialStudiesKG2SS5 />} />     
              <Route path="/KG/SocialStudies/Units/Two/6" element={<SocialStudiesKG2SS6 />} />     
              <Route path="/KG/SocialStudies/Units/Two/7" element={<SocialStudiesKG2SS7 />} />     
              <Route path="/KG/SocialStudies/Units/Two/8" element={<SocialStudiesKG2SS8 />} />     
              <Route path="/KG/SocialStudies/Units/Two/9" element={<SocialStudiesKG2SS9 />} />     
              <Route path="/KG/SocialStudies/Units/Two/10" element={<SocialStudiesKG2SS10 />} />     
              <Route path="/KG/SocialStudies/Units/Two/11" element={<SocialStudiesKG2SS11 />} />     
              <Route path="/KG/SocialStudies/Units/Two/12" element={<SocialStudiesKG2SS12 />} />     
              <Route path="/KG/SocialStudies/Units/Two/13" element={<SocialStudiesKG2SS13 />} />     
              <Route path="/KG/SocialStudies/Units/Two/14" element={<SocialStudiesKG2SS14 />} />     
              <Route path="/KG/SocialStudies/Units/Two/15" element={<SocialStudiesKG2SS15 />} />     
              <Route path="/KG/SocialStudies/Units/Two/16" element={<SocialStudiesKG2SS16 />} />     
              <Route path="/KG/SocialStudies/Units/Two/17" element={<SocialStudiesKG2SS17 />} />     
              <Route path="/KG/SocialStudies/Units/Two/18" element={<SocialStudiesKG2SS18 />} />     
              <Route path="/KG/SocialStudies/Units/Two/19" element={<SocialStudiesKG2SS19 />} />     
              <Route path="/KG/SocialStudies/Units/Two/20" element={<SocialStudiesKG2SS20 />} />     
              <Route path="/KG/SocialStudies/Units/Two/21" element={<SocialStudiesKG2SS21 />} />     
              <Route path="/KG/SocialStudies/Units/Two/22" element={<SocialStudiesKG2SS22 />} />     
              <Route path="/KG/SocialStudies/Units/Two/23" element={<SocialStudiesKG2SS23 />} />     
              <Route path="/KG/SocialStudies/Units/Two/24" element={<SocialStudiesKG2SS24 />} />     
              <Route path="/KG/SocialStudies/Units/Two/25" element={<SocialStudiesKG2SS25 />} />     
              <Route path="/KG/SocialStudies/Units/Two/26" element={<SocialStudiesKG2SS26 />} />     
              <Route path="/KG/SocialStudies/Units/Two/27" element={<SocialStudiesKG2SS27 />} />     
              <Route path="/KG/SocialStudies/Units/Two/28" element={<SocialStudiesKG2SS28 />} />    
              <Route path="/KG/SocialStudies/Units/Three/1" element={<SocialStudiesKG3SS1 />} />     
              <Route path="/KG/SocialStudies/Units/Three/1A" element={<SocialStudiesKG3SS1A />} />     
              <Route path="/KG/SocialStudies/Units/Three/2" element={<SocialStudiesKG3SS2 />} />     
              <Route path="/KG/SocialStudies/Units/Three/3" element={<SocialStudiesKG3SS3 />} />     
              <Route path="/KG/SocialStudies/Units/Three/4" element={<SocialStudiesKG3SS4 />} />     
              <Route path="/KG/SocialStudies/Units/Three/5" element={<SocialStudiesKG3SS5 />} />     
              <Route path="/KG/SocialStudies/Units/Three/6" element={<SocialStudiesKG3SS6 />} />     
              <Route path="/KG/SocialStudies/Units/Three/7" element={<SocialStudiesKG3SS7 />} />     
              <Route path="/KG/SocialStudies/Units/Three/8" element={<SocialStudiesKG3SS8 />} />     
              <Route path="/KG/SocialStudies/Units/Three/9" element={<SocialStudiesKG3SS9 />} />     
              <Route path="/KG/SocialStudies/Units/Three/10" element={<SocialStudiesKG3SS10 />} />     
              <Route path="/KG/SocialStudies/Units/Three/11" element={<SocialStudiesKG3SS11 />} />     
              <Route path="/KG/SocialStudies/Units/Three/12" element={<SocialStudiesKG3SS12 />} />     
              <Route path="/KG/SocialStudies/Units/Three/13" element={<SocialStudiesKG3SS13 />} />     
              <Route path="/KG/SocialStudies/Units/Three/14" element={<SocialStudiesKG3SS14 />} />     
              <Route path="/KG/SocialStudies/Units/Three/15" element={<SocialStudiesKG3SS15 />} />     
              <Route path="/KG/SocialStudies/Units/Three/16" element={<SocialStudiesKG3SS16 />} />     
              <Route path="/KG/SocialStudies/Units/Three/17" element={<SocialStudiesKG3SS17 />} />     
              <Route path="/KG/SocialStudies/Units/Three/18" element={<SocialStudiesKG3SS18 />} />     
              <Route path="/KG/SocialStudies/Units/Three/19" element={<SocialStudiesKG3SS19 />} />     
              <Route path="/KG/SocialStudies/Units/Three/20" element={<SocialStudiesKG3SS20 />} />     
              <Route path="/KG/SocialStudies/Units/Three/21" element={<SocialStudiesKG3SS21 />} />     
              <Route path="/KG/SocialStudies/Units/Three/22" element={<SocialStudiesKG3SS22 />} />     
              <Route path="/KG/SocialStudies/Units/Three/23" element={<SocialStudiesKG3SS23 />} />     
              <Route path="/KG/SocialStudies/Units/Three/24" element={<SocialStudiesKG3SS24 />} />   
              <Route path="/KG/SocialStudies/Units/Four/1" element={<SocialStudiesKG4SS1 />} />     
              <Route path="/KG/SocialStudies/Units/Four/1A" element={<SocialStudiesKG4SS1A />} />     
              <Route path="/KG/SocialStudies/Units/Four/2" element={<SocialStudiesKG4SS2 />} />     
              <Route path="/KG/SocialStudies/Units/Four/3" element={<SocialStudiesKG4SS3 />} />     
              <Route path="/KG/SocialStudies/Units/Four/4" element={<SocialStudiesKG4SS4 />} />     
              <Route path="/KG/SocialStudies/Units/Four/5" element={<SocialStudiesKG4SS5 />} />     
              <Route path="/KG/SocialStudies/Units/Four/6" element={<SocialStudiesKG4SS6 />} />     
              <Route path="/KG/SocialStudies/Units/Four/7" element={<SocialStudiesKG4SS7 />} />     
              <Route path="/KG/SocialStudies/Units/Four/8" element={<SocialStudiesKG4SS8 />} />     
              <Route path="/KG/SocialStudies/Units/Four/9" element={<SocialStudiesKG4SS9 />} />     
              <Route path="/KG/SocialStudies/Units/Four/10" element={<SocialStudiesKG4SS10 />} />     
              <Route path="/KG/SocialStudies/Units/Four/11" element={<SocialStudiesKG4SS11 />} />     
              <Route path="/KG/SocialStudies/Units/Four/12" element={<SocialStudiesKG4SS12 />} />     
              <Route path="/KG/SocialStudies/Units/Four/13" element={<SocialStudiesKG4SS13 />} />     
              <Route path="/KG/SocialStudies/Units/Four/14" element={<SocialStudiesKG4SS14 />} />     
              <Route path="/KG/SocialStudies/Units/Four/15" element={<SocialStudiesKG4SS15 />} />     
              <Route path="/KG/SocialStudies/Units/Four/16" element={<SocialStudiesKG4SS16 />} />     
              <Route path="/KG/SocialStudies/Units/Four/17" element={<SocialStudiesKG4SS17 />} />     
              <Route path="/KG/SocialStudies/Units/Four/18" element={<SocialStudiesKG4SS18 />} />     
              <Route path="/KG/SocialStudies/Units/Four/19" element={<SocialStudiesKG4SS19 />} />     
              <Route path="/KG/SocialStudies/Units/Four/20" element={<SocialStudiesKG4SS20 />} />     
              <Route path="/KG/SocialStudies/Units/Four/21" element={<SocialStudiesKG4SS21 />} />     
              <Route path="/KG/SocialStudies/Units/Four/22" element={<SocialStudiesKG4SS22 />} />     
              <Route path="/KG/SocialStudies/Units/Four/23" element={<SocialStudiesKG4SS23 />} />     
              <Route path="/KG/SocialStudies/Units/Four/24" element={<SocialStudiesKG4SS24 />} />   
              <Route path="/KG/Science/Units" element={<KGScienceUnits />} />               
              <Route path="/KG/Science/Units/One/1" element={<ScienceKGSC1 />} />     
              <Route path="/KG/Science/Units/One/2" element={<ScienceKGSC2 />} />     
              <Route path="/KG/Science/Units/One/3" element={<ScienceKGSC3 />} />     
              <Route path="/KG/Science/Units/One/4" element={<ScienceKGSC4 />} />     
              <Route path="/KG/Science/Units/One/5" element={<ScienceKGSC5 />} />     
              <Route path="/KG/Science/Units/One/6" element={<ScienceKGSC6 />} />     
              <Route path="/KG/Science/Units/One/7" element={<ScienceKGSC7 />} />     
              <Route path="/KG/Science/Units/One/8" element={<ScienceKGSC8 />} />     
              <Route path="/KG/Science/Units/One/9" element={<ScienceKGSC9 />} />     
              <Route path="/KG/Science/Units/One/10" element={<ScienceKGSC10 />} />     
              <Route path="/KG/Science/Units/One/11" element={<ScienceKGSC11 />} />     
              <Route path="/KG/Science/Units/One/12" element={<ScienceKGSC12 />} />     
              <Route path="/KG/Science/Units/One/13" element={<ScienceKGSC13 />} />              
              <Route path="/KG/Science/Units/Two/1" element={<ScienceKG2SC1 />} />     
              <Route path="/KG/Science/Units/Two/2" element={<ScienceKG2SC2 />} />     
              <Route path="/KG/Science/Units/Two/3" element={<ScienceKG2SC3 />} />     
              <Route path="/KG/Science/Units/Two/4" element={<ScienceKG2SC4 />} />     
              <Route path="/KG/Science/Units/Two/5" element={<ScienceKG2SC5 />} />     
              <Route path="/KG/Science/Units/Two/6" element={<ScienceKG2SC6 />} />     
              <Route path="/KG/Science/Units/Two/7" element={<ScienceKG2SC7 />} />     
              <Route path="/KG/Science/Units/Two/8" element={<ScienceKG2SC8 />} />     
              <Route path="/KG/Science/Units/Two/9" element={<ScienceKG2SC9 />} />     
              <Route path="/KG/Science/Units/Two/10" element={<ScienceKG2SC10 />} />     
              <Route path="/KG/Science/Units/Two/11" element={<ScienceKG2SC11 />} />     
              <Route path="/KG/Science/Units/Two/12" element={<ScienceKG2SC12 />} />     
              <Route path="/KG/Science/Units/Two/13" element={<ScienceKG2SC13 />} />     
              <Route path="/KG/Science/Units/Two/14" element={<ScienceKG2SC14 />} />     
              <Route path="/KG/Science/Units/Two/15" element={<ScienceKG2SC15 />} />     
              <Route path="/KG/Science/Units/Two/16" element={<ScienceKG2SC16 />} />     
              <Route path="/KG/Science/Units/Two/17" element={<ScienceKG2SC17 />} />     
              <Route path="/KG/Science/Units/Two/18" element={<ScienceKG2SC18 />} />     
              <Route path="/KG/Science/Units/Two/19" element={<ScienceKG2SC19 />} />   
              <Route path="/KG/Science/Units/Three/1" element={<ScienceKG3SC1 />} />     
              <Route path="/KG/Science/Units/Three/2" element={<ScienceKG3SC2 />} />     
              <Route path="/KG/Science/Units/Three/3" element={<ScienceKG3SC3 />} />     
              <Route path="/KG/Science/Units/Three/4" element={<ScienceKG3SC4 />} />     
              <Route path="/KG/Science/Units/Three/5" element={<ScienceKG3SC5 />} />     
              <Route path="/KG/Science/Units/Three/6" element={<ScienceKG3SC6 />} />     
              <Route path="/KG/Science/Units/Three/7" element={<ScienceKG3SC7 />} />     
              <Route path="/KG/Science/Units/Three/8" element={<ScienceKG3SC8 />} />     
              <Route path="/KG/Science/Units/Three/9" element={<ScienceKG3SC9 />} />     
              <Route path="/KG/Science/Units/Three/10" element={<ScienceKG3SC10 />} />     
              <Route path="/KG/Science/Units/Three/11" element={<ScienceKG3SC11 />} />     
              <Route path="/KG/Science/Units/Three/12" element={<ScienceKG3SC12 />} />
              <Route path="/KG/Science/Units/Four/1" element={<ScienceKG4SC1 />} />     
              <Route path="/KG/Science/Units/Four/2" element={<ScienceKG4SC2 />} />     
              <Route path="/KG/Science/Units/Four/3" element={<ScienceKG4SC3 />} />     
              <Route path="/KG/Science/Units/Four/4" element={<ScienceKG4SC4 />} />     
              <Route path="/KG/Science/Units/Four/5" element={<ScienceKG4SC5 />} />     
              <Route path="/KG/Science/Units/Four/6" element={<ScienceKG4SC6 />} />     
              <Route path="/KG/Science/Units/Four/7" element={<ScienceKG4SC7 />} />     
              <Route path="/KG/Science/Units/Four/8" element={<ScienceKG4SC8 />} />     
              <Route path="/KG/Science/Units/Four/9" element={<ScienceKG4SC9 />} />     
              <Route path="/KG/Science/Units/Four/10" element={<ScienceKG4SC10 />} />     
              <Route path="/KG/Science/Units/Four/11" element={<ScienceKG4SC11 />} />     
              <Route path="/KG/Science/Units/Four/12" element={<ScienceKG4SC12 />} />     
              <Route path="/KG/Science/Units/Four/13" element={<ScienceKG4SC13 />} />     
              <Route path="/KG/Science/Units/Four/14" element={<ScienceKG4SC14 />} />     
              <Route path="/KG/Science/Units/Four/15" element={<ScienceKG4SC15 />} />     
              <Route path="/KG/Science/Units/Four/16" element={<ScienceKG4SC16 />} />     
              <Route path="/KG/Science/Units/Four/17" element={<ScienceKG4SC17 />} />     
              <Route path="/KG/Science/Units/Four/18" element={<ScienceKG4SC18 />} />
              <Route path="/KG/Math/Units" element={<KGMathUnits />} />
              <Route path="/KG/Math/Units/One/CommonCoreStandard" element={<MathKG1CC />} />
              <Route path="/KG/Math/Units/One/1" element={<MathKG1MA1 />} />     
              <Route path="/KG/Math/Units/One/2" element={<MathKG1MA2 />} />     
              <Route path="/KG/Math/Units/One/3" element={<MathKG1MA3 />} />     
              <Route path="/KG/Math/Units/One/4" element={<MathKG1MA4 />} />     
              <Route path="/KG/Math/Units/One/5" element={<MathKG1MA5 />} />     
              <Route path="/KG/Math/Units/One/6" element={<MathKG1MA6 />} />     
              <Route path="/KG/Math/Units/One/7" element={<MathKG1MA7 />} />     
              <Route path="/KG/Math/Units/One/8" element={<MathKG1MA8 />} />     
              <Route path="/KG/Math/Units/One/9" element={<MathKG1MA9 />} />     
              <Route path="/KG/Math/Units/One/10" element={<MathKG1MA10 />} />     
              <Route path="/KG/Math/Units/One/11" element={<MathKG1MA11 />} />     
              <Route path="/KG/Math/Units/One/12" element={<MathKG1MA12 />} />
              <Route path="/KG/Math/Units/Two/CommonCoreStandard" element={<MathKG2CC />} />
              <Route path="/KG/Math/Units/Two/1" element={<MathKG2MA1 />} />     
              <Route path="/KG/Math/Units/Two/2" element={<MathKG2MA2 />} />     
              <Route path="/KG/Math/Units/Two/3" element={<MathKG2MA3 />} />     
              <Route path="/KG/Math/Units/Two/4" element={<MathKG2MA4 />} />     
              <Route path="/KG/Math/Units/Two/5" element={<MathKG2MA5 />} />     
              <Route path="/KG/Math/Units/Two/6" element={<MathKG2MA6 />} />     
              <Route path="/KG/Math/Units/Two/7" element={<MathKG2MA7 />} />     
              <Route path="/KG/Math/Units/Two/8" element={<MathKG2MA8 />} />     
              <Route path="/KG/Math/Units/Two/9" element={<MathKG2MA9 />} />     
              <Route path="/KG/Math/Units/Two/10" element={<MathKG2MA10 />} />     
              <Route path="/KG/Math/Units/Two/11" element={<MathKG2MA11 />} />
              <Route path="/KG/Math/Units/Three/CommonCoreStandard" element={<MathKG3CC />} />
              <Route path="/KG/Math/Units/Three/1" element={<MathKG3MA1 />} />     
              <Route path="/KG/Math/Units/Three/2" element={<MathKG3MA2 />} />     
              <Route path="/KG/Math/Units/Three/3" element={<MathKG3MA3 />} />     
              <Route path="/KG/Math/Units/Three/4" element={<MathKG3MA4 />} />     
              <Route path="/KG/Math/Units/Three/5" element={<MathKG3MA5 />} />     
              <Route path="/KG/Math/Units/Three/6" element={<MathKG3MA6 />} />     
              <Route path="/KG/Math/Units/Three/7" element={<MathKG3MA7 />} />     
              <Route path="/KG/Math/Units/Three/8" element={<MathKG3MA8 />} />     
              <Route path="/KG/Math/Units/Three/9" element={<MathKG3MA9 />} />     
              <Route path="/KG/Math/Units/Three/10" element={<MathKG3MA10 />} />     
              <Route path="/KG/Math/Units/Three/11" element={<MathKG3MA11 />} />
              <Route path="/KG/Math/Units/Three/12" element={<MathKG3MA12 />} />
              <Route path="/KG/Math/Units/Three/13" element={<MathKG3MA13 />} />
              <Route path="/KG/Math/Units/Four/CommonCoreStandard" element={<MathKG4CC />} />
              <Route path="/KG/Math/Units/Four/1" element={<MathKG4MA1 />} />     
              <Route path="/KG/Math/Units/Four/2" element={<MathKG4MA2 />} />     
              <Route path="/KG/Math/Units/Four/3" element={<MathKG4MA3 />} />     
              <Route path="/KG/Math/Units/Four/4" element={<MathKG4MA4 />} />     
              <Route path="/KG/Math/Units/Four/5" element={<MathKG4MA5 />} />     
              <Route path="/KG/Math/Units/Four/6" element={<MathKG4MA6 />} />     
              <Route path="/KG/Math/Units/Four/7" element={<MathKG4MA7 />} />     
              <Route path="/KG/Math/Units/Four/8" element={<MathKG4MA8 />} />     
              <Route path="/KG/Math/Units/Four/9" element={<MathKG4MA9 />} />     
              <Route path="/KG/Math/Units/Four/10" element={<MathKG4MA10 />} />     
              <Route path="/KG/Math/Units/Four/11" element={<MathKG4MA11 />} />
              <Route path="/KG/Math/Units/Four/12" element={<MathKG4MA12 />} />
              <Route path="/KG/Math/Units/Four/13" element={<MathKG4MA13 />} />
              <Route path="/KG/Math/Units/Four/14" element={<MathKG4MA14 />} />
              <Route path="/G1/Math/Units" element={<G1MathUnits />} />
              <Route path="/G1/Math/Units/One/CommonCoreStandard" element={<MathG11CC />} />
              <Route path="/G1/Math/Units/One/1" element={<MathG11MA1 />} />     
              <Route path="/G1/Math/Units/One/2" element={<MathG11MA2 />} />     
              <Route path="/G1/Math/Units/One/3" element={<MathG11MA3 />} />     
              <Route path="/G1/Math/Units/One/4" element={<MathG11MA4 />} />     
              <Route path="/G1/Math/Units/One/5" element={<MathG11MA5 />} />     
              <Route path="/G1/Math/Units/One/6" element={<MathG11MA6 />} />     
              <Route path="/G1/Math/Units/One/7" element={<MathG11MA7 />} />     
              <Route path="/G1/Math/Units/One/8" element={<MathG11MA8 />} />     
              <Route path="/G1/Math/Units/One/9" element={<MathG11MA9 />} />     
              <Route path="/G1/Math/Units/One/10" element={<MathG11MA10 />} />     
              <Route path="/G1/Math/Units/One/11" element={<MathG11MA11 />} />                   
              <Route path="/G1/Math/Units/Two/CommonCoreStandard" element={<MathG12CC />} />
              <Route path="/G1/Math/Units/Two/1" element={<MathG12MA1 />} />     
              <Route path="/G1/Math/Units/Two/2" element={<MathG12MA2 />} />     
              <Route path="/G1/Math/Units/Two/3" element={<MathG12MA3 />} />     
              <Route path="/G1/Math/Units/Two/4" element={<MathG12MA4 />} />     
              <Route path="/G1/Math/Units/Two/5" element={<MathG12MA5 />} />     
              <Route path="/G1/Math/Units/Two/6" element={<MathG12MA6 />} />     
              <Route path="/G1/Math/Units/Two/7" element={<MathG12MA7 />} />     
              <Route path="/G1/Math/Units/Two/8" element={<MathG12MA8 />} />     
              <Route path="/G1/Math/Units/Two/9" element={<MathG12MA9 />} />     
              <Route path="/G1/Math/Units/Two/10" element={<MathG12MA10 />} />     
              <Route path="/G1/Math/Units/Two/11" element={<MathG12MA11 />} />
              <Route path="/G1/Math/Units/Three/CommonCoreStandard" element={<MathG13CC />} />
              <Route path="/G1/Math/Units/Three/1" element={<MathG13MA1 />} />     
              <Route path="/G1/Math/Units/Three/2" element={<MathG13MA2 />} />     
              <Route path="/G1/Math/Units/Three/3" element={<MathG13MA3 />} />     
              <Route path="/G1/Math/Units/Three/4" element={<MathG13MA4 />} />     
              <Route path="/G1/Math/Units/Three/5" element={<MathG13MA5 />} />     
              <Route path="/G1/Math/Units/Three/6" element={<MathG13MA6 />} />     
              <Route path="/G1/Math/Units/Three/7" element={<MathG13MA7 />} />     
              <Route path="/G1/Math/Units/Three/8" element={<MathG13MA8 />} />     
              <Route path="/G1/Math/Units/Three/9" element={<MathG13MA9 />} />     
              <Route path="/G1/Math/Units/Three/10" element={<MathG13MA10 />} />     
              <Route path="/G1/Math/Units/Three/11" element={<MathG13MA11 />} />
              <Route path="/G1/Math/Units/Three/12" element={<MathG13MA12 />} />
              <Route path="/G1/Math/Units/Three/13" element={<MathG13MA13 />} />
              <Route path="/G1/Math/Units/Three/14" element={<MathG13MA14 />} />
              <Route path="/G1/Math/Units/Three/15" element={<MathG13MA15 />} />
              <Route path="/G1/Math/Units/Three/16" element={<MathG13MA16 />} />
              <Route path="/G1/Math/Units/Four/CommonCoreStandard" element={<MathG14CC />} />
              <Route path="/G1/Math/Units/Four/1" element={<MathG14MA1 />} />     
              <Route path="/G1/Math/Units/Four/2" element={<MathG14MA2 />} />     
              <Route path="/G1/Math/Units/Four/3" element={<MathG14MA3 />} />     
              <Route path="/G1/Math/Units/Four/4" element={<MathG14MA4 />} />     
              <Route path="/G1/Math/Units/Four/5" element={<MathG14MA5 />} />     
              <Route path="/G1/Math/Units/Four/6" element={<MathG14MA6 />} />     
              <Route path="/G1/Math/Units/Four/7" element={<MathG14MA7 />} />     
              <Route path="/G1/Math/Units/Four/8" element={<MathG14MA8 />} />     
              <Route path="/G1/Math/Units/Four/9" element={<MathG14MA9 />} />     
              <Route path="/G1/Math/Units/Four/10" element={<MathG14MA10 />} />     
              <Route path="/G1/Math/Units/Four/11" element={<MathG14MA11 />} />
              <Route path="/G1/Math/Units/Four/12" element={<MathG14MA12 />} />
              <Route path="/G1/Math/Units/Four/13" element={<MathG14MA13 />} />
              <Route path="/G1/Math/Units/Four/14" element={<MathG14MA14 />} />
              <Route path="/G1/Math/Units/Four/15" element={<MathG14MA15 />} />
              <Route path="/G1/Math/Units/Four/16" element={<MathG14MA16 />} />
              <Route path="/G1/Math/Units/Four/17" element={<MathG14MA17 />} />
              <Route path="/G2/Math/Units" element={<G2MathUnits />} />
              <Route path="/G2/Math/Units/One/CommonCoreStandard" element={<MathG21CC />} />
              <Route path="/G2/Math/Units/One/1" element={<MathG21MA1 />} />     
              <Route path="/G2/Math/Units/One/2" element={<MathG21MA2 />} />     
              <Route path="/G2/Math/Units/One/3" element={<MathG21MA3 />} />     
              <Route path="/G2/Math/Units/One/4" element={<MathG21MA4 />} />     
              <Route path="/G2/Math/Units/One/5" element={<MathG21MA5 />} />     
              <Route path="/G2/Math/Units/One/6" element={<MathG21MA6 />} />     
              <Route path="/G2/Math/Units/One/7" element={<MathG21MA7 />} />     
              <Route path="/G2/Math/Units/One/8" element={<MathG21MA8 />} />     
              <Route path="/G2/Math/Units/One/9" element={<MathG21MA9 />} />     
              <Route path="/G2/Math/Units/One/10" element={<MathG21MA10 />} />     
              <Route path="/G2/Math/Units/One/11" element={<MathG21MA11 />} />                   
              <Route path="/G2/Math/Units/One/12" element={<MathG21MA12 />} />                   
              <Route path="/G2/Math/Units/One/13" element={<MathG21MA13 />} />                   
              <Route path="/G2/Math/Units/One/14" element={<MathG21MA14 />} />                   
              <Route path="/G2/Math/Units/One/15" element={<MathG21MA15 />} />                   
              <Route path="/G2/Math/Units/One/16" element={<MathG21MA16 />} />                   
              <Route path="/G2/Math/Units/One/17" element={<MathG21MA17 />} />                   
              <Route path="/G2/Math/Units/Two/CommonCoreStandard" element={<MathG22CC />} />
              <Route path="/G2/Math/Units/Two/1" element={<MathG22MA1 />} />     
              <Route path="/G2/Math/Units/Two/2" element={<MathG22MA2 />} />     
              <Route path="/G2/Math/Units/Two/3" element={<MathG22MA3 />} />     
              <Route path="/G2/Math/Units/Two/4" element={<MathG22MA4 />} />     
              <Route path="/G2/Math/Units/Two/5" element={<MathG22MA5 />} />     
              <Route path="/G2/Math/Units/Two/6" element={<MathG22MA6 />} />     
              <Route path="/G2/Math/Units/Two/7" element={<MathG22MA7 />} />     
              <Route path="/G2/Math/Units/Two/8" element={<MathG22MA8 />} />     
              <Route path="/G2/Math/Units/Two/9" element={<MathG22MA9 />} />     
              <Route path="/G2/Math/Units/Two/10" element={<MathG22MA10 />} />     
              <Route path="/G2/Math/Units/Two/11" element={<MathG22MA11 />} />
              <Route path="/G2/Math/Units/Two/12" element={<MathG22MA12 />} />
              <Route path="/G2/Math/Units/Three/CommonCoreStandard" element={<MathG23CC />} />
              <Route path="/G2/Math/Units/Three/1" element={<MathG23MA1 />} />     
              <Route path="/G2/Math/Units/Three/2" element={<MathG23MA2 />} />     
              <Route path="/G2/Math/Units/Three/3" element={<MathG23MA3 />} />     
              <Route path="/G2/Math/Units/Three/4" element={<MathG23MA4 />} />     
              <Route path="/G2/Math/Units/Three/5" element={<MathG23MA5 />} />     
              <Route path="/G2/Math/Units/Three/6" element={<MathG23MA6 />} />     
              <Route path="/G2/Math/Units/Three/7" element={<MathG23MA7 />} />     
              <Route path="/G2/Math/Units/Three/8" element={<MathG23MA8 />} />     
              <Route path="/G2/Math/Units/Three/9" element={<MathG23MA9 />} />     
              <Route path="/G2/Math/Units/Three/10" element={<MathG23MA10 />} />     
              <Route path="/G2/Math/Units/Three/11" element={<MathG23MA11 />} />
              <Route path="/G2/Math/Units/Three/12" element={<MathG23MA12 />} />
              <Route path="/G2/Math/Units/Four/CommonCoreStandard" element={<MathG24CC />} />
              <Route path="/G2/Math/Units/Four/1" element={<MathG24MA1 />} />     
              <Route path="/G2/Math/Units/Four/2" element={<MathG24MA2 />} />     
              <Route path="/G2/Math/Units/Four/3" element={<MathG24MA3 />} />     
              <Route path="/G2/Math/Units/Four/4" element={<MathG24MA4 />} />     
              <Route path="/G2/Math/Units/Four/5" element={<MathG24MA5 />} />     
              <Route path="/G2/Math/Units/Four/6" element={<MathG24MA6 />} />     
              <Route path="/G2/Math/Units/Four/7" element={<MathG24MA7 />} />     
              <Route path="/G2/Math/Units/Four/8" element={<MathG24MA8 />} />     
              <Route path="/G2/Math/Units/Four/9" element={<MathG24MA9 />} />     
              <Route path="/G2/Math/Units/Four/10" element={<MathG24MA10 />} />     
              <Route path="/G2/Math/Units/Four/11" element={<MathG24MA11 />} />
              <Route path="/G2/Math/Units/Four/12" element={<MathG24MA12 />} />
              <Route path="/G2/Math/Units/Four/13" element={<MathG24MA13 />} />
              <Route path="/G2/Math/Units/Four/14" element={<MathG24MA14 />} />
              <Route path="/G2/Math/Units/Four/15" element={<MathG24MA15 />} />
              <Route path="/G2/Math/Units/Four/16" element={<MathG24MA16 />} />
              <Route path="/G2/Math/Units/Four/17" element={<MathG24MA17 />} />
              <Route path="/G1" element={<G1 />} />                         
              <Route path="/G1/English/Units" element={<G1Units />} />
              <Route path="/G1/English/Units/One/CommonCoreStandard" element={<EnglishG1CC />} />
              <Route path="/G1/English/Units/One/1" element={<EnglishG1E1 />} />              
              <Route path="/G1/English/Units/One/2" element={<EnglishG1E2 />} />           
              <Route path="/G1/English/Units/One/3" element={<EnglishG1E3 />} />           
              <Route path="/G1/English/Units/One/4" element={<EnglishG1E4 />} />           
              <Route path="/G1/English/Units/One/5" element={<EnglishG1E5 />} />           
              <Route path="/G1/English/Units/One/6" element={<EnglishG1E6 />} />           
              <Route path="/G1/English/Units/One/7" element={<EnglishG1E7 />} />           
              <Route path="/G1/English/Units/One/8" element={<EnglishG1E8 />} />
              <Route path="/G1/English/Units/Two/CommonCoreStandard" element={<EnglishG12CC />} />
              <Route path="/G1/English/Units/Two/1" element={<EnglishG12E1 />} />              
              <Route path="/G1/English/Units/Two/2" element={<EnglishG12E2 />} />           
              <Route path="/G1/English/Units/Two/3" element={<EnglishG12E3 />} />           
              <Route path="/G1/English/Units/Two/4" element={<EnglishG12E4 />} />           
              <Route path="/G1/English/Units/Two/5" element={<EnglishG12E5 />} />           
              <Route path="/G1/English/Units/Two/6" element={<EnglishG12E6 />} />           
              <Route path="/G1/English/Units/Two/7" element={<EnglishG12E7 />} />           
              <Route path="/G1/English/Units/Two/8" element={<EnglishG12E8 />} />
              <Route path="/G1/English/Units/Two/9" element={<EnglishG12E9 />} />
              <Route path="/G1/English/Units/Two/10" element={<EnglishG12E10 />} />
              <Route path="/G1/English/Units/Three/CommonCoreStandard" element={<EnglishG13CC />} />
              <Route path="/G1/English/Units/Three/1" element={<EnglishG13E1 />} />              
              <Route path="/G1/English/Units/Three/2" element={<EnglishG13E2 />} />           
              <Route path="/G1/English/Units/Three/3" element={<EnglishG13E3 />} />           
              <Route path="/G1/English/Units/Three/4" element={<EnglishG13E4 />} />           
              <Route path="/G1/English/Units/Three/5" element={<EnglishG13E5 />} />           
              <Route path="/G1/English/Units/Three/6" element={<EnglishG13E6 />} />           
              <Route path="/G1/English/Units/Three/7" element={<EnglishG13E7 />} />           
              <Route path="/G1/English/Units/Three/8" element={<EnglishG13E8 />} />
              <Route path="/G1/English/Units/Three/9" element={<EnglishG13E9 />} />
              <Route path="/G1/English/Units/Four/CommonCoreStandard" element={<EnglishG14CC />} />
              <Route path="/G1/English/Units/Four/1" element={<EnglishG14E1 />} />              
              <Route path="/G1/English/Units/Four/2" element={<EnglishG14E2 />} />           
              <Route path="/G1/English/Units/Four/3" element={<EnglishG14E3 />} />           
              <Route path="/G1/English/Units/Four/4" element={<EnglishG14E4 />} />           
              <Route path="/G1/English/Units/Four/5" element={<EnglishG14E5 />} />           
              <Route path="/G1/English/Units/Four/6" element={<EnglishG14E6 />} />           
              <Route path="/G1/English/Units/Four/7" element={<EnglishG14E7 />} />           
              <Route path="/G1/English/Units/Four/8" element={<EnglishG14E8 />} />
              <Route path="/G1/English/Units/Four/9" element={<EnglishG14E9 />} />
              <Route path="/G1/Science/Units" element={<G1ScienceUnits />} />
              <Route path="/G1/Science/Units/One/1" element={<ScienceG1SC1 />} />            
              <Route path="/G1/Science/Units/One/2" element={<ScienceG1SC2 />} />
              <Route path="/G1/Science/Units/One/3" element={<ScienceG1SC3 />} />
              <Route path="/G1/Science/Units/One/4" element={<ScienceG1SC4 />} />
              <Route path="/G1/Science/Units/One/5" element={<ScienceG1SC5 />} />
              <Route path="/G1/Science/Units/One/6" element={<ScienceG1SC6 />} />
              <Route path="/G1/Science/Units/One/7" element={<ScienceG1SC7 />} />
              <Route path="/G1/Science/Units/One/8" element={<ScienceG1SC8 />} />
              <Route path="/G1/Science/Units/One/9" element={<ScienceG1SC9 />} />
              <Route path="/G1/Science/Units/One/10" element={<ScienceG1SC10 />} />
              <Route path="/G1/Science/Units/One/11" element={<ScienceG1SC11 />} />
              <Route path="/G1/Science/Units/One/12" element={<ScienceG1SC12 />} />
              <Route path="/G1/Science/Units/One/13" element={<ScienceG1SC13 />} />
              <Route path="/G1/Science/Units/One/14" element={<ScienceG1SC14 />} />
              <Route path="/G1/Science/Units/One/15" element={<ScienceG1SC15 />} />
              <Route path="/G1/Science/Units/One/16" element={<ScienceG1SC16 />} />
              <Route path="/G1/Science/Units/One/17" element={<ScienceG1SC17 />} />
              <Route path="/G1/Science/Units/One/18" element={<ScienceG1SC18 />} />
              <Route path="/G1/Science/Units/One/19" element={<ScienceG1SC19 />} />
              <Route path="/G1/Science/Units/One/20" element={<ScienceG1SC20 />} />
              <Route path="/G1/Science/Units/One/21" element={<ScienceG1SC21 />} />
              <Route path="/G1/Science/Units/One/22" element={<ScienceG1SC22 />} />
              <Route path="/G1/Science/Units/One/23" element={<ScienceG1SC23 />} />
              <Route path="/G1/Science/Units/One/24" element={<ScienceG1SC24 />} />
              <Route path="/G1/Science/Units/One/25" element={<ScienceG1SC25 />} />
              <Route path="/G1/Science/Units/One/26" element={<ScienceG1SC26 />} />
              <Route path="/G1/Science/Units/One/27" element={<ScienceG1SC27 />} />
              <Route path="/G1/Science/Units/One/28" element={<ScienceG1SC28 />} />
              <Route path="/G1/Science/Units/One/29" element={<ScienceG1SC29 />} />
              <Route path="/G1/Science/Units/One/30" element={<ScienceG1SC30 />} />
              <Route path="/G1/Science/Units/One/31" element={<ScienceG1SC31 />} />
              <Route path="/G1/Science/Units/One/32" element={<ScienceG1SC32 />} />
              <Route path="/G1/Science/Units/One/33" element={<ScienceG1SC33 />} />
              <Route path="/G1/Science/Units/One/34" element={<ScienceG1SC34 />} />
              <Route path="/G1/Science/Units/Two/1" element={<ScienceG12SC1 />} />              
              <Route path="/G1/Science/Units/Two/2" element={<ScienceG12SC2 />} />
              <Route path="/G1/Science/Units/Two/3" element={<ScienceG12SC3 />} />
              <Route path="/G1/Science/Units/Two/4" element={<ScienceG12SC4 />} />
              <Route path="/G1/Science/Units/Two/5" element={<ScienceG12SC5 />} />
              <Route path="/G1/Science/Units/Two/6" element={<ScienceG12SC6 />} />
              <Route path="/G1/Science/Units/Two/7" element={<ScienceG12SC7 />} />
              <Route path="/G1/Science/Units/Two/8" element={<ScienceG12SC8 />} />
              <Route path="/G1/Science/Units/Two/9" element={<ScienceG12SC9 />} />
              <Route path="/G1/Science/Units/Three/1" element={<ScienceG13SC1 />} />              
              <Route path="/G1/Science/Units/Three/2" element={<ScienceG13SC2 />} />
              <Route path="/G1/Science/Units/Three/3" element={<ScienceG13SC3 />} />
              <Route path="/G1/Science/Units/Three/4" element={<ScienceG13SC4 />} />
              <Route path="/G1/Science/Units/Three/5" element={<ScienceG13SC5 />} />
              <Route path="/G1/Science/Units/Three/6" element={<ScienceG13SC6 />} />
              <Route path="/G1/Science/Units/Three/7" element={<ScienceG13SC7 />} />
              <Route path="/G1/Science/Units/Three/8" element={<ScienceG13SC8 />} />
              <Route path="/G1/Science/Units/Three/9" element={<ScienceG13SC9 />} />
              <Route path="/G1/Science/Units/Four/1" element={<ScienceG14SC1 />} />
              <Route path="/G1/Science/Units/Four/2" element={<ScienceG14SC2 />} />
              <Route path="/G1/Science/Units/Four/3" element={<ScienceG14SC3 />} />
              <Route path="/G1/Science/Units/Four/4" element={<ScienceG14SC4 />} />
              <Route path="/G1/Science/Units/Four/5" element={<ScienceG14SC5 />} />
              <Route path="/G1/Science/Units/Four/6" element={<ScienceG14SC6 />} />
              <Route path="/G1/SocialStudies/Units" element={<G1SocialStudiesUnits />} />
              <Route path="/G1/SocialStudies/Units/One/1" element={<SocialStudiesG1SS1 />} />
              <Route path="/G1/SocialStudies/Units/One/2" element={<SocialStudiesG1SS2 />} />
              <Route path="/G1/SocialStudies/Units/One/3" element={<SocialStudiesG1SS3 />} />
              <Route path="/G1/SocialStudies/Units/One/4" element={<SocialStudiesG1SS4 />} />              
              <Route path="/G1/SocialStudies/Units/One/5" element={<SocialStudiesG1SS5 />} />              
              <Route path="/G1/SocialStudies/Units/One/6" element={<SocialStudiesG1SS6 />} />              
              <Route path="/G1/SocialStudies/Units/One/7" element={<SocialStudiesG1SS7 />} />              
              <Route path="/G1/SocialStudies/Units/Two/1" element={<SocialStudiesG12SS1 />} />
              <Route path="/G1/SocialStudies/Units/Two/2" element={<SocialStudiesG12SS2 />} />
              <Route path="/G1/SocialStudies/Units/Two/3" element={<SocialStudiesG12SS3 />} />
              <Route path="/G1/SocialStudies/Units/Two/4" element={<SocialStudiesG12SS4 />} />              
              <Route path="/G1/SocialStudies/Units/Two/5" element={<SocialStudiesG12SS5 />} />              
              <Route path="/G1/SocialStudies/Units/Two/6" element={<SocialStudiesG12SS6 />} />              
              <Route path="/G1/SocialStudies/Units/Two/7" element={<SocialStudiesG12SS7 />} />              
              <Route path="/G1/SocialStudies/Units/Two/8" element={<SocialStudiesG12SS8 />} />              
              <Route path="/G1/SocialStudies/Units/Two/9" element={<SocialStudiesG12SS9 />} />              
              <Route path="/G1/SocialStudies/Units/Two/10" element={<SocialStudiesG12SS10 />} />              
              <Route path="/G1/SocialStudies/Units/Three/1" element={<SocialStudiesG13SS1 />} />
              <Route path="/G1/SocialStudies/Units/Three/2" element={<SocialStudiesG13SS2 />} />
              <Route path="/G1/SocialStudies/Units/Three/3" element={<SocialStudiesG13SS3 />} />
              <Route path="/G1/SocialStudies/Units/Three/4" element={<SocialStudiesG13SS4 />} />              
              <Route path="/G1/SocialStudies/Units/Three/5" element={<SocialStudiesG13SS5 />} />              
              <Route path="/G1/SocialStudies/Units/Three/6" element={<SocialStudiesG13SS6 />} />              
              <Route path="/G1/SocialStudies/Units/Three/7" element={<SocialStudiesG13SS7 />} />              
              <Route path="/G1/SocialStudies/Units/Three/8" element={<SocialStudiesG13SS8 />} />                                                      
              <Route path="/G1/SocialStudies/Units/Four/1" element={<SocialStudiesG14SS1 />} />
              <Route path="/G1/SocialStudies/Units/Four/2" element={<SocialStudiesG14SS2 />} />
              <Route path="/G1/SocialStudies/Units/Four/3" element={<SocialStudiesG14SS3 />} />
              <Route path="/G1/SocialStudies/Units/Four/4" element={<SocialStudiesG14SS4 />} />              
              <Route path="/G1/SocialStudies/Units/Four/5" element={<SocialStudiesG14SS5 />} />              
              <Route path="/G1/SocialStudies/Units/Four/6" element={<SocialStudiesG14SS6 />} />              
              <Route path="/G1/SocialStudies/Units/Four/7" element={<SocialStudiesG14SS7 />} />              
              <Route path="/G1/SocialStudies/Units/Four/8" element={<SocialStudiesG14SS8 />} />                                        
              <Route path="/G1/SocialStudies/Units/Four/9" element={<SocialStudiesG14SS9 />} />
              <Route path="/G1/SocialStudies/Units/Four/10" element={<SocialStudiesG14SS10 />} />              
              <Route path="/G1/SocialStudies/Units/Four/11" element={<SocialStudiesG14SS11 />} />              
              <Route path="/G1/SocialStudies/Units/Four/12" element={<SocialStudiesG14SS12 />} />              
              <Route path="/G1/SocialStudies/Units/Four/13" element={<SocialStudiesG14SS13 />} />              
              <Route path="/G1/SocialStudies/Units/Four/14" element={<SocialStudiesG14SS14 />} />                                        
              <Route path="/G2" element={<G2 />} />
              <Route path="/G2/English/Units" element={<G2Units />} />
              <Route path="/G2/English/Units/One/CommonCoreStandard" element={<EnglishG2CC />} />
              <Route path="/G2/English/Units/One/1" element={<EnglishG2E1 />} />              
              <Route path="/G2/English/Units/One/2" element={<EnglishG2E2 />} />           
              <Route path="/G2/English/Units/One/3" element={<EnglishG2E3 />} />           
              <Route path="/G2/English/Units/One/4" element={<EnglishG2E4 />} />           
              <Route path="/G2/English/Units/One/5" element={<EnglishG2E5 />} />           
              <Route path="/G2/English/Units/One/6" element={<EnglishG2E6 />} />           
              <Route path="/G2/English/Units/One/7" element={<EnglishG2E7 />} />           
              <Route path="/G2/English/Units/One/8" element={<EnglishG2E8 />} />
              <Route path="/G2/English/Units/Two/CommonCoreStandard" element={<EnglishG22CC />} />
              <Route path="/G2/English/Units/Two/1" element={<EnglishG22E1 />} />              
              <Route path="/G2/English/Units/Two/2" element={<EnglishG22E2 />} />           
              <Route path="/G2/English/Units/Two/3" element={<EnglishG22E3 />} />           
              <Route path="/G2/English/Units/Two/4" element={<EnglishG22E4 />} />           
              <Route path="/G2/English/Units/Two/5" element={<EnglishG22E5 />} />           
              <Route path="/G2/English/Units/Two/6" element={<EnglishG22E6 />} />           
              <Route path="/G2/English/Units/Two/7" element={<EnglishG22E7 />} />
              <Route path="/G2/English/Units/Three/CommonCoreStandard" element={<EnglishG23CC />} />
              <Route path="/G2/English/Units/Three/1" element={<EnglishG23E1 />} />              
              <Route path="/G2/English/Units/Three/2" element={<EnglishG23E2 />} />           
              <Route path="/G2/English/Units/Three/3" element={<EnglishG23E3 />} />           
              <Route path="/G2/English/Units/Three/4" element={<EnglishG23E4 />} />           
              <Route path="/G2/English/Units/Three/5" element={<EnglishG23E5 />} />           
              <Route path="/G2/English/Units/Three/6" element={<EnglishG23E6 />} />           
              <Route path="/G2/English/Units/Three/7" element={<EnglishG23E7 />} />
              <Route path="/G2/English/Units/Four/CommonCoreStandard" element={<EnglishG24CC />} />
              <Route path="/G2/English/Units/Four/1" element={<EnglishG24E1 />} />              
              <Route path="/G2/English/Units/Four/2" element={<EnglishG24E2 />} />           
              <Route path="/G2/English/Units/Four/3" element={<EnglishG24E3 />} />           
              <Route path="/G2/English/Units/Four/4" element={<EnglishG24E4 />} />           
              <Route path="/G2/English/Units/Four/5" element={<EnglishG24E5 />} />           
              <Route path="/G2/English/Units/Four/6" element={<EnglishG24E6 />} />           
              <Route path="/G2/English/Units/Four/7" element={<EnglishG24E7 />} />
              <Route path="/G2/English/Units/Four/6" element={<EnglishG24E8 />} />           
              <Route path="/G2/English/Units/Four/7" element={<EnglishG24E9 />} />
              <Route path="/G2/Science/Units" element={<G2ScienceUnits />} />
              <Route path="/G2/Science/Units/One/1" element={<ScienceG2SC1 />} />
              <Route path="/G2/Science/Units/One/2" element={<ScienceG2SC2 />} />
              <Route path="/G2/Science/Units/One/3" element={<ScienceG2SC3 />} />
              <Route path="/G2/Science/Units/One/4" element={<ScienceG2SC4 />} />
              <Route path="/G2/Science/Units/One/5" element={<ScienceG2SC5 />} />
              <Route path="/G2/Science/Units/One/6" element={<ScienceG2SC6 />} />
              <Route path="/G2/Science/Units/One/7" element={<ScienceG2SC7 />} />
              <Route path="/G2/Science/Units/One/8" element={<ScienceG2SC8 />} />
              <Route path="/G2/Science/Units/One/9" element={<ScienceG2SC9 />} />
              <Route path="/G2/Science/Units/One/10" element={<ScienceG2SC10 />} />
              <Route path="/G2/Science/Units/One/11" element={<ScienceG2SC11 />} />
              <Route path="/G2/Science/Units/One/12" element={<ScienceG2SC12 />} />
              <Route path="/G2/Science/Units/One/13" element={<ScienceG2SC13 />} />
              <Route path="/G2/Science/Units/One/13" element={<ScienceG2SC13 />} />
              <Route path="/G2/Science/Units/One/14" element={<ScienceG2SC14 />} />
              <Route path="/G2/Science/Units/One/15" element={<ScienceG2SC15 />} />
              <Route path="/G2/Science/Units/One/16" element={<ScienceG2SC16 />} />
              <Route path="/G2/Science/Units/One/17" element={<ScienceG2SC17 />} />
              <Route path="/G2/Science/Units/One/18" element={<ScienceG2SC18 />} />
              <Route path="/G2/Science/Units/One/18A" element={<ScienceG2SC18A />} />
              <Route path="/G2/Science/Units/One/19" element={<ScienceG2SC19 />} />
              <Route path="/G2/Science/Units/One/20" element={<ScienceG2SC20 />} />
              <Route path="/G2/Science/Units/One/21" element={<ScienceG2SC21 />} />
              <Route path="/G2/Science/Units/Two/1" element={<ScienceG22SC1 />} />
              <Route path="/G2/Science/Units/Two/2" element={<ScienceG22SC2 />} />
              <Route path="/G2/Science/Units/Two/3" element={<ScienceG22SC3 />} />
              <Route path="/G2/Science/Units/Two/4" element={<ScienceG22SC4 />} />
              <Route path="/G2/Science/Units/Two/5" element={<ScienceG22SC5 />} />
              <Route path="/G2/Science/Units/Two/6" element={<ScienceG22SC6 />} />
              <Route path="/G2/Science/Units/Two/7" element={<ScienceG22SC7 />} />
              <Route path="/G2/Science/Units/Two/8" element={<ScienceG22SC8 />} />
              <Route path="/G2/Science/Units/Two/9" element={<ScienceG22SC9 />} />
              <Route path="/G2/Science/Units/Two/10" element={<ScienceG22SC10 />} />
              <Route path="/G2/Science/Units/Two/11" element={<ScienceG22SC11 />} />
              <Route path="/G2/Science/Units/Three/1" element={<ScienceG23SC1 />} />
              <Route path="/G2/Science/Units/Three/2" element={<ScienceG23SC2 />} />
              <Route path="/G2/Science/Units/Three/3" element={<ScienceG23SC3 />} />
              <Route path="/G2/Science/Units/Three/4" element={<ScienceG23SC4 />} />
              <Route path="/G2/Science/Units/Three/5" element={<ScienceG23SC5 />} />
              <Route path="/G2/Science/Units/Three/6" element={<ScienceG23SC6 />} />
              <Route path="/G2/Science/Units/Three/7" element={<ScienceG23SC7 />} />
              <Route path="/G2/Science/Units/Three/8" element={<ScienceG23SC8 />} />
              <Route path="/G2/Science/Units/Three/8A" element={<ScienceG23SC8A />} />
              <Route path="/G2/Science/Units/Three/9" element={<ScienceG23SC9 />} />
              <Route path="/G2/Science/Units/Three/10" element={<ScienceG23SC10 />} />
              <Route path="/G2/Science/Units/Three/11" element={<ScienceG23SC11 />} />
              <Route path="/G2/Science/Units/Three/12" element={<ScienceG23SC12 />} />
              <Route path="/G2/Science/Units/Three/13" element={<ScienceG23SC13 />} />
              <Route path="/G2/Science/Units/Three/14" element={<ScienceG23SC14 />} />
              <Route path="/G2/Science/Units/Three/15" element={<ScienceG23SC15 />} />
              <Route path="/G2/Science/Units/Four/1" element={<ScienceG24SC1 />} />
              <Route path="/G2/Science/Units/Four/2" element={<ScienceG24SC2 />} />
              <Route path="/G2/Science/Units/Four/3" element={<ScienceG24SC3 />} />
              <Route path="/G2/Science/Units/Four/4" element={<ScienceG24SC4 />} />
              <Route path="/G2/Science/Units/Four/5" element={<ScienceG24SC5 />} />
              <Route path="/G2/Science/Units/Four/6" element={<ScienceG24SC6 />} />
              <Route path="/G2/Science/Units/Four/7" element={<ScienceG24SC7 />} />
              <Route path="/G2/Science/Units/Four/8" element={<ScienceG24SC8 />} />
              <Route path="/G2/SocialStudies/Units" element={<G2SocialStudiesUnits />} />
              <Route path="/G2/SocialStudies/Units/One/1" element={<SocialStudiesG2SS1 />} />
              <Route path="/G2/SocialStudies/Units/One/2" element={<SocialStudiesG2SS2 />} />
              <Route path="/G2/SocialStudies/Units/One/3" element={<SocialStudiesG2SS3 />} />
              <Route path="/G2/SocialStudies/Units/One/4" element={<SocialStudiesG2SS4 />} />
              <Route path="/G2/SocialStudies/Units/Two/1" element={<SocialStudiesG22SS1 />} />
              <Route path="/G2/SocialStudies/Units/Two/2" element={<SocialStudiesG22SS2 />} />
              <Route path="/G2/SocialStudies/Units/Two/3" element={<SocialStudiesG22SS3 />} />
              <Route path="/G2/SocialStudies/Units/Two/4" element={<SocialStudiesG22SS4 />} />
              <Route path="/G2/SocialStudies/Units/Three/1" element={<SocialStudiesG23SS1 />} />
              <Route path="/G2/SocialStudies/Units/Three/2" element={<SocialStudiesG23SS2 />} />
              <Route path="/G2/SocialStudies/Units/Three/3" element={<SocialStudiesG23SS3 />} />
              <Route path="/G2/SocialStudies/Units/Three/4" element={<SocialStudiesG23SS4 />} />
              <Route path="/G2/SocialStudies/Units/Four/1" element={<SocialStudiesG24SS1 />} />
              <Route path="/G2/SocialStudies/Units/Four/2" element={<SocialStudiesG24SS2 />} />
              <Route path="/G2/SocialStudies/Units/Four/3" element={<SocialStudiesG24SS3 />} />
              <Route path="/G2/SocialStudies/Units/Four/4" element={<SocialStudiesG24SS4 />} />
              <Route path="/G2/SocialStudies/Units/Four/5" element={<SocialStudiesG24SS5 />} />
              <Route path="/G2/SocialStudies/Units/Four/6" element={<SocialStudiesG24SS6 />} />
              <Route path="/G2/SocialStudies/Units/Four/7" element={<SocialStudiesG24SS7 />} />
              <Route path="/G2/SocialStudies/Units/Four/8" element={<SocialStudiesG24SS8 />} />
              <Route path="/G2/SocialStudies/Units/Four/9" element={<SocialStudiesG24SS9 />} />
              <Route path="/G2/SocialStudies/Units/Four/10" element={<SocialStudiesG24SS10 />} />
              <Route path="/G2/SocialStudies/Units/Four/11" element={<SocialStudiesG24SS11 />} />
            </Routes>
        </div>
    </Router>
  )
}