import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Vowels"} />
        <div className="p-4">
          <p>
            <strong>Vowel digraphs</strong> are two vowels next to each other
            that usually make one sound. The vowel digraphs{" "}
            <strong>ai, ea, ee, and oa</strong> often male a long vowel sound.
          </p>
          <p className="mt-4">
            Read each clue. Complete each word with the correct vowel digraph.
          </p>
        </div>
        <div>
          <div className="p-4">
            <p>1. You do this when we see guests.</p>
            <p className="pl-10">
              gr
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              t
            </p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>2. The water that falls from the sky.</p>
            <p className="pl-10">
              r
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              n
            </p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>3. You ride this on the water.</p>
            <p className="pl-10">
              b
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              t
            </p>
          </div>
        </div>
        <div className="mb-24">
          <div className="p-4">
            <p>4. You do something when it is dirty. </p>
            <p className="pl-10">
              cl
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
              n
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/4">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/One/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E5;