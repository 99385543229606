import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E8 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>Fix the sentences and write the fixed sentences.</p>
        </div>
        <div className="">
          <div className="p-4 w-full">
            <p className="text-lg">1. she was going to the store</p>
          </div>
          <div className="p-4 w-full -mt-8">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"
            />
          </div>
        </div>
        <div className="">
          <div className="p-4 w-full">
            <p className="text-lg">2. henry felt very happy</p>
          </div>
          <div className="p-4 w-full -mt-8">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"
            />
          </div>
        </div>
        <div className="">
          <div className="p-4 w-full">
            <p className="text-lg">
              3. would you take me to the lake on monday
            </p>
          </div>
          <div className="p-4 w-full -mt-8">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"
            />
          </div>
        </div>
        <div className="">
          <div className="p-4 w-full">
            <p className="text-lg">4. can you see the big bus</p>
          </div>
          <div className="p-4 w-full -mt-8">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"
            />
          </div>
        </div>
        <div className="mb-24">
          <div className="p-4 w-full">
            <p className="text-lg">5. father told me to eat my dinner</p>
          </div>
          <div className="p-4 w-full -mt-8">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/7">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Two/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E8;