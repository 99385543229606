import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>Write the names and make them plurals.</p>
        </div>
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">1. Cow</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">2. Book</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">3. Glass</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">4. Orange</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">5. Tree</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">6. Baby</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">7. Flower</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">8. Mango</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex">
          <div className="p-4 w-1/2">
            <p className="text-2xl">9. Car</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       
        <div className="flex mb-24">
          <div className="p-4 w-1/2">
            <p className="text-2xl">10. Brush</p>
          </div>
          <div className="p-4 w-1/2">
            <input
              type="text"
              className="outline-none w-full px-2 text-xl border-b-blue-500 border-b-2"              
            />
          </div>
        </div>       

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/7">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Two/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E7;