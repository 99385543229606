import React, { useState } from "react";
import { Checkbox, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import toTheStore from "../../assets/Two/toTheStore.png";
import { useSpeechSynthesis } from "react-speech-kit";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const E3 = () => {
  const speakData =
    "Katie likes to go to the store. She likes to shop. She goes to the bookstore with her mom. She shops for a book. She likes going to the food store with her dad. She likes shopping for food. She goes to the toy store with her grandfather. She buys a toy. She goes to the clothing store with her mom. She looks for a coat. She likes going to the shoe store with her grandmother. She buys new shoes. She goes to a pet store. She looks for a pet. Katie likes the pet store best. She buys a puppy.";  
  const [isDis, setIsDis] = useState(true);
  const { speak, speaking, cancel } = useSpeechSynthesis();
  const changeNext = () => {
    setIsDis(!isDis);
  };

  return (
    <div>
      <div>
        <BannerTitle title={"To the store"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={toTheStore} alt="To The Store" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Katie likes to go to the store. She likes to shop. She goes to the
            bookstore with her mom. She shops for a book. She likes going to the
            food store with her dad. She likes shopping for food. She goes to
            the toy store with her grandfather. She buys a toy. She goes to the
            clothing store with her mom. She looks for a coat. She likes going
            to the shoe store with her grandmother. She buys new shoes. She goes
            to a pet store. She looks for a pet. Katie likes the pet store best.
            She buys a puppy.
          </Typography>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can understand what i read."
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;
