import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA14 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Fill in the blanks.</p>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB1} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    readOnly
                    value={"1"}
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    readOnly
                    value={"3"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB2} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB3} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB4} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB5} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB6} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB7} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB8} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB9} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="">
            <img src={IMAGES.L3.MA16.BBB10} alt="" className="" />
          </div>
          <div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
                <div className="px-2">
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-auto">
              <div className="grid grid-cols-2">
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Tens"}
                    maxLength="1"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg"
                    readOnly
                    value={"Ones"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/15">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA14;
