import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const SS1 = () => {
  const speakData =
    "Street food in very popular in Thailand. There are lots of food stalls and carts, and you can also see vendors on bicycles, motorcycles, or boats! Some of the most typical street foods are spicy Thai noodles and sticky rice with peanuts. If you like meat you can also have some satays. They are pieces of meat that people barbecue on sticks. If you like fish, why not try some fried fish cakes? You can watch the vendor fry them right in front of you. Later you can have some mango for dessert. It's very sweet and delicious!";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"POPULAR STREET FOOD IN THE WORLD"} />
        <div className="p-4">
          <p>
            Street food in very popular in Thailand. There are lots of food
            stalls and carts, and you can also see vendors on bicycles,
            motorcycles, or boats! Some of the most typical street foods are
            spicy Thai noodles and sticky rice with peanuts. If you like meat
            you can also have some satays. They are pieces of meat that people
            barbecue on sticks. If you like fish, why not try some fried fish
            cakes? You can watch the vendor fry them right in front of you.
            Later you can have some mango for dessert. It's very sweet and
            delicious!
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SS1.StreetFood1}
            alt="StreetFood"
            className="w-full"
          />
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/SocialStudies/Units/Four/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1;
