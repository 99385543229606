import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC14 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Thermal Energy"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.SC14.ThermalEnergy}
            alt="ThermalEnergy"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Two/13">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/Two/15">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC14;
