import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA3 = () => {
  const speakData =
    "Greater than. Less than. When the digits in the largest place value are the same, you have to look at the digits in the next place value spot to the right.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Greater than or less than place value"} />
        
        <div className="p-4">
          <img src={IMAGES.L3.MA3.GL1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA3.GL2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA3.GL3} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA3;