import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const CCStandard = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "What are the Common Core Statement Standards for English Language Arts?"
          }
        />
        <div className="flex px-8 py-4 md:px-20 lg:px-20">
          <ul className="list-disc font-bold">
            <li>
              The Common Core asks student to read stories and literature, as
              well as more complex texts that provide facts and background
              knowledge in other areas such as science and social studies.
              Students will be challenged and asked questions that push them to
              refer back to what they've read. It covers the four areas of
              learning English such as; reading, writing, speaking and
              listening.
            </li>
          </ul>
        </div>
        <div className="flex px-8 py-4 md:px-20 lg:px-20 mb-20">
          <ul className="list-decimal font-bold">
            <li>Work independently.</li>
            <li>Improve critical thinking skills.</li>
            <li>
              Help the child get to know sounds, words and language, and develop
              early literacy skills.
            </li>
            <li>Learn to value books and stories.</li>
            <li>Strengthens the brains and build vocabulary.</li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Two/1">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CCStandard;