import React, { useState } from "react";
import { Checkbox, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import StoneSoup from "../../assets/Four/StoneSoup.png";
import { useSpeechSynthesis } from "react-speech-kit";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const E3 = () => {
  const speakData =
    "Fox was poor and hungry. No one would feed him. He had an idea. “ I will make stone soup for everyone. First I need a big bowl and some water,” he said. Bear brought a big pot. Elephant brought some water. Rabbit brought some carrots. Mouse brought some beans. Other animals brought some onions. They brought peas and potatoes. The stone soup had become a feast! Everyone ate until they were full.";
  const [isDis, setIsDis] = useState(true);
  const { speak, speaking, cancel } = useSpeechSynthesis();
  const changeNext = () => {
    setIsDis(!isDis);
  };

  return (
    <div>
      <div>
        <div className="flex bg-zmh-lightblue-500 text-white text-xl font-bold justify-center items-center p-6">
          <Typography variant="h4">Stone soup</Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={StoneSoup} alt="StoneSoup" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Fox was poor and hungry. No one would feed him. He had an idea. “ I
            will make stone soup for everyone. First I need a big bowl and some
            water,” he said. Bear brought a big pot. Elephant brought some
            water. Rabbit brought some carrots. Mouse brought some beans. Other
            animals brought some onions. They brought peas and potatoes. The
            stone soup had become a feast! Everyone ate until they were full.
          </Typography>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can understand what i read."
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Four/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;
