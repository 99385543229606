import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E10 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Read each groups of words."} />
        <div className="m-3 shadow-gray-500 shadow-md border-current rounded-lg">
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              The words in a sentence are in order. They tell a thought. It is a
              complete sentence.
            </Typography>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              Example: I like to play football.
            </Typography>
          </div>
        </div>
        <div className="m-3 shadow-gray-500 shadow-md border-current rounded-lg">
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              Words that are not in order do not form a complete sentence.
            </Typography>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12 mb-20">
            <Typography variant="lead">
              Example : play ball I to like
            </Typography>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/9">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Two/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E10;
