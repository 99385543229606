import React, { useState } from "react";
import { useDrop } from "react-dnd";
import ItemTypes from "./ItemType";
import Box from "./Box";

const data = [
  { id: 1, name: "win", type: ItemTypes.PERSON },
  { id: 2, name: "fin", type: ItemTypes.PERSON },
  { id: 3, name: "pin", type: ItemTypes.PERSON },
];

const Container = () => {
  const normalTarget = "flex flex-col items-center content-center pt-10 m-2 h-32 text-white";
  const canDropTarget =
    "flex flex-col items-center content-center pt-10 m-2 h-96 text-white";

  const [items, setItems] = useState(data);
  const [target, setTarget] = useState([]);

  const [{ canDrop }, dropRef] = useDrop({
    accept: ItemTypes.PERSON,
    drop: (item) => addItemToBox(item.id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const addItemToBox = (id) => {
    const itemList = items.filter((item) => id === item.id);    
    setTarget([...target, itemList[0]]);
    const newItemsList = items.filter((item) => id !== item.id);
    setItems(newItemsList);
  };

  return (
    <div>
      <div className="flex flex-wrap border-2 border-zmh-gray-500 p-2 m-2">
        {items.map((item) => (
          <Box
            draggable
            key={item.id}
            data={data}
            id={item.id}
            name={item.name}
            type={item.type}
          />
        ))}
      </div>
      <div>
        <div className="w-full h-96 bg-ice-cone bg-contain bg-no-repeat bg-center">
          <div className="p-2 m-2">
            <div
              className={canDrop ? canDropTarget : normalTarget}
              ref={dropRef}
            >
              {target.map((target) => (
                <Box
                  key={target.id}
                  id={target.id}
                  name={target.name}
                  type={target.type}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container;
