import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Join the sentences. Use the conjunctions in the box. You can use
            more than once.
          </p>
        </div>
        <div className="p-2 m-2 border-2 border-black">
          <div className="flex justify-between">
            <p>and</p>
            <p>but</p>
            <p>or</p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>
              1. We are going to the fair,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              we’re going to the beach.
            </p>
          </div>
          <div className="p-4">
            <p>
              2. The beach is fun,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              I like the rides at the fair.
            </p>
          </div>
          <div className="p-4">
            <p>
              3. I like to drive the small cars,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              I like to wave at my mother.
            </p>
          </div>
          <div className="p-4">
            <p>
              4. She likes the games,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              I love driving.
            </p>
          </div>
          <div className="p-4">
            <p>
              5. I’ll pick the sports car,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              I’ll pick the truck.
            </p>
          </div>
          <div className="p-4">
            <p>
              6. The car is pretty,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              the truck looks huge.
            </p>
          </div>
          <div className="p-4">
            <p>
              7. Maybe I can drive one,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              then I can drive the other one.
            </p>
          </div>
          <div className="p-4 mb-24">
            <p>
              8. I’ll pick the red car,{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={3}
              />{" "}
              I’ll drive the blue truck later.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Three/6">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Three/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E7;