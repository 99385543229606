import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E3 = () => {
  const speakData =
    "Children live on farms all over the world. These boys and girls learn about on the farm. Some children can milk a cow. Other children can feed the horses. Children on a farm may even help cut a sheep’s wool! Children on farms can learn about fruits and vegetables also. Boys and girls can help in the garden. They can pick apples from the apple trees. They can pick berries from the bushes. Some children can even plant the seeds by themselves! Living on a farm can be fun.";

  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Living On A Farm"} />
        <div className="p-4">
          <p>Read the story and choose the correct answers.</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.E3.LivingOnAFarm}
            alt="LivingOnAFarm"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Children live on farms all over the world. These boys and girls
            learn about on the farm. Some children can milk a cow. Other
            children can feed the horses. Children on a farm may even help cut a
            sheep’s wool!
          </p>
          <p>
            Children on farms can learn about fruits and vegetables also. Boys
            and girls can help in the garden. They can pick apples from the
            apple trees. They can pick berries from the bushes. Some children
            can even plant the seeds by themselves! Living on a farm can be fun.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;
