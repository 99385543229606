import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC4 = () => {
  const speakData =
    "Some animals like squirrels bury seeds to save for eating later during Winter. However, if they do not return to get the seed, the seed can grow into a new plant. Do you know this plant? How does this plant depend on animals? This is a Venus fly trap and it gets its nutrients by eating insects.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"Ecosystems ; Interactions, Energy, and Dynamics"}
        />
        <div className="m-2 mb-20">
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant6} alt="" className="w-full" />
          </div>
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant7} alt="" className="w-full" />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Two/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Two/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC4;