import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E3 = () => {
  const speakData =
    "Hamsters are small animals. They were found in Syria hundreds of years ago. They live in many parts of the world. Hamsters like to hoard, or keep, food in their big cheeks. Their cheeks are pouches. They store nuts and seeds inside. Hamsters are known to like to stay up at night. This means they are nocturnal. Some family members think hamsters are great pets. But hamsters like to stay up late.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Hamsters"} />
        <div className="p-4">
          <p>
            Read the text. Look at the bold words closely. Match the words with
            the correct meanings and answer the question.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.E3.Hamsters}
            alt="Hamsters"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Hamsters are small animals. They were found in Syria hundreds of
            years ago. They live in many parts of the world. Hamsters like to
            <strong> hoard</strong>, or keep, food in their big cheeks. Their
            cheeks are pouches. They store nuts and seeds inside. Hamsters are
            known to like to <strong>stay up at night</strong>. This means they
            are <strong>nocturnal</strong>. Some family members think hamsters
            are great pets. But hamsters like to stay up late.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Three/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;