import React from 'react';
import { Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets/index';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const E3 = () => {
  const speakData =
    "Buddy the bear was an old teddy bear. Buddy the Bear began to fall apart. Buddy the Bear lost an arm. We gave him a new one. Buddy the Bear lost an eye. We gave him a new one. Buddy the Bear lost an ear. We gave him a new one. Buddy the Bear looked different than before. We still love buddy the Bear just as much.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Buddy the Bear"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.Bear} alt="Bear" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Buddy the bear was an old teddy bear. Buddy the Bear began to fall
            apart. Buddy the Bear lost an arm. We gave him a new one. Buddy the
            Bear lost an eye. We gave him a new one. Buddy the Bear lost an ear.
            We gave him a new one. Buddy the Bear looked different than before.
            We still love buddy the Bear just as much.
          </Typography>
        </div>

        <div className="flex p-4">
          <div className="flex items-center h-5">
            <input
              id="my-checkbox"
              aria-describedby="my-checkbox-text"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
          <div className="ml-2 text-sm">
            <label
              htmlFor="my-checkbox"
              className="font-medium text-gray-900 dark:text-gray-300"
            >
              I can listen a story.
            </label>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/One/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/One/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E3;