import React from "react";
import { Typography, Radio, Checkbox } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Choose The Correct Answer"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. Which event happens first in the story?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="The wolf chases the sheep away."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="The boy plays a trick."
                defaultChecked
              />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="No one comes to help." />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. Why did the people not help the boy?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="They were afraid of the wolf." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA5"
                name="Q2"
                label="They didn’t believe the boy."
                defaultChecked
              />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="They were bored." />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. What event happens last in the story?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="The boy cried “Help, Wolf”." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA8"
                name="Q3"
                label="The boy learned a lesson."
                defaultChecked
              />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="The people ran to help." />
            </div>
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            color="blue"
            label="I can read a story. I can answer questions."
            defaultChecked
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/1">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Two/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E2;
