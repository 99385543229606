import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Family2IMG from "../../assets/L1/Ana2.png";
import Family3IMG from "../../assets/L1/Ana3.png";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS9 = () => {
  const speakData =
    "My mother’s name is Cathy and my dad’s name is Peter. My mother has long hair and she is very tender. My father is tall and slim and he is a very funny person. My parents got three children. There is my brother Tim, my baby brother David and me. Tim is a sweet boy who love playing and being with me all the time. He is 7 years old and he is in second grade. My baby brother, David is seven month old baby , he is full of personality and he likes being on my father’s lap.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"This is my family"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={Family2IMG} alt="Family" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            My mother’s name is Cathy and my dad’s name is Peter. My mother has
            long hair and she is very tender. My father is tall and slim and he
            is a very funny person.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={Family3IMG} alt="Family" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            My parents got three children. There is my brother Tim, my baby
            brother David and me. Tim is a sweet boy who love playing and being
            with me all the time. He is 7 years old and he is in second grade.
            My baby brother, David is seven month old baby , he is full of
            personality and he likes being on my father’s lap.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/8">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS9;
