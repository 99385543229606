import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Alphabetical order"} />
        <div className="m-3 shadow-gray-500 shadow-md border-current rounded-lg">
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              <strong>
                We can put words in order. A group of words can be put in the
                order of the alphabet by their first letters. This is called
                alphabetical order.
              </strong>
            </Typography>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              1. look at the first letter of each word.
            </Typography>
          </div>
          <div className="flex pl-10 pb-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              <u>c</u>at, <u>b</u>at, <u>f</u>at
            </Typography>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              2. Decide which letter comes first in the alphabet.{" "}
              <strong> b</strong>
            </Typography>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">3. Write this word.</Typography>
          </div>
          <div className="flex pl-10 pb-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              <u>b</u>at
            </Typography>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <Typography variant="lead">
              4. Continue until you use all of the words.
            </Typography>
          </div>
          <div className="flex pl-10 pb-4 md:p-12 lg:p-12 mb-20">
            <Typography variant="lead">
              <u>b</u>at, <u>c</u>at, <u>f</u>at
            </Typography>
          </div>
        </div>
        <br />
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/4">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E5;
