import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Fill in the blank."} />
        <div className="p-4">
          <p>
            Are you an invention? Do you have a great idea for something new?
            Write your ideas in the following blank.
          </p>
        </div>
        <div className="p-4">
          <p>
            1. Name of invention{" "}
            <input
              type="text"
              className="outline-none text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </p>
        </div>
        <div className="p-4">
          <p>2. What it is made of
            <input
              type="text"
              className="outline-none text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </p>
        </div>
        <div className="p-4">
          <p>3. What it does
            <input
              type="text"
              className="outline-none text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </p>
        </div>
        <div className="p-4">
          <p>4. Who would use it
            <input
              type="text"
              className="outline-none text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </p>
        </div>
        <div className="p-4 whitespace-nowrap">
          <p>5. How much it would cost
            <input
              type="text"
              className="outline-none w-36 text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Three/2">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;