import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Toys"} />
        <div className="p-4">
          <p>
            1. <strong>Herbivores</strong> - They eat only
            <strong> plants</strong>.
          </p>
          <p>
            2. <strong>Carnivores</strong> - They eat only
            <strong> meat (other animals)</strong>.
          </p>
          <p>
            3. <strong>Omnivores-</strong> - They eat only
            <strong> plants and meats</strong>.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC5.TypeOfAnimalsByFood}
            alt="AnimalsByFood"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Three/4">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC5;
