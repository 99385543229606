import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA2 = () => {

  return (
    <div>
      <div>
        <BannerTitle title={"Mathematical Practices"} />
        <div className="px-8 py-4">
          <ul className="list-decimal">
            <li>Make sense of problems and persevere in solving them.</li>
            <li>Reason abstractly and quantitatively.</li>
            <li>
              Construct viable arguments and critique the reasoning of others.
            </li>
            <li>Model with mathematics.</li>
            <li>Use appropriate tools strategically.</li>
            <li>Attend to precision.</li>
            <li>Look for and make use of structure.</li>
            <li>Look for and express regularity in repeated reasoning.</li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/1">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA2;
