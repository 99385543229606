import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Sun from "../../assets/Two/sun.png";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E5 = () => {
  const normalStyle =
    "w-full px-4 py-10 m-2 bg-gray-200 hover:bg-gray-300 text-gray-800 text-lg font-medium rounded-2xl";
  const correctStyle =
    "w-full px-4 py-10 m-2 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";
  const errorStyle =
    "w-full px-4 py-10 m-2 bg-red-600 hover:bg-red-700 text-white text-lg font-medium rounded-2xl";
  const showCorrectSytle =
    "w-full px-4 py-10 m-2 opacity-50 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";

  const checkAnswer = (e) => {
        if(e.currentTarget.id === '1'){
            document.getElementById(e.currentTarget.id).className = correctStyle;
            document.getElementById(2).disabled = true;    
        } else {
            document.getElementById(e.currentTarget.id).className = errorStyle;
            document.getElementById(1).className = showCorrectSytle;
            document.getElementById(1).disabled = true;    
        }
    }

  return (
    <div>
      <div>
        <BannerTitle
          title={"Look at the picture and choose the correct answer."}
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">2.</Typography>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={Sun} alt="Sun" className="h-full w-full" />
          </div>
        </div>
        <div className="flex bg-white p-4 mb-20">
          <button onClick={checkAnswer} id={1} className={normalStyle}>
            Yellow
          </button>
          <button onClick={checkAnswer} id={2} className={normalStyle}>
            Red
          </button>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/4">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E5;
