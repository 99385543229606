import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS7 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Please label the pictures by using the words in the box."}
        />
        <div className="flex justify-between p-3 m-3 border-2 border-gray-700">
          <div>Countryside</div>
          <div>City</div>
          <div>Village</div>
          <div>Town</div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2">
            <img src={IMAGES.L2.SS7.L2Q1} alt="Q1" className="w-full rounded-lg shadow-lg" />
            <input
              className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
              type="text" placeholder="Answer Here"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2">
            <img src={IMAGES.L2.SS7.L2Q2} alt="Q2" className="w-full rounded-lg shadow-lg" />
            <input
              className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
              type="text" placeholder="Answer Here"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2">
            <img src={IMAGES.L2.SS7.L2Q3} alt="Q3" className="w-full rounded-lg shadow-lg" />
            <input
              className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
              type="text" placeholder="Answer Here"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <div className="mb-2">
            <img src={IMAGES.L2.SS7.L2Q4} alt="Q4" className="w-full rounded-lg shadow-lg" />
            <input
              className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
              type="text" placeholder="Answer Here"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/6">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS7;
