import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton";
import UnitsButton from "../components/UnitsButton";

const G2 = () => {
  return (
    <div className="bg-white">
      <div>
        <div className="flex flex-col pt-8 pb-16 px-10 justify-center bg-gradient-to-t from-zmh-lightblue-down to-zmh-lightblue-top">
          <div className="font-bold text-8xl text-white">G2</div>
          <div className="text-4xl text-white">Grade 2</div>
          <div className="text-zmh-lightblue-down">.</div>
        </div>
      </div>
      <div className="bg-white rounded-t-3xl -mt-10">
        <div className="m-4 p-4 grid grid-cols-2 gap-4">
          <div>
            <Link to="/G2/English/Units">
              <UnitsButton title={"English"} />
            </Link>
          </div>
          <div>
            <Link to="/G2/Math/Units">
              <UnitsButton title={"Math"} />
            </Link>
          </div>
          <div>
            <Link to="/G2/Science/Units">
              <UnitsButton title={"Science"} />
            </Link>
          </div>
          <div>
            <Link to="/G2/SocialStudies/Units">
              <UnitsButton title={"Social Studies"} />
            </Link>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex w-full justify-center items-center p-4">
            <Link to="/">
              <BackButton />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default G2;