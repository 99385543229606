import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC2 = () => {
  const speakData =
    "Plants Depend on Animals. Think about how plants need animals. Plants need animals to help with pollination, distribution or spreading of seeds, and obtaining air. How are these insects helping the plants? These insects are pollinators. They help plants by picking up pollen on their bodies and transporting it to other flowers.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"Ecosystems ; Interactions, Energy, and Dynamics"}
        />
        <div className="m-2 mb-20">
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant1} alt="" className="w-full" />
          </div>
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant2} alt="" className="w-full" />
          </div>
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant3} alt="" className="w-full" />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Two/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Two/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC2;