import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA11 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Add Using Place Value Chart"} />
        <div className="p-4">
          <p>
            Represent the numbers on the place value chart and find the sum.
          </p>
        </div>
        <div className="p-4">
          <div className=" font-body text-2xl text-center mb-6">
            <p>
              812 + 5 ={" "}
              <input
                type="text"
                className="outline-none w-24 text-center text-2xl border-2 border-black"
                maxLength={3}
              />
            </p>
          </div>
          <div className="grid grid-cols-4 gap-2 border-2 border-gray-500 bg-gray-500 text-white text-center">
            <div></div>
            <div>
              <p>Hundreds</p>
            </div>
            <div>
              <p>Tens</p>
            </div>
            <div>
              <p>Ones</p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-dashed border-2 border-gray-600 text-center">
            <div>
              <p>812</p>
            </div>
            <div>
              <p>8</p>
            </div>
            <div>
              <p>1</p>
            </div>
            <div>
              <p>2</p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-dashed border-b-2 border-l-2 border-r-2 border-gray-600 text-center">
            <div>
              <p>7</p>
            </div>
            <div>
              <p></p>
            </div>
            <div>
              <p></p>
            </div>
            <div>
              <p>7</p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-dashed border-b-2 border-l-2 border-r-2 border-gray-600 text-center">
            <div>
              <p></p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl bg-blue-300 text-white border border-black"
                maxLength={3}
              />
              <p>Hundreds</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl bg-indigo-300 text-white border border-black"
                maxLength={3}
              />
              <p>Tens</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl bg-gray-200 border border-black"
                maxLength={3}
              />
              <p>Ones</p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className=" font-body text-2xl text-center mb-6">
            <p>
              365 + 4 ={" "}
              <input
                type="text"
                className="outline-none w-24 text-center text-2xl border-2 border-black"
                maxLength={3}
              />
            </p>
          </div>
          <div className="grid grid-cols-4 gap-2 border-2 border-gray-500 bg-gray-500 text-white text-center">
            <div></div>
            <div>
              <p>Hundreds</p>
            </div>
            <div>
              <p>Tens</p>
            </div>
            <div>
              <p>Ones</p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-dashed border-2 border-gray-600 text-center">
            <div>
              <p>365</p>
            </div>
            <div>
              <p>
                <input
                  type="text"
                  className="outline-none w-full h-full text-center text-2xl"
                  maxLength={3}
                />
              </p>
            </div>
            <div>
              <p>
                <input
                  type="text"
                  className="outline-none w-full h-full text-center text-2xl"
                  maxLength={3}
                />
              </p>
            </div>
            <div>
              <p>
                <input
                  type="text"
                  className="outline-none w-full h-full text-center text-2xl"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-dashed border-b-2 border-l-2 border-r-2 border-gray-600 text-center">
            <div>
              <p>4</p>
            </div>
            <div>
              <p></p>
            </div>
            <div>
              <p></p>
            </div>
            <div>
              <p>
                <input
                  type="text"
                  className="outline-none w-full h-full text-center text-2xl"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-dashed border-b-2 border-l-2 border-r-2 border-gray-600 text-center">
            <div>
              <p></p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl bg-blue-300 text-white border border-black"
                maxLength={3}
              />
              <p>Hundreds</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl bg-indigo-300 text-white border border-black"
                maxLength={3}
              />
              <p>Tens</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl bg-gray-200 border border-black"
                maxLength={3}
              />
              <p>Ones</p>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Two/10">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Two/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA11;
