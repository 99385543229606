import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E3 = () => {
  const speakData =
    "Sarah likes to play dress-up. She likes to dress as a bride. Sarah will put on a long white dress and drape a pretty shawl on her head. She even has some roses. Sometimes she will put on her big sister’s dance outfit. Then, she will do a dance. Sarah also thinks it is fun to be a clown. She will put on her dad’s shoes and color her nose red. Sarah likes playing mom best. She dresses up in her mom’s clothes. She takes her bag and shops for food in the kitchen pantry. Her doll is always her baby. She feeds her baby. Then, she signs her baby to sleep.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Playing Dress-Up"} />
        <div className="p-4">
          <p>Read the passage and answer the questions.</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.E3.PlayingDressUp}
            alt="PlayingDressUp"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Sarah likes to play dress-up. She likes to dress as a bride. Sarah
            will put on a long white dress and drape a pretty shawl on her head.
            She even has some roses. Sometimes she will put on her big sister’s
            dance outfit. Then, she will do a dance. Sarah also thinks it is fun
            to be a clown. She will put on her dad’s shoes and color her nose
            red.
          </p>
          <p>
            {" "}
            Sarah likes playing mom best. She dresses up in her mom’s clothes.
            She takes her bag and shops for food in the kitchen pantry. Her doll
            is always her baby. She feeds her baby. Then, she signs her baby to
            sleep.
          </p>
          <p>Playing dress-up is a lot of fun.</p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;