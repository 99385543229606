import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS3 = () => {
  const speakData =
    "High Landforms. Some landforms rise way above the land around them. Mountains are the tallest landform, at least 1,000 feet above the surrounding land. Mountains usually have steep sloping sides and often come to a point at the top, while others are more round at the top. Hills also rise above the land, but are not as tall as mountains. Their tops are usually rounded instead of pointed. A third kind of tall landform is a plateau. A plateau is very flat on the top, like a plate or table. It is made in an interesting way: by forces inside the Earth pushing big areas of rock and land upward.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Land Forms"} />
        <div className="p-4">
          <p className="font-bold text-center">High Landforms</p>
        </div>
        <div className="p-4">
          <p>Some landforms rise way above the land around them.</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF4} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Mountains are the tallest landform, at least 1,000 feet above the
            surrounding land. Mountains usually have steep sloping sides and
            often come to a point at the top, while others are more round at the
            top
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF5} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Hills also rise above the land, but are not as tall as mountains.
            Their tops are usually rounded instead of pointed.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF6} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            A third kind of tall landform is a <strong>plateau</strong>. A
            plateau is very flat on the top, like a plate or table. It is made
            in an interesting way: by forces inside the Earth pushing big areas
            of rock and land upward.
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Three/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;
