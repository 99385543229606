import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E8 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Read each pair of sentences. Write the word from the word bank that
            makes sense in both sentences.
          </p>
        </div>
        <div className="p-2 m-2 border-2 border-black">
          <div className="flex justify-between">
            <p>back</p>
            <p>fall</p>
            <p>roll</p>
            <p>saw</p>
            <p>star</p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              1. Matt{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              a lion at the zoo.
            </p>
            <p>
              Dad cut the tree with a{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              .
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              2. Leaves change colors in the{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              .
            </p>
            <p>
              Raindrops{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              from the sky.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              3. Jan was the{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              of the show.
            </p>
            <p>
              She drew a{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              on her paper.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              4. I ate a{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              at lunch.
            </p>
            <p>
              Sam will{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              the ball to her.
            </p>
          </div>
        </div>
        <div className="mb-24">
          <div className="p-4">
            <p>
              5. Ann shut the{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              door.
            </p>
            <p>
              Tim swam on his{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={4}
              />{" "}
              .
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/7">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Four/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E8;