import React from "react";
import { Link } from "react-router-dom";
import FMTree from '../../assets/L1/FMTree.jpg';
import AnaFMTree from '../../assets/L1/AnaFMTree.jpg';
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS12 = () => {
  return (
    <div>
      <div>        
        <BannerTitle title={"Family Tree"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={FMTree} alt="Family Tree" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={AnaFMTree}
            alt="Ana's Family Tree"
            className="h-full w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/11">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/One/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS12;
