import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E4 = () => {
  const normalStyle =
    "px-2 ml-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded";
  const correctStyle =
    "px-2 ml-2 bg-green-500 hover:bg-green-600 text-white rounded";
  const errorStyle = 
    "px-2 ml-2 bg-red-600 hover:bg-red-700 text-white rounded";
  const showCorrectSytle =
    "px-2 ml-2 opacity-50 bg-green-500 hover:bg-green-600 text-white rounded";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":        
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":        
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":        
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":        
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":        
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("5").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":        
        document.getElementById("6").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":        
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":        
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":                
        document.getElementById("10").className = showCorrectSytle;
        document.getElementById("9").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":        
        document.getElementById("10").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      default:
      // code block
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div>
          <div className="p-4">
            <p>Choose the proper verb in each of the sentences below.</p>
          </div>
          <div className="p-4">
            <p>
              1. Good weather (
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                are
              </button>
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                is
              </button>
              {" ) "}
              important when on holiday.
            </p>
          </div>
          <div className="p-4">
            <p>
              2. The animals in the forest (
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                was
              </button>
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                were
              </button>
              {" ) "}
              restless.
            </p>
          </div>
          <div className="p-4">
            <p>
              3. The students(
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                comes
              </button>
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                come
              </button>
              {" ) "}
              to the gym every week.
            </p>
          </div>
          <div className="p-4">
            <p>
              4. Jim and Jackie (
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                is
              </button>
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                are
              </button>
              {" ) "}
              cousins.
            </p>
          </div>
          <div className="p-4">
            <p>
              5. Paul (
              <button onClick={checkAnswer} id="9" className={normalStyle}>
                are
              </button>
              <button onClick={checkAnswer} id="10" className={normalStyle}>
                is
              </button>
              {" ) "}
              leaving early.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Four/3">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Four/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E4;
