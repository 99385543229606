import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { IMAGES } from "../../assets";

const E2 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 px-10 py-2 rounded-full text-black m-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-600 px-10 py-2 rounded-full text-white m-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-600 px-10 py-2 rounded-full text-white m-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-green-600 px-10 py-2 rounded-full text-white m-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
         document.getElementById("2").className = showCorrectSytle;
         document.getElementById("1").className = errorStyle;
         document.getElementById("1").disabled = true;
         document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("3").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("3").className = showCorrectSytle;
        document.getElementById("4").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("5").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("6").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Super Silent e"} />
        <div className="p-4">
          <p>
            <strong>Directions:</strong> Look at the following pictures. Decide
            if the word has a short or long vowel sound. Choose the correct word.
          </p>
        </div>
        <div className="mb-20">
          <div>
            <div className="p-4">
              <img
                src={IMAGES.L2.E2.SSL1}
                alt="SuperSlientE"
                className="w-24 h-24 mx-auto"
              />
            </div>
            <div className="flex bg-white p-4 md:p-12 lg:p-12 justify-center -mt-5">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                tub
              </button>
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                tube
              </button>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img
                src={IMAGES.L2.E2.SSL2}
                alt="SuperSlientE"
                className="w-24 h-24 mx-auto"
              />
            </div>
            <div className="flex bg-white p-4 md:p-12 lg:p-12 justify-center -mt-5">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                Rat
              </button>
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                Rate
              </button>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img
                src={IMAGES.L2.E2.SSL3}
                alt="SuperSlientE"
                className="w-24 h-24 mx-auto"
              />
            </div>
            <div className="flex bg-white p-4 md:p-12 lg:p-12 justify-center -mt-5">
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                Can
              </button>
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                Cane
              </button>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img
                src={IMAGES.L2.E2.SSL4}
                alt="SuperSlientE"
                className="w-24 h-24 mx-auto"
              />
            </div>
            <div className="flex bg-white p-4 md:p-12 lg:p-12 justify-center -mt-5">
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                Rob
              </button>
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                Robe
              </button>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Two/1">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Two/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E2;
