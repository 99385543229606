import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Count the objects"} />        
        <div className="flex p-4">
          <div className="w-1/2 mr-2">
            <img src={IMAGES.L1.MA7.Cow} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-24 p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 mr-2">
            <img src={IMAGES.L1.MA7.Tree} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-24 p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 mr-2">
            <img src={IMAGES.L1.MA7.Fish} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-24 p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 mr-2">
            <img src={IMAGES.L1.MA7.Ball} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-24 p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 mr-2">
            <img src={IMAGES.L1.MA7.Candy} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-24 p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/6">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/One/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA7;