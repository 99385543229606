import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />

        <div className="p-4">
          <div className="mb-4">
            <p>1) Write down which digit is in <b>ones</b> place:</p>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"167"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                readOnly
                value={"7"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"425"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"319"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"830"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"56"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"902"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"621"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"83"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>            
          </div>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <p>2) Write down which digit is in <b>tens</b> place:</p>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"273"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"194"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"832"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"508"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"42"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"315"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"209"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"93"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>            
          </div>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <p>2) Write down which digit is in <b>hundreds</b> place:</p>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"635"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"302"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"128"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"39"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"271"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"840"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 mb-4">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"65"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-b-2 border-t-2 border-l-2 border-black"
                readOnly
                value={"481"}
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-r-2 border-b-2 border-t-2 border-dashed border-black"
                maxLength="2"
              />
            </div>            
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Two/4">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA5;