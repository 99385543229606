import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS7 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Different Modes of Transportation Past and Present"}
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS7.DiffTransport}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/6">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Three/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS7;
