import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Suffixes er and est "} />
        <div className="p-4">
          <p>
            <strong>Example: </strong> The puppy is small<strong>er</strong>{" "}
            than its mom. This puppy is the small<strong>est</strong> puppy in
            the litter.
          </p>
        </div>
        <div className="flex p-4">
          <div className=" p-4 mx-auto border-2 border-blue-500 rounded-lg">
            + er
          </div>
          <div className=" p-4 mx-auto border-2 border-blue-500 rounded-lg">
            + est
          </div>
        </div>
        <div className="p-4 mb-20">
          <div className="flex mb-2">
            <div className="w-1/3">
              <p>1. Loud</p>
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/3">
              <p>2. Old</p>
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/3">
              <p>3. Neat</p>
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/3">
              <p>4. Fast</p>
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/3">
              <p>5. Kind</p>
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-1/3">
              <p>6. Tall</p>
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
            <div className="w-1/3 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Two/4">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E5;