import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>Sort the words in the word bank into the groups.</p>
        </div>
        <div className="p-2 m-2 border-2 border-black mb-4">
          <div className="flex justify-between">
            <p>apple</p>
            <p>banana</p>
            <p>bowl</p>
            <p>coat</p>
          </div>
          <div className="flex justify-between">
            <p>dish</p>
            <p>fork</p>
            <p>glove</p>
            <p>hat</p>
          </div>
          <div className="flex justify-between">
            <p>orange</p>
            <p>pear</p>
            <p>scarf</p>
            <p>spoon</p>
          </div>
        </div>
        <div className="m-2">
          <table className="table-auto w-full border-collapse border border-black">
            <thead>
              <tr>
                <th className="border border-black p-2">Fruits We Eat</th>
                <th className="border border-black p-2">
                  Things We Wear In Cold Weather
                </th>
                <th className="border border-black p-2">
                  Things We Use To Eat With
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/5">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Four/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;