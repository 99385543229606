import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS2 = () => {
  const speakData =
    "In the past, children had to walk everywhere. From home to school, to the park, to the shops and to see their friends or families. Only very wealthy families had cars and children didn’t often get to ride in them. Do you like walking? Imagine if you had to walk everywhere, for everything! Children would have had very sore feet in the olden days.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Transport : Past and Present"} />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            In the past, children had to walk everywhere. From home to school,
            to the park, to the shops and to see their friends or families. Only
            very wealthy families had cars and children didn’t often get to ride
            in them.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS2.Transport1}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="px-2 py-4 ml-4 md:p-12 lg:p-12 bg-blue-700 text-white mb-24">
          <p>
            Do you like walking? Imagine if you had to walk everywhere, for
            everything! Children would have had very sore feet in the olden
            days.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/1A">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
