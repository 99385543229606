import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS2 = () => {
  const normalStyle = "px-2 bg-gray-200 hover:bg-gray-200 rounded text-black";
  const correctStyle =
    "px-2 bg-green-500 hover:bg-green-600 rounded text-white";
  const errorStyle = "px-2 bg-red-500 hover:bg-red-600 rounded text-white";
  const showCorrectSytle =
    "px-2 opacity-50 bg-green-500 hover:bg-green-600 rounded text-white";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("9").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("9").className = showCorrectSytle;
        document.getElementById("10").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "11":
        document.getElementById("11").className = correctStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":
        document.getElementById("11").className = showCorrectSytle;
        document.getElementById("12").className = errorStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"True or False"} />
        <div className="p-4">
          <p>
            1. People started wearing clothes about 200 years ago.{" "}
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              true
            </button>
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              false
            </button>
          </p>
        </div>
        <div className="p-4">
          <p>
            2. About f,000 years ago, people learned to weave threads together
            to make pieces of fabric.
            <button onClick={checkAnswer} id="3" className={normalStyle}>
              true
            </button>
            <button onClick={checkAnswer} id="4" className={normalStyle}>
              false
            </button>
          </p>
        </div>
        <div className="p-4">
          <p>
            3. The linen tunic from ancient Egypt is now in a museum.
            <button onClick={checkAnswer} id="5" className={normalStyle}>
              true
            </button>
            <button onClick={checkAnswer} id="6" className={normalStyle}>
              false
            </button>
          </p>
        </div>
        <div className="p-4">
          <p>
            4. Fabric usually last long.
            <button onClick={checkAnswer} id="7" className={normalStyle}>
              true
            </button>
            <button onClick={checkAnswer} id="8" className={normalStyle}>
              false
            </button>
          </p>
        </div>
        <div className="p-4">
          <p>
            5. With spinning wheels, people could make thread more quickly, so
            making fabric was easier.
            <button onClick={checkAnswer} id="9" className={normalStyle}>
              true
            </button>
            <button onClick={checkAnswer} id="10" className={normalStyle}>
              false
            </button>
          </p>
        </div>
        <div className="p-4">
          <p>
            6. Fabrics became more comfortable.
            <button onClick={checkAnswer} id="11" className={normalStyle}>
              true
            </button>
            <button onClick={checkAnswer} id="12" className={normalStyle}>
              false
            </button>
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Three/1">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
