import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <img src={IMAGES.L3.UQ2} alt="" />
        </div>
        <div className="flex mx-2 border border-black justify-between p-4">
          <div>morning</div>
          <div>evening</div>
          <div>sun</div>
          <div>moon</div>
          <div>afternoon</div>
        </div>
        <div className="p-4">
          <p>
            Every{" "}
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-28 text-center"
              type="text"
              maxLength={9}
            />, the sun rises in the east
          </p>
        </div>
        <div className="p-4">
          <p>
            By the{" "}
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-28 text-center"
              type="text"
              maxLength={9}
            />, the sun is high in the sky
          </p>
        </div>
        <div className="p-4">
          <p>
            In the{" "}
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-28 text-center"
              type="text"
              maxLength={9}
            />, the sun sets in the west
          </p>
        </div>
        <div className="p-4">
          <p>
            At night, we cannot see the{" "}
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-28 text-center"
              type="text"
              maxLength={9}
            />
          </p>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Three/4">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC6;
