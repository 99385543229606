import React from "react";
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E1 = () => {
  const speakData =
    "Butterflies are lovely to look at, but here is how to make one you can eat! You will need: 2 frozen pancakes, 1 banana, 2 grapes, 2 pieces of link sausages, 2 toothpicks and jelly. Here is how to make it: Toast the pancakes and cut them in half. This will make the four pieces you will need for the four wings of the butterfly. Peel the banana and place it on the plate. This will be the butterfly’s body. Place the cut sides of the pancakes next to the banana to form the butterfly’s wings. Spread jelly on the “wings.” Use the toothpicks to hold the grapes on the banana as eyes. Cook the sausages and place them at the top of the banana as antennae.";

  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Butterflies"} />
        <div className="p-4">
          <p>Read the passage and answer the questions.</p>
        </div>
        <div className="p-4">
          <p>
            Butterflies are lovely to look at, but here is how to make one you
            can eat! You will need: 2 frozen pancakes, 1 banana, 2 grapes, 2
            pieces of link sausages, 2 toothpicks and jelly.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.E1.Butterflies}
            alt="Butterflies"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 m-4 mb-20">
          <p>Here is how to make it:</p>
          <ul className="list-disc">
            <li>
              Toast the pancakes and cut them in half. This will make the four
              pieces you will need for the four wings of the butterfly.
            </li>
            <li>
              Peel the banana and place it on the plate. This will be the
              butterfly’s body.
            </li>
            <li>
              Place the cut sides of the pancakes next to the banana to form the
              butterfly’s wings.
            </li>
            <li>Spread jelly on the “wings.”</li>
            <li>
              Use the toothpicks to hold the grapes on the banana as eyes.
            </li>
            <li>
              Cook the sausages and place them at the top of the banana as
              antennae.
            </li>
          </ul>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Four/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;