import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS22 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Label the rooms in a house."} />
        <div className="flex justify-between p-3 m-3 border-2 border-gray-700">
          <div>Bedroom</div>
          <div>Living Room</div>
          <div>Bathroom</div>
          <div>Kitchen</div>
        </div>
        <div>
          <div className="p-4 md:p-12 lg:p-12 mb-20">
            <img
              src={IMAGES.L2.SS22.QHouse}
              alt="House"
              className="w-full mx-auto"
            />
            <div className="flex p-3">
              <input
                className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
                type="text"
                value="A."
                disabled
              />
              <input
                className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
            <div className="flex p-3">
              <input
                className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
                type="text"
                value="B."
                disabled
              />
              <input
                className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
            <div className="flex p-3">
              <input
                className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
                type="text"
                value="C."
                disabled
              />
              <input
                className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
            <div className="flex p-3">
              <input
                className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
                type="text"
                value="D."
                disabled
              />
              <input
                className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/21">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/23">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS22;