import React from "react";
import { Typography, Radio, Checkbox } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS11 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "Answer the following questions and choose the correct answer."
          }
        />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">1. How old is Ana ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="4 years old" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="5 years old" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="6 years old" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">2. Where does Ana live ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="England" />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="USA" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="Italy" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. How many brother has she got ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="One brother" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Two brothers" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Three brothers" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. How many cousins has Ana got ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA10" name="Q4" label="2 cousins" />
            </div>
            <div className="flex-row">
              <Radio id="QA11" name="Q4" label="3 cousins" />
            </div>
            <div className="flex-row">
              <Radio id="QA12" name="Q4" label="4 cousins" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              5. What kind of pet does Ana has ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA10" name="Q4" label="A cat" />
            </div>
            <div className="flex-row">
              <Radio id="QA11" name="Q4" label="A bird" />
            </div>
            <div className="flex-row">
              <Radio id="QA12" name="Q4" label="A dog" />
            </div>
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            color="blue"
            label="I can answer questions."
            defaultChecked
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/10">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/One/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS11;
