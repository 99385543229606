import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS10 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Some people live in…"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Cottage</p>
            <img
              src={IMAGES.L2.SS10.Cottage}
              alt="Cottage"
              className="w-full"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Hut</p>
            <img
              src={IMAGES.L2.SS10.Hut}
              alt="Hut"
              className="w-full"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Bungalow</p>
            <img
              src={IMAGES.L2.SS10.Bungalow}
              alt="Bungalow"
              className="w-full"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Villa</p>
            <img
              src={IMAGES.L2.SS10.Villa}
              alt="Villa"
              className="w-full"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/9">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS10;
