import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Finish the number sentences. Use the number lines to help."}
        />
        <div className="p-4">
          <div>
            <div className="">
              <img src={IMAGES.L1.MA9.NumberLine} alt="" className="w-full" />
            </div>
            <div className="flex w-1/2 mx-auto mt-2">
              <p className="font-bold text-2xl m-2">9</p>
              <p className="font-bold text-2xl m-2">+</p>
              <p className="font-bold text-2xl m-2">7</p>
              <p className="font-bold text-2xl m-2">=</p>
              <input
                type="text"
                className="outline-none w-full pl-4 text-2xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
          <div>
            <div className="">
              <img src={IMAGES.L1.MA9.NumberLine} alt="" className="w-full" />
            </div>
            <div className="flex w-1/2 mx-auto mt-2">
              <p className="font-bold text-2xl m-2">14</p>
              <p className="font-bold text-2xl m-2">+</p>
              <p className="font-bold text-2xl m-2">3</p>
              <p className="font-bold text-2xl m-2">=</p>
              <input
                type="text"
                className="outline-none w-full pl-4 text-2xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
          <div>
            <div className="">
              <img src={IMAGES.L1.MA9.NumberLine} alt="" className="w-full" />
            </div>
            <div className="flex w-1/2 mx-auto mt-2">
              <p className="font-bold text-2xl m-2">17</p>
              <p className="font-bold text-2xl m-2">+</p>
              <p className="font-bold text-2xl m-2">2</p>
              <p className="font-bold text-2xl m-2">=</p>
              <input
                type="text"
                className="outline-none w-full pl-4 text-2xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
          <div>
            <div className="">
              <img src={IMAGES.L1.MA9.NumberLine} alt="" className="w-full" />
            </div>
            <div className="flex w-1/2 mx-auto mt-2">
              <p className="font-bold text-2xl m-2">5</p>
              <p className="font-bold text-2xl m-2">+</p>
              <p className="font-bold text-2xl m-2">11</p>
              <p className="font-bold text-2xl m-2">=</p>
              <input
                type="text"
                className="outline-none w-full pl-4 text-2xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
          <div>
            <div className="">
              <img src={IMAGES.L1.MA9.NumberLine} alt="" className="w-full" />
            </div>
            <div className="flex w-1/2 mx-auto mt-2">
              <p className="font-bold text-2xl m-2">6</p>
              <p className="font-bold text-2xl m-2">+</p>
              <p className="font-bold text-2xl m-2">6</p>
              <p className="font-bold text-2xl m-2">=</p>
              <input
                type="text"
                className="outline-none w-full pl-4 text-2xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
          <div>
            <div className="">
              <img src={IMAGES.L1.MA9.NumberLine} alt="" className="w-full" />
            </div>
            <div className="flex w-1/2 mx-auto mt-2">
              <p className="font-bold text-2xl m-2">8</p>
              <p className="font-bold text-2xl m-2">+</p>
              <p className="font-bold text-2xl m-2">12</p>
              <p className="font-bold text-2xl m-2">=</p>
              <input
                type="text"
                className="outline-none w-full pl-4 text-2xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
          <div>
            <div className="">
              <img src={IMAGES.L1.MA9.NumberLine} alt="" className="w-full" />
            </div>
            <div className="flex w-1/2 mx-auto mt-2">
              <p className="font-bold text-2xl m-2">13</p>
              <p className="font-bold text-2xl m-2">+</p>
              <p className="font-bold text-2xl m-2">3</p>
              <p className="font-bold text-2xl m-2">=</p>
              <input
                type="text"
                className="outline-none w-full pl-4 text-2xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/One/8">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/One/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;