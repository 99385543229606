import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC8 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div>
          <div className="p-4">
            <p className="text-center">Animal</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L2.Q1} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div className="flex">
            <div className="px-2 text-center">Number of legs</div>
            <div className="px-2 text-center">Number of wings</div>
            <div className="px-2 text-center">How does it move?</div>
          </div>
          <div className="flex">
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="text-center">Animal</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L2.Q2} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div className="flex">
            <div className="px-2 text-center">Number of legs</div>
            <div className="px-2 text-center">Number of wings</div>
            <div className="px-2 text-center">How does it move?</div>
          </div>
          <div className="flex">
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="text-center">Animal</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L2.Q3} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div className="flex">
            <div className="px-2 text-center">Number of legs</div>
            <div className="px-2 text-center">Number of wings</div>
            <div className="px-2 text-center">How does it move?</div>
          </div>
          <div className="flex">
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="text-center">Animal</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L2.Q4} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div className="flex">
            <div className="px-2 text-center">Number of legs</div>
            <div className="px-2 text-center">Number of wings</div>
            <div className="px-2 text-center">How does it move?</div>
          </div>
          <div className="flex">
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="text-center">Animal</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L2.Q5} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div className="flex">
            <div className="px-2 text-center">Number of legs</div>
            <div className="px-2 text-center">Number of wings</div>
            <div className="px-2 text-center">How does it move?</div>
          </div>
          <div className="flex">
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="text-center">Animal</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L2.Q6} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div className="flex">
            <div className="px-2 text-center">Number of legs</div>
            <div className="px-2 text-center">Number of wings</div>
            <div className="px-2 text-center">How does it move?</div>
          </div>
          <div className="flex">
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="text-center">Animal</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L2.Q7} alt="Quiz" className="w-1/2 mx-auto" />
          </div>
          <div className="flex">
            <div className="px-2 text-center">Number of legs</div>
            <div className="px-2 text-center">Number of wings</div>
            <div className="px-2 text-center">How does it move?</div>
          </div>
          <div className="flex">
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="w-1/3 px-4">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Two/7">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC8;
