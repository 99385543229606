import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { IMAGES } from "../../assets/index";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SS18 = () => {
   useEffect(() => console.log("mounted"), []);
  return (
    <div>
      <div>
        <BannerTitle title={"Activities that family do together"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <LazyLoadImage
            
            src={IMAGES.L1.SS16.ActivitiesIMG}
            alt="Activities"
            className="border-2 border-zmh-gray-500 w-full"
          />
        </div>
        <div className="flex-row p-4 md:p-12 lg:p-12 text-center mb-20">
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Talk
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.TalkIMG}
              alt="Talk"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Walk
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.WalkIMG}
              alt="Walk"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Make Food
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.MakeFoodIMG}
              alt="Make Food"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Go On Holiday
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.GoOnHolidayIMG}
              alt="GoOnHoliday"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Go On Picnic
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.GoOnPicnicIMG}
              alt="GoOnPicnic"
              className="w-full"
            />
          </div>

          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Eat
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.EatIMG}
              alt="Eat"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Play
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.PlayIMG}
              alt="Play"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Read
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.ReadIMG}
              alt="Read"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Do The House Chores
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.DoTheHouseChoresIMG}
              alt="DoTheHouseChores"
              className="w-full"
            />
          </div>
          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Go Shopping
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.GoShoppingIMG}
              alt="GoShopping"
              className="w-full"
            />
          </div>

          <div className="border-zmh-gray-500 border-2 mb-2">
            <p className="w-full p-2 bg-zmh-gray-500 text-white text-2xl">
              Explore
            </p>
            <LazyLoadImage
              
              src={IMAGES.L1.SS16.ExploreIMG}
              alt="Explore"
              className="w-full"
            />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/17">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/One/19">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS18;
