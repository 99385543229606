import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E8 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>Fix the sentences and write the fixed sentences.</p>
        </div>
        <div className="m-2">
          <table className="table-auto w-full border-collapse border border-black">
            <thead>
              <tr>
                <th className="border border-black p-2"></th>
                <th className="border border-black p-2 whitespace-nowrap">Sometimes, I ………</th>
                <th className="border border-black">Right now, I am ………</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-2">1</td>
                <td className="border border-black p-2">talk</td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"                    
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">2</td>
                <td className="border border-black p-2">sleep</td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"                    
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">3</td>
                <td className="border border-black p-2">watch</td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"                    
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">4</td>
                <td className="border border-black p-2">cook</td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"                    
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">5</td>
                <td className="border border-black p-2">cry</td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"                    
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">6</td>
                <td className="border border-black p-2">clean</td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"                    
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">7</td>
                <td className="border border-black p-2">jump</td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"                    
                  />
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Three/7">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Three/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E8;