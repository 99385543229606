import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC7 = () => {
  const speakData =
    "Throwing poisonous rubbish from factories into water is one of the reasons for water pollution. Water pollution is the contamination of water sources by substances which make the water unusable for drinking, cooking, cleaning, swimming, and other activities. Pollutants include chemicals, trash, bacteria, and parasites. All forms of pollution eventually make their way to water.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Human impacts on Earth System"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SC7.HumanImpacts}
            alt="HumanImpacts"
            className="w-full"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Throwing poisonous rubbish from factories into water is one of the
            reasons for water pollution. Water pollution is the contamination of
            water sources by substances which make the water unusable for
            drinking, cooking, cleaning, swimming, and other activities.
            Pollutants include chemicals, trash, bacteria, and parasites. All
            forms of pollution eventually make their way to water.
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Four/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/Four/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC7;
