import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC21 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4 bg-yellow-200 rounded-lg border border-black m-2 text-sm">
          <p>
            <strong>Solids</strong> keep their shape.
          </p>
          <p>
            <strong>Liquids</strong> take the shape of their container.
          </p>
          <p>
            <strong>Gases</strong> spread out to fill the space they are in.
          </p>
        </div>
        <div className="p-4">
          <p>Write each item in the correct column</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC5.Q3} alt="" className="w-full" />
        </div>
        <div className="flex px-2">
          <div className="w-1/3 border border-black rounded-tl rounded-bl py-4">
            <div className="text-center font-bold text-blue-500">
              <p>Solid</p>
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
          </div>
          <div className="w-1/3 border border-black py-4">
            <div className="text-center font-bold text-blue-500">
              <p>Liquid</p>
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
          </div>
          <div className="w-1/3 border border-black rounded-tr rounded-br py-4">
            <div className="text-center font-bold text-blue-500">
              <p>Gas</p>
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
            <div className="text-center">
              <input
                type="text"
                className="outline-none w-24 text-center text-sm border-b-blue-500 border-b-2 mx-1"
                maxLength={11}
              />
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/One/20">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC21;