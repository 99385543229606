import React from 'react';
import { Typography, Radio, Checkbox } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Read the story. Look at the bold words. Choose the answers."}
        />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">1. What is a rose ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="A type of flower" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="A type of herb" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="A type of big tree" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">2. What is soil ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="Liquid" />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="Dirt" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="Sand" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">3. What is basil ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="A fruit" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="A flower" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="An herb" />
            </div>
          </div>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12">
          <Checkbox
            color="blue"
            label="I can understand words in a story."
            defaultChecked
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            color="blue"
            label="I can look at the text to define words I do not know."
            defaultChecked
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/1">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;