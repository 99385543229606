import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC23 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 w-full py-3 rounded-lg text-black";
  const correctStyle =
    "bg-green-500 hover:bg-green-600 w-full py-3 rounded-lg text-white";
  const errorStyle =
    "bg-red-500 hover:bg-red-600 w-full py-3 rounded-lg text-white";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-green-600 w-full py-3 rounded-lg text-white";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "3":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;      
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Transparent, Translucent or Opaque?"} />
        <div className="mb-20">
          <div>
            <div className="p-4 mt-4 mx-4 border-2 border-green-600 rounded-lg">
              <p className="text-center">A Window</p>
            </div>
            <div className="p-4">
              <div className="flex p-4 md:p-12 lg:p-12">
                <img
                  src={IMAGES.L1.Window}
                  alt="LeftOrRight"
                  className="w-1/2 mx-auto"
                />
              </div>
            </div>
            <div className="text-center mx-4 mb-4">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                Transparent
              </button>
            </div>
            <div className="text-center mx-4 mb-4">
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                Translucent
              </button>
            </div>
            <div className="text-center mx-4 mb-4">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                Opaque
              </button>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/22">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/One/24">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC23;