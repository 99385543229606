import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC1 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Level 1"} />
        <div className="p-4">
          <p className="font-bold text-center text-2xl">
            Waves and their Applications in Technologies for Information
            Transfer
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/One/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC1;
