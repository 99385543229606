import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA5 = () => {
  const speakData =
    "Estimating. Use what you already know to figure out a value close to the excet amount. Using any information you already know to help make the guess. Comparing the lengths. How much longer is the jump rope than the tennis racket? Mightly awesome.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Measure and estimate lengths in standard units"} />
        <div className="p-4">
          <p className="font-bold">Measurement and Data</p>
          <p>Measurement and estimate lengths in standard units</p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA5.ME1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA5.ME2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA5.ME3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA5.ME4} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA5.ME5} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA5.ME6} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA5;