import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC19 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"The Law Of Conservation Of Energy"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.SC19.SunToEarth}
            alt="ConservationOfEnergy"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <p>
            The <strong>light</strong> radiated by the Sun carries energy. When
            this light reaches a surface, part of it gets absorbed and
            transformed <strong>into heat</strong>. That is why places in the
            sun feel warmer than places in the shade.
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Two/17">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC19;
