import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const SS7 = () => {  
  const speakData =
    "Maple syrup is traditional in Canada. It’s made from the sap of maple tree. You can see a red maple trees. You can see a red maple left on Canada’s national flag. The maple sap is collected in early spring. People cut small holes in the sides of the trees and collect the sap in buckets. Then they cook the sap to take out the water and make syrup. If they continue cooling the sap, it turns into maple candy. A lot of Canadians put maple syrup on their pancakes. They also add it to cakes, cookies, and other desserts.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"DESSERTS"} />
        <div className="p-4">
          <p>
            Maple syrup is traditional in Canada. It’s made from the sap of
            maple tree. You can see a red maple trees. You can see a red maple
            left on Canada’s national flag. The maple sap is collected in early
            spring. People cut small holes in the sides of the trees and collect
            the sap in buckets. Then they cook the sap to take out the water and
            make syrup. If they continue cooling the sap, it turns into maple
            candy. A lot of Canadians put maple syrup on their pancakes. They
            also add it to cakes, cookies, and other desserts.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SS1.Desserts1}
            alt="Desserts"
            className="w-full"
          />
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Four/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/SocialStudies/Units/Four/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS7;
