import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div>
          <div className="p-4">
            <p>
              Write <strong>“T”</strong> for true or <strong>“F”</strong> for
              false beside each statement.
            </p>
          </div>
          <div className="p-4 flex">
            <div className="3/4">
              <p>
                1. Walking under a ladder is bad luck because something could
                fall on your head.
              </p>
            </div>
            <div className="1/4">
              <input
                type="text"
                className="outline-none w-12 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
              />
            </div>
          </div>
          <div className="p-4 flex">
            <div className="3/4">
              <p>2. Superstitious people believe in bad luck.</p>
            </div>
            <div className="1/4">
              <input
                type="text"
                className="outline-none w-12 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
              />
            </div>
          </div>
          <div className="p-4 flex">
            <div className="3/4">
              <p>3. Long ago, people believed that gods lived in trees.</p>
            </div>
            <div className="1/4">
              <input
                type="text"
                className="outline-none w-12 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
              />
            </div>
          </div>
          <div className="p-4 flex">
            <div className="3/4">
              <p>4. Knocking on wood would bring you good luck.</p>
            </div>
            <div className="1/4">
              <input
                type="text"
                className="outline-none w-12 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
              />
            </div>
          </div>
          <div className="p-4 flex">
            <div className="3/4">
              <p>5. Breaking a mirror may bring 20 years of bad luck.</p>
            </div>
            <div className="1/4">
              <input
                type="text"
                className="outline-none w-12 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
              />
            </div>
          </div>
          <div className="p-4 flex mb-20">
            <div className="3/4">
              <p>6. The most superstitious day is the 13th of each month.</p>
            </div>
            <div className="1/4">
              <input
                type="text"
                className="outline-none w-12 px-2 text-xl border-b-2 border-b-blue-500 font-bold uppercase text-center"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Four/1">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Four/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E2;
