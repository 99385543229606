import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC3 = () => {
  const speakData =
    "Look at this unique seed. Describe how you think it feels? The small barbs on the seeds feel like the sticky side of Velcro. How would these barbs help the plants? Animals help plants by carrying the seeds of the plant to a different place. The sticky barbs get tangled in animal fur or feathers and are then carried far away to new places where the plant has room to grow.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"Ecosystems ; Interactions, Energy, and Dynamics"}
        />
        <div className="m-2 mb-20">
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant4} alt="" className="w-full" />
          </div>
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant5} alt="" className="w-full" />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;