import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA14 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Name each shape and count how many sides each shape has got.</p>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S1} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    readOnly
                    value={"Triangle"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                    readOnly
                    value={"3"}
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S2} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S3} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S4} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S5} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S6} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S7} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S8} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 grid grid-cols-3">
          <div className="">
            <img src={IMAGES.L4.MA16.S9} alt="" className="" />
          </div>
          <div className="col-span-2">
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Name : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="10"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 place-items-center">
                <div>
                  <p>Sides : </p>
                </div>
                <div>
                  <input
                    type="text"
                    className="outline-none w-full text-center text-lg border-b-2 border-b-black"                    
                    maxLength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/15">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Four/17">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA14;
