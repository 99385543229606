import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC5 = () => {
  const speakData =
    "Do animal parents care for their babies?  How? What do they do? How do your parents take care of you? Do you do anything to show your parents that you need something like food or a drink of water? Maybe a hug? What do baby animals do in order to communicate their needs? You’re going to do some “research” today to discover how animals support and help their offspring survive. Take a look at these photos. While you’re watching, think about how adult animals care for their babies. What patterns did you notice in the behavior of the adult animals as they were caring for their offsprings?";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Parent"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Read texts and use media to determine patterns in behavior of
            parents and offspring that help offspring survive.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa1} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Do animal parents care for their babies?  How? What do they do? How
            do your parents take care of you?
          </p>
        </div>
        <div className="p-4">
          <p>
            Do you do anything to show your parents that you need something like
            food or a drink of water? Maybe a hug?
          </p>
        </div>
        <div className="p-4">
          <p>What do baby animals do in order to communicate their needs?</p>
        </div>
        <div className="p-4">
          <p>
            You’re going to do some “research” today to discover how animals
            support and help their offspring survive. Take a look at these
            photos. While you’re watching, think about how adult animals care
            for their babies.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa3} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa4} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa5} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa6} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa7} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa8} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa9} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa10} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa11} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Pa12} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            What patterns did you notice in the behavior of the adult animals as
            they were caring for their offsprings?
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC5;