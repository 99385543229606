import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA10 = () => {
  const speakData =
    "Whick snack do most people prefer. Fruit 9. Which snack do the least amount of people prefer. Crackers two. How many students like scoccer and basketball altogether. How many students like soccer and basketball. altogether. How many more students prefer strawberries than oranges.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Represent and Interpret Data"} />
        <div className="p-4">
          <img src={IMAGES.L4.MA10.ID1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA10.ID2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA10.ID3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA10.ID4} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Four/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA10;