import React from "react";
import { Typography, Radio, Checkbox } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Read carefully and choose the correct answers."} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1 . What types of families had car in the old days?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="Wealthy families"
                defaultChecked
              />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Poor Families" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2 . In the past, children had to _____ to go one place to another.
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA3" name="Q2" label="ride a car" />
            </div>
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="walk" defaultChecked />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3 . The people use animals to pull cart loaded with lots of things
              in the old days.
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA5" name="Q3" label="False" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q3" label="True" defaultChecked />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. Children in the olden days would have loved to wear the _____
              with wheels.
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q4" label="hats" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q4" label="shoes" defaultChecked />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              5 . Travelling with cars and buses make our lives more _____ .
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q5" label="easier" defaultChecked />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q5" label="difficult" />
            </div>
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            color="blue"
            label="I can answer questions."
            defaultChecked
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/5">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS6;
