import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Compound Words"} />
        <div className="p-4">
          <p>
            <strong>Directions:</strong> A compound word is a{" "}
            <strong>
              union of two or more words, either with or without a hyphen.
            </strong>
          </p>
          <p className="mt-4">
            Read the two words. Then write them together as one compound word.
          </p>
        </div>
        <div>
          <div className="p-4">
            <p className="px-10">
              1. Foot + Ball =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              2. Rain + Bow =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              3. Hair + Tie =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              4. Pan + Cake =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              5. Star + Fish =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              6. Tooth + Brush =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              7. Cow + Boy =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              8. Butter + Fly =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4">
            <p className="px-10">
              9. Cup + Cake =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
          <div className="p-4 mb-24">
            <p className="px-10">
              10. Basket + Ball =
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={10}
              />
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/6">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/One/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E7;