import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA13 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Heavy or Light"} />
        <div className="p-4">
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <p>Which object is heavy?</p>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL1} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL2} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL3} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL4} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL5} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL6} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL7} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL8} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <p>Which object is light?</p>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL9} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL10} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL11} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL12} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL13} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL14} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL15} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">                
                <div>
                  <img src={IMAGES.L3.MA13.QHL16} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>          
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Three/12">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA13;