import React from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA12 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("6").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("7").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("7").className = showCorrectSytle;
        document.getElementById("8").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("9").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("9").className = showCorrectSytle;
        document.getElementById("10").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Choose the number that is bigger"} />
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                5
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                2
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                6
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                9
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                3
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                4
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                8
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                7
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="9" className={normalStyle}>
                7
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="10" className={normalStyle}>
                1
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-white rounded-2xl m-2">
            
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/11">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA12;
