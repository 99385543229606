import React from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA12 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"3"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"5"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"6"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"5"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"6"}
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"5"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"7"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"0"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"7"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"8"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"6"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"6"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"3"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"3"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"3"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"9"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"0"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"8"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"6"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"7"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"0"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"7"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"2"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"0"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"6"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"8"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Hundreds"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Tens"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"Ones"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black"></div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"8"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"5"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"1"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"+"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={""}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"4"}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"7"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                readOnly
                value={"="}
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full text-center text-lg"
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Two/11">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA12;