import React from 'react';
import { LOGO } from '../images';
import {Link} from "react-router-dom";

const Home = () => {
  return (
    <div className="w-full bg-zmh-gray-500 h-full">
      <div>
        <div className="flex justify-center items-center bg-white h-32">
          <h3>Banner HERE</h3>
        </div>
        <br />
        <div className="flex justify-end items-end bg-transparent text-white px-4 font-bold">
          <h2>Mingalarpar Thet Naing Tun</h2>
        </div>
        <br />
        <div className="flex justify-center items-center mx-auto px-4">
          <div className="flex flex-col bg-white max-w-lg rounded-2xl overflow-hidden shadow-lg">
            <div>
              <img className="w-full" src={LOGO.KG} alt="logo" />
            </div>
            <div>
              <div className="px-6 py-4">
                {/* <div className="font-bold text-xl mb-2">KG</div> */}
                <p className="text-gray-700 text-base">
                  All the grade level are structured with Common Core State
                  Standards, students who master the standards in reading,
                  writing, speaking, and language as they advance through the
                  grades will exhibit the following capabilities. Four subjects
                  are covered in kindergarten: English, Math, Science, and
                  Social Studies. There will be four units and assessments for
                  each subject. Student will be awarded a certificate if all of
                  the assessments are completely successful.
                </p>
                <br />
                <p>Age range: 5 to 6 years</p>
              </div>
              <div className="px-6 pb-4">
                <Link to="KG">
                  <button className="bg-zmh-gray-500 hover:bg-zmh-gray-700 text-white py-2 px-4 rounded-full">
                    Enter Classroom
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="flex justify-center items-center mx-auto px-4">
          <div className="flex flex-col bg-white max-w-lg rounded-2xl overflow-hidden shadow-lg">
            <div>
              <img className="w-full" src={LOGO.G1} alt="logo" />
            </div>
            <div>
              <div className="px-6 py-4">
                {/* <div className="font-bold text-xl mb-2">Grade 1</div> */}
                <p className="text-gray-700 text-base">
                  All the grade level are structured with Common Core State
                  Standards, students who master the standards in reading,
                  writing, speaking, and language as they advance through the
                  grades will exhibit the following capabilities. Four subjects
                  are covered in kindergarten: English, Math, Science, and
                  Social Studies. There will be four units and assessments for
                  each subject. Student will be awarded a certificate if all of
                  the assessments are completely successful.
                </p>
                <br />
                <p>Age range: 6 to 7 years</p>
              </div>
              <div className="px-6 pb-4">
                <Link to="/G1">
                  <button className="bg-zmh-gray-500 hover:bg-zmh-gray-700 text-white font-bold py-2 px-4 rounded-full">
                    Enter Classroom
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="flex justify-center items-center mx-auto px-4">
          <div className="flex flex-col bg-white max-w-lg rounded-2xl overflow-hidden shadow-lg">
            <div>
              <img className="w-full" src={LOGO.G2} alt="logo" />
            </div>
            <div>
              <div className="px-6 py-4">
                {/* <div className="font-bold text-xl mb-2">Grade 2</div> */}
                <p className="text-gray-700 text-base">
                  All the grade level are structured with Common Core State
                  Standards, students who master the standards in reading,
                  writing, speaking, and language as they advance through the
                  grades will exhibit the following capabilities. Four subjects
                  are covered in kindergarten: English, Math, Science, and
                  Social Studies. There will be four units and assessments for
                  each subject. Student will be awarded a certificate if all of
                  the assessments are completely successful.
                </p>
                <br />
                <p>Age range: 7 to 8 years</p>
              </div>
              <div className="px-6 pb-4">
                <Link to="/G2">
                  <button className="bg-zmh-gray-500 hover:bg-zmh-gray-700 text-white font-bold py-2 px-4 rounded-full">
                    Enter Classroom
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default Home;