import React, { useState } from "react";
import { Checkbox, Input } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";

const E11 = () => {
  const [isDis, setIsDis] = useState(true);
  const changeNext = () => {
    setIsDis(!isDis);
  };
  return (
    <div>
      <div>
        <div className="flex bg-zmh-lightblue-600 text-white text-xl font-bold justify-center items-center p-6">
          <p>
            Write three sentences with the title
            <strong> ‘games I like to play’</strong>
          </p>
        </div>
        <div className="p-10">
          <div className="w-full mb-10">
            <Input variant="static" label="1." />
          </div>
          <div className="w-full mb-10">
            <Input variant="static" label="2." />
          </div>
          <div className="w-full">
            <Input variant="static" label="3." />
          </div>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can fix my writing."
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/10">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E11;
