import React from "react";
import { Link } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA2 = () => {
  const speakData = "ONE,TWO,THREE,FOUR, AND , WHAT COMES NEXT ?";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        break;
      case "2":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        break;
      case "3":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        break;
      case "4":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("4").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Number 1 to 10"} />
        <div className="p-4">
          <p className="text-center font-bold text-xl">1, 2, 3, 4, and ...</p>
          <p className="text-center font-bold text-2xl mt-20">
            What comes next?
          </p>
        </div>
        <div>
          <div className="mt-10 p-4">
            <div className="flex -mt-2">
              <div className="w-1/5 m-2">
                <button onClick={checkAnswer} id="1" className={normalStyle}>
                  1
                </button>
              </div>
              <div className=" w-1/5 m-2">
                <button onClick={checkAnswer} id="2" className={normalStyle}>
                  2
                </button>
              </div>
              <div className=" w-1/5 m-2">
                <button onClick={checkAnswer} id="3" className={normalStyle}>
                  3
                </button>
              </div>
              <div className=" w-1/5 m-2">
                <button onClick={checkAnswer} id="4" className={normalStyle}>
                  4
                </button>
              </div>
              <div className=" w-1/5 m-2">
                <button onClick={checkAnswer} id="5" className={normalStyle}>
                  5
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Math/Units/One/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA2;
