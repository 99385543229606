import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import BannerTitle from "../../../../../components/BannerTitle";

const SS13 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "How many different kinds of houses do you know? Make a list in the given box below"
          }
        />
        <div className="flex p-3">
          <input
            className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
            type="text"
            value="1."
            disabled
          />
          <input
            className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
            type="text"
            placeholder="Answer Here"
          />
        </div>
        <div className="flex p-3">
          <input
            className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
            type="text"
            value="2."
            disabled
          />
          <input
            className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
            type="text"
            placeholder="Answer Here"
          />
        </div>
        <div className="flex p-3">
          <input
            className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
            type="text"
            value="3."
            disabled
          />
          <input
            className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
            type="text"
            placeholder="Answer Here"
          />
        </div>
        <div className="flex p-3">
          <input
            className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
            type="text"
            value="4."
            disabled
          />
          <input
            className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
            type="text"
            placeholder="Answer Here"
          />
        </div>
        <div className="flex p-3">
          <input
            className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
            type="text"
            value="5."
            disabled
          />
          <input
            className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
            type="text"
            placeholder="Answer Here"
          />
        </div>
        <div className="flex p-3">
          <input
            className="rounded-l-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-10 mt-3 p-3"
            type="text"
            value="6."
            disabled
          />
          <input
            className="rounded-r-lg border-t-2 border-r-2 border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
            type="text"
            placeholder="Answer Here"
          />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/12">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS13;
