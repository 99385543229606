import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA15 = () => {
  const speakData =
    "Circle. 1 Side. 0 Corner. Triangle. 3 Sides. 3 Corners. Square. 4 Sides. 4 Corners. Rectangle. 4 Sides. 4 Corners. Pentagon. 5 Sides. 5 Corners. Oval. 1 Side. 0 Corner. Rombus. 4 Sides. 4 Corners. Hexagon. 6 Sides. 6 Corners.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Geometry"} />
        <div className="p-4">
          <p>Reason with shapes and their attributes.</p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA15.Geometry} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/14">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/Four/16">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA15;