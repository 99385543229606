import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"ADDITION FACTS TO 12 SHEET 1"} />
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L1.MA7.AdditionQ} alt="" className="w-full" />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">3</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">3</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">1</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">4</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">4</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">1</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">5</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">6</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">1</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">0</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">3</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">3</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">5</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">1</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">4</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">5</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">5</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">3</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">7</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">1</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">7</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">5</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">4</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">4</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">6</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">1</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">9</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">3</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">5</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="1"
            />
          </div>
          <div className="flex w-1/2 mx-auto mt-2">
            <p className="font-bold text-3xl m-2">8</p>
            <p className="font-bold text-3xl m-2">+</p>
            <p className="font-bold text-3xl m-2">2</p>
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-full pl-4 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/One/6">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/One/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA7;