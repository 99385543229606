import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS1 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Transportation : Past and Present"} />
        <div className="p-4">
          <p className="text-center font-bold underline">Table of Contents</p>
        </div>
        <div className="flex p-4">
          <div className="w-1/4">
            <p className="font-bold">Unit 3:</p>
          </div>
          <div className="w-3/4">
            <p className="font-bold">Transportation : Past and Present</p>
            <p>Different Modes of Transportation</p>            
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Three/1A">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1;
