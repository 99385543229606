import React from 'react';
import { Typography, Radio, Checkbox } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Choose the correct answer"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. What causes Tortoise to win the race ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Hare get lost during the race" />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="Hare gives up halfway through the race"
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="Hare stops many times along the way"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. What does Hare do after he eats a snack ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA4"
                name="Q2"
                label="He goes for a swim in the water."
              />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="He catches up to Tortoise" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="He takes a nap" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">3. Hare brags about______.</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA7"
                name="Q3"
                label="He wants tortoise to win the race"
              />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="He can run very fast" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="He has many running shoes" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. Which sentence from the book shows that Hare brags ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA10"
                name="Q4"
                label="“Nobody can beat me” Hare said."
              />
            </div>
            <div className="flex-row">
              <Radio id="QA11" name="Q4" label="He wanted to win" />
            </div>
            <div className="flex-row">
              <Radio id="QA12" name="Q4" label="Tortoise won the race." />
            </div>
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            color="blue"
            label="I can read a story. I can answer questions."
            defaultChecked
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Four/1">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Four/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;