import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { IMAGES } from "../../assets";

const SS4 = () => {
  return (
    <div>
      <BannerTitle title={"You are a Citizen "} />
      <div className="p-4">
        <p>
          Determine each picture represents someone exhibiting good citizenship
          or bad citizenship. Tick in the correct column.
        </p>
      </div>

      <div className="mx-1">
        <div className="flex">
          <div className="w-1/3 border border-black items-center">
            <p className="text-center">Example</p>
          </div>
          <div className="w-1/3 border border-black">
            <p className="text-center">Good Citizenship</p>
          </div>
          <div className="w-1/3 border border-black">
            <p className="text-center">Bad Citizenship</p>
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 p-4">
            <img src={IMAGES.L1.SS4.QEG} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" defaultChecked />
          </div>          
        </div>
        <div className="flex">
          <div className="w-1/3 p-4">
            <img src={IMAGES.L1.SS4.Q1} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
        </div>
        <div className="flex">
          <div className="w-1/3 p-4">
            <img src={IMAGES.L1.SS4.Q2} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
        </div>
        <div className="flex">
          <div className="w-1/3 p-4">
            <img src={IMAGES.L1.SS4.Q3} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
        </div>
        <div className="flex">
          <div className="w-1/3 p-4">
            <img src={IMAGES.L1.SS4.Q4} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
          <div className="w-1/3 flex justify-center items-center">
            <input type="checkbox" className="w-12 h-12" />
          </div>          
        </div>
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/One/3">
          <BackButton />
        </Link>

        <Link to="/G1/SocialStudies/Units/One/5">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default SS4;
