import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS4 = () => {
  const speakData =
    "Lots of children nowadays like to travel to places on bicycles or a scooter. Did you know that bicycles have been around for a long time, but they used to look very different to the way they look now. Does this look like your bicycle? It has wheels, a seat to sit on and handlebars to hold, but it is very different to the bicycles that kids like to ride today.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Transport : Past and Present"} />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            Lots of children nowadays like to travel to places on bicycles or a
            scooter. Did you know that bicycles have been around for a long
            time, but they used to look very different to the way they look now.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS4.Transport4}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="px-2 py-4 ml-4 md:p-12 lg:p-12 bg-blue-700 text-white mb-24">
          <p>
            Does this look like your bicycle? It has wheels, a seat to sit on
            and handlebars to hold, but it is very different to the bicycles
            that kids like to ride today.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Three/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;
