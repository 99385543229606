export const IMAGES = {
  L1: {
    SS1: {
      Watermill: require("./L1/Watermills.png"),
    },
    SS4: {
      Plane: require("./L1/Plane.png"),
      Propeller: require("./L1/Propeller.png"),
      AirBalloon: require("./L1/AirBalloon.png"),
      JetEngine: require("./L1/JetEngine.png"),
      AIrship: require("./L1/AIrship.png"),
      Helicopter: require("./L1/Helicopter.png"),
    },
  },
  L2: {
    SS1: {
      Statue: require("./L2/Statue.png"),
    },
    SS2: {
      Q1: require("./L2/Q1.png"),
      Q2: require("./L2/Q2.png"),
      Q3: require("./L2/Q3.png"),
      Q4: require("./L2/Q4.png"),
      Q5: require("./L2/Q5.png"),
    },
  },
  L3: {
    SS1: {
      Clothes: require("./L3/Clothes.png"),
    },
  },
  L4: {
    SS1: {
      StreetFood1: require("./L4/StreetFood.png"),
      StreetFood2: require("./L4/StreetFood2.png"),
      Beverages: require("./L4/Beverages.png"),
      Desserts1: require("./L4/Desserts.png"),
      Desserts2: require("./L4/Desserts2.png"),
      Desserts3: require("./L4/Desserts3.png"),
    },
  },
};