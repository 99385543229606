import React from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC10 = () => {
  const speakData =
    "Using public transports. Turn off the lights when not in use. Recycle and Reuse. No to plastic bags. Reduction of forest fires and smoking. Use of fans instead of Air Conditioner. Use filters for chimneys. Avoid usage of crackers.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"Think and talk other ways to reduce air pollution."}
        />
        <div className="p-8">
          <ul className="list-disc">
            <li>Using public transports. ...</li>
            <li>Turn off the lights when not in use. ...</li>
            <li>Recycle and Reuse. ...</li>
            <li>No to plastic bags. ...</li>
            <li>Reduction of forest fires and smoking. ...</li>
            <li>Use of fans instead of Air Conditioner. ...</li>
            <li>Use filters for chimneys. ...</li>
            <li>Avoid usage of crackers. ...</li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Four/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC10;
