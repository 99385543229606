import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Short Vowel Sound"} />
        <div className="p-4">
          <p>
            There are five main vowels:{" "}
            <strong>
              <i>a, e, i, o, and u.</i>
            </strong>{" "}
            the short vowel sounds are <strong>a</strong> as in c<u>a</u>t.{" "}
            <strong>e</strong> as in b<u>e</u>d. <strong>i</strong> as in sh
            <u>i</u>p, <strong>o</strong>
            as in b<u>o</u>x, and <strong>u</strong> as in t<u>u</u>b.
          </p>
          <p className="mt-4">
            Complete each word with the correct short vowel sound.
          </p>
        </div>
        <div>
          <div className="p-4">            
            <p className="pl-10">
              1. s
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              d
            </p>
            <p className="pl-10">
              2. b
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              t
            </p>
            <p className="pl-10">
              3. l
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              p
            </p>
            <p className="pl-10">
              4. m
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              p
            </p>
            <p className="pl-10">
              5. c
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              b
            </p>
            <p className="pl-10">
              6. s
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              x
            </p>
            <p className="pl-10">
              7. m
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              x
            </p>
            <p className="pl-10">
              8. p
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              t
            </p>
            <p className="pl-10">
              9. v
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />              
              t
            </p>            
          </div>
        </div>        

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/5">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/One/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;