import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const CC = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Common Core State Standards"} />
        <div className="p-4">
          <p>
            In Kindergarten, instructional time should focus on two critical
            areas: (1) representing and comparing whole numbers, initially with
            sets of objects; (2) describing shapes and space. More learning time
            in Kindergarten should be devoted to number than to other topics.
          </p>
        </div>
        <div className="p-8 mb-20">
          <ul className="list-decimal">
            <li>
              Students use numbers, including written numerals, to represent
              quantities and to solve quantitative problems, such as counting
              objects in a set; counting out a given number of objects;
              comparing sets or numerals; and modeling simple joining and
              separating situations with sets of objects, or eventually with
              equations such as 5 + 2 = 7 and 7 – 2 = 5. (Kindergarten students
              should see addition and subtraction equations, and student writing
              of equations in kindergarten is encouraged, but it is not
              required.) Students choose, combine, and apply effective
              strategies for answering quantitative questions, including quickly
              recognizing the cardinalities of small sets of objects, counting
              and producing sets of given sizes, counting the number of objects
              in combined sets, or counting the number of objects that remain in
              a set after some are taken away.
            </li>
            <li>
              Students describe their physical world using geometric ideas
              (e.g., shape, orientation, spatial relations) and vocabulary. They
              identify, name, and describe basic two-dimensional shapes, such as
              squares, triangles, circles, rectangles, and hexagons, presented
              in a variety of ways (e.g., with different sizes and
              orientations), as well as three-dimensional shapes such as cubes,
              cones, cylinders, and spheres. They use basic shapes and spatial
              reasoning to model objects in their environment and to construct
              more complex shapes.
            </li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Three/1">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CC;