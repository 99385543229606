import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA12 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Heavy or Light"} />
        <div className="p-4">
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <p>Click the picture that is heavier</p>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Car</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QCar} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>School Bus</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QSchoolBus} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Books</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QBooks} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Book</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QBook} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Computer</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QComputer} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Mobile Phone</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QMobilePhone} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Cup</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QCup} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Bed</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QBed} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Desk</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QDesk} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Lamp</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QLamp} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <p>Click the picture that is lighter</p>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Tree</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QTree} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Bird</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QBird} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Jacket</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QJacket} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Backpack</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QBackpack} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Fish</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QFish} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Whale</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QWhale} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Guitar</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QGuitar} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Headphone</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QHeadphone} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="flex mb-4">
              <div className="w-1/2">
                <div className="text-center">
                  <p>Hammer</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QHammer} alt="" className="" />
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-center">
                  <p>Nail</p>
                </div>
                <div>
                  <img src={IMAGES.L3.MA12.QNail} alt="" className="" />
                </div>
              </div>
            </div>
            <div className="w-full grid gap-4 grid-cols-2">
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  type="text"
                  maxLength={1}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Three/11">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Three/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA12;
