import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import UndoButton from "../../../../../components/UndoButton";
import ResetButton from "../../../../../components/ResetButton";
import CanvasDaw from "react-canvas-draw";
import { IMAGES } from "../../assets";

const SC4 = () => {
  const [color, setColor] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      let newColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      setColor(newColor);
    }, 1000);
    return () => clearInterval(interval);
  }, [color]);

  const firstCanvasRef = useRef(null);

  const clear = () => {
    firstCanvasRef.current.clear();
  };

  const undo = () => {
    firstCanvasRef.current.undo();
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div className="w-full bg-green-500 text-white text-center">
            Match animals and their habitats.
          </div>
        </div>
        <div className="m-3 border-2 border-black">
          <CanvasDaw
            canvasWidth={360}
            ref={firstCanvasRef}
            brushRadius={3}
            lazyRadius={0}
            brushColor={color}
            imgSrc={IMAGES.L4.SC4.CanvasDraw1}
          />
        </div>
        <div className="w-full flex text-center justify-center"></div>
        <div className="flex p-3 justify-center mb-20">
          <UndoButton type={undo} />
          <ResetButton type={clear} />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Four/3">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units/Four/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC4;