import React,{useState} from 'react';
import { Checkbox } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E11 = () => {
    const normalStyle = "flex-col w-1/2 border-2 border-zmh-gray-700 mr-2"
    const correctStyle = "flex-col w-1/2 border-2 border-green-500 mr-2 shadow-xl shadow-green-500";
    const errorStyle = "flex-col w-1/2 border-2 border-red-500 mr-2 shadow-xl shadow-red-500";
    const answer = [
        {id: '1'},
        {id: '2'},
        {id: '3'},
    ];

    const [isDis , setIsDis] = useState(true);
    const checkAnswer = (e) => {
        const ans = answer.filter((answer)=>{
            return answer.id === e.currentTarget.id;
        });

        if(ans.length===0){
            document.getElementById(e.currentTarget.id).className = errorStyle;
        } else {
            document.getElementById(e.currentTarget.id).className = correctStyle;
        }
    }
    const changeNext = () => {
        setIsDis(!isDis);
    }

  return (
    <div>
      <div>
        <BannerTitle title={"Read the words in each picture."} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <p>
            Click the pictures that rhyme with the word <strong>“man”.</strong>
          </p>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12">
          <div id={1} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.Car} alt="Van" className="h-full w-full" />
          </div>
          <div id={2} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.Fan} alt="Fan" className="h-full w-full" />
          </div>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12">
          <div id={3} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.Pan} alt="Pan" className="h-full w-full" />
          </div>
          <div id={4} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.Bag} alt="Bag" className="h-full w-full" />
          </div>
        </div>

        <br />
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can rhyme. I can rhyme short vowels words."
          />
        </div>
        <br />
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/One/10">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/One/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E11;