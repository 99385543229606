import React from "react";
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"The Race"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">1. Which character is sad ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Snail" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Rat" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Turtle" />
            </div>
          </div>
        </div>
        <div className="p-4">
          <p>Answer with only two words.</p>
        </div>
        <div>
          <div className="p-4">
            <Typography variant="lead">
              2. Which animal told Sally Snail he was too slow ?
            </Typography>
          </div>
          <div className="m-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
            />
          </div>
        </div>
        <div className="mb-20">
          <div className="p-4">
            <Typography variant="lead">
              3. Which animal helped Sally Snail ?
            </Typography>
          </div>
          <div className="m-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/One/3">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/One/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E4;