import React from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Container from "./DragNDrop_One/Container";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Drag and drop each proper noun in the correct box below."}
        />
        <div className="flex p-6">Drag the words to the correct columns</div>
      </div>
      <div className="mb-20">
        <DndProvider backend={TouchBackend}>
          {/* Here, render a component that uses DND inside it */}
          <Container />
        </DndProvider>
      </div>
      <div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/9">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;
