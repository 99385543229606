import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { Typography, Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              Put some grains of rice on a drum and gently strike the drum.
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="The vibrations from the drum cause the rice to dance around."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="The vibrations from the drum cause the rice to  look down."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="The vibrations from the drum cause the rice to still around."
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <img src={IMAGES.L1.Quiz1} alt="Quiz" className="w-full" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/8">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/One/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC9;
