import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E7 = () => {
    

  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Be sure to say each word aloud. Listen to each sound as you say the
            word. Think of what letter stands for each sound.
          </p>
        </div>
        <div>
          <div className="p-4">
            <p>Eg: What is the first sound in the word fly?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
              disabled
              value={"y"}
            />
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>1. What is the first sound in the word music?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
            />
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>2. What is the second sound in the word hot?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
            />
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>3. What is the last sound in the word sleep?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
            />
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>4. What is the first sound in the word cry?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
            />
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>5. What is the last sound in the word yes?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
            />
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>6. What is the first sound in the word cloud?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
            />
          </div>
        </div>
        <div className="mb-24">
          <div className="p-4">
            <p>7. What is the second sound in the word dad?</p>
          </div>
          <div className="mx-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={1}
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/One/6">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/One/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E7;