import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />

        <div className="p-4">
          <div className="mb-6">
            <p>Write the time below each clock.</p>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C1} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  readOnly
                  value={"2"}
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  readOnly
                  value={"20"}
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C2} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C3} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C4} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C5} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C6} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C7} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C8} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA4.C9} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>          
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/3">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Four/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA4;