import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA3 = () => {
  const speakData = "Place values. Each digit in a number has a title, depending on where it is positioned. 6 hundreds. plus. 4 tens. plus. 3 ones. 6 is in the hundreds place. 4 is in the tens place. 3 is in the ones place.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Understand place value"} />
        <div className="p-4">
          <p>Number and Operations in Base Ten</p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA3.PV1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA3.PV2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.MA3.PV3} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA3;