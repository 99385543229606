export const IMAGES = {
  L1: {
    MA1: {
      1: require("./L1/1.png"),
      2: require("./L1/2.png"),
      3: require("./L1/3.png"),
      4: require("./L1/4.png"),
      5: require("./L1/5.png"),
      6: require("./L1/6.png"),
      7: require("./L1/7.png"),
      8: require("./L1/8.png"),
      9: require("./L1/9.png"),
      10: require("./L1/10.png"),
    },
    MA5: {
      Count1: require("./L1/Count1.png"),
      Star: require("./L1/Star.png"),
      Heart: require("./L1/Heart.png"),
      Diamond: require("./L1/Diamond.png"),
      Smile: require("./L1/Smile.png"),
    },
    MA6: {
      Count2: require("./L1/Count2.png"),
      Quiz1: require("./L1/Quiz1.png"),
      Quiz2: require("./L1/Quiz2.png"),
      Quiz3: require("./L1/Quiz3.png"),
      Quiz4: require("./L1/Quiz4.png"),
    },
    MA7: {
      Cow: require("./L1/Cow.png"),
      Tree: require("./L1/Tree.png"),
      Fish: require("./L1/Fish.png"),
      Ball: require("./L1/Ball.png"),
      Candy: require("./L1/Candy.png"),
    },
    MA8: {
      IceCream: require("./L1/IceCream.png"),
      Bat: require("./L1/Bat.png"),
      Gear: require("./L1/Gear.png"),
      Crystal: require("./L1/Crystal.png"),
      Rose: require("./L1/Rose.png"),
    },
  },
  L2: {
    MA1: {
      Addition1: require("./L2/Addition1.png"),
    },
    MA2: {
      PumpkinQuiz1: require("./L2/PumpkinQuiz1.png"),
      PumpkinQuiz2: require("./L2/PumpkinQuiz2.png"),
    },
    MA3: {
      D1: require("./L2/D1.png"),
      D2: require("./L2/D2.png"),
      D3: require("./L2/D3.png"),
      D4: require("./L2/D4.png"),
      D5: require("./L2/D5.png"),
      D6: require("./L2/D6.png"),
      D7: require("./L2/D7.png"),
      D8: require("./L2/D8.png"),
      D9: require("./L2/D9.png"),
      D10: require("./L2/D10.png"),
    },
    MA4: {
      ADD1: require("./L2/ADD1.png"),
      ADD2: require("./L2/ADD2.png"),
      ADD3: require("./L2/ADD3.png"),
      ADD4: require("./L2/ADD4.png"),
      ADD5: require("./L2/ADD5.png"),
      ADD6: require("./L2/ADD6.png"),
      ADD7: require("./L2/ADD7.png"),
      ADD8: require("./L2/ADD8.png"),
      ADD9: require("./L2/ADD9.png"),
    },
    MA5: {
      Subtraction: require("./L2/Subtraction.png"),
    },
    MA6: {
      SUB1: require("./L2/SUB1.png"),
      SUB2: require("./L2/SUB2.png"),
      SUB3: require("./L2/SUB3.png"),
      SUB4: require("./L2/SUB4.png"),
      SUB5: require("./L2/SUB5.png"),
      SUB6: require("./L2/SUB6.png"),
    },
    MA7: {
      SUB7: require("./L2/SUB7.png"),
      SUB8: require("./L2/SUB8.png"),
      SUB9: require("./L2/SUB9.png"),
      SUB10: require("./L2/SUB10.png"),
      SUB11: require("./L2/SUB11.png"),
      SUB12: require("./L2/SUB12.png"),
    },
    MA8: {
      SUB13: require("./L2/SUB13.png"),
      SUB14: require("./L2/SUB14.png"),
      SUB15: require("./L2/SUB15.png"),
    },
    MA9: {
      strawberry: require("./L2/strawberry.png"),
      burger: require("./L2/burger.png"),
      bee: require("./L2/bee.png"),
      leaf: require("./L2/leaf.png"),
      bear: require("./L2/bear.png"),
    },
  },
  L3: {
    MA1: {
      Base1: require("./L3/Base1.png"),
      Base2: require("./L3/Base2.png"),
    },
    MA2: {
      CanvasDraw1: require("./L3/DrawCanvas1.png"),
    },
    MA3: {
      CanvasDraw2: require("./L3/DrawCanvas2.png"),
    },
    MA4: {
      Q1: require("./L3/Q1.png"),
      Q2: require("./L3/Q2.png"),
      Q3: require("./L3/Q3.png"),
      Q4: require("./L3/Q4.png"),

      Q6: require("./L3/Q6.png"),
      Q7: require("./L3/Q7.png"),
      Q8: require("./L3/Q8.png"),
      Q9: require("./L3/Q9.png"),
    },
    MA5: {
      Longer: require("./L3/Longer.png"),
    },
    MA6: {
      HeavyOrLight: require("./L3/HeavyOrLight.png"),
    },
    MA7: {
      CanvasDraw3: require("./L3/DrawCanvas3.png"),
    },
    MA8: {
      AQ1: require("./L3/AQ1.png"),
      AQ2: require("./L3/AQ2.png"),
      AQ3: require("./L3/AQ3.png"),
      AQ4: require("./L3/AQ4.png"),
      AQ5: require("./L3/AQ5.png"),
    },
    MA9: {
      LH1: require("./L3/LH1.png"),
      LH2: require("./L3/LH2.png"),
      LH3: require("./L3/LH3.png"),
      LH4: require("./L3/LH4.png"),
      LH5: require("./L3/LH5.png"),
      LH6: require("./L3/LH6.png"),
    },
    MA10: {
      CanvasDraw4: require("./L3/DrawCanvas4.png"),
    },
    MA11: {
      TS1: require("./L3/TS1.png"),
      TS2: require("./L3/TS2.png"),
      TS3: require("./L3/TS3.png"),
      TS4: require("./L3/TS4.png"),
      TS5: require("./L3/TS5.png"),
    },
    MA12: {
      QCar: require("./L3/QCar.png"),
      QSchoolBus: require("./L3/QSchoolBus.png"),
      QComputer: require("./L3/QComputer.png"),
      QMobilePhone: require("./L3/QMobilePhone.png"),
      QBooks: require("./L3/QBooks.png"),
      QBook: require("./L3/QBook.png"),
      QCup: require("./L3/QCup.png"),
      QBed: require("./L3/QBed.png"),
      QDesk: require("./L3/QDesk.png"),
      QLamp: require("./L3/QLamp.png"),
      QTree: require("./L3/QTree.png"),
      QBird: require("./L3/QBird.png"),
      QJacket: require("./L3/QJacket.png"),
      QBackpack: require("./L3/QBackpack.png"),
      QFish: require("./L3/QFish.png"),
      QWhale: require("./L3/QWhale.png"),
      QGuitar: require("./L3/QGuitar.png"),
      QHeadphone: require("./L3/QHeadphone.png"),
      QHammer: require("./L3/QHammer.png"),
      QNail: require("./L3/QNail.png"),
    },
    MA13: {
      QHL1: require("./L3/QHL1.png"),
      QHL2: require("./L3/QHL2.png"),
      QHL3: require("./L3/QHL3.png"),
      QHL4: require("./L3/QHL4.png"),
      QHL5: require("./L3/QHL5.png"),
      QHL6: require("./L3/QHL6.png"),
      QHL7: require("./L3/QHL7.png"),
      QHL8: require("./L3/QHL8.png"),
      QHL9: require("./L3/QHL9.png"),
      QHL10: require("./L3/QHL10.png"),
      QHL11: require("./L3/QHL11.png"),
      QHL12: require("./L3/QHL12.png"),
      QHL13: require("./L3/QHL13.png"),
      QHL14: require("./L3/QHL14.png"),
      QHL15: require("./L3/QHL15.png"),
      QHL16: require("./L3/QHL16.png"),
    },
  },
  L4: {
    MA1: {
      DrawCanvas1: require("./L4/DrawCanvas1.png"),
    },
    MA2: {
      DrawCanvas2: require("./L4/DrawCanvas2.png"),
    },
    MA3: {
      DrawCanvas3: require("./L4/DrawCanvas3.png"),
    },
    MA4: {
      DrawCanvas4: require("./L4/DrawCanvas4.png"),
    },
    MA5: {
      ShapesBanner: require("./L4/ShapesBanner.png"),
      Shapes: require("./L4/Shapes.png"),
    },
    MA6: {
      FindShapesBanner: require("./L4/FindShapesBanner.png"),
    },
    MA7: {
      DrawCanvas5: require("./L4/DrawCanvas5.png"),
    },
    MA8: {
      DrawCanvas6: require("./L4/DrawCanvas6.png"),
    },
    MA9: {
      DrawCanvas7: require("./L4/DrawCanvas7.png"),
    },
    MA10: {
      DrawCanvas8: require("./L4/DrawCanvas8.png"),
    },
    MA11: {
      DrawCanvas9: require("./L4/DrawCanvas9.png"),
    },
    MA12: {
      DrawCanvas10: require("./L4/DrawCanvas10.png"),
    },
    MA13: {
      DrawCanvas11: require("./L4/DrawCanvas11.png"),
    },
    MA14: {
      QS1: require("./L4/QS1.png"),
      QS2: require("./L4/QS2.png"),
      QS3: require("./L4/QS3.png"),
      QS4: require("./L4/QS4.png"),
      QS5: require("./L4/QS5.png"),
      QS6: require("./L4/QS6.png"),
      QS7: require("./L4/QS7.png"),
      QS8: require("./L4/QS8.png"),
      QS9: require("./L4/QS9.png"),
      QS10: require("./L4/QS10.png"),
      QS11: require("./L4/QS11.png"),
      QS12: require("./L4/QS12.png"),
      QS13: require("./L4/QS13.png"),
      QS14: require("./L4/QS14.png"),
      QS15: require("./L4/QS15.png"),
      QS16: require("./L4/QS16.png"),
    }
  },
};