import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA8 = () => {
  return (
    <div>
      <div className="font-body">
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Write the value of underlined digit</p>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>1.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                1<u>5</u>4 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
                readOnly
                value={"50"}
                maxLength={"3"}
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>2.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                44<u>7</u> ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
                readOnly
                value={"7"}
                maxLength={"3"}
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>3.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>8</u>43 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
                readOnly
                value={"800"}
                maxLength={"3"}
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>4.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                34<u>5</u> ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>5.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>8</u>94 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>6.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                3<u>3</u>4 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>7.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                6<u>7</u>4 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>8.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>2</u>53 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>9.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                4<u>4</u>6 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>10.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                45<u>1</u> ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>11.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>3</u>76 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>12.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>6</u>25 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>13.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                8<u>5</u>5 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>14.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                54<u>2</u> ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>15.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>1</u>68 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>16.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>3</u>69 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>17.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                1<u>6</u>4 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>18.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                73<u>8</u> ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>19.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>4</u>09 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>20.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                38<u>9</u> ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>21.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                7<u>9</u>9 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>22.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                7<u>0</u>7 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>23.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                <u>3</u>28 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="text-center text-2xl">
              <p>24.</p>
            </div>
            <div className="text-right text-2xl">
              <p>
                8<u>4</u>8 ={" "}
              </p>
            </div>
            <div className="col-span-2">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-b-2 border-black"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Two/7">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Two/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA8;