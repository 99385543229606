import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS13 = () => {
  const speakData =
    "The first known writing tools were used by cave men who sharpen stones to scratch pictures into their walls. The reed pen. was the first tools which used ink and this allowed Egyptians to write on a sheets of papyrus scrolls. Quill Pen Quill (or quill pen). is a writing tool which is made from a flight feather of a large bird and which uses ink to leave marks on a writing surface. A pencil. is made of graphite (or lead)and it is also a hexagonal prism or cylinder writing utensil that is also used to draw, usually on paper. Pencils. have been around for thousands of years now. A fountain pen. is a writing tool which uses a metal nib to apply a water-based ink to paper but can hold enough ink inside to write several pages before being refilled. The first practical version of the fountain pen was produced in 1884 by the American inventor.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"The History of Communication Writing Tools"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Tools1} alt="WritingTools" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            The first known writing tools were used by cave men who{" "}
            <strong>sharpen stones</strong> to scratch pictures into their
            walls.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Tools2} alt="WritingTools" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            <strong>The reed pen</strong> was the first tools which used ink and
            this allowed Egyptians to write on a sheets of papyrus scrolls.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Tools3} alt="WritingTools" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            <strong>Quill Pen</strong> Quill (or quill pen) is a writing tool
            which is made from a flight feather of a large bird and which uses
            ink to leave marks on a writing surface.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Tools4} alt="WritingTools" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            <strong>A pencil</strong>  is made of graphite (or lead)and it is
            also a hexagonal prism or cylinder writing utensil that is also used
            to draw, usually on paper. Pencils have been around for thousands of
            years now.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Tools5} alt="WritingTools" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            <strong>A fountain pen</strong> is a writing tool which uses a
            metal nib to apply a water-based ink to paper but can hold enough
            ink inside to write several pages before being refilled. The first
            practical version of the fountain pen was produced in 1884 by the
            American inventor.
          </p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Four/11">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Four/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS13;
