import React from "react";
import { Link } from 'react-router-dom';
import { Typography, Radio, } from "@material-tailwind/react";
import { useSpeechSynthesis } from "react-speech-kit";
import { IMAGES } from "../../assets";
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from '../../../../../components/BannerTitle';

const E5 = () => {
const speakData =
  "Once there was a poor boy who lived with his mother in India. More than anything else, the boy wanted a drum. One day, Mother gave the boy a stick. The boy tapped on everything with his stick. As he went tapping along, he saw a woman struggling to light her stove. “Take this stick to light your stove,” he said. The woman gave the boy some bread as thanks. Then, the boy heard a hungry baby crying. “Take this bread,” the boy said to the baby’s mother . The mother gave the boy a large jug as thanks. Then, the boy saw an old man lying in the dirt. The boy filled the jug with water for him. The man said that robbers stole his goods. “Take this jug to sell,” the boy said. The man gave the boy one of his horses as thanks. The boy rode down the street. He saw a sad man and some . musicians The man had no horse to ride to his wedding. “Take this horse!” the boy said as he hopped off. The musicians gave the boy a drum as thanks. The boy shouted with joy and banged on his drum. Later, the boy told Mother the whole story “When you are kind, your good deeds come back to you,” Mother said. It was a lesson the boy never forgot";
const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Listening ( The Drum ) "} />
        <div className="p-4">
          <p>Listen carefully and answer the questions.</p>
        </div>
        <div className="p-4">
          <img
            src={IMAGES.L3.E5.TheDrum}
            alt="TheDrum"
            className="w-full mx-auto"
          />
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. How might the mother feel when the boy tells her his story?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="worried" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="unhappy" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Pleased" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. What lesson can readers learn from ‘The Drum’?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="to be honest" />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="to be kind" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="to be clever" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. Why does the old man give the boy a horse?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA7"
                name="Q3"
                label="Because the boy gave him a jug of water"
              />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Because the boy needed it" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Because the boy paid for it" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. What is the boy’s problem in the story?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA7"
                name="Q3"
                label="He wants to make money to buy a horse."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA8"
                name="Q3"
                label="He wants to make new friends in his town."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA9"
                name="Q3"
                label="He wants a drum but does not have one."
              />
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              5. He saw the woman struggling to light her stove. What does
              struggling mean?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="excited to be something" />
            </div>
            <div className="flex-row">
              <Radio
                id="QA8"
                name="Q3"
                label="doing something with no problems"
              />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="hard time doing something" />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Three/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E5;