import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS2 = () => {
  const normalStyle = "px-2 bg-gray-200 hover:bg-gray-200 rounded text-black";
  const correctStyle =
    "px-2 bg-green-500 hover:bg-green-600 rounded text-white";
  const errorStyle = "px-2 bg-red-500 hover:bg-red-600 rounded text-white";
  const showCorrectSytle =
    "px-2 opacity-50 bg-green-500 hover:bg-green-600 rounded text-white";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;      
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;      
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":        
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;      
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"DESSERTS"} />
        <div className="p-4">
          <p className="font-bold mb-4">Choose the correct words.</p>
          <p>
            1. Maple syrup comes from the__________. (
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              sap
            </button>
            /
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              fruit of maple trees
            </button>
            )
          </p>
        </div>
        <div className="p-4">
          <p>
            2. In Canada people collect maple sap in _______. (
            <button onClick={checkAnswer} id="3" className={normalStyle}>
              corn
            </button>
            /
            <button onClick={checkAnswer} id="4" className={normalStyle}>
              buckets
            </button>
            )
          </p>
        </div>
        <div className="p-4">
          <p>
            3. The maple sap is collected in early _________. (
            <button onClick={checkAnswer} id="5" className={normalStyle}>
              spring
            </button>
            /
            <button onClick={checkAnswer} id="6" className={normalStyle}>
              summer
            </button>
            )
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Four/7">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units/Four/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
