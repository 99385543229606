import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";

const E10 = () => {
  return (
    <div>
      <div>
        <div className="flex bg-zmh-lightblue-600 text-white text-xl font-bold justify-center items-center p-6">
          <p>
            <strong>Preposition</strong>
          </p>
        </div>
        <div className="w-full pl-10 pr-10 pt-5">
          <p className="text-lg">
            A <strong>preposition</strong> is a type of word. It shows how a
            noun connects to another word in the sentence.
          </p>
        </div>
        <div className="w-full pl-10 pr-10 pt-5">
          <p>Use the prepositions to complete each sentence.</p>
        </div>
        <div className="w-full pl-10 pr-10 pt-5 text-center">
          <strong>
            <u>to</u>&emsp;<u>from</u>&emsp;<u>for</u>&emsp;<u>by</u>&emsp;
            <u>with</u>
          </strong>
        </div>
        <div className="p-10 mb-20">
          <div className="w-full mb-10">
            <p>
              1 . Bob went
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              his friend’s house.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              2 . Sally can go to school
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              bus.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              3. I saw Mom at home
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Dad.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              4 . You got a gift
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              your uncle.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              5 . The card is
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Alice.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Four/9">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Four/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E10;
