import React from "react";
import { Link } from 'react-router-dom';
import { Typography, Radio, } from "@material-tailwind/react";
import { useSpeechSynthesis } from "react-speech-kit";
import { IMAGES } from "../../assets";
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from '../../../../../components/BannerTitle';

const E5 = () => {
const speakData =
  "I’m going to the dentist. I’m a little scared. A friendly woman named Jamie asks me to wait. She says I can read, play with toys, or watch a movie. After a few minutes, Dana calls my name. Dana is the dentist’s helper. Dana shows me around the office. She teaches me how to clean my teeth at home. Dana says she will make my teeth super clean. She shows me the tools she will use. Some of the tools look scary, but the cleaning doesn’t hurt. Next, Dana takes special pictures of my teeth. The dentist comes to check my teeth. She looks at the pictures of my teeth. The dentist says I need a filling. I will have to come back another day. I ask if getting the filling will hurt. The dentist says it won’t hurt much because it will be small. The dentist says it’s good we found the problem early. I’m not happy I need a filling, but I’m glad I went to the dentist!";

const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Listening ( Going to the Dentist ) "} />
        <div className="p-4">
          <p>Listen carefully and answer the questions.</p>
        </div>
        <div className="p-4">
          <img
            src={IMAGES.L4.E5.GoingToDentist}
            alt="GoingToDentist"
            className="w-1/2 mx-auto"
          />
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. What happens after the dentist looks at the girl’s teeth?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="The dentist shows the girl the cleaning tools."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="The dentist tells the girl that she is all done."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="The dentist tells the girl that she needs a filling."
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. Why does the girl wait in a room until her name is called?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA4"
                name="Q2"
                label="The girl waits in the room because Jamie asks her to wait there."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA5"
                name="Q2"
                label="The girl waits waits in the room because she doesn’t want to see the dentist."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA6"
                name="Q2"
                label="The girl waits in the room because the door is locked."
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. What helps the girl to feel more comfortable at the dentist’s
              office?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA7"
                name="Q3"
                label="The books, toys, and movies in the waiting room"
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA8"
                name="Q3"
                label="The tools the dentist uses to clean her teeth"
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA9"
                name="Q3"
                label="The special pictures of her teeth that Dana takes"
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. How does the girl feel when she looks at the cleaning tools?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA7"
                name="Q3"
                label="The girl thinks they look funny and wants to play with them."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA8"
                name="Q3"
                label="The girl thinks they look scary and won’t let Dana use them on her."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA9"
                name="Q3"
                label="The girl thinks they look scary but finds out that they don’t hurt."
              />
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">5. What is a dentist?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA7"
                name="Q3"
                label="A doctor who takes pictures of different things"
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA8"
                name="Q3"
                label="A doctor who looks at teeth and gums"
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA9"
                name="Q3"
                label="A doctor who helps kids to read and play with toys."
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Four/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E5;