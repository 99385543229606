import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Use “a” or “an” before each noun."} />       
        <div className="p-4 flex">
          <div className="w-1/2">
            1. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>drink</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            2. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>ostrich</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            3. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>emperor</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            4. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>merchant</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            5. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>octopus</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            6. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>sample</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            7. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>end</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            8. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>piece</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            9. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>ice cube</p>
          </div>
        </div>
        <div className="p-4 flex mb-20">
          <div className="w-1/2">
            10. 
            <input
              type="text"
              className="outline-none w-2/3 text-lg border-b-blue-500 border-b-2 ml-4 mx-1 text-center"
              maxLength={2}
            />
          </div>
          <div className="w-1/2">
            <p>umbrella</p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Three/6">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E7;