import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div className="">
            <p>
              1) Murrayfield Stadium in Edinburgh can hold a maximum capacity of
              67,144.
            </p>
          </div>
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA9.QR1} alt="" className="" />
          </div>
          <div className="px-8">
            <ul className="list-disc">
              <li>
                Round this to the nearest 10
                <input
                  type="text"
                  className="outline-none w-24 text-center text-2xl border-b-2 border-black"
                />
              </li>
              <li>
                Round this to the nearest 100
                <input
                  type="text"
                  className="outline-none w-24 text-center text-2xl border-b-2 border-black"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <p>
              2) There were 65,784 tickets sold for the Scotland vs Ireland
              match during the Six Nations.
            </p>
          </div>
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA9.QR2} alt="" className="" />
          </div>
          <div className="px-8">
            <ul className="list-disc">
              <li>
                Round this to the nearest 10
                <input
                  type="text"
                  className="outline-none w-24 text-center text-2xl border-b-2 border-black"
                />
              </li>
              <li>
                Round this to the nearest 100
                <input
                  type="text"
                  className="outline-none w-24 text-center text-2xl border-b-2 border-black"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="p-4">
          <div className="">
            <p>
              3) The SSE Hydro in Glasgow has a maximum capacity of 13,000. Justin
              Bieber sold 12,367 tickets for one of his gigs.
            </p>
          </div>
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA9.QR3} alt="" className="" />
          </div>
          <div className="px-8">
            <ul className="list-disc">
              <li>
                Round this to the nearest 10
                <input
                  type="text"
                  className="outline-none w-24 text-center text-2xl border-b-2 border-black"
                />
              </li>
              <li>
                Round this to the nearest 100
                <input
                  type="text"
                  className="outline-none w-24 text-center text-2xl border-b-2 border-black"
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;
