import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS3 = () => {
  const speakData =
    "We have buses, trains, trams, ridesharing services, taxis, bicycles, motorbikes, scooters and some kids even have shoes that have wheels in them! I bet children in the olden days would have loved shoes with wheels. Does your family have a car now? Most families do, but if you don’t there are heaps of transport options to help you get around in them!";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Transport : Past and Present"} />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            We have buses, trains, trams, ridesharing services, taxis, bicycles,
            motorbikes, scooters and some kids even have shoes that have wheels
            in them! I bet children in the olden days would have loved shoes
            with wheels.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS3.Transport2}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="px-2 py-4 ml-4 md:p-12 lg:p-12 bg-blue-700 text-white mb-24">
          <p>
            Does your family have a car now? Most families do, but if you don’t
            there are heaps of transport options to help you get around in them!
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;
