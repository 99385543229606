import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E8 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Write <strong className="text-red-500">a</strong> or{" "}
            <strong className="text-red-500">an</strong> in front of each noun.
          </p>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">1. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">orange</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">2. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">baby</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">3. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">banana</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">4. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">elephant</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">5. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">ruler</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">6. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">airplane</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">7. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">coconut</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">8. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">dog</p>
          </div>
        </div>
        <div className="flex">
          <div className="p-4 w-1/3"><p className="text-2xl">9. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">apple</p>
          </div>
        </div>
        <div className="flex mb-20">
          <div className="p-4 w-1/3"><p className="text-2xl">10. </p></div>
          <div className="p-4 w-1/3">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
              maxLength={2}
            />
          </div>
          <div className="p-4 w-1/3">
            <p className="text-2xl">car</p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/One/7">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E8;