import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC8 = () => {
  const normalStyle = "w-full bg-gray-200 hover:bg-gray-200 rounded text-black";
  const correctStyle =
    "w-full bg-green-500 hover:bg-green-600 rounded text-white";
  const errorStyle = "w-full bg-red-500 hover:bg-red-600 rounded text-white";
  const showCorrectSytle =
    "w-full opacity-50 bg-green-500 hover:bg-green-600 rounded text-white";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("3").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("3").className = showCorrectSytle;
        document.getElementById("4").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("10").className = showCorrectSytle;
        document.getElementById("9").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("10").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "11":
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("11").className = errorStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":        
        document.getElementById("12").className = correctStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "13":
        document.getElementById("13").className = correctStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        break;
      case "14":
        document.getElementById("13").className = showCorrectSytle;
        document.getElementById("14").className = errorStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="mb-24">
          <div>
            <div className="p-4">
              <img src={IMAGES.L4.SC8.CauseQ1} alt="Quiz" className="w-full" />
            </div>
            <div className="flex">
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="1" className={normalStyle}>
                  Natural
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="2" className={normalStyle}>
                  Human-caused
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img src={IMAGES.L4.SC8.CauseQ2} alt="Quiz" className="w-full" />
            </div>
            <div className="flex">
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="3" className={normalStyle}>
                  Natural
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="4" className={normalStyle}>
                  Human-caused
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img src={IMAGES.L4.SC8.CauseQ3} alt="Quiz" className="w-full" />
            </div>
            <div className="flex">
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="5" className={normalStyle}>
                  Natural
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="6" className={normalStyle}>
                  Human-caused
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img src={IMAGES.L4.SC8.CauseQ4} alt="Quiz" className="w-full" />
            </div>
            <div className="flex">
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="7" className={normalStyle}>
                  Natural
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="8" className={normalStyle}>
                  Human-caused
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img src={IMAGES.L4.SC8.CauseQ5} alt="Quiz" className="w-full" />
            </div>
            <div className="flex">
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="9" className={normalStyle}>
                  Natural
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="10" className={normalStyle}>
                  Human-caused
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img src={IMAGES.L4.SC8.CauseQ6} alt="Quiz" className="w-full" />
            </div>
            <div className="flex">
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="11" className={normalStyle}>
                  Natural
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="12" className={normalStyle}>
                  Human-caused
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4">
              <img src={IMAGES.L4.SC8.CauseQ7} alt="Quiz" className="w-full" />
            </div>
            <div className="flex">
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="13" className={normalStyle}>
                  Natural
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button onClick={checkAnswer} id="14" className={normalStyle}>
                  Human-caused
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Four/7">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC8;
