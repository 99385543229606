import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">Label the picture</div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SC9.Quiz} alt="Quiz" className="w-full" />
        </div>
        <div className="px-4">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-white sm:text-sm">1 .</span>
            </div>
            <input
              type="text"
              name="one"
              id="one"
              className="w-full rounded-md bg-zmh-gray-500 text-white outline-none pl-7 py-4 pr-12 sm:text-sm"
            />
          </div>
        </div>
        <div className="px-4">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-white sm:text-sm">2 .</span>
            </div>
            <input
              type="text"
              name="one"
              id="one"
              className="w-full rounded-md bg-zmh-gray-500 text-white outline-none pl-7 py-4 pr-12 sm:text-sm"
            />
          </div>
        </div>
        <div className="px-4">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-white sm:text-sm">3 .</span>
            </div>
            <input
              type="text"
              name="one"
              id="one"
              className="w-full rounded-md bg-zmh-gray-500 text-white outline-none pl-7 py-4 pr-12 sm:text-sm"
            />
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Three/8">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/Three/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC9;