import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA7 = () => {
  const speakData =
    "I found some coins. i found 11 cents. i already had money before. i had.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Work with time and money"} />        
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin7} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin8} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin9} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin10} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin11} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin12} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin13} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin14} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin15} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin16} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin17} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin18} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Four/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA7;