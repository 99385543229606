import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Determine which letter best represents the length/height.</p>
        </div>
        <img src={IMAGES.L3.MA6.QQ1} alt="" className="w-full" />
        <div className="p-4">
          <div className="grid grid-cols-2">
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ2} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Popcon</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="1a"
                    type="radio"
                    name="Q1"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="1a"
                    aria-describedby="1a"
                  />
                  <label
                    htmlFor="1a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 1 inch
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="1b"
                    type="radio"
                    name="Q1"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="1b"
                    aria-describedby="1b"
                  />
                  <label
                    htmlFor="1b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 3 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="1c"
                    type="radio"
                    name="Q1"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="1c"
                    aria-describedby="1c"
                  />
                  <label
                    htmlFor="1c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 1 mile
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="1d"
                    type="radio"
                    name="Q1"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="1d"
                    aria-describedby="1d"
                  />
                  <label
                    htmlFor="1d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 7 inches
                  </label>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ3} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Lollipop</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="2a"
                    type="radio"
                    name="Q2"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="2a"
                    aria-describedby="2a"
                  />
                  <label
                    htmlFor="2a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 1 inch
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="2b"
                    type="radio"
                    name="Q2"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="2b"
                    aria-describedby="2b"
                  />
                  <label
                    htmlFor="2b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 3 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="2c"
                    type="radio"
                    name="Q2"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="2c"
                    aria-describedby="2c"
                  />
                  <label
                    htmlFor="2c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 1 mile
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="2d"
                    type="radio"
                    name="Q2"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="2c"
                    aria-describedby="2c"
                  />
                  <label
                    htmlFor="2c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 7 inches
                  </label>
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <div className="grid grid-cols-2">
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ4} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Desk</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="3a"
                    type="radio"
                    name="Q3"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="3a"
                    aria-describedby="3a"
                  />
                  <label
                    htmlFor="3a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 2 yards
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="3b"
                    type="radio"
                    name="Q3"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="3b"
                    aria-describedby="3b"
                  />
                  <label
                    htmlFor="3b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 2 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="3c"
                    type="radio"
                    name="Q3"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="3c"
                    aria-describedby="3c"
                  />
                  <label
                    htmlFor="3c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 75 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="3d"
                    type="radio"
                    name="Q3"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="3d"
                    aria-describedby="3d"
                  />
                  <label
                    htmlFor="3d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 100 inches
                  </label>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ5} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Ferris Wheel</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="4a"
                    type="radio"
                    name="Q4"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="4a"
                    aria-describedby="4a"
                  />
                  <label
                    htmlFor="4a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 6 yards
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="4b"
                    type="radio"
                    name="Q4"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="4b"
                    aria-describedby="4b"
                  />
                  <label
                    htmlFor="4b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 1 mile
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="4c"
                    type="radio"
                    name="Q4"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="4c"
                    aria-describedby="4c"
                  />
                  <label
                    htmlFor="4c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 75 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="4d"
                    type="radio"
                    name="Q4"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="4d"
                    aria-describedby="4d"
                  />
                  <label
                    htmlFor="4d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 100 inches
                  </label>
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <div className="grid grid-cols-2">
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ6} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Toothbrush</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="5a"
                    type="radio"
                    name="Q5"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="5a"
                    aria-describedby="5a"
                  />
                  <label
                    htmlFor="5a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 10 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="5b"
                    type="radio"
                    name="Q5"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="5b"
                    aria-describedby="5b"
                  />
                  <label
                    htmlFor="5b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 1 yard
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="5c"
                    type="radio"
                    name="Q5"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="5c"
                    aria-describedby="5c"
                  />
                  <label
                    htmlFor="5c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 7 inches
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="5d"
                    type="radio"
                    name="Q5"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="5d"
                    aria-describedby="5d"
                  />
                  <label
                    htmlFor="5d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 3 inches
                  </label>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ7} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Can of Soda</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="6a"
                    type="radio"
                    name="Q6"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="6a"
                    aria-describedby="6a"
                  />
                  <label
                    htmlFor="6a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 1 yard
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="6b"
                    type="radio"
                    name="Q6"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="6b"
                    aria-describedby="6b"
                  />
                  <label
                    htmlFor="6b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 4 inches
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="6c"
                    type="radio"
                    name="Q6"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="6c"
                    aria-describedby="6c"
                  />
                  <label
                    htmlFor="6c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 1 foot
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="6d"
                    type="radio"
                    name="Q6"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="6d"
                    aria-describedby="6d"
                  />
                  <label
                    htmlFor="6d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 1 inch
                  </label>
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <div className="grid grid-cols-2">
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ8} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Frog</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="7a"
                    type="radio"
                    name="Q7"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="7a"
                    aria-describedby="7a"
                  />
                  <label
                    htmlFor="7a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 2 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="7b"
                    type="radio"
                    name="Q7"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="7b"
                    aria-describedby="7b"
                  />
                  <label
                    htmlFor="7b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 1 foot
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="7c"
                    type="radio"
                    name="Q7"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="7c"
                    aria-describedby="7c"
                  />
                  <label
                    htmlFor="7c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 3 inches
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="7d"
                    type="radio"
                    name="Q7"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="7d"
                    aria-describedby="7d"
                  />
                  <label
                    htmlFor="7d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 9 inches
                  </label>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ9} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Key</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="8a"
                    type="radio"
                    name="Q8"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="8a"
                    aria-describedby="8a"
                  />
                  <label
                    htmlFor="8a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 5 feet
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="8b"
                    type="radio"
                    name="Q8"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="8b"
                    aria-describedby="8b"
                  />
                  <label
                    htmlFor="8b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 2 inches
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="8c"
                    type="radio"
                    name="Q8"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="8c"
                    aria-describedby="8c"
                  />
                  <label
                    htmlFor="8c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 6 inches
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="8d"
                    type="radio"
                    name="Q8"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="8d"
                    aria-describedby="8d"
                  />
                  <label
                    htmlFor="8d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 2 yards
                  </label>
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <div className="grid grid-cols-2">
            <div className="p-2">
              <div>
                <img src={IMAGES.L3.MA6.QQ10} alt="" className="w-full" />
              </div>
              <div className="p-2">
                <p>Screw</p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    id="9a"
                    type="radio"
                    name="Q9"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="9a"
                    aria-describedby="9a"
                  />
                  <label
                    htmlFor="9a"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    A. 1 yard
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="9b"
                    type="radio"
                    name="Q9"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="9b"
                    aria-describedby="9b"
                  />
                  <label
                    htmlFor="9b"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    B. 10 inches
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="9c"
                    type="radio"
                    name="Q9"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="9c"
                    aria-describedby="9c"
                  />
                  <label
                    htmlFor="9c"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    C. 8 inches
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="9d"
                    type="radio"
                    name="Q9"
                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    aria-labelledby="9d"
                    aria-describedby="9d"
                  />
                  <label
                    htmlFor="9d"
                    className="text-sm font-medium text-gray-900 ml-2 block"
                  >
                    D. 1 inch
                  </label>
                </div>
              </div>
            </div>
            <div className="p-2">
              
            </div>            
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/5">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA6;
