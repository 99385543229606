import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>Use the prepositions in the box to complete the sentences.</p>
        </div>
        <div className="p-2 m-2 border-2 border-black">
          <div className="flex justify-between">
            <p>at</p>
            <p>down</p>
            <p>on</p>
            <p>behind</p>
          </div>
          <div className="flex justify-between">
            <p>around</p>
            <p>in</p>
            <p>over</p>
            <p>inside</p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              1. I live{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              Yangon.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              2. The necklace is{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              her neck.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              3. We drove{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              the bridge.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              4. The cat is{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              the table.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              5. Nyi Nyi left the toy{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              his friend's house.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              6. They rode theirs bikes{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              the hill.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              7. It is time to eat breakfast so he{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              to eat.
            </p>
          </div>
        </div>
        <div className="mb-24">
          <div className="p-4">
            <p>
              8. The dog is hiding{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              the tree.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E9;