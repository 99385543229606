import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA7 = () => {
  const speakData =
    "8+1 is 9 . 9+1 is 10 . 10+1 is 11 . 11+1 is 12 . In each step the numbers go up by 1 . The missing numbers are 10 and 12.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Number and Operations in Base Ten"} />
        <div className="p-4">
          <p>Extend the counting sequence.</p>
        </div>
        <div>
          <img src={IMAGES.L3.MA7.Base10} alt="" className="w-full" />
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg mt-10">
          <p>
            8+1 is 9 . 9+1 is 10 . 10+1 is 11 . 11+1 is 12 . In each step the
            numbers go up by 1 . The missing numbers are 10 and 12.
          </p>
        </div>
       

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/Three/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA7;