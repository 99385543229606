import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA8 = () => {
  return (
    <div>
      <div className="">
        <BannerTitle title={"Mathematics-Number Sequence Quiz"} />
        <div className="p-4">
          <p>Write in the Missing Number.</p>
        </div>
        <div className="px-8 mt-4">
          <ul className="list-disc">
            <li>
              <p className="text-2xl">
                {"3 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 5 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 8 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 10 11 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 12 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 14 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 17 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 19 20 "}
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 40 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 43 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 45 46 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 72 73 74 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 76 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 78 79 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                {" 92 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 94 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 97 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 100 "}
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 2 3 4 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 6 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 8 9 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                {" 58 59 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 61 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 63 64 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 68 "}
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 29 30 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 32 33 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 35 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                {" 83 84 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 87 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 89 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 91 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 93 "}
              </p>
            </li>
            <li className="mt-6">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 20 21 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 23 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 25 26 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" 28 29 "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </li>
          </ul>
        </div>

        <div className="mb-24 bg-gray-100"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/7">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA8;