import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA2 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black text-xl w-full py-4";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white text-xl w-full py-4";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white text-xl w-full py-4";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white text-xl w-full py-4";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("5").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("7").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("7").className = showCorrectSytle;
        document.getElementById("8").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Choose the correct number that is next in each sequence.</p>
        </div>
        <div className="py-4 mb-10">
          <div className="p-4">
            <div className="flex items-center">
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                1
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                2
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                3
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                4
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-zmh-lightblue-300 font-bold text-4xl text-center p-2 m-2 rounded-lg">
                ?
              </p>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                5
              </button>
            </div>
            <div className=" w-1/2 m-2">
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                6
              </button>
            </div>
          </div>
        </div>
        <div className="py-4 mb-10">
          <div className="p-4">
            <div className="flex items-center">
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                9
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                8
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                7
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                6
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-zmh-lightblue-300 font-bold text-4xl text-center p-2 m-2 rounded-lg">
                ?
              </p>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                4
              </button>
            </div>
            <div className=" w-1/2 m-2">
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                5
              </button>
            </div>
          </div>
        </div>
        <div className="py-4 mb-10">
          <div className="p-4">
            <div className="flex items-center">
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                2
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                4
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                6
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                8
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-zmh-lightblue-300 font-bold text-4xl text-center p-2 m-2 rounded-lg">
                ?
              </p>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                10
              </button>
            </div>
            <div className=" w-1/2 m-2">
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                11
              </button>
            </div>
          </div>
        </div>
        <div className="py-4 mb-40">
          <div className="p-4">
            <div className="flex items-center">
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                1
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                3
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                5
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-white font-bold text-center p-4 m-2 rounded-lg">
                7
              </p>
              <p className="w-1/5 bg-zmh-gray-700 text-zmh-lightblue-300 font-bold text-4xl text-center p-2 m-2 rounded-lg">
                ?
              </p>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                9
              </button>
            </div>
            <div className=" w-1/2 m-2">
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                6
              </button>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/2">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/One/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA2;
