import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS3 = () => {
  const speakData =
    "Smoke Signals. Smoke signals were used by many cultures including the Native Americans a very long time ago.  It is one of the oldest forms of visual, long distance communication. Messages of warning, help and important news were sent in messages using smoke signals. ";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Very Early Types of Communication"} />
        <div className="p-4">
          <p className="text-center font-bold text-orange-700">Smoke Signals</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SmokeSignal}
            alt="SmokeSignal"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <p>
            Smoke signals were used by many cultures including the Native
            Americans a very long time ago. It is one of the oldest forms of
            visual, long distance communication. Messages of warning, help and
            important news were sent in messages using smoke signals.
          </p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Four/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;
