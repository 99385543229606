import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA5 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Pratice adding 3 single digit numbers mentally."}
        />
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">1)</p>
            <p className="text-2xl">
              {"4 + 8 + 3 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">2)</p>
            <p className="text-2xl">
              {"7 + 5 + 2 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">3)</p>
            <p className="text-2xl">
              {"3 + 4 + 6 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">4)</p>
            <p className="text-2xl">
              {"8 + 4 + 1 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">5)</p>
            <p className="text-2xl">
              {"9 + 4 + 4 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">6)</p>
            <p className="text-2xl">
              {"3 + 7 + 8 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">7)</p>
            <p className="text-2xl">
              {"6 + 2 + 7 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">8)</p>
            <p className="text-2xl">
              {"9 + 1 + 5 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">9)</p>
            <p className="text-2xl">
              {"7 + 5 + 6 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">10)</p>
            <p className="text-2xl">
              {"8 + 4 + 7 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">11)</p>
            <p className="text-2xl">
              {"4 + 9 + 3 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">12)</p>
            <p className="text-2xl">
              {"2 + 7 + 6 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">13)</p>
            <p className="text-2xl">
              {"8 + 8 + 2 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">14)</p>
            <p className="text-2xl">
              {"6 + 5 + 6 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">15)</p>
            <p className="text-2xl">
              {"4 + 3 + 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">16)</p>
            <p className="text-2xl">
              {"2 + 8 + 7 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">17)</p>
            <p className="text-2xl">
              {"6 + 9 + 5 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">18)</p>
            <p className="text-2xl">
              {"8 + 1 + 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">19)</p>
            <p className="text-2xl">
              {"4 + 7 + 8 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">20)</p>
            <p className="text-2xl">
              {"7 + 7 + 6 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">21)</p>
            <p className="text-2xl">
              {"9 + 0 + 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">22)</p>
            <p className="text-2xl">
              {"7 + 8 + 3 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">23)</p>
            <p className="text-2xl">
              {"3 + 8 + 7 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">24)</p>
            <p className="text-2xl">
              {"2 + 8 + 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/3">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA5;
