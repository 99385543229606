import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS28 = () => {
  const speakData =
    "At my house we love. At my house we hug. At my house we play. At my house we eat. At my house we sleep. At my house we walk with no shoes on a feet";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"My House"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.SS28.MyHousePoem}
            alt="MyHouse"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <div className="break-all">
            <p>At my house we love</p>
            <p>At my house we hug </p>
            <p>At my house we play </p>
            <p>At my house we eat </p>
            <p>At my house we sleep</p>
            <p>At my house we walk with no shoes on a feet</p>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/27">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS28;
