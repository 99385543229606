import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const CC = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Common Core State Standards"} />
        <div className="p-4">
          <p>
            In Grade 2, instructional time should focus on four critical areas:
            (1) extending understanding of base-ten notation; (2) building
            fluency with addition and subtraction; (3) using standard units of
            measure; and (4) describing and analyzing shapes.
          </p>
        </div>
        <div className="p-8 mb-20">
          <ul className="list-decimal">
            <li>
              Students extend their understanding of the base-ten system. This
              includes ideas of counting in fives, tens, and multiples of
              hundreds, tens, and ones, as well as number relationships
              involving these units, including comparing. Students understand
              multi-digit numbers (up to 1000) written in base-ten notation,
              recognizing that the digits in each place represent amounts of
              thousands, hundreds, tens, or ones (e.g., 853 is 8 hundreds + 5
              tens + 3 ones).
            </li>
            <li>
              Students extend their understanding of the base-ten system. This
              includes ideas of counting in fives, tens, and multiples of
              hundreds, tens, and ones, as well as number relationships
              involving these units, including comparing. Students understand
              multi-digit numbers (up to 1000) written in base-ten notation,
              recognizing that the digits in each place represent amounts of
              thousands, hundreds, tens, or ones (e.g., 853 is 8 hundreds + 5
              tens + 3 ones).
            </li>
            <li>
              Students recognize the need for standard units of measure
              (centimeter and inch) and they use rulers and other measurement
              tools with the understanding that linear measure involves an
              iteration of units. They recognize that the smaller the unit, the
              more iterations they need to cover a given length.
            </li>
            <li>
              Students describe and analyze shapes by examining their sides and
              angles. Students investigate, describe, and reason about
              decomposing and combining shapes to make other shapes. Through
              building, drawing, and analyzing two- and three-dimensional
              shapes, students develop a foundation for understanding area,
              volume, congruence, similarity, and symmetry in later grades.
            </li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Two/1">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CC;