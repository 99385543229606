import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA8 = () => {
  return (
    <div>
      <div className="">
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Find the sum.</p>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"1)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>41</p>
                <p>+ 24</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  readOnly
                  value={"85"}
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"2)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>41</p>
                <p>+ 42</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"3)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>31</p>
                <p>+ 23</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"4)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>1</p>
                <p>+ 53</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"5)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>6</p>
                <p>+ 90</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"                  
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"6)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>83</p>
                <p>+ 13</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"7)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>70</p>
                <p>+ 23</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"8)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>61</p>
                <p>+ 12</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"9)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>77</p>
                <p>+ 10</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"                  
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"10)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>7</p>
                <p>+ 71</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"11)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>70</p>
                <p>+ 6</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"12)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>81</p>
                <p>+ 15</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"13)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>23</p>
                <p>+ 61</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"                  
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"14)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>42</p>
                <p>+ 6</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"15)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>25</p>
                <p>+ 33</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"16)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>18</p>
                <p>+ 30</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"17)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>68</p>
                <p>+ 1</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"                  
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"18)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>82</p>
                <p>+ 12</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"19)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>48</p>
                <p>+ 20</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
            <div className="flex px-2">
              <div className="w-1/3">
                <p>{"20)"}</p>
              </div>
              <div className="w-2/3 text-right">
                <p>24</p>
                <p>+ 54</p>
                <hr className="h-px bg-gray-900 border-0 dark:bg-gray-700" />
                <input
                  type="text"
                  className="outline-none w-full text-right text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </div>
            </div>
          </div>
        </div>
        

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/One/7">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/One/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA8;
