import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA13 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Find the sums"} />
        <div className="p-4">
          <div className="grid grid-cols-3">
            <div className="text-lg">
              <p>
                1) 4 + 6 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                2) 6 + 5 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                3) 1 + 7 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-3">
            <div className="text-lg">
              <p>
                4) 6 + 6 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                5) 7 + 3 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                6) 2 + 7 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-3">
            <div className="text-lg">
              <p>
                7) 2 + 6 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                8) 4 + 4 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                9) 8 + 3 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-3">
            <div className="text-lg">
              <p>
                10) 8 + 7 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                11) 2 + 2 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                12) 3 + 1 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
        </div>
        
        <div className="p-4">
          <div className="grid grid-cols-3">
            <div className="text-lg">
              <p>
                13) 6 + 2 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                14) 6 + 3 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                15) 7 + 5 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-3">
            <div className="text-lg">
              <p>
                16) 2 + 1 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                17) 3 + 7 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                18) 2 + 8 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-3">
            <div className="text-lg">
              <p>
                19) 9 + 7 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                20) 7 + 1 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
            <div className="text-lg">
              <p>
                21) 3 + 9 =
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={3}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/One/12">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/One/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA13;