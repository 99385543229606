import React from "react";
import { Link } from 'react-router-dom';
import { Typography, Radio, } from "@material-tailwind/react";
import { useSpeechSynthesis } from "react-speech-kit";
import { IMAGES } from "../../assets";
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from '../../../../../components/BannerTitle';

const E5 = () => {
const speakData =
  "Billy is a puppy. He lives in a big house with a blue roof. He loves to play fetch with Emmy.  Emmy throws the ball. Billy chases it. Then, Billy sees Cat. He drops the ball and chases Cat. Cat runs down the street, and Billy chases him. Emmy cries, “Come back, Billy.” Cat runs through a farm, and Billy chases him. Cat runs into the forest, and Billy chases him. Cat climbs up a tree. Billy turns to go home. He cannot see the big house with a blue roof. “Oh, no, I am lost,” Billy says. Billy runs down a path. He sees Fox. “How can I get home?” he asks Fox.  I have never been out of the forest,” says Fox. “Ask Cow in the farmer’s field,” Fox says. Billy runs to the farmer’s field. Billy says to Cow, “I am lost.” “Do you know the way to my house?” Billy asks.“ I have never been off the farm,” says Cow. “Ask Owl,” Cow says.  Billy goes to Owl in the barn.“Please wake up, Owl,” says Billy.“I need your help,” he says.“I am lost!” he cries.“ Do you know a big housewith a blue roof?” Billy asks.“Yes, I do,” Owl says.Owl flies, and Billy chases her.Then, Billy sees Emmy at the big housewith a blue roof.“Thank you, Owl,” says Billy.";
const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Listening ( Billy gets lost ) "} />
        <div className="p-4">
          <p>Listen carefully and answer the questions.</p>
        </div>
        <div className="p-4">
          <img
            src={IMAGES.L2.E5.BillyGetLost}
            alt="BillyGetLost"
            className="w-full mx-auto"
          />
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. The game of fetch is ___________ .
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="a game where a dog brings back what is thrown"
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="a game dogs play with each other"
              />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="a game using a rope" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. Why did Billy run away from Emmy?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="She scared him." />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="He chased a cat." />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="He didn’t like her." />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. Owl most likely knew the way to Billy’s house because
              __________.
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="owls know everything" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="she and Billy were friends" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="she flies far and wide" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. How did Billy know he was lost?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="Fox told him." />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Billy couldn’t see his house." />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Emmy told him." />
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              5. Fox and Cow couldn’t help Billy because ____________.
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="They couldn’t talk." />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="He never asked them for help." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA9"
                name="Q3"
                label="They’d never been away from their homes."
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E5;