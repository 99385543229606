import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA12 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="text-center">
          <div className="p-4">
            <p className="text-2xl">
              {"42 = "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" tens "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" ones "}
            </p>
          </div>
          <div className="p-4">
            <p className="text-2xl">
              {"35 = "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" tens "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" ones "}
            </p>
          </div>
          <div className="p-4">
            <p className="text-2xl">
              {"28 = "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" tens "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" ones "}
            </p>
          </div>
        </div>

        <div>
          <div className="p-4">
            <img src={IMAGES.L3.MA12.Q1} alt="" className="w-full" />
          </div>
          <div className="text-center">
            <div className="px-4">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" tens "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" ones "}
              </p>
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="p-4">
            <img src={IMAGES.L3.MA12.Q2} alt="" className="w-full" />
          </div>
          <div className="text-center">
            <div className="px-4">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" tens "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" ones "}
              </p>
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="p-4">
            <img src={IMAGES.L3.MA12.Q3} alt="" className="w-full" />
          </div>
          <div className="p-4">
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-dashed border-2"
                maxLength="2"
              />
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-dashed border-2"
                maxLength="2"
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <div className="p-4">
            <p className="text-2xl">
              {" 5 tens 3 ones = "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
          <div className="p-4">
            <p className="text-2xl">
              {" 2 tens 5 ones = "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
          <div className="p-4">
            <p className="text-2xl">
              {" 4 tens 6 ones = "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div className="text-center">
          <div className="p-4">
            <p className="text-2xl">Write 26 in expanded form</p>
          </div>
          <div className="">
            <p className="text-2xl">
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" + "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div className="text-center">
          <div className="p-4">
            <p className="text-2xl">Write 45 in expanded form</p>
          </div>
          <div className="">
            <p className="text-2xl">
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
              {" + "}
              <input
                type="text"
                className="outline-none w-8 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/11">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA12;
