import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA10 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"True or False Subtraction Worksheet"} />
        <div className="p-4">
          <p>
            Look at the equations. Write the answer for each subtraction and
            find out if the equations are true or false. Check the correct
            square box for each equation.
          </p>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">13 - 3</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">18 - 9</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    FALSE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">15 - 6</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">10 - 1</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    FALSE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">19 - 4</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">16 - 2</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    FALSE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">16 - 8</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">12 - 4</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    FALSE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">12 - 7</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">10 - 5</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    FALSE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">14 - 3</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">13 - 2</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">17 - 8</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">11 - 3</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-4 p-4 bg-gray-200 shadow-xl rounded-lg border-dashed border-2 border-black">
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <p className="text-xl">18 - 5</p>
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <p className="text-xl">15 - 2</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
              <div>
                <p className="text-3xl">=</p>
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-12 text-center text-2xl border-black border-dashed border-2"
                  maxLength="2"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center justify-items-center">
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    TRUE
                  </label>
                </div>
              </div>
              <div></div>
              <div className="mt-4">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="chk1"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    FALSE
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Two/9">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Two/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA10;
