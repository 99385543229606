import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC14 = () => {
  const speakData =
    "The earth’s rotation is stable and it creates a predictable pattern of day and night like this ball. The earth is not static meaning it’s not still it’s rotating . The pattern of the sun rising and setting is constant . It’s a stable cycle. We expect those changes to happen just like we expect ice to melt when heat is applied . Freeze when temperatures drop. The water molecules haven’t changed they remain stable . So it’s still water but just in different state of matter. When I mix this vinegar and baking soda they cause a chemical change . The molecules in baking soda or sodium carbonate react with molecules in vinegar . A weak acetic acid which causes a change. Baking cookies is also a chemical change . When enough heat is applied to the mixture the dough transforms into a cookie . A delicious change . Chemical change can’t be reversed . You can’t separate baking soda and vinegar. In nature a caterpillar transforms into a butterfly . This caterpillar will go through many changes before it becomes a butterfly . But all of those changes are predictable.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Stability and Change"} />
        <div className="p-4 flex">
          <div className="w-1/2">
            <img
              src={IMAGES.L3.SC14.EarthGIF}
              alt="StabilityandChange"
              className="w-full"
            />
          </div>
          <div className="w-1/2 p-2">
            <p>
              The earth’s rotation is stable and it creates a predictable
              pattern of day and night like{" "}
              <strong className="text-red-500">this ball</strong>.
            </p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2 p-2">
            <p>
              The earth is not static meaning it’s not still it’s rotating . The
              pattern of the sun rising and setting is constant . It’s a stable
              cycle.
            </p>
          </div>
          <div className="w-1/2">
            <img
              src={IMAGES.L3.SC14.BasketBallGIF}
              alt="StabilityandChange"
              className="w-full"
            />
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            <img
              src={IMAGES.L3.SC14.IcedGIF}
              alt="StabilityandChange"
              className="w-full"
            />
          </div>
          <div className="w-1/2 p-2">
            <p>
              We expect those changes to happen just like we expect ice to melt
              when heat is applied . Freeze when temperatures drop.
            </p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            <img
              src={IMAGES.L3.SC14.WaterMolecule}
              alt="StabilityandChange"
              className="w-full"
            />
          </div>
          <div className="w-1/2 p-2">
            <p>
              The water molecules haven’t changed they remain stable . So it’s
              still water but just in different state of matter.
            </p>
          </div>
        </div>
        <div className="p-4">
          <div>
            <img
              src={IMAGES.L3.SC14.Vinegar}
              alt="StabilityandChange"
              className="w-full"
            />
          </div>
          <div>
            <p>
              When I mix this vinegar and baking soda they cause a chemical
              change . The molecules in baking soda or sodium carbonate react
              with molecules in vinegar . A weak acetic acid which causes a
              change.
            </p>
          </div>
        </div>
        <div className="p-4">
          <div>
            <img
              src={IMAGES.L3.SC14.Baking}
              alt="StabilityandChange"
              className="w-full"
            />
          </div>
          <div>
            <p>
              Baking cookies is also a chemical change . When enough heat is
              applied to the mixture the dough transforms into a cookie . A
              delicious change . Chemical change can’t be reversed . You can’t
              separate baking soda and vinegar.
            </p>
          </div>
        </div>
        <div className="p-4">
          <div>
            <img
              src={IMAGES.L3.SC14.Caterpillar}
              alt="StabilityandChange"
              className="w-full"
            />
          </div>
          <div>
            <p>
              In nature a caterpillar transforms into a butterfly . This
              caterpillar will go through many changes before it becomes a
              butterfly . But all of those changes are predictable.
            </p>
          </div>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/13">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/15">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC14;