import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>7. Use the prefixes in the box. Fill in the blanks.</p>
        </div>
        <div className="p-2 m-2 border-2 border-black">
          <div className="flex justify-between">
            <p>pre-</p>
            <p>re-</p>
            <p>un-</p>
          </div>
          <div className="flex justify-between">
            <p>before</p>
            <p>again</p>
            <p>not</p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              1.{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              view means to look before.
            </p>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>
              2.{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              tie means to take ties apart.
            </p>
          </div>
        </div>
        <div className="mb-24">
          <div className="p-4">
            <p>
              3.{" "}
              <input
                type="text"
                className="outline-none w-20 text-xl border-b-2 border-b-blue-500"
                maxLength={6}
              />{" "}
              tie means to take ties apart.
            </p>
          </div>
        </div>
        

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E9;