import React, { useState } from "react";
import { useDrop } from "react-dnd";
import ItemTypes from "./ItemType";
import Box from "./Box";

const data = [
  { id: 1, name: "beautiful", type: ItemTypes.ADJECTIVES },
  { id: 2, name: "big", type: ItemTypes.ADJECTIVES },
  { id: 3, name: "black", type: ItemTypes.COLORS },
  { id: 4, name: "blue", type: ItemTypes.COLORS },
  { id: 5, name: "dress", type: ItemTypes.CLOTHES },
  { id: 6, name: "expensive", type: ItemTypes.ADJECTIVES },
  { id: 7, name: "green", type: ItemTypes.COLORS },
  { id: 8, name: "hat", type: ItemTypes.CLOTHES },
  { id: 9, name: "long", type: ItemTypes.ADJECTIVES },
  { id: 10, name: "shoes", type: ItemTypes.CLOTHES },
  { id: 11, name: "tunic", type: ItemTypes.CLOTHES },
  { id: 12, name: "white", type: ItemTypes.COLORS },
];

const Container = () => {
  const normalTarget = "flex flex-wrap bg-zmh-gray-500 p-2 m-2 text-white";
  const canDropTarget = "flex flex-wrap bg-green-500 p-2 m-2 text-white";

  const [items, setItems] = useState(data);
  const [target, setTarget] = useState([]);
  const [target2, setTarget2] = useState([]);
  const [target3, setTarget3] = useState([]);

  const [{ canDrop }, dropRef] = useDrop({
    accept: ItemTypes.ADJECTIVES,
    drop: (item) => addItemToBox(ItemTypes.ADJECTIVES, item.id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [{ canDrop2 }, dropRef2] = useDrop({
    accept: ItemTypes.COLORS,
    drop: (item) => addItemToBox(ItemTypes.COLORS, item.id),
    collect: (monitor) => ({
      isOver2: monitor.isOver(),
      canDrop2: monitor.canDrop(),
    }),
  });
  const [{ canDrop3 }, dropRef3] = useDrop({
    accept: ItemTypes.CLOTHES,
    drop: (item) => addItemToBox(ItemTypes.CLOTHES, item.id),
    collect: (monitor) => ({
      isOver3: monitor.isOver(),
      canDrop3: monitor.canDrop(),
    }),
  });

  const addItemToBox = (method, id) => {
    const itemList = items.filter((item) => id === item.id);    
    switch (method) {
      case "adjectives":
        setTarget([...target, itemList[0]]);
        break;
      case "colors":
        setTarget2([...target2, itemList[0]]);
        break;
      case "clothes":
        setTarget3([...target3, itemList[0]]);
        break;
      default:
    }
    const newItemsList = items.filter((item) => id !== item.id);
    setItems(newItemsList);
  };

  return (
    <div>
      <div className="flex flex-wrap border-2 border-zmh-gray-500 p-2 m-2">
        {items.map((item) => (
          <Box
            draggable
            key={item.id}
            data={data}
            id={item.id}
            name={item.name}
            type={item.type}
          />
        ))}
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">ADJECTIVES</h6>
        <div className={canDrop ? canDropTarget : normalTarget} ref={dropRef}>
          {target.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">
            Adjectives can be dropped here!
          </small>
        </div>
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">COLORS</h6>
        <div className={canDrop2 ? canDropTarget : normalTarget} ref={dropRef2}>
          {target2.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">
            Colors can be dropped here!
          </small>
        </div>
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">CLOTHES</h6>
        <div className={canDrop3 ? canDropTarget : normalTarget} ref={dropRef3}>
          {target3.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">
            Clothes can be dropped here!
          </small>
        </div>
      </div>
    </div>
  );
};

export default Container;
