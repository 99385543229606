import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC3 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("3").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("3").className = showCorrectSytle;
        document.getElementById("4").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("5").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("6").className = correctStyle;        
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("7").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("7").className = showCorrectSytle;
        document.getElementById("8").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="">
          <div className="p-4">
            <p>1 . Animals need food to live and grow.</p>
          </div>
          <div className="flex -mt-2">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                True
              </button>
            </div>
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                False
              </button>
            </div>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>2 . Matters in ecosystem are called plants.</p>
          </div>
          <div className="flex -mt-2">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                True
              </button>
            </div>
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                False
              </button>
            </div>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>3 . Air is living thing.</p>
          </div>
          <div className="flex -mt-2">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                True
              </button>
            </div>
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                False
              </button>
            </div>
          </div>
        </div>
        <div className="">
          <div className="p-4">
            <p>4 . Water is non-living thing.</p>
          </div>
          <div className="flex -mt-2">
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                True
              </button>
            </div>
            <div className="w-1/2 m-2">
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                False
              </button>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Three/2">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;
