import React from "react";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS21 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "Look at the pictures. Write the word each symbol could stand for a map."
          }
        />
        <div className="flex justify-between p-3 m-3 border-2 border-gray-700">
          <div>River</div>
          <div>Mountain</div>
          <div>Forest</div>
        </div>
        <div className="flex justify-between p-3 m-3 border-2 border-gray-700">
          <div>Hospital</div>
          <div>Airport</div>
          <div>Railroad</div>
        </div>
        <div className="flex">
          <div className="w-1/2 p-4 md:p-12 lg:p-12">
            <div className="mb-2">
              <img
                src={IMAGES.L4.SS21.Symbol1}
                alt="Q1"
                className="w-full h-24 rounded-lg shadow-lg"
              />
              <input
                className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
          </div>
          <div className="w-1/2 p-4 md:p-12 lg:p-12">
            <div className="mb-2">
              <img
                src={IMAGES.L4.SS21.Symbol2}
                alt="Q2"
                className="w-full h-24 rounded-lg shadow-lg"
              />
              <input
                className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex p-4 md:p-12 lg:p-12">
            <div className="mb-2">
              <img
                src={IMAGES.L4.SS21.Symbol3}
                alt="Q3"
                className="w-full h-24 rounded-lg shadow-lg"
              />
              <input
                className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <div className="mb-2">
              <img
                src={IMAGES.L4.SS21.Symbol4}
                alt="Q4"
                className="w-full h-24 rounded-lg shadow-lg"
              />
              <input
                className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
          </div>
        </div>
        <div className="flex mb-20">
          <div className="flex p-4 md:p-12 lg:p-12">
            <div className="mb-2">
              <img
                src={IMAGES.L4.SS21.Symbol5}
                alt="Q5"
                className="w-full h-24 rounded-lg shadow-lg"
              />
              <input
                className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12 mb-20">
            <div className="mb-2">
              <img
                src={IMAGES.L4.SS21.Symbol6}
                alt="Q6"
                className="w-full h-24 rounded-lg shadow-lg"
              />
              <input
                className="rounded-lg border-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full mt-3 p-3"
                type="text"
                placeholder="Answer Here"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/20">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS21;
