export const IMAGES = {
  L1: {
    E1: {
      NativeAmericanDance: require("./L1/NativeAmericanDance.png"),
    },
    E3: {
      GoAwayGerms: require("./L1/GoAwayGerms.png"),
    },
  },
  L2: {
    E1: {
      SL1: require("./L2/Eight.png"),
      SL2: require("./L2/Wrist.png"),
      SL3: require("./L2/Knee.png"),
      SL4: require("./L2/Lamb.png"),
      SL5: require("./L2/Knob.png"),
      SL6: require("./L2/Calf.png"),
      SL7: require("./L2/Whistle.png"),
      SL8: require("./L2/Light.png"),
      SL9: require("./L2/Wrench.png"),
      SL10: require("./L2/Comb.png"),
      SL11: require("./L2/Thumb.png"),
      SL12: require("./L2/Knief.png"),
    },
    E2: {
      SSL1: require("./L2/Tube.png"),
      SSL2: require("./L2/Rat.png"),
      SSL3: require("./L2/Cane.png"),
      SSL4: require("./L2/Robe.png"),
    },
  },
  L3: {
    E1: {
      Unexpected: require("./L3/Unexpected.png"),
    },
  },
  L4: {
    E1: {
      Superstition: require("./L4/Superstition.png"),
      KnockOnWood: require("./L4/KnockOnWood.png"),
      BreakingAMirror: require("./L4/BreakingAMirror.png"),
      Firday13: require("./L4/Firday13.png"),
      FingerCrossed: require("./L4/FingerCrossed.png"),
    },
  },
};
