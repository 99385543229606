import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Commutative Property"} />
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">2 + 5 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    value={"7"}
                    readOnly
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="mb-2">
              <p className="font-bold text-2xl text-blue-500">So</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">5 + 2 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"
                    value={"7"}
                    readOnly
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">3 + 1 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="mb-2">
              <p className="font-bold text-2xl text-blue-500">So</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">1 + 3 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">8 + 0 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="mb-2">
              <p className="font-bold text-2xl text-blue-500">So</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">0 + 8 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">4 + 5 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="mb-2">
              <p className="font-bold text-2xl text-blue-500">So</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">5 + 4 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="p-4 m-4 rounded-lg shadow-xl bg-gray-300">
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">5 + 2 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
            <div className="mb-2">
              <p className="font-bold text-2xl text-blue-500">So</p>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <p className="text-end font-bold text-3xl m-2">2 + 5 =</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    className="outline-none w-full pl-4 text-center text-3xl border-blue-500 border-2 rounded-lg"                    
                    maxLength="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Two/3">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Two/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA4;
