import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import UndoButton from "../../../../../components/UndoButton";
import ResetButton from "../../../../../components/ResetButton";
import CanvasDaw from "react-canvas-draw";
import { IMAGES } from "../../assets";

const MA17 = () => {
  const [color, setColor] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      let newColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      setColor(newColor);
    }, 1000);
    return () => clearInterval(interval);
  }, [color]);

  const firstCanvasRef = useRef(null);

  const clear = () => {
    firstCanvasRef.current.clear();
  };

  const undo = () => {
    firstCanvasRef.current.undo();
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div className="w-full text-center"></div>
        </div>
        <div className="m-3 border-2 border-black">
          <CanvasDaw
            canvasWidth={360}
            ref={firstCanvasRef}
            brushRadius={3}
            lazyRadius={0}
            brushColor={color}
            // brushColor={"#de2302"}
            // brushColor={"#52fc03"}
            imgSrc={IMAGES.L4.MA15.DrawCanvas3}
          />
        </div>
        <div className="w-full flex text-center justify-center"></div>
        <div className="flex p-3 justify-center mb-20">
          <UndoButton type={undo} />
          <ResetButton type={clear} />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/15">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA17;