import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA6 = () => {
  const speakData =
    "Penny. A penny is worth one cent. Nickel. A nickel is worth 5 cents. Dime. A dime is worth 10 cents. Quatar. A quatar is worth 25 cents. 100 cents is equal to 1 dollar.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Work with time and money"} />
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin4} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin5} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA6.Coin6} alt="" className="w-full" />
        </div>
        

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/5">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Four/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA6;