import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS10 = () => {
  const speakData = "Teddy is making a map to show how to get to his house. Can you tell a friend about Teddy’s map? What do you see on your way to school or on your way to the park or shops? You could draw your own map and tell a friend about it.";

  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"All about map"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SS10.Map8} alt="Map" className="w-full" />
        </div>
        <div className="px-4 py-4 ml-4 bg-blue-700 text-white rounded-l-full">
          <p>Teddy is making a map to show how to get to his house</p>
        </div>
        <div className="p-4 md:p-12 lg:p-12 mb-20">
          <p>
            Can you tell a friend about Teddy’s map? What do you see on your way
            to school or on your way to the park or shops? You could draw your
            own map and tell a friend about it.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Four/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS10;
