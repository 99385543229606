import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS21 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"The Living Room"} />
        <div className="mb-20">
          <div className="p-3">
            <p>
              In the living room, we might have a sofa, a television or some
              chairs.
            </p>
            <p>
              In the living room, we like to relax and spend time with our
              families.
            </p>
          </div>
          <div className="p-4 md:p-12 lg:p-12">
            <img
              src={IMAGES.L2.SS21.TheLivingRoom}
              alt="TheLivingRoom"
              className="w-8/12 mx-auto"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/20">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/22">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS21;
