import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC5 = () => {
  const speakData =
    "Because there is such little water in the desert, not many living things can survive here. Animals and plants that live here are specially adapted to the harsh, dry conditions. Many desert plants have leaves that collect and store water. Since water is so scarce, most desert animals get their water from eating these plants, or from the blood and body tissues of their prey. Some animals, like kangaroos and lizards, live in burrows which do not get too hot or cold and have damp air inside. These animals stay in their burrows during the hot days, coming out at night to feed. Camels can drink large amounts of water at one time and can survive as long as two weeks without drinking. They have a large spread out feet that help them to walk on the soft sand.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Deserts"} />
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Deserts</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SC5.Deserts} alt="Deserts" className="w-full" />
        </div>
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Animals the Desert</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SC5.AnimalsOfDeserts}
            alt="AnimalsOfDeserts"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC5;
