import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC8 = () => {
  const speakData =
    "These vibrations travel through the air until they reach our ears. These travelling vibrations are called sound waves. Sound is made when an object vibrates. Press your fingers to your throat and talk to a friend. Can you feel the vibrations in your throat? Strike a tuning fork or percussion instrument and then touch it gently with your finger. Can you feel it vibrating?";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Vibrations"} />
        <div className="p-4 mt-4 mx-4 bg-yellow-900">
          <p className="text-white">
            These vibrations travel through the air until they reach our ears.
            These travelling vibrations are called <strong>sound waves</strong>
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.Vibrations2} alt="Vibration" className="w-full" />
        </div>
        <div className="p-4 mt-4 mx-4 bg-pink-900">
          <p className="text-white">
            Sound is made when an object <strong>vibrates</strong>. Press your
            fingers to your throat and talk to a friend. Can you feel the
            vibrations in your throat?
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.Vibrations3} alt="Vibration" className="w-full" />
        </div>
        <div className="p-4 mt-4 mx-4 bg-pink-900">
          <p className="text-white">
            Strike a tuning fork or percussion instrument and then touch it
            gently with your finger. Can you feel it <strong>vibrating</strong>?
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.Vibrations4} alt="Vibration" className="w-full" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/7">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/One/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC8;
