import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA14 = () => {
  const speakData =
    "One half is one of two equal parts. Two equals parts are halves. 2 halves make one whole. One third is one of three equal parts. Three equals parts are thirds. 3 thirds make one whole. One fourth is one of four equal parts. four equal parts are fourths. 4 fourths make one whole. I'd rather have one half of a candy bar than one fourth.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "Reason with shapes and their attributesWork with time and money"
          }
        />
        <div className="p-4">
          <img src={IMAGES.L4.MA14.Geo1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA14.Geo2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA14.Geo3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA14.Geo4} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA14.Geo5} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA14.Geo6} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/13">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Four/15">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA14;