import React from "react";
import { Link } from "react-router-dom";
import { Typography,Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div>
          <div className="px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. Baby birds will chirp and cry to let their parents know they
              are hungry and want food. The parents know to bring them food when
              they hear the cries. One parent stays and watches the babies while
              the other goes to collect food.
            </Typography>
            <Typography variant="lead">
              How are the parents and offspring behaving to help them survive?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="They all work on their own." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="They are behaving poorly and will not survive."
              />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="They are working together." />
            </div>
          </div>
        </div>
        <div>
          <div className="px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. Bird 1: Does not prepare a nest ahead of time, lays eggs near
              the ground in a weak nest.
            </Typography>
            <Typography variant="lead">
              Bird 2: Builds a strong nest ahead of time, lays her eggs in the
              nest high up in the trees.
            </Typography>
            <Typography variant="lead">
              Which bird is behaving in a way that will help the babies survive?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Both birds" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Bird 1" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Bird 2" />
            </div>
          </div>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Two/5">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Two/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC6;
