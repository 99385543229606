import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC18 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div className="w-full bg-green-500 text-white text-center">
            Please tick if you have seen this patterns
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 p-4">
            <img src={IMAGES.L1.SC3.Q1} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <div className="flex">
              <input type="checkbox" className="w-10 h-10 mx-auto mt-16 text-green-500" />
            </div>
            <div className="flex">
              <input type="checkbox" className="w-10 h-10 mx-auto mt-28 text-green-500" />
            </div>
            <div className="flex">
              <input type="checkbox" className="w-10 h-10 mx-auto mt-28 text-green-500" />
            </div>
          </div>
        </div>
        <div className="flex mb-24">
          <div className="w-1/2 p-4">
            <img src={IMAGES.L1.SC3.Q2} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <div className="flex">
              <input type="checkbox" className="w-10 h-10 mx-auto mt-16 text-green-500" />
            </div>
            <div className="flex">
              <input type="checkbox" className="w-10 h-10 mx-auto mt-28 text-green-500" />
            </div>
            <div className="flex">
              <input type="checkbox" className="w-10 h-10 mx-auto mt-28 text-green-500" />
            </div>
          </div>
        </div>
        
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/One/17">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units/One/18A">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC18;