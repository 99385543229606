import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS9 = () => {
  const speakData =
    "Can you see an X on the map? That’s where the treasure is. Sometimes, people draw maps to help find places and things. What can you see on this map? What has the pirate drawn on their map to help them find the buried treasure?";

  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"All about map"} />

        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SS9.Map7} alt="Map" className="w-full" />
        </div>
        <div className="pl-4 py-4 ml-4 bg-blue-700 text-white rounded-l-full">
          <p>Can you see an X on the map? That’s where the treasure is.</p>
        </div>
        <div className="p-4 md:p-12 lg:p-12 mb-20">
          <p>
            Sometimes, people draw maps to help find places and things. What can
            you see on this map? What has the pirate drawn on their map to help
            them find the buried treasure?
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/8">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Four/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS9;