import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS8 = () => {
  const speakData =
    "There were no cars, planes, or trains! To get from place to place, you could walk, ride a horse, use a stagecoach or carriage, or sail on a ship. A stagecoach is one way that people got from place to place 300 years ago!";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle
          title={"Different Modes of Transportation : Past and Present"}
        />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            There were no cars, planes, or trains! To get from place to place,
            you could walk, ride a horse, use a stagecoach or carriage, or sail
            on a ship.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SS8.Past1} alt="Transport" className="w-full" />
        </div>
        <div className="px-2 py-4 ml-4 md:p-12 lg:p-12 bg-blue-700 text-white mb-24">
          <p>
            A stagecoach is one way that people got from place to place 300
            years ago!
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/7">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Three/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS8;
