import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC17 = () => {
  const normalStyle = "flex-col w-1/2 border-2 border-zmh-gray-700 mr-2";
  const correctStyle =
    "flex-col w-1/2 border-2 border-green-500 mr-2 shadow-xl shadow-green-500";
  const errorStyle =
    "flex-col w-1/2 border-2 border-red-500 mr-2 shadow-xl shadow-red-500";
  const answer = [{ id: "1" }, { id: "4" }];

  const checkAnswer = (e) => {
    const ans = answer.filter((answer) => {
      return answer.id === e.currentTarget.id;
    });

    if (ans.length === 0) {
      document.getElementById(e.currentTarget.id).className = errorStyle;
    } else {
      document.getElementById(e.currentTarget.id).className = correctStyle;
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className=" p-4 md:p-12 lg:p-12">
          <p>Choose the pictures that has Kinetic Energy.</p>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12">
          <div id={1} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L2.SC17.Q5} alt="Quiz" className="h-full w-full" />
          </div>
          <div id={2} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L2.SC17.Q6} alt="Quiz" className="h-full w-full" />
          </div>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12 mb-20">
          <div id={3} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L2.SC17.Q7} alt="Quiz" className="h-full w-full" />
          </div>
          <div id={4} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L2.SC17.Q8} alt="Quiz" className="h-full w-full" />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Two/16">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/Two/18">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC17;
