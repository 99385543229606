import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { IMAGES } from "../../assets";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS3 = () => {
  const speakData =
    "How did Fast Food get to be so Popular? Most of us have some idea that too much fast food isn't good for us. So the big question is how did fast food get to be so popular? Centuries ago, people lived in communities and didn't have cars. Food came from local farmers. Houses didn't have electricity and easy ways of storing and cooking food. Nothing was really fast. The closest thing to fast food in these times was street food - food customers could buy as they walked down the street. Street food is still popular today. Over the past few centuries, cars, microwave ovens, freezers, and giant grocery stores have made it easy for us to eat without ever really cooking. We don't have to depend on local farms anymore because packaged foods can be shipped long distances. This new way of eating inspired a lot of new food businesses. Packaged foods like potato chips and cookies, fast food restaurants with drive-thru windows, pizza to go, and hotdogs at gas stations are examples of the many ways we can get fast food. A lot of people think of McDonald's when they think of fast food. McDonald's restaurants can be found all over the world, but McDonald's was not America's first fast food restaurant. The first one was actually White Castle, a place to get burgers and fries that opened in Kansas in the early 1900s. McDonald's didn't come along for another few decades. Now there are thousands of fast food restaurants in America and all over the world.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Fast Food"} />
        <div className="p-4">
          <p className="text-center font-bold">
            How did Fast Food get to be so Popular?
          </p>
        </div>
        <div className="p-4">
          <p>
            Most of us have some idea that too much fast food isn't good for us.
            So the big question is how did fast food get to be so popular?
          </p>
        </div>
        <div className="p-4">
          <p>
            Centuries ago, people lived in communities and didn't have cars.
            Food came from local farmers. Houses didn't have electricity and
            easy ways of storing and cooking food. Nothing was really fast. The
            closest thing to fast food in these times was street food - food
            customers could buy as they walked down the street. Street food is
            still popular today.
          </p>
        </div>
        <div className="p-4">
          <p>
            Over the past few centuries, cars, microwave ovens, freezers, and
            giant grocery stores have made it easy for us to eat without ever
            really cooking. We don't have to depend on local farms anymore
            because packaged foods can be shipped long distances.
          </p>
        </div>
        <div className="p-4">
          <p>
            This new way of eating inspired a lot of new food businesses.
            Packaged foods like potato chips and cookies, fast food restaurants
            with drive-thru windows, pizza to go, and hotdogs at gas stations
            are examples of the many ways we can get fast food.
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.SS1.Fast4} alt="FastFood" />
        </div>
        <div className="p-4">
          <p>
            A lot of people think of McDonald's when they think of fast food.
            McDonald's restaurants can be found all over the world, but
            McDonald's was not America's first fast food restaurant. The first
            one was actually White Castle, a place to get burgers and fries that
            opened in Kansas in the early 1900s. McDonald's didn't come along
            for another few decades. Now there are thousands of fast food
            restaurants in America and all over the world.
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L2.SS1.Fast5} alt="FastFood" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;
