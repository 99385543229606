import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS7 = () => {
  const normalStyle = "flex-col w-1/2 border-2 border-zmh-gray-700 mr-2";
  const correctStyle =
    "flex-col w-1/2 border-2 border-green-500 mr-2 shadow-xl shadow-green-500";
  const errorStyle =
    "flex-col w-1/2 border-2 border-red-500 mr-2 shadow-xl shadow-red-500";
  const answer = [{ id: "1" }, { id: "3" }, { id: "6" }, { id: "8" }];

  const checkAnswer = (e) => {
    const ans = answer.filter((answer) => {
      return answer.id === e.currentTarget.id;
    });

    if (ans.length === 0) {
      document.getElementById(e.currentTarget.id).className = errorStyle;
    } else {
      document.getElementById(e.currentTarget.id).className = correctStyle;
    }
  };  

  return (
    <div>
      <div>
        <BannerTitle
          title={"Choose all the pictures that show good citizenship."}
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <p>Choose all the pictures that show good citizenship.</p>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12">
          <div id={1} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz1} alt="Quiz" className="w-full" />
          </div>
          <div id={2} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz2} alt="Quiz" className="w-full" />
          </div>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12">
          <div id={3} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz3} alt="Quiz" className="w-full" />
          </div>
          <div id={4} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz4} alt="Quiz" className="w-full" />
          </div>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12">
          <div id={5} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz5} alt="Quiz" className="w-full" />
          </div>
          <div id={6} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz6} alt="Quiz" className="w-full" />
          </div>
        </div>
        <div className="flex flex-row p-4 md:p-12 lg:p-12">
          <div id={7} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz7} alt="Quiz" className="w-full" />
          </div>
          <div id={8} onClick={checkAnswer} className={normalStyle}>
            <img src={IMAGES.L1.SS7.Quiz8} alt="Quiz" className="w-full" />
          </div>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/One/6">
            <BackButton />
          </Link>
          <Link to="/G1/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS7;