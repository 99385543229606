import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC3 = () => {
  const speakData = "Potential Energy is the energy stored in an object before moving. Kinetic Energy is the energy that a moving object has.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Potential Energy and Kinetic Energy"} />
        <div className="p-4">
          <p>
            <strong>Potential Energy</strong> is the energy stored in an object
            before moving.
          </p>
          <p>
            <strong>Kinetic Energy</strong> is the energy that a moving object
            has.
          </p>
        </div>
        <div className="flex">
          <img
            src={IMAGES.L2.SC3.EnergyTypes1}
            alt="EnergyTypes"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;
