import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC19 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"A Structure and Properties of Matter"} />
        <div className="p-4">
          <div className="w-full bg-green-500 text-white text-center">
            What is matter?          
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M1} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M2} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M3} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M4} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M5} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M6} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M7} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M8} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M9} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M10} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M11} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M12} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M13} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M14} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M15} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M16} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M17} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M18} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M19} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M20} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M21} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC4.M22} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-24">
          <img src={IMAGES.L1.SC4.M23} alt="" className="w-full" />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/One/18A">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units/One/20">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC19;