import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA3 = () => {
  const speakData =
    "Here are some stars.  We have 7 green stars and 3 blue stars . Can you tell me how many stars we have in all? We have 10 stars together . Now the stars just moved positions. Is the answer still the same? Yes, the answer is still 10 , the same as before. Changing the order of addends does not change the sum is called communicative property.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Commutative Property"} />
        <div className="p-4">
          <p>
            Understand and apply properties of operations and the relationship
            between addition and subtraction
          </p>
        </div>
        <div>
          <img
            src={IMAGES.L2.MA1.CommutativeProperty}
            alt=""
            className="w-full"
          />
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg">
          <p>
            Here are some stars. We have 7 green stars and 3 blue stars . Can
            you tell me how many stars we have in all? We have 10 stars together
            . Now the stars just moved positions. Is the answer still the same?
            Yes, the answer is still 10 , the same as before.
          </p>
        </div>
        <div className="p-4">
          <p className="font-bold">
            Changing the order of addends does not change the sum is called
            communicative property.
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA3;