import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />

        <div className="p-4">
          <div className="mb-6">
            <p>Write the time inside each clock below.</p>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ7} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  readOnly
                  value={"10"}
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  readOnly
                  value={"30"}
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ8} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ9} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ10} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ11} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ12} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ13} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ14} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ15} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ16} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ17} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>

          <div className="mb-6">
            <div className="w-1/2 mx-auto">
              <img src={IMAGES.L4.MA9.TQ18} alt="" className="" />
            </div>
            <div className="w-1/2 mx-auto text-center">
              <p className="text-2xl">
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
                {" : "}
                <input
                  type="text"
                  className="outline-none w-16 text-center text-2xl border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/8">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Four/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;