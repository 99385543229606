import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS10 = () => {
  const speakData =
    "Television. The invention of television in the 1920s was a breakthrough in technology because it was the first time sound and pictures could be sent over the air.  At first, television images were only displayed in black and white and it wasn’t until the 1970s that people could see colour on the screen. ";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Modern Communication with Machines"} />
        <div className="p-4">
          <p className="text-center font-bold text-purple-700">Television</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Television} alt="Television" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            The invention of television in the 1920s was a breakthrough in
            technology because it was the first time sound and pictures could be
            sent over the air. At first, television images were only displayed
            in black and white and it wasn’t until the 1970s that people could
            see colour on the screen.
          </p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Four/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Four/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS10;
