import React from "react";
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Choose the correct answer."} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1 . When did people start wearing clothes?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="100,000 years ago " />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="8,000 years ago " />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="2,500 years ago " />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2 . What were clothes made of about 8,000 years ago?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="animal skin" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="fabric" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="grass" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3 . What invention helped people to make thread about 2,500 years
              ago?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="spinning wheels " />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="needles" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="sewing machines" />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Three/2">
            <BackButton />
          </Link>
          <Link to="/G2/SocialStudies/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;