import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC3 = () => {
  const speakData =
    "The Arctic Circle is located at the very top of the Earth. It is very cold in the Arctic all year round. The only plants that can grow in the Arctic region are grass and mosses. Trees are unable to grow because the ground stays frozen all year round. In some places in the Arctic it is too cold fro anything to grow at all. There are many land mammals in the Arctic including ox, reindeer, artic foxes, weasels, wolves, polar bears and brown bears. Seals, walruses and whales live here and feed from the plankton and fish in the sea.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"The Artic"} />
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>The Artic</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SC3.Artic} alt="Artic" className="w-full" />
        </div>
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Animals of the Artic</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SC3.AnimalsOfArtic}
            alt="AnimalsOfArtic"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;
