import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { Typography, Radio } from "@material-tailwind/react";
import BannerTitle from "../../../../../components/BannerTitle";

const SS9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Draw and write about your own statue. "} />
        <div>
          <div className="p-4">
            <p>Answer the following questions.</p>
          </div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">1. When did they go out?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Last week" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Last night" />
            </div>
          </div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. Who is the best friend of Mandy?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Tina" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Christ" />
            </div>
          </div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. What did they do while they eat?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="They told stories and jokes." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="They danced."
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/G1/SocialStudies/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS9;
