import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E1 = () => {
  const speakData =
    "If you are a superstitious person, you probably believe in bad luck and will do certain things to prevent bad things from happening to you. Did you know that walking under a ladder was bad luck? This superstition began many years ago when criminals were hanged in public. The ladder that led to the scaffold was a symbol of death. You may see someone knock on wood for good luck. Long ago, it was believed that gods lived in trees and if you knocked on the tree, the god would be happy and take care of you. Breaking a mirror is  supposed to mean seven years of bad luck. This idea came from the belief that the image in the mirror was actually a person’s soul. If the mirror broke, the soul  would be lost forever. The day when people are most superstitious is Friday  the 13th.  The fact that Jesus Christ died on a Friday and that there were 13 men at the Last Supper could be reasons for this belief. Another might be that Friday was called “hangman’s day”, a day when criminals were executed. Whether you are superstitious or not shouldn’t matter as long as you keep your fingers crossed.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Do you believe in superstition? "} />
        <div className="p-4 m-4">
          <p>
            If you are a superstitious person, you probably believe in bad luck
            and will do certain things to prevent bad things from happening to
            you.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.E1.Superstition}
            alt="Superstition"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 m-4">
          <p>
            Did you know that walking under a ladder was bad luck? This
            superstition began many years ago when criminals were hanged in
            public. The ladder that led to the scaffold was a symbol of death.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.E1.KnockOnWood}
            alt="KnockOnWood"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 m-4">
          <p>
            You may see someone knock on wood for good luck. Long ago, it was
            believed that gods lived in trees and if you knocked on the tree,
            the god would be happy and take care of you.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.E1.BreakingAMirror}
            alt="BreakingAMirror"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 m-4">
          <p>
            Breaking a mirror is supposed to mean seven years of bad luck. This
            idea came from the belief that the image in the mirror was actually
            a person’s soul. If the mirror broke, the soul would be lost
            forever.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.E1.Firday13}
            alt="Firday13"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 m-4">
          <p>
            The day when people are most superstitious is Friday the 13th. The
            fact that Jesus Christ died on a Friday and that there were 13 men
            at the Last Supper could be reasons for this belief. Another might
            be that Friday was called “hangman’s day”, a day when criminals were
            executed.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.E1.FingerCrossed}
            alt="FingerCrossed"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 m-4 mb-20">
          <p>
            Whether you are superstitious or not shouldn’t matter as long as you
            keep your fingers crossed.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Four/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/English/Units/Four/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;