import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Steps of scientific method"} />
        <div className="p-4">
          <p>Answer the correct steps from number 1 to 5. Put the correct number in the blanks.</p>
        </div>
        <div className="flex p-4">
          <div className="w-3/4">
            
          </div>
          <div className="w-1/4">
            <p className="text-center font-bold">Step</p>
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-3/4">
            <p>Ask a question.</p>
          </div>
          <div className="w-1/4">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-3/4">
            <p>Draw conclusion.</p>
          </div>
          <div className="w-1/4">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-3/4">
            <p>Make a hypothesis.</p>
          </div>
          <div className="w-1/4">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-3/4">
            <p>Conduct an Experiment.</p>
          </div>
          <div className="w-1/4">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-3/4">
            <p>Make an abosevation.</p>
          </div>
          <div className="w-1/4">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-3/4">
            <p>Report your result.</p>
          </div>
          <div className="w-1/4">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Four/1">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Four/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC2;
