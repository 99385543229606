import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"GEOMETRY"} />
        <div className="p-4">
          <p>Find shapes in your class or home.</p>
        </div>
        <div className="p-4">
          <div className="">
            <img src={IMAGES.L4.MA6.FindShapesBanner} alt="" className="w-full" />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Four/5">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Four/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA6;
