import React from "react";
import { Link } from "react-router-dom";
import { Typography, Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS6 = () => {
  return (
    <div>
      <BannerTitle title={"Citizenship"} />
      <div className="p-4">
        <p>Read the questions carefully and choose the correct answers.</p>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            1. What is the story mostly about?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA1" name="Q1" label="How to share crayons" />
          </div>
          <div className="flex-row">
            <Radio id="QA2" name="Q1" label="Being a good citizen" />
          </div>
          <div className="flex-row">
            <Radio id="QA3" name="Q1" label="Picking up trash" />
          </div>
        </div>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            2. How is Sam a good citizen in the classroom?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA4" name="Q2" label="Listening to the teacher" />
          </div>
          <div className="flex-row">
            <Radio id="QA5" name="Q2" label="Taking turns" />
          </div>
          <div className="flex-row">
            <Radio id="QA6" name="Q2" label="Sharing crayons" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            3. If you were NOT a good citizen, then you would ____________.
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Share" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Ignore the teacher" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Take turns" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            4. What does the underlined word, litter, mean in the story?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="To throw trash on the ground" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="To share" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="To put trash in the trash can" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            5. If they help keep the town clean, then they are __________.
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Being good citizens" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Not following the laws" />
          </div>
          <div className="flex-row">
            <Radio
              id="QA9"
              name="Q3"
              label="Listening to the teacher"
            />
          </div>
        </div>
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/One/5">
          <BackButton />
        </Link>

        <Link to="/G1/SocialStudies/Units/One/7">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default SS6;
