import React from "react";
import { Link } from "react-router-dom";
import Family2 from "../../assets/L1/FM2.png";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS13 = () => {
  const speakData =
    "Families are big, and families are small. Families are different and we love them all. Some have fathers. Some have mothers. Some have brothers and some have sisters. I love my family.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Family"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={Family2} alt="Family" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <div className="break-all">
            <p>Families are big,</p>
            <p>and families are small. </p>
            <p>Families are different and we love them all. </p>
            <p>Some have fathers.</p>
            <p>Some have mothers.</p>
            <p>Some have brothers and some have sisters.</p>
            <p>I love my family.</p>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/12">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS13;
