import React from 'react';
import { Typography, Radio, Checkbox } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Choose The Correct Answer"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">1. Who was in the car ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Mom and Dad" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Caleb and Dad" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Mom and Caleb" defaultChecked />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. Where did Mom take Caleb ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="The Market" />
            </div>
            <div className="flex-row">
              <Radio
                id="QA5"
                name="Q2"
                label="The Grocery Store"
                defaultChecked
              />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="The Park" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">3. How does Caleb feel ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="Sad" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Happy" defaultChecked />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Sleepy" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">4. What does Caleb love ?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA10" name="Q4" label="Car" />
            </div>
            <div className="flex-row">
              <Radio id="QA11" name="Q4" label="Jacket" />
            </div>
            <div className="flex-row">
              <Radio id="QA12" name="Q4" label="Oranges" defaultChecked />
            </div>
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            color="blue"
            label="I can answer questions."
            defaultChecked
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/One/1">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/One/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;