import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS2 = () => {  
  return (
    <div>
      <div>
        <BannerTitle title={"WRITE TRUE OR FALSE"} />
        <div className="p-4">
          <div>
            <div>1. People use water mills to make bread.</div>
            <div>
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div>2. Windmills only work when its windy.</div>
            <div>
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div>3. Watermills don’t use energy from nature.</div>
            <div>
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div>4. We can use windmills to produce electricity.</div>
            <div>
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div>5. Watermills are useful in places with no rivers.</div>
            <div>
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div>6. People used windmills thousands of years ago.</div>
            <div>
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full"
                type="text"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/One/1">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units/One/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
