import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA14 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>
            Choose from the below options, the new figures you get after
            decomposing each shape shown below.
          </p>
        </div>
        <div className="mb-10">
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <p className="text-2xl font-bold">1.</p>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS1} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  a{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS2} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  b{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS3} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  c{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS4} alt="" className="" />
            </div>
          </div>
        </div>
        <div className="mb-10">
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <p className="text-2xl font-bold">2.</p>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS5} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio2"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  a{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS6} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio2"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  b{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS7} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio2"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  c{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS8} alt="" className="" />
            </div>
          </div>
        </div>
        <div className="mb-10">
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <p className="text-2xl font-bold">3.</p>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS9} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio3"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  a{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS10} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio3"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  b{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS11} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio3"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  c{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS12} alt="" className="" />
            </div>
          </div>
        </div>
        <div className="mb-10">
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <p className="text-2xl font-bold">4.</p>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS13} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio4"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  a{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS14} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio4"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  b{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS15} alt="" className="" />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="grid content-center justify-items-center">
              <div className="flex items-center mb-4">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio4"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  c{" )"}
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <img src={IMAGES.L4.MA14.QS16} alt="" className="" />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Four/13">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA14;
