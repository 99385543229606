import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA5 = () => {
  const speakData =
    "There were 35 chocolates in this jar. 8 chocolates were eaten by your friends. So, How many chocolates should there be in the jar? 8 chocolates less or 35 chocolates.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Operations and Algebraic Thinking"} />
        <div className="p-4">
          <img src={IMAGES.L1.MA5.Example1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L1.MA5.Example2} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/One/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/One/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA5;