import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E1 = () => {
  const speakData =
    "American Indians have long used dancing to express themselves. Each dance has a deep and emotional meaning to a given tribe. The Great Plains Indian dancers dress in feathers and painted masks for the fast and colorful Fancy Dance. It is the most exciting of the dances. Other Indian dancers perform the Hoop Dance with large hoops that they swing and shape to resemble patterns from nature. It is by far the most difficult dance. The Pueblo Indian dancers, wearing butterfly headdresses, imitate the peaceful life of the butterfly with the Butterfly Dance. The dance is graceful and beautiful.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Native American Dance"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.E1.NativeAmericanDance}
            alt="NativeAmericanDance"
            className="w-full"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            American Indians have long used dancing to express themselves. Each
            dance has a deep and emotional meaning to a given tribe. The Great
            Plains Indian dancers dress in feathers and painted masks for the
            fast and colorful Fancy Dance. It is the most exciting of the
            dances. Other Indian dancers perform the Hoop Dance with large hoops
            that they swing and shape to resemble patterns from nature. It is by
            far the most difficult dance. The Pueblo Indian dancers, wearing
            butterfly headdresses, imitate the peaceful life of the butterfly
            with the Butterfly Dance. The dance is graceful and beautiful.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/English/Units/One/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E1;
