import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E4 = () => {
  const normalStyle =
    "px-2 ml-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded";
  const correctStyle =
    "px-2 bg-green-500 hover:bg-green-600 text-white rounded";
  const errorStyle = 
    "px-2 bg-red-600 hover:bg-red-700 text-white rounded";
  const showCorrectSytle =
    "px-2 opacity-50 bg-green-500 hover:bg-green-600 text-white rounded";    

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;              
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":                
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      default:
      // code block
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Possessive Adjectives"} />
        <div className="p-4">
          <p>
            A Possessive Adjective can replace a possessive noun to show
            ownership.
          </p>
          <p>
            <strong>Example:</strong> This is Jack’s cap. This is his cap.
          </p>
        </div>
        <div className="p-4">
          <u>Choose the correct possessive adjectives.</u>
        </div>
        <div className="p-4">
          <p>
            1. Michael puts (
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              his
            </button>
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              her
            </button>
            {" ) "}
            toys to into the box.
          </p>
        </div>
        <div className="p-4">
          <p>
            2. Mrs. Smith forgot to bring (
            <button onClick={checkAnswer} id="3" className={normalStyle}>
              her
            </button>
            <button onClick={checkAnswer} id="4" className={normalStyle}>
              their
            </button>
            {" ) "}
            keys.
          </p>
        </div>
        <div className="p-4">
          <p>
            3. Mum had (
            <button onClick={checkAnswer} id="5" className={normalStyle}>
              its
            </button>
            <button onClick={checkAnswer} id="6" className={normalStyle}>
              her
            </button>
            {" ) "}
            hair permed yesterday.
          </p>
        </div>
        <div className="p-4">
          <p>
            4. We take (
            <button onClick={checkAnswer} id="7" className={normalStyle}>
              your
            </button>
            <button onClick={checkAnswer} id="8" className={normalStyle}>
              our
            </button>
            {" ) "}
            own lunch to the park.
          </p>
        </div>
        <div className="p-4">
          <p>
            5. My cat likes licking (
            <button onClick={checkAnswer} id="9" className={normalStyle}>
              their
            </button>
            <button onClick={checkAnswer} id="10" className={normalStyle}>
              its
            </button>
            {" ) "}
            tail.
          </p>
        </div>
      </div>
      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G2/English/Units/Three/3">
          <BackButton />
        </Link>
        <Link to="/G2/English/Units/Three/5">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default E4;
