import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA8 = () => {  
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "3":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "4":        
        document.getElementById("4").className = correctStyle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "5":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("5").className = errorStyle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":        
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "8":        
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "9":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("9").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "10":        
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("10").className = errorStyle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "11":
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("11").className = errorStyle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":
        document.getElementById("12").className = correctStyle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "13":        
        document.getElementById("15").className = showCorrectSytle;
        document.getElementById("13").className = errorStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      case "14":
        document.getElementById("15").className = showCorrectSytle;
        document.getElementById("14").className = errorStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      case "15":
        document.getElementById("15").className = correctStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Count and choose"} />        
        <div>
          <div className="p-4">
            <img src={IMAGES.L1.MA8.IceCream} alt="" className="w-full" />
          </div>
          <div className="p-4">
            <div className="flex -mt-2">
              <div className="w-1/3 m-2">
                <button onClick={checkAnswer} id="1" className={normalStyle}>
                  5
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="2" className={normalStyle}>
                  6
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="3" className={normalStyle}>
                  4
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <img src={IMAGES.L1.MA8.Bat} alt="" className="w-full" />
          </div>
          <div className="p-4">
            <div className="flex -mt-2">
              <div className="w-1/3 m-2">
                <button onClick={checkAnswer} id="4" className={normalStyle}>
                  10
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="5" className={normalStyle}>
                  8
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="6" className={normalStyle}>
                  9
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <img src={IMAGES.L1.MA8.Gear} alt="" className="w-full" />
          </div>
          <div className="p-4">
            <div className="flex -mt-2">
              <div className="w-1/3 m-2">
                <button onClick={checkAnswer} id="7" className={normalStyle}>
                  6
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="8" className={normalStyle}>
                  7
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="9" className={normalStyle}>
                  5
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <img src={IMAGES.L1.MA8.Crystal} alt="" className="w-full" />
          </div>
          <div className="p-4">
            <div className="flex -mt-2">
              <div className="w-1/3 m-2">
                <button onClick={checkAnswer} id="10" className={normalStyle}>
                  8
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="11" className={normalStyle}>
                  7
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="12" className={normalStyle}>
                  9
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div className="p-4">
            <img src={IMAGES.L1.MA8.Rose} alt="" className="w-full" />
          </div>
          <div className="p-4">
            <div className="flex -mt-2">
              <div className="w-1/3 m-2">
                <button onClick={checkAnswer} id="13" className={normalStyle}>
                  6
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="14" className={normalStyle}>
                  9
                </button>
              </div>
              <div className=" w-1/3 m-2">
                <button onClick={checkAnswer} id="15" className={normalStyle}>
                  8
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/7">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/One/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA8;