import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS4 = () => {
  const normalStyle = "px-2 bg-gray-200 hover:bg-gray-200 rounded text-black";
  const correctStyle =
    "px-2 bg-green-500 hover:bg-green-600 rounded text-white";
  const errorStyle = "px-2 bg-red-500 hover:bg-red-600 rounded text-white";
  const showCorrectSytle =
    "px-2 opacity-50 bg-green-500 hover:bg-green-600 rounded text-white";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"POPULAR STREET FOOD IN THE WORLD"} />
        <div>
          <div className="p-4">
            <p className="font-bold">Order the words.</p>
          </div>
          <div className="px-4">
            <p>1. have/ you/ What/ can/ for dessert/ Brazil/ in</p>
          </div>
          <div className="mx-4">
            <textarea
              rows="4"
              className="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>
              2. a ball of/ made/ warm bread/with/Pão de queijo/tapioca
              flour/is/and cheese.
            </p>
          </div>
          <div className="mx-4">
            <textarea
              rows="4"
              className="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>
        </div>

        <div className="p-4">
          <p className="font-bold mb-4">Complete the sentence.</p>
          <p>
            3. In Brazil you can eat popcorn with __________ (
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              sauces
            </button>
            /
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              sugar
            </button>
            )
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Four/3">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units/Four/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;