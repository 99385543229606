import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4 border border-blue-500 m-4 rounded-xl">
          <div className="flex justify-between ">
            <p>my</p>
            <p>your</p>
            <p>our</p>
            <p>their</p>
          </div>
          <div className="flex justify-between">
            <p>his</p>
            <p>her</p>
            <p>its</p>
          </div>
        </div>
        <div className="p-4">
          <p>
            1. Have you seen bob’s cat.
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
            cat is missing.
          </p>
        </div>
        <div className="p-4">
          <p>
            2. We all like
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
            teacher very much.
          </p>
        </div>
        <div className="p-4">
          <p>
            3. I am wearing a new dress. Do you like
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
            dress?
          </p>
        </div>
        <div className="p-4">
          <p>
            4. You have a hamster. Is that
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
            hamster?
          </p>
        </div>
        <div className="p-4">
          <p>
            5. I have two brothers.
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
            names are Joe and Rob.
          </p>
        </div>
        <div className="p-4">
          <p>
            6. Fido is the dog’s name.
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
            name is cute.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Three/4">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E5;