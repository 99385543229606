import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Domino Addition"} />
        <div className="p-4">
          <p>Count the number of dots on each domino and fill in the total.</p>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D1} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D2} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D3} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D4} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D5} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D6} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D7} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D8} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D9} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4 mb-20">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L2.MA3.D10} alt="Domino" className="w-full" />
          </div>
          <div className="flex w-full mt-2">
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">+</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
            <p className="font-bold text-3xl m-2">=</p>
            <input
              type="text"
              className="outline-none w-1/3 px-8 text-3xl border-blue-500 border-2 rounded-lg"
              maxLength="2"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Two/2">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA3;
