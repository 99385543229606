import React from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import Container from './DragNDrop_One/Container';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E13 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "A noun is a word. It can be a person, a place, a thing, or an idea."
          }
        />
        <div className="flex p-6">Drag the words to the correct columns</div>
      </div>
      <div className="mb-20">
        <DndProvider backend={TouchBackend}>
          {/* Here, render a component that uses DND inside it */}
          <Container />
        </DndProvider>
      </div>
      <div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/One/12">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/One/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E13;