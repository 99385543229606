import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS1A = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Transportation : Past and Present"} />
        <div className="p-4 md:p-12 lg:p-12 text-center">
          <p className="text-2xl font-bold">Learning Objectives</p>
        </div>
        <div className="p-4 md:p-12 lg:p-12">
          <p className="text-xl font-bold">Children will be able to</p>
          <ul>
            <li>- understand different modes of transportation</li>
            <li>- describes ,in details, past forms of transportations</li>
            <li>- describes several forms of modern transportations</li>
          </ul>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS1.TransportCarPastPresent}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS1.TransportTrainPast}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SS1.TransportTrainPresent}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/1">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1A;
