import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC5 = () => {
  const speakData =
    "Where do plants get the air that they need to survive? Animals give off carbon dioxide that plants need to survive. How can animals help plants meet their basic needs? Animals help to pollinate flowers so they can reproduce. Animals transport seeds away from the parent plant so they have room to grow. Animals provide nutrients for some plants. Animals provide air (carbon dioxide) that plants need to survive.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"Ecosystems ; Interactions, Energy, and Dynamics"}
        />
        <div className="m-2 mb-20">
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant8} alt="" className="w-full" />
          </div>
          <div className="flex">
            <img src={IMAGES.L2.SC1.Plant9} alt="" className="w-full" />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Two/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC5;