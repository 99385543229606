import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA15 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>
            Fill in the blanks to find the amounts.
          </p>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB1} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"1"}
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"5"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB2} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"0"}
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"4"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB3} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB4} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB5} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB6} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB7} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB8} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB9} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB10} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB11} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L3.MA15.BB12} alt="" className="" />
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
              <div className="px-2">
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg border-b-2 border-b-black"
                  maxLength="1"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2">
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Tens"}
                  maxLength="1"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="outline-none w-full text-center text-lg"
                  readOnly
                  value={"Ones"}
                  maxLength="1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/14">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/16">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA15;
