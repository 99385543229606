export const IMAGES = {
  L1: {
    SC1: {
      Forces: require("./L1/Forces.png"),
    },
    SC2: {
      PushOrPull: require("./L1/PushPull.png"),
    },
    SC3: {
      Push: require("./L1/Push.png"),
    },
    SC4: {
      Pull: require("./L1/Pull.png"),
    },
    SC5: {
      PQ1: require("./L1/PQ1.png"),
      PQ2: require("./L1/PQ2.png"),
      PQ3: require("./L1/PQ3.png"),
      PQ4: require("./L1/PQ4.png"),
      PQ5: require("./L1/PQ5.png"),
      PQ6: require("./L1/PQ6.png"),
    },
    SC6: {
      DoorPushPull: require("./L1/DoorPushPull.png"),
    },
    SC7: {
      PushOne: require("./L1/Push1.png"),
    },
    SC8: {
      PullOne: require("./L1/Pull1.png"),
    },
    SC9: {
      Q1: require("./L1/Q1.png"),
      Q2: require("./L1/Q2.png"),
      Q3: require("./L1/Q3.png"),
      Q4: require("./L1/Q4.png"),
      Q5: require("./L1/Q5.png"),
    },
    SC10: {
      HeavyOrLight: require("./L1/HeavyOrLight.png"),
    },
    SC11: {
      MoreForce: require("./L1/MoreForce.png"),
    },
    SC12: {
      HeavyMoreForce: require("./L1/HeavyMoreForce.png"),
      LightForce: require("./L1/LightForce.png"),
    },
    SC13: {
      QForce1: require("./L1/QForce1.png"),
      QForce2: require("./L1/QForce2.png"),
    },
  },
  L2: {
    SC1: {
      Energy: require("./L2/Energy.png"),
    },
    SC2: {
      EnergyTypes: require("./L2/EnergyTypes.png"),
    },
    SC3: {
      EnergyTypes1: require("./L2/EnergyTypes1.png"),
    },
    SC5: {
      FormOfPotentialEnergy: require("./L2/FormOfPotentialEnergy.png"),
    },
    SC6: {
      ChemicalEnergy: require("./L2/ChemicalEnergy.png"),
    },
    SC7: {
      ElasticEnergy: require("./L2/ElasticEnergy.png"),
    },
    SC8: {
      NuclearEnergy: require("./L2/NuclearEnergy.png"),
    },
    SC9: {
      GravitationalEnergy: require("./L2/GravitationalEnerygy.png"),
    },
    SC10: {
      Q1: require("./L2/Quiz1.png"),
      Q2: require("./L2/Quiz2.png"),
      Q3: require("./L2/Quiz3.png"),
      Q4: require("./L2/Quiz4.png"),
    },
    SC11: {
      FormOfKineticEnergy: require("./L2/FormOfKineticEnergy.png"),
    },
    SC12: {
      MechinalEnergy: require("./L2/MechanicalEnergy.png"),
    },
    SC13: {
      ElectricalEnergy: require("./L2/EletricalEnergy.png"),
    },
    SC14: {
      ThermalEnergy: require("./L2/ThermalEnergy.png"),
    },
    SC15: {
      LightEnergy: require("./L2/LightEnergy.png"),
    },
    SC16: {
      SoundEnergy: require("./L2/SoundEnergy.png"),
    },
    SC17: {
      Q5: require("./L2/Quiz5.png"),
      Q6: require("./L2/Quiz6.png"),
      Q7: require("./L2/Quiz7.png"),
      Q8: require("./L2/Quiz8.png"),
    },
    SC18: {
      ConservationOfEnergy: require("./L2/TheLawOfConservationOfEnergy.png"),
    },
    SC19: {
      SunToEarth: require("./L2/SunToEarth.png"),
    },
  },
  L3: {
    SC1: {
      StructureAndProcess1: require("./L3/StructureAndProcess1.png"),
      StructureAndProcess2: require("./L3/StructureAndProcess2.png"),
      StructureAndProcess3: require("./L3/StructureAndProcess3.png"),
    },
    SC2: {
      StructureAndProcess4: require("./L3/StructureAndProcess4.png"),
      StructureAndProcess5: require("./L3/StructureAndProcess5.png"),
    },
    SC4: {
      AnimalsByFood: require("./L3/AnimalsByFood.png"),
    },
    SC5: {
      TypeOfAnimalsByFood: require("./L3/TypeOfAnimalsByFood.png"),
    },
    SC6: {
      EatQuiz1: require("./L3/EatQuiz1.png"),
      EatQuiz2: require("./L3/EatQuiz2.png"),
    },
    SC7: {
      GrowingFlower: require("./L3/GrowingAFlower.png"),
    },
    SC8: {
      Plants: require("./L3/Plants.png"),
    },
    SC9: {
      Quiz: require("./L3/Quiz2.png"),
    },
    SC10: {
      FoodChain: require("./L3/FoodChain.png"),
    },
    SC11: {
      WhatisFoodChain: require("./L3/WhatisFoodChain.png"),
    },
    SC12: {
      FoodChainQuiz: require("./L3/FoodChainQuiz.png"),
    },
  },
  L4: {
    SC1: {
      Resources: require("./L4/Resources.png"),
    },
    SC2: {
      Resources2: require("./L4/Resources2.png"),
    },
    SC4: {
      NaturalDisasters: require("./L4/NaturalDisasters.png"),
    },
    SC5: {
      Earthquake: require("./L4/Earthquake.png"),
      ForestFire: require("./L4/ForestFire.png"),
      Lightning: require("./L4/Lightning.png"),
      Flood: require("./L4/Flood.png"),
      VolcanicEruption: require("./L4/VolcanicEruption.png"),
    },
    SC6: {
      QVolcanic: require("./L4/QVolcanic.png"),
      QForestFire: require("./L4/QForestFire.png"),
      QFlood: require("./L4/QFlood.png"),
    },
    SC7: {
      HumanImpacts: require("./L4/HumanImpacts.png"),
    },
    SC9: {
      AirPollution: require("./L4/AirPollution.png"),
    },
    SC10: {
      Walking: require("./L4/Walking.png"),
      RidingBike: require("./L4/RidingBike.png"),
      PublicTransport: require("./L4/PublicTransport.png"),
    },
  },
};