import React from "react";
import { Link } from "react-router-dom";
import { Typography, Radio } from "@material-tailwind/react";
import { useSpeechSynthesis } from "react-speech-kit";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E5 = () => {
  const speakData =
    "Lily the cat woke up from her nap. She jumped down from the bed. She saw a door. She walked through the door. She saw a room. She crossed the room. She saw a chair. She circled a chair. She saw a table. She strolled under the table. She saw the couch. She jumped up on the couch. She saw Jack’s lap. Lilly curled up on Jack’s lap to nap.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Listening  ( Lily the Cat ) "} />
        <div className="p-4">
          <p>Listen carefully and answer the questions.</p>
        </div>
        <div className="p-4">
          <img
            src={IMAGES.L1.E5.LillyTheCat}
            alt="LillyTheCat"
            className="w-1/2 mx-auto"
          />
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. What did Lily do after she took a nap ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="jumped down" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="sat in a chair" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="ate lunch" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. How do you know Lily likes Jack ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="She played with him." />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="She sat on his lap." />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="She called his name." />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. What did it mean when Lily circled the chair ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="Drew a circle" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Played a game" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Walked around it" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. What did Lily do at the end of the story ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="Went to her bed" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Sat on Jack’s lap" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Sat by her bowl" />
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              5. Before Lily saw the room what did she do ?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="Saw Jack" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Played with a ball" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Walked through a door" />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/One/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/One/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E5;
