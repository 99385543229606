import React, { useState } from 'react';
import { Checkbox, Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E12 = () => {
    const [isDis, setIsDis] = useState(true);
    const changeNext = () => {
        setIsDis(!isDis);
    }
  return (
    <div>
      <div>
        <BannerTitle title={"Read the poem four times."} />
        <div className="flex px-4 mt-4 md:p-12 lg:p-12">
          <Typography variant="lead">The fat cat</Typography>
        </div>
        <div className="flex px-4 md:p-12 lg:p-12">
          <Typography variant="lead">sat on the hat,</Typography>
        </div>
        <div className="flex px-4 md:p-12 lg:p-12">
          <Typography variant="lead">the hat went flat.</Typography>
        </div>
        <div className="flex px-4 md:p-12 lg:p-12">
          <Typography variant="lead">“Meow, meow!” said the cat.</Typography>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can understand what i read."
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/One/11">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/One/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E12;