import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS3 = () => {
  const speakData = "Some people live in a village. A village is smaller than a town. It has homes and sometimes some other buildings like shops and a place of worship. It may even have a playground. Have you ever been to a village?";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Village"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.SS3.Village}
            alt="Village"
            className="h-full w-full"
          />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            Some people live in a village. A village is smaller than a town. It
            has homes and sometimes some other buildings like shops and a place
            of worship. It may even have a playground. Have you ever been to a
            village?
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;
