import React from 'react';
import { Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import HandT from '../../assets/Four/TheHareAndTheTortoise.png';
import HandT1 from "../../assets/Four/HandT1.png";
import HandT2 from "../../assets/Four/HandT2.png";
import HandT3 from "../../assets/Four/HandT3.png";
import HandT4 from "../../assets/Four/HandT4.png";
import HandT5 from '../../assets/Four/HandT5.png';
import { useSpeechSynthesis } from "react-speech-kit";
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const E1 = () => {
  const speakData =
    "Once a upon a time, there was a tortoise and a hare. The hare liked to brag about how fast he could run. “Nobody can beat me!” Hare said.“let’s race and find out!” Tortoise said. Hare wanted to win. He hoped away and left Tortoise for behind. Tortoise took one step after another. Hare wanted a snack. He hoped to a field and ate some plants. Tortoise took one step after another. Hare wanted a nap. He hoped under a tree and fell fast asleep. Tortoise took one step after another. He passed Hare and kept going. Hare woke up from his nap. He hoped to his feet and ran. He could not run fast enough. Tortoise won the race. He smiled at Hare. “Slow and steady wins the race.” Tortoise said.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"The Hare and The Tortoise"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={HandT}
            alt="TheHareAndTheTortoise"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Once a upon a time, there was a tortoise and a hare. The hare liked
            to brag about how fast he could run. “Nobody can beat me!” Hare
            said.“let’s race and find out!” Tortoise said. Hare wanted to win.
            He hoped away and left Tortoise for behind.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={HandT1}
            alt="TheHareAndTheTortoise"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Tortoise took one step after another. Hare wanted a snack. He hoped
            to a field and ate some plants.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={HandT2}
            alt="TheHareAndTheTortoise"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Tortoise took one step after another. Hare wanted a nap. He hoped
            under a tree and fell fast asleep.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={HandT3}
            alt="TheHareAndTheTortoise"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Tortoise took one step after another. He passed Hare and kept going.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={HandT4}
            alt="TheHareAndTheTortoise"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Hare woke up from his nap. He hoped to his feet and ran. He could
            not run fast enough.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={HandT5}
            alt="TheHareAndTheTortoise"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            Tortoise won the race. He smiled at Hare. “Slow and steady wins the
            race.” Tortoise said.
          </Typography>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Four/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/Four/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;