import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Unscramble the mixed-up"} />
        <div className="p-4">
          <p>
            Unscramble the mixed-up letters to make proper words. Write the
            correct Answer.
          </p>
        </div>
        <div className="p-4 flex">
          <div className="w-1/4">
            <p className="font-bold">1. e r a h c</p>
          </div>
          <div className="w-1/4">
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
          </div>
          <div className="w-2/4">
            <p>stretch your arms out</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/4">
            <p className="font-bold">2. t e m e</p>
          </div>
          <div className="w-1/4">
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
          </div>
          <div className="w-2/4">
            <p>get together with a friend.</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/4">
            <p className="font-bold">3. a t m e</p>
          </div>
          <div className="w-1/4">
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
          </div>
          <div className="w-2/4">
            <p>put it on the barbecue</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/4">
            <p className="font-bold">4. m e t a</p>
          </div>
          <div className="w-1/4">
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
          </div>
          <div className="w-2/4">
            <p>all the players together</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/4">
            <p className="font-bold">5. o t a c</p>
          </div>
          <div className="w-1/4">
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
          </div>
          <div className="w-2/4">
            <p>goes with the hat</p>
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/4">
            <p className="font-bold">6. y t a s</p>
          </div>
          <div className="w-1/4">
            <input
              type="text"
              className="outline-none w-16 text-lg border-b-blue-500 border-b-2 mx-1"
              maxLength={5}
            />
          </div>
          <div className="w-2/4">
            <p>don't go</p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Three/5">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;