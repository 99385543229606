import React from "react";
import { useDrag } from "react-dnd";

const Box = ({ id, name, type }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type,
    item: { id, name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const normalStyle =
    "p-3 m-2 bg-zmh-lightblue-700 text-white rounded-2xl";
  const dragStyle =
    "p-3 m-2 bg-zmh-lightblue-400 text-white rounded-2xl";

  return (
    <div>
      <div className={isDragging ? dragStyle : normalStyle} ref={dragRef}>
        {name}
      </div>
    </div>
  );
};

export default Box;
