import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC13 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";
  const showBox = "p-2 bg-yellow-500 text-black m-2 rounded-2xl";
  const hideBox = "hidden";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("1").className = errorStyle;
        document.getElementById("myAlert").className = showBox;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = correctStyle;
        document.getElementById("myAlert").className = showBox;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;        
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Which one of these men should use more force?</p>
        </div>
        <div className="flex">
          <div className="w-1/2 m-2">
            <div className="p-4 md:p-12 lg:p-12">
              <img
                src={IMAGES.L1.SC13.QForce1}
                alt="Quiz"
                className="w-full h-48"
              />
            </div>
          </div>
          <div className="w-1/2 m-2">
            <div className="p-4 md:p-12 lg:p-12">
              <img
                src={IMAGES.L1.SC13.QForce2}
                alt="Quiz"
                className="w-full h-48"
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 m-2">
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              A
            </button>
          </div>
          <div className="w-1/2 m-2">
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              B
            </button>
          </div>
        </div>
        <div className={hideBox} id="myAlert">
          <p>
            <strong>B</strong> needs to use more force because it is heavier.
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/One/12">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC13;
