import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { Typography, Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS7 = () => {
  return (
    <div>
      <BannerTitle title={"Land form scene"} />
      <div className="p-4">
        <img src={IMAGES.L3.LandFromScene} alt="LandFromScene" />
      </div>
      <div className="p-4">
        <p>Choose the correct answers.</p>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            1. What animal is drinking water?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA1" name="Q1" label="Deer" />
          </div>
          <div className="flex-row">
            <Radio id="QA2" name="Q1" label="lion" />
          </div>
          <div className="flex-row">
            <Radio id="QA3" name="Q1" label="rabbit" />
          </div>
        </div>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">2. How many hills do you see?</Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA4" name="Q2" label="2" />
          </div>
          <div className="flex-row">
            <Radio id="QA5" name="Q2" label="3" />
          </div>
          <div className="flex-row">
            <Radio id="QA6" name="Q2" label="4" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            3. What is at the top of the mountain?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Snow" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="tree" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="house" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">4. What is on the island?</Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Coconut tree" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="boat" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="tent" />
          </div>
        </div>
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/Three/6">
          <BackButton />
        </Link>

        <Link to="/G1/SocialStudies/Units/Three/8">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default SS7;