import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA11 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("9").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("9").className = showCorrectSytle;
        document.getElementById("10").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "11":
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("11").className = errorStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":
        document.getElementById("12").className = correctStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "13":
        document.getElementById("13").className = correctStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        break;
      case "14":
        document.getElementById("13").className = showCorrectSytle;
        document.getElementById("14").className = errorStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        break;
      case "15":
        document.getElementById("16").className = showCorrectSytle;
        document.getElementById("15").className = errorStyle;
        document.getElementById("15").disabled = true;
        document.getElementById("16").disabled = true;
        break;
      case "16":
        document.getElementById("16").className = correctStyle;
        document.getElementById("15").disabled = true;
        document.getElementById("16").disabled = true;
        break;
      case "17":
        document.getElementById("18").className = showCorrectSytle;
        document.getElementById("17").className = errorStyle;
        document.getElementById("17").disabled = true;
        document.getElementById("18").disabled = true;
        break;
      case "18":
        document.getElementById("18").className = correctStyle;
        document.getElementById("17").disabled = true;
        document.getElementById("18").disabled = true;
        break;
      case "19":
        document.getElementById("19").className = correctStyle;
        document.getElementById("19").disabled = true;
        document.getElementById("20").disabled = true;
        break;
      case "20":
        document.getElementById("19").className = showCorrectSytle;
        document.getElementById("20").className = errorStyle;
        document.getElementById("19").disabled = true;
        document.getElementById("20").disabled = true;
        break;
      case "21":
        document.getElementById("22").className = showCorrectSytle;
        document.getElementById("21").className = errorStyle;
        document.getElementById("21").disabled = true;
        document.getElementById("22").disabled = true;
        break;
      case "22":
        document.getElementById("22").className = correctStyle;
        document.getElementById("21").disabled = true;
        document.getElementById("22").disabled = true;
        break;
      case "23":
        document.getElementById("24").className = showCorrectSytle;
        document.getElementById("23").className = errorStyle;
        document.getElementById("23").disabled = true;
        document.getElementById("24").disabled = true;
        break;
      case "24":
        document.getElementById("24").className = correctStyle;
        document.getElementById("23").disabled = true;
        document.getElementById("24").disabled = true;
        break;
      case "25":
        document.getElementById("25").className = correctStyle;
        document.getElementById("25").disabled = true;
        document.getElementById("26").disabled = true;
        break;
      case "26":
        document.getElementById("25").className = showCorrectSytle;
        document.getElementById("26").className = errorStyle;
        document.getElementById("25").disabled = true;
        document.getElementById("26").disabled = true;
        break;
      case "27":
        document.getElementById("28").className = showCorrectSytle;
        document.getElementById("27").className = errorStyle;
        document.getElementById("27").disabled = true;
        document.getElementById("28").disabled = true;
        break;
      case "28":
        document.getElementById("28").className = correctStyle;
        document.getElementById("27").disabled = true;
        document.getElementById("28").disabled = true;
        break;
      case "29":
        document.getElementById("29").className = correctStyle;
        document.getElementById("29").disabled = true;
        document.getElementById("30").disabled = true;
        break;
      case "30":
        document.getElementById("29").className = showCorrectSytle;
        document.getElementById("30").className = errorStyle;
        document.getElementById("29").disabled = true;
        document.getElementById("30").disabled = true;
        break;
      case "31":
        document.getElementById("32").className = showCorrectSytle;
        document.getElementById("31").className = errorStyle;
        document.getElementById("31").disabled = true;
        document.getElementById("32").disabled = true;
        break;
      case "32":
        document.getElementById("32").className = correctStyle;
        document.getElementById("31").disabled = true;
        document.getElementById("32").disabled = true;
        break;
      case "33":
        document.getElementById("33").className = correctStyle;
        document.getElementById("33").disabled = true;
        document.getElementById("34").disabled = true;
        break;
      case "34":        
        document.getElementById("33").className = showCorrectSytle;
        document.getElementById("34").className = errorStyle;
        document.getElementById("33").disabled = true;
        document.getElementById("34").disabled = true;
        break;
      case "35":
        document.getElementById("36").className = showCorrectSytle;
        document.getElementById("35").className = errorStyle;
        document.getElementById("35").disabled = true;
        document.getElementById("36").disabled = true;
        break;
      case "36":
        document.getElementById("36").className = correctStyle;
        document.getElementById("35").disabled = true;
        document.getElementById("36").disabled = true;
        break;
      case "37":
        document.getElementById("37").className = correctStyle;
        document.getElementById("37").disabled = true;
        document.getElementById("38").disabled = true;
        break;
      case "38":
        document.getElementById("37").className = showCorrectSytle;
        document.getElementById("38").className = errorStyle;        
        document.getElementById("37").disabled = true;
        document.getElementById("38").disabled = true;
        break;
      case "39":
        document.getElementById("40").className = showCorrectSytle;
        document.getElementById("39").className = errorStyle;
        document.getElementById("39").disabled = true;
        document.getElementById("40").disabled = true;
        break;
      case "40":
        document.getElementById("40").className = correctStyle;
        document.getElementById("39").disabled = true;
        document.getElementById("40").disabled = true;
        break;
      case "41":
        document.getElementById("41").className = correctStyle;
        document.getElementById("41").disabled = true;
        document.getElementById("42").disabled = true;
        break;
      case "42":
        document.getElementById("41").className = showCorrectSytle;
        document.getElementById("42").className = errorStyle;        
        document.getElementById("41").disabled = true;
        document.getElementById("42").disabled = true;
        break;
      case "43":
        document.getElementById("43").className = correctStyle;
        document.getElementById("43").disabled = true;
        document.getElementById("44").disabled = true;
        break;
      case "44":
        document.getElementById("43").className = showCorrectSytle;
        document.getElementById("44").className = errorStyle;
        document.getElementById("43").disabled = true;
        document.getElementById("44").disabled = true;
        break;
      case "45":
        document.getElementById("46").className = showCorrectSytle;
        document.getElementById("45").className = errorStyle;
        document.getElementById("45").disabled = true;
        document.getElementById("46").disabled = true;
        break;
      case "46":
        document.getElementById("46").className = correctStyle;
        document.getElementById("45").disabled = true;
        document.getElementById("46").disabled = true;
        break;
      case "47":
        document.getElementById("48").className = showCorrectSytle;
        document.getElementById("47").className = errorStyle;
        document.getElementById("47").disabled = true;
        document.getElementById("48").disabled = true;
        break;
      case "48":
        document.getElementById("48").className = correctStyle;
        document.getElementById("47").disabled = true;
        document.getElementById("48").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Choose the number that is smaller"} />
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                3
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                9
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                24
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                21
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                15
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                17
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                7
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                3
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="9" className={normalStyle}>
                3
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="10" className={normalStyle}>
                11
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="11" className={normalStyle}>
                13
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="12" className={normalStyle}>
                1
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="13" className={normalStyle}>
                9
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="14" className={normalStyle}>
                12
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="15" className={normalStyle}>
                13
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="16" className={normalStyle}>
                4
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="17" className={normalStyle}>
                20
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="18" className={normalStyle}>
                4
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="19" className={normalStyle}>
                4
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="20" className={normalStyle}>
                15
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="21" className={normalStyle}>
                14
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="22" className={normalStyle}>
                12
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="23" className={normalStyle}>
                25
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="24" className={normalStyle}>
                20
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="25" className={normalStyle}>
                12
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="26" className={normalStyle}>
                19
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="27" className={normalStyle}>
                13
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="28" className={normalStyle}>
                10
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="29" className={normalStyle}>
                12
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="30" className={normalStyle}>
                15
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="31" className={normalStyle}>
                18
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="32" className={normalStyle}>
                7
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="33" className={normalStyle}>
                2
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="34" className={normalStyle}>
                19
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="35" className={normalStyle}>
                21
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="36" className={normalStyle}>
                12
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="37" className={normalStyle}>
                15
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="38" className={normalStyle}>
                16
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="39" className={normalStyle}>
                23
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="40" className={normalStyle}>
                9
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="41" className={normalStyle}>
                13
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="42" className={normalStyle}>
                19
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full mb-20">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="43" className={normalStyle}>
                10
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="44" className={normalStyle}>
                20
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="45" className={normalStyle}>
                15
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="46" className={normalStyle}>
                2
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="47" className={normalStyle}>
                10
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="48" className={normalStyle}>
                3
              </button>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/10">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/One/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA11;
