import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS20 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"The dinning room"} />
        <div className="mb-20">
          <div className="p-3">
            <p>
              What do we find in the dinning room to keep us eating more
              nutritious food ?
            </p>
          </div>
          <div className="p-3">
            <strong>In the dinning room, we find</strong>
          </div>
          <div className="mt-3 p-3 justify-center text-center text-2xl">
            <strong>A dinning table and chair</strong>
          </div>
          <div className="p-4 md:p-12 lg:p-12">
            <img
              src={IMAGES.L2.SS20.DinningRoom}
              alt="DinningRoom"
              className=" w-8/12 mx-auto"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/19">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/21">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS20;
