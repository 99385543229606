import React from "react";
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Choose the correct answer."} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">1 . Where is Easter island?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Pacific Ocean" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Indian Ocean" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Atlantic Ocean" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2 . The island is famous for its _____ stone statues
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="778" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="887" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="789" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3 . What was on Easter Island when people first arrived 1,700
              years ago?{" "}
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="sea creatures " />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="only trees" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="plants and animals" />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Two/2">
            <BackButton />
          </Link>
          <Link to="/G2/SocialStudies/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;