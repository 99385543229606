export const IMAGES = {
  L1: {
    SS16: {
      ActivitiesIMG: require("./L1/Activities.webp"),
      TalkIMG: require("./L1/Talk.webp"),
      EatIMG: require("./L1/Eat.webp"),
      WalkIMG: require("./L1/Walk.webp"),
      PlayIMG: require("./L1/Play.webp"),
      MakeFoodIMG: require("./L1/MakeFood.webp"),
      ReadIMG: require("./L1/Read.webp"),
      GoOnHolidayIMG: require("./L1/GoOnHoliday.webp"),
      DoTheHouseChoresIMG: require("./L1/DoTheHouseChores.webp"),
      GoOnPicnicIMG: require("./L1/GoOnPicnic.webp"),
      GoShoppingIMG: require("./L1/GoShopping.webp"),
      ExploreIMG: require("./L1/Explore.webp"),
    },
  },
  L2: {
    SS1: {
      HousesAndHomes: require("./L2/HousesAndHomes.png"),
    },
    SS2: {
      CountrySide: require("./L2/CountrySide.png"),
    },
    SS3: {
      Village: require("./L2/Village.png"),
    },
    SS4: {
      Town: require("./L2/Town.png"),
    },
    SS5: {
      City: require("./L2/City.png"),
    },
    SS6: {
      Asking1: require("./L2/Asking1.jpg"),
    },
    SS7: {
      L2Q1: require("./L2/L2Q1.png"),
      L2Q2: require("./L2/L2Q2.png"),
      L2Q3: require("./L2/L2Q3.png"),
      L2Q4: require("./L2/L2Q4.png"),
    },
    SS8: {
      DiffHouseAndHomes: require("./L2/DiffHouseAndHome.png"),
    },
    SS9: {
      Apartment: require("./L2/Apartment.png"),
      House: require("./L2/House.png"),
      WoddenHouse: require("./L2/WoddenHouse.png"),
      TownHouse: require("./L2/TownHouse.png"),
    },
    SS10: {
      Cottage: require("./L2/Cottage.png"),
      Hut: require("./L2/Hut.png"),
      Bungalow: require("./L2/Bungalow.png"),
      Villa: require("./L2/Villa.png"),
    },
    SS11: {
      iGloo: require("./L2/iGloo.png"),
      MudHouse: require("./L2/MudHouse.png"),
      FarmHouse: require("./L2/FarmHouse.png"),
      Caravan: require("./L2/Caravan.png"),
    },
    SS14: {
      MyHouse: require("./L2/MyHouse.png"),
    },
    SS16: {
      Bed: require("./L2/Bed.png"),
    },
    SS17: {
      Bedroom: require("./L2/Bedroom.png"),
      Kitchen: require("./L2/Kitchen.png"),
      LivingRoom: require("./L2/LivingRoom.png"),
      Bathroom: require("./L2/BathRoom.png"),
    },
    SS18: {
      Food: require("./L2/Food.png"),
      KitchenUtensils: require("./L2/KitchenUtensils.png"),
      Water: require("./L2/Water.png"),
    },
    SS19: {
      BathOrShower: require("./L2/BathOrShower.png"),
      Toilet: require("./L2/Toilet.png"),
      Sink: require("./L2/Sink.png"),
    },
    SS20: {
      DinningRoom: require("./L2/DinningRoom.png"),
    },
    SS21: {
      TheLivingRoom: require("./L2/TheLivingRoom.png"),
    },
    SS22: {
      QHouse: require("./L2/QHouse.png"),
    },
    SS23: {
      QBathroom: require("./L2/QBathRoom.png"),
    },
    SS24: {
      QBedroom: require("./L2/QBedRoom.png"),
    },
    SS25: {
      QDinningRoom: require("./L2/QDinningRoom.png"),
    },
    SS26: {
      QKitchen: require("./L2/QKitchen.png"),
    },
    SS27: {
      QLivingRoom: require("./L2/QLivingRoom.png"),
    },
    SS28: {
      MyHousePoem: require("./L2/MyHousePoem.png"),
    },
  },
  L3: {
    SS1: {
      TransportCarPastPresent: require("./L3/TransportCarPastPresent.png"),
      TransportTrainPast: require("./L3/TransportTrainPast.png"),
      TransportTrainPresent: require("./L3/TransportTrainPresent.png"),
    },
    SS2: {
      Transport1: require("./L3/Transport1.png"),
    },
    SS3: {
      Transport2: require("./L3/Transport2.png"),
      Transport3: require("./L3/Transport3.png"),
    },
    SS4: {
      Transport4: require("./L3/Transport4.png"),
    },
    SS5: {
      Transport5: require("./L3/Transport5.png"),
    },
    SS7: {
      DiffTransport: require("./L3/DiffTransport.jpg"),
    },
    SS8: {
      Past1: require("./L3/Past1.png"),
    },
    SS9: {
      Past2: require("./L3/Past2.png"),
    },
    SS10: {
      Past3: require("./L3/Past3.png"),
      Past4: require("./L3/Past4.png"),
    },
    SS11: {
      Ship1: require("./L3/Ship1.png"),
      Ship2: require("./L3/Ship2.png"),
      Ship3: require("./L3/Ship3.png"),
    },
    SS12: {
      Train1: require("./L3/Train1.png"),
      Train2: require("./L3/Train2.png"),
      Train3: require("./L3/Train3.png"),
      Train4: require("./L3/Train4.png"),
    },
    SS13: {
      Train5: require("./L3/Train5.png"),
      Train6: require("./L3/Train6.png"),
    },
    SS14: {
      Ambulance1: require("./L3/Ambulance1.png"),
      Ambulance2: require("./L3/Ambulance2.png"),
    },
    SS15: {
      FireTruck1: require("./L3/FireTruck1.png"),
      FireTruck2: require("./L3/FireTruck2.png"),
    },
    SS16: {
      Old: require("./L3/Old1.png"),
    },
    SS17: {
      Car1: require("./L3/Car1.png"),
      Car2: require("./L3/Car2.png"),
      Car3: require("./L3/Car3.png"),
      Car4: require("./L3/Car4.png"),
      Car5: require("./L3/Car5.png"),
      Car6: require("./L3/Car6.png"),
    },
    SS18: {
      Plane1: require("./L3/Plane1.png"),
      Plane2: require("./L3/Plane2.png"),
      Plane3: require("./L3/Plane3.png"),
      Plane4: require("./L3/Plane4.png"),
      Plane5: require("./L3/Plane5.png"),
    },
    SS19: {
      Air: require("./L3/Air.png"),
    },
    SS20: {
      F1: require("./L3/F1.png"),
      F2: require("./L3/F2.png"),
      F3: require("./L3/F3.png"),
      F4: require("./L3/F4.png"),
      F5: require("./L3/F5.png"),
      F6: require("./L3/F6.png"),
      F7: require("./L3/F7.png"),
      F8: require("./L3/F8.png"),
      F9: require("./L3/F9.png"),
      F10: require("./L3/F10.png"),
    },
    SS24: {
      Poem1: require("./L3/Poem1.png"),
      Poem2: require("./L3/Poem2.png"),
      Poem3: require("./L3/Poem3.png"),
      Poem4: require("./L3/Poem4.png"),
    },
  },
  L4: {
    SS1: {
      Obj: require("./L4/Obj.png"),
    },
    SS2: {
      AllAboutMap: require("./L4/AllAboutMap.png"),
    },
    SS3: {
      M1: require("./L4/M1.png"),
      M2: require("./L4/M2.png"),
      Map1: require("./L4/Map1.png"),
    },
    SS4: {
      M3: require("./L4/M3.png"),
      M4: require("./L4/M4.png"),
      Map2: require("./L4/Map1.png"),
    },
    SS5: {
      Map3: require("./L4/Map1.png"),
    },
    SS6: {
      M5: require("./L4/M5.png"),
      M6: require("./L4/M6.png"),
      Map4: require("./L4/Map2.png"),
    },
    SS7: {
      M7: require("./L4/M7.png"),
      Map5: require("./L4/Map3.png"),
    },
    SS8: {
      Map6: require("./L4/Map4.png"),
    },
    SS9: {
      Map7: require("./L4/Map5.png"),
    },
    SS10: {
      Map8: require("./L4/Map6.png"),
    },
    SS11: {
      M8: require("./L4/M8.png"),
      M9: require("./L4/M9.png"),
      Map8: require("./L4/Map2.png"),
      Map9: require("./L4/Map3.png"),
      Map10: require("./L4/Map6.png"),
    },
    SS12: {      
      CanvasDraw1: require("./L4/CanvasDraw1.png"),      
    },
    SS13: {
      MapPoem: require("./L4/MapPoem.png"),
    },
    SS14: {
      MapQ: require("./L4/MapQ.png"),
    },
    SS15: {
      Compass1: require("./L4/Compass1.png"),
    },
    SS16: {
      Compass2: require("./L4/Compass2.png"),
    },
    SS17: {
      Compass3: require("./L4/Compass3.png"),
      Compass4: require("./L4/Compass4.png"),
    },
    SS18: {
      CompassQ: require("./L4/Compass5.png"),
    },
    SS19: {
      LeftRightQ: require("./L4/QuestionLeftRight.png"),
    },
    SS20: {
      MapSymbol: require("./L4/MapSymbol.png"),
    },
    SS21: {
      Symbol1: require("./L4/Symbol1.png"),
      Symbol2: require("./L4/Symbol2.png"),
      Symbol3: require("./L4/Symbol3.png"),
      Symbol4: require("./L4/Symbol4.png"),
      Symbol5: require("./L4/Symbol5.png"),
      Symbol6: require("./L4/Symbol6.png"),
    },
  },
};