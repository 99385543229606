import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"BEVERAGES "} />
        <div className="p-4">
          <p>
            Gubdi is a special drink from Sudan. It’s made from the fruit of the
            baobab tree. Farmers like to drink it in the summer because it’s
            very refreshing. Baobab fruit is called monkey bread! It's very
            healthy because it contains lots of carbohydrates and proteins. It
            also has more vitamin C than oranges!
          </p>
        </div>
        <div className="p-4">
          <p>
            To make gubdi, you break open the baobab fruit and take out the dry
            pulp. Then you put the pulp in water and squeeze it to make a white
            juice. Some people also use baobab fruit to make ice cream and
            milkshakes.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SS1.Beverages}
            alt="Beverages"
            className="w-full"
          />
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Four/4">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units/Four/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS5;
