export const IMAGES = {
  L1: {
    MA3: {
      DrawCanvas1: require("./L1/DrawCanvas1.png"),
    },
    MA4: {
      DrawCanvas2: require("./L1/DrawCanvas2.png"),
    },
    MA5: {
      Ruler: require("./L1/Ruler.png"),
    },
    MA6: {
      Addition: require("./L1/Addition.png"),
    },
    MA7: {
      AdditionQ: require("./L1/AdditionQ.png"),
    },
    MA8: {
      Q1: require("./L1/Q1.png"),
      Q2: require("./L1/Q2.png"),
      Q3: require("./L1/Q3.png"),
      Q4: require("./L1/Q4.png"),
      Q5: require("./L1/Q5.png"),
    },
    MA9: {
      NumberLine: require("./L1/NumberLine.png"),
    },
    MA11: {
      QA1: require("./L1/QA1.png"),
      QA2: require("./L1/QA2.png"),
      QA3: require("./L1/QA3.png"),
      QA4: require("./L1/QA4.png"),
      QA5: require("./L1/QA5.png"),
      QA6: require("./L1/QA6.png"),
    },
  },
  L2: {
    MA1: {
      CommutativeProperty: require("./L2/CommutativeProperty.png"),
    },
    MA5: {
      CPQBanner: require("./L2/CPQBanner.png"),
      Q1: require("./L2/Q1.png"),
      Q2: require("./L2/Q2.png"),
      Q3: require("./L2/Q3.png"),
      Q4: require("./L2/Q4.png"),
    },
    MA6: {
      DrawCanvas1: require("./L2/DrawCanvas1.png"),
    },
    MA7: {
      AssociativeProperty: require("./L2/AssociativeProperty.png"),
    },
  },
  L3: {
    MA1: {
      AddSubtract: require("./L3/AddSubtract.png"),
    },
    MA7: {
      Base10: require("./L3/Base10.png"),
    },
    MA11: {
      PlaceValue: require("./L3/PlaceValue.png"),
    },
    MA12: {
      Q1: require("./L3/Q1.png"),
      Q2: require("./L3/Q2.png"),
      Q3: require("./L3/Q3.png"),
    },
    MA13: {
      Block1: require("./L3/Block1.png"),
      Block2: require("./L3/Block2.png"),
      Block3: require("./L3/Block3.png"),
      Block4: require("./L3/Block4.png"),
      Block5: require("./L3/Block5.png"),
      Block6: require("./L3/Block6.png"),
    },
    MA15: {
      BB1: require("./L3/BB1.png"),
      BB2: require("./L3/BB2.png"),
      BB3: require("./L3/BB3.png"),
      BB4: require("./L3/BB4.png"),
      BB5: require("./L3/BB5.png"),
      BB6: require("./L3/BB6.png"),
      BB7: require("./L3/BB7.png"),
      BB8: require("./L3/BB8.png"),
      BB9: require("./L3/BB9.png"),
      BB10: require("./L3/BB10.png"),
      BB11: require("./L3/BB11.png"),
      BB12: require("./L3/BB12.png"),
    },
    MA16: {
      BBB1: require("./L3/BBB1.png"),
      BBB2: require("./L3/BBB2.png"),
      BBB3: require("./L3/BBB3.png"),
      BBB4: require("./L3/BBB4.png"),
      BBB5: require("./L3/BBB5.png"),
      BBB6: require("./L3/BBB6.png"),
      BBB7: require("./L3/BBB7.png"),
      BBB8: require("./L3/BBB8.png"),
      BBB9: require("./L3/BBB9.png"),
      BBB10: require("./L3/BBB10.png"),
    },
  },
  L4: {
    MA3: {
      MD1: require("./L4/MD1.png"),
    },
    MA4: {
      CanvasDraw1: require("./L4/CanvasDraw1.png"),
    },
    MA5: {
      SL1: require("./L4/SL1.png"),
      SL2: require("./L4/SL2.png"),
      SL3: require("./L4/SL3.png"),
    },
    MA6: {
      CanvasDraw2: require("./L4/CanvasDraw2.png"),
    },
    MA7: {
      TIME: require("./L4/TIME.png"),
    },
    MA8: {
      TQ1: require("./L4/TQ1.png"),
      TQ2: require("./L4/TQ2.png"),
      TQ3: require("./L4/TQ3.png"),
      TQ4: require("./L4/TQ4.png"),
      TQ5: require("./L4/TQ5.png"),
      TQ6: require("./L4/TQ6.png"),
    },
    MA9: {
      TQ7: require("./L4/TQ7.png"),
      TQ8: require("./L4/TQ8.png"),
      TQ9: require("./L4/TQ9.png"),
      TQ10: require("./L4/TQ10.png"),
      TQ11: require("./L4/TQ11.png"),
      TQ12: require("./L4/TQ12.png"),
      TQ13: require("./L4/TQ13.png"),
      TQ14: require("./L4/TQ14.png"),
      TQ15: require("./L4/TQ15.png"),
      TQ16: require("./L4/TQ16.png"),
      TQ17: require("./L4/TQ17.png"),
      TQ18: require("./L4/TQ18.png"),
    },
    MA10: {
      TQ19: require("./L4/TQ19.png"),
      TQ20: require("./L4/TQ20.png"),
      TQ21: require("./L4/TQ21.png"),
      TQ22: require("./L4/TQ22.png"),
      TQ23: require("./L4/TQ23.png"),
      TQ24: require("./L4/TQ24.png"),
      TQ25: require("./L4/TQ25.png"),
      TQ26: require("./L4/TQ26.png"),
      TQ27: require("./L4/TQ27.png"),
    },
    MA11: {
      RDI: require("./L4/RID.png"),
    },
    MA12: {
      QCookie: require("./L4/QCookie.png"),
    },
    MA13: {
      FavShape: require("./L4/FavShape.png"),
    },
    MA14: {
      Book: require("./L4/Book.png"),
    },
    MA15: {
      Geometry: require("./L4/Geomatry.png"),
    },
    MA16: {
      S1: require("./L4/S1.png"),
      S2: require("./L4/S2.png"),
      S3: require("./L4/S3.png"),
      S4: require("./L4/S4.png"),
      S5: require("./L4/S5.png"),
      S6: require("./L4/S6.png"),
      S7: require("./L4/S7.png"),
      S8: require("./L4/S8.png"),
      S9: require("./L4/S9.png"),
    },
    MA17: {
      S10: require("./L4/S10.png"),
      S11: require("./L4/S11.png"),
      S12: require("./L4/S12.png"),
      S13: require("./L4/S13.png"),
      S14: require("./L4/S14.png"),
      S15: require("./L4/S15.png"),
      S16: require("./L4/S16.png"),
      S17: require("./L4/S17.png"),
      S18: require("./L4/S18.png"),
    },
  },
};