import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const SS1 = () => {
  const speakData =
    "Some machines use energy from nature. Watermills use energy from flowing water. Thousands of years ago people built watermills to make flour from grain. Then they use the flour to make bread and cakes. Watermills have a big wheel on the outside. The river turns the wheel. Then the wheel turn as axle inside the watermill. The axle turns a round millstone that breaks the grain into flour. Windmills use energy from the wind. Hundreds of years ago people started using windmills to make flour. Windmills have long arms with sails. The wind pushes the sails and turns the arms. Inside the mill, an axle turns a millstone. Windmills are useful in places that don’t have big rivers, but they only work when it’s windy.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"WATERMILLS "} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.SS1.Watermill}
            alt="Watermill"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Some machines use energy from nature. Watermills use energy from
            flowing water. Thousands of years ago people built watermills to
            make flour from grain. Then they use the flour to make bread and
            cakes. Watermills have a big wheel on the outside. The river turns
            the wheel. Then the wheel turn as axle inside the watermill.{" "}
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            The axle turns a round millstone that breaks the grain into flour.
            Windmills use energy from the wind. Hundreds of years ago people
            started using windmills to make flour. Windmills have long arms with
            sails. The wind pushes the sails and turns the arms. Inside the
            mill, an axle turns a millstone. Windmills are useful in places that
            don’t have big rivers, but they only work when it’s windy.
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/SocialStudies/Units/One/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1;
