import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Rearrange the sentence"} />
        <div>
          <div className="p-4">
            <p>
              1. long used/ have/American Indians/ express themselves/ dancing
              to.{" "}
            </p>
          </div>
          <div className="mx-4">
            <textarea
              rows="4"
              className="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>2. is/ graceful/ and beautiful/ The dance/</p>
          </div>
          <div className="mx-4">
            <textarea
              rows="4"
              className="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>3. difficult dance/It is/the most/by far.</p>
          </div>
          <div className="mx-4">
            <textarea
              rows="4"
              className="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>
        </div>
        <div className="mb-40">
          <div className="p-4">
            <p>
              4. dancers/ The Great Plains Indian/ feathers/ and painted masks/
              dress in.
            </p>
          </div>
          <div className="mx-4">
            <textarea
              rows="4"
              className="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/1">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/One/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;