import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS3 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Fill in the blanks by using the words in the box."}
        />
        <div className="p-4 border border-blue-500 m-2">
          <div className="flex justify-between">
            <div>anger</div>
            <div>helps</div>
            <div>throws</div>
            <div>asks to use</div>
            <div>waits</div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <div className="mb-4">
              1. A good citizen
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={6}
              />
              elderly person.
            </div>
          </div>
          <div>
            <div className="mb-4">
              2. A good citizen control
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={6}
              />
              in public.
            </div>
          </div>
          <div>
            <div className="mb-4">
              3. A good citizen
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={6}
              />
              for signal to cross the road.
            </div>
          </div>
          <div>
            <div className="mb-4">
              4. A good citizen
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-20 mx-2"
                type="text"
                maxLength={6}
              />
              rubbish in a bin.
            </div>
          </div>
          <div>
            <div className="mb-4">
              5. A good citizen
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-24 mx-2"
                type="text"
                maxLength={11}
              />
              something belongs to someone.
            </div>
          </div>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/One/2">
            <BackButton />
          </Link>

          <Link to="/G1/SocialStudies/Units/One/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;