import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC20 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p className="text-center font-bold">A Glass</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.Glass} alt="Quiz" className="w-1/2 mx-auto" />
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA1" name="Q1" label="Opaque" />
          </div>
          <div className="flex-row">
            <Radio id="QA2" name="Q1" label="Transparent" />
          </div>
          <div className="flex-row">
            <Radio id="QA3" name="Q1" label="Translucent" />
          </div>
        </div>

        <div className="mb-20"></div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/19">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/One/21">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC20;