import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS6 = () => {
  const speakData =
    "Mail or Post System. People used pen and paper to write letters and this was a very popular way of communicating. In the 1800s, official postal systems were set up all over the world, to deliver letters and parcels. ";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Early Communication"} />
        <div className="p-4">
          <p className="text-center font-bold text-purple-700">
            Mail or Post System
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Post} alt="Post" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            People used pen and paper to write letters and this was a very
            popular way of communicating. In the 1800s, official postal systems
            were set up all over the world, to deliver letters and parcels.
          </p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Four/5">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Four/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS6;
