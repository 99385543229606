import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS5 = () => {
  const speakData =
    "There are lots of other ways that transportation has changed from the past until now. Did you know that in the past they didn’t have trucks like we do now, but they used animals to pull carts loaded with all sorts of things that people needed in different places. We are lucky that we are able to travel comfortably now in cars and buses. These types of transport sure do make our lives easier.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Transport : Past and Present"} />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            There are lots of other ways that transportation has changed from
            the past until now. Did you know that in the past they didn’t have
            trucks like we do now, but they used animals to pull carts loaded
            with all sorts of things that people needed in different places.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS5.Transport5}
            alt="Transport"
            className="w-full"
          />
        </div>
        <div className="p-4 md:p-12 lg:p-12 mb-24">
          <p>
            We are lucky that we are able to travel comfortably now in cars and
            buses. These types of transport sure do make our lives easier.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS5;
