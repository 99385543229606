import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS14 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"What is a map?"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SS14.MapQ} alt="Map" className="w-full" />
        </div>

        <div className="p-10 mb-20">
          <div className="w-full mb-10">
            <p>
              1 . The school is on the
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Avenue.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              2 . You can go to the Gas Station from
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Street.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              3. Kim and Sam house live on
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Avenue.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              4 . Police Station is in the corner of
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Street and
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Avenue.
            </p>
          </div>
          <div className="w-full mb-10">
            <p>
              5 . Main Street and
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-16 ml-2 mr-2"
                type="text"
              />
              Street go by the park.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/13">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Four/15">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS14;
