import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E7 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Look at the verbs below. Write the verb with –ed in the other column
            to show it was done yesterday.
          </p>
        </div>
        <div className="m-2">
          <table className="table-auto w-full border-collapse border border-black">
            <thead>
              <tr>
                <th className="border border-black p-2"></th>
                <th className="border border-black p-2">
                  Today, I
                </th>
                <th className="border border-black p-2">
                  Yesterday, I
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black p-2">
                  1
                </td>
                <td className="border border-black p-2">
                  ask
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  2
                </td>
                <td className="border border-black p-2">
                  pick
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  3
                </td>
                <td className="border border-black p-2">
                  want
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  4
                </td>
                <td className="border border-black p-2">
                  turn
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  5
                </td>
                <td className="border border-black p-2">
                  like
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  6
                </td>
                <td className="border border-black p-2">
                  help
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              <tr>
                <td className="border border-black p-2">
                  7
                </td>
                <td className="border border-black p-2">
                  jump
                </td>
                <td className="border border-black p-2">
                  <input
                    type="text"
                    className="outline-none w-full px-2 text-xl"
                  />
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/6">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Four/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E7;