import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS18 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"The Kitchen"} />
        <div className="mb-10">
          <div className="p-3">
            <p>
              What do we find in the kitchen that is important for us to be
              healthy?
            </p>
          </div>
          <div className="p-3">
            <strong>In the kitchen, we find</strong>
          </div>
          <div className="mt-3 p-3 justify-center text-center text-2xl">
            <strong>Food</strong>
          </div>
          <div className="p-4 md:p-12 lg:p-12">
            <img
              src={IMAGES.L2.SS18.Food}
              alt="Food"
              className=" w-6/12 mx-auto"
            />
          </div>
        </div>

        <div className="mb-10">
          <div className="mt-3 p-3 justify-center text-center text-2xl">
            <strong>Kitchen Utensils</strong>
          </div>
          <div className="p-4 md:p-12 lg:p-12">
            <img
              src={IMAGES.L2.SS18.KitchenUtensils}
              alt="KitchenUtensils"
              className=" w-6/12 mx-auto"
            />
          </div>
        </div>

        <div className="mb-20">
          <div className="mt-3 p-3 justify-center text-center text-2xl">
            <strong>Water</strong>
          </div>
          <div className="p-4 md:p-12 lg:p-12">
            <img
              src={IMAGES.L2.SS18.Water}
              alt="Water"
              className=" w-6/12 mx-auto"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/17">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/19">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS18;
