import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA6 = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={"Subtraction"}
        />
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">1)</p>
            <p className="text-2xl">
              {"10 - 3 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">2)</p>
            <p className="text-2xl">
              {"12 - 3 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">3)</p>
            <p className="text-2xl">
              {"14 - 5 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">4)</p>
            <p className="text-2xl">
              {"12 - 8 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">5)</p>
            <p className="text-2xl">
              {"20 - 5 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">6)</p>
            <p className="text-2xl">
              {"15 - 5 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">7)</p>
            <p className="text-2xl">
              {"16 - 8 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">8)</p>
            <p className="text-2xl">
              {"12 - 4 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">9)</p>
            <p className="text-2xl">
              {"10 - 7 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">10)</p>
            <p className="text-2xl">
              {"20 - 8 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">11)</p>
            <p className="text-2xl">
              {"14 - 8 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">12)</p>
            <p className="text-2xl">
              {"17 - 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">13)</p>
            <p className="text-2xl">
              {"18 - 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">14)</p>
            <p className="text-2xl">
              {"15 - 7 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">15)</p>
            <p className="text-2xl">
              {"20 - 6 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">16)</p>
            <p className="text-2xl">
              {"12 - 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">17)</p>
            <p className="text-2xl">
              {"14 - 4 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">18)</p>
            <p className="text-2xl">
              {"13 - 9 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">19)</p>
            <p className="text-2xl">
              {"19 - 7 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4 flex">
            <p className="text-2xl mr-4">20)</p>
            <p className="text-2xl">
              {"18 - 3 = "}
              <input
                type="text"
                className="outline-none w-12 text-center text-2xl border-black border-b-2"
                maxLength="2"
              />
            </p>
          </div>
        </div>
       

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/5">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA6;