import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA7 = () => {
  const speakData =
    "The problem says 2+6+4=12 is the same as 10+2=12. In this example, we can combine the 6 and 4 together to make 10. Now we just have to add 2+10 which is 12. So the blank in this problem should be filled with what number ? The answer is 12.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Associative Property"} />
        <div className="p-4">
          <img
            src={IMAGES.L2.MA7.AssociativeProperty}
            alt=""
            className="w-full"
          />
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg">
          <p>
            The problem says 2+6+4=12 is the same as 10+2=12. In this
            example, we can combine the 6 and 4 together to make 10. Now we just
            have to add 2+10 which is 12. So the blank in this problem should be
            filled with what number ? The answer is 12.
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Two/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/Two/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA7;