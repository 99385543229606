import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA8 = () => {
  const normalStyle =
    "w-full px-4 py-3 mb-2 bg-gray-200 hover:bg-gray-300 text-gray-800 text-lg font-medium";
  const correctStyle =
    "w-full px-4 py-3 mb-2 bg-green-500 hover:bg-green-600 text-white text-lg font-medium";
  const errorStyle =
    "w-full px-4 py-3 mb-2 bg-red-600 hover:bg-red-700 text-white text-lg font-medium";
  const showCorrectSytle =
    "w-full px-4 py-3 mb-2 opacity-50 bg-green-500 hover:bg-green-600 text-white text-lg font-medium";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "2":
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "3":
        document.getElementById("3").className = errorStyle;
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = errorStyle;
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("6").className = errorStyle;
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("4").disabled = true;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("7").className = errorStyle;
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "9":
        document.getElementById("9").className = errorStyle;
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        document.getElementById("9").disabled = true;
        break;
      case "10":
        document.getElementById("10").className = correctStyle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "11":
        document.getElementById("11").className = errorStyle;
        document.getElementById("10").className = showCorrectSytle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":
        document.getElementById("12").className = errorStyle;
        document.getElementById("10").className = showCorrectSytle;
        document.getElementById("10").disabled = true;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "13":
        document.getElementById("13").className = correctStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      case "14":
        document.getElementById("14").className = errorStyle;
        document.getElementById("13").className = showCorrectSytle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      case "15":
        document.getElementById("15").className = errorStyle;
        document.getElementById("13").className = showCorrectSytle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        document.getElementById("15").disabled = true;
        break;
      case "16":
        document.getElementById("17").className = errorStyle;
        document.getElementById("18").className = showCorrectSytle;
        document.getElementById("16").disabled = true;
        document.getElementById("17").disabled = true;
        document.getElementById("18").disabled = true;
        break;
      case "17":
        document.getElementById("17").className = errorStyle;
        document.getElementById("18").className = showCorrectSytle;
        document.getElementById("16").disabled = true;
        document.getElementById("17").disabled = true;
        document.getElementById("18").disabled = true;
        break;
      case "18":
        document.getElementById("18").className = correctStyle;
        document.getElementById("16").disabled = true;
        document.getElementById("17").disabled = true;
        document.getElementById("18").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div className="">
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Choose the correct Time</p>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L4.MA8.TQ1} alt="" className="" />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <button onClick={checkAnswer} id={1} className={normalStyle}>
                1:30
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={2} className={normalStyle}>
                2:30
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={3} className={normalStyle}>
                3:30
              </button>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L4.MA8.TQ2} alt="" className="" />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <button onClick={checkAnswer} id={4} className={normalStyle}>
                4:30
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={5} className={normalStyle}>
                5:30
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={6} className={normalStyle}>
                6:30
              </button>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L4.MA8.TQ3} alt="" className="" />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <button onClick={checkAnswer} id={7} className={normalStyle}>
                4:00
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={8} className={normalStyle}>
                5:00
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={9} className={normalStyle}>
                6:00
              </button>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L4.MA8.TQ4} alt="" className="" />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <button onClick={checkAnswer} id={10} className={normalStyle}>
                10:00
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={11} className={normalStyle}>
                11:00
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={12} className={normalStyle}>
                12:00
              </button>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L4.MA8.TQ5} alt="" className="" />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <button onClick={checkAnswer} id={13} className={normalStyle}>
                8:30
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={14} className={normalStyle}>
                9:30
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={15} className={normalStyle}>
                10:30
              </button>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="w-1/2 mx-auto">
            <img src={IMAGES.L4.MA8.TQ6} alt="" className="" />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <button onClick={checkAnswer} id={16} className={normalStyle}>
                7:00
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={17} className={normalStyle}>
                8:00
              </button>
            </div>
            <div>
              <button onClick={checkAnswer} id={18} className={normalStyle}>
                9:00
              </button>
            </div>
          </div>
        </div>

        <div className="mb-24 bg-gray-100"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/7">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Four/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA8;
