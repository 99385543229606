import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS9 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Some people live in…"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">
              Apartment
            </p>
            <img
              src={IMAGES.L2.SS9.Apartment}
              alt="Apartment"
              className="w-full"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">
              House
            </p>
            <img src={IMAGES.L2.SS9.House} alt="House" className="w-full" />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">
              Wodden House
            </p>
            <img
              src={IMAGES.L2.SS9.WoddenHouse}
              alt="WoddenHouse"
              className="w-full"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">
              Town House
            </p>
            <img
              src={IMAGES.L2.SS9.TownHouse}
              alt="TownHouse"
              className="w-full"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS9;
