import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const SS9 = () => {
  const speakData =
    "In Peru, people eat a traditional dessert called mazamorra morada. This dessert is dark purple because it’s made from purple corn! Peruvians cook the corn with sugar, spices, and fruit like green apples and pineapple. They they mix it with dried fruits. Another dessert in Peru is lucuma fruit. It’s a tropical fruit that is green on the outside and orange on the inside. Some people say lucuma fruit has a sweet, nutty flavor. They often use it to make desserts like ice cream, milkshakes, pastries, and cakes.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"DESSERTS"} />
        <div className="p-4">
          <p>
            In Peru, people eat a traditional dessert called mazamorra morada.
            This dessert is dark purple because it’s made from purple corn!
            Peruvians cook the corn with sugar, spices, and fruit like green
            apples and pineapple. They they mix it with dried fruits.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SS1.Desserts2}
            alt="Desserts"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <p>
            Another dessert in Peru is lucuma fruit. It’s a tropical fruit that
            is green on the outside and orange on the inside. Some people say
            lucuma fruit has a sweet, nutty flavor. They often use it to make
            desserts like ice cream, milkshakes, pastries, and cakes.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SS1.Desserts3}
            alt="Desserts"
            className="w-full"
          />
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Four/8">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/SocialStudies/Units/Four/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS9;