import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>List four natural resources that you remember.</p>
        </div>
        <div className="p-4">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-white sm:text-sm">1 .</span>
            </div>
            <input
              type="text"
              name="one"
              id="one"
              className="w-full rounded-md bg-zmh-gray-500 text-white outline-none pl-7 py-4 pr-12 sm:text-sm"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-white sm:text-sm">2 .</span>
            </div>
            <input
              type="text"
              name="two"
              id="two"
              className="w-full rounded-md bg-zmh-gray-500 text-white outline-none pl-7 py-4 pr-12 sm:text-sm"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-white sm:text-sm">3 .</span>
            </div>
            <input
              type="text"
              name="three"
              id="three"
              className="w-full rounded-md bg-zmh-gray-500 text-white outline-none pl-7 py-4 pr-12 sm:text-sm"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-white sm:text-sm">4 .</span>
            </div>
            <input
              type="text"
              name="four"
              id="four"
              className="w-full rounded-md bg-zmh-gray-500 text-white outline-none pl-7 py-4 pr-12 sm:text-sm"
            />
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Four/2">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;
