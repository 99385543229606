import React from 'react';
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Understanding story ideas "} />
        <div className="p-4">
          <p>Choose the correct answer .</p>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. Who invented the popsicle?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Eduard Haas " />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Frank Epperson" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="An eleven-year-old girl" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. When was the popsicle invented?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="1905" />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="1950" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="1904" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. What name was given to the popsicle first?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="Frozen drink" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Popsicle" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="European icicle" />
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. Who was selling wafer-like biscuits sprinkled with sugar?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="An eleven-year-old boy" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="A young man" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="An Arab vendor" />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Three/1">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;