import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Operations and Algebraic Thinking"} />

        <div className="p-4">
          <div className="grid grid-cols-4 text-center text-sm">
            <div className="border border-black">
              <b>The Problem</b>
              <p>The important parts are underlined</p>
            </div>
            <div className="border border-black">
              <p className="">&nbsp;</p>
              <p className="">&nbsp;</p>
              <p className="">+ or -</p>
              <p className="">&nbsp;</p>
              <p className="">&nbsp;</p>
            </div>
            <div className="border border-black col-span-2">
              <p>Number sentence and answer.</p>
            </div>
          </div>
          <div className="grid grid-cols-4 text-center text-sm">
            <div className="border border-black p-2">
              <p>
                I had{" "}
                <b>
                  <u>15</u>
                </b>{" "}
                pencils. I gave{" "}
                <b>
                  <u>5 away</u>
                </b>
                . How many have I got{" "}
                <b>
                  <u>left</u>
                </b>{" "}
                now?
              </p>
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"
                readOnly
                value={"-"}
                maxLength="1"
              />
            </div>
            <div className="border border-black col-span-2">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"
                readOnly
                value={"15-5=10"}
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4 text-center text-sm">
            <div className="border border-black p-2">
              <p>
                I had{" "}
                <b>
                  <u>12</u>
                </b>{" "}
                toy bats. Daddy gave me{" "}
                <b>
                  <u>3 more</u>
                </b>
                . How many toy bats have I got now?
              </p>
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"
                maxLength="1"
              />
            </div>
            <div className="border border-black col-span-2">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"                
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4 text-center text-sm">
            <div className="border border-black p-2">
              <p>
                I had{" "}
                <b>
                  <u>27</u>
                </b>{" "}
                books. Mummy{" "}
                <b>
                  <u>took away 7</u>
                </b>
                . How many books have I got{" "}
                <b>
                  <u>left</u>
                </b>{" "}
                now?
              </p>
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"
                maxLength="1"
              />
            </div>
            <div className="border border-black col-span-2">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"                
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4 text-center text-sm">
            <div className="border border-black p-2">
              <p>
                I had{" "}
                <b>
                  <u>20</u>
                </b>{" "}
                ducks. Daddy gave me{" "}
                <b>
                  <u>4 more</u>
                </b>
                . How many ducks have I got now?
              </p>
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"
                maxLength="1"
              />
            </div>
            <div className="border border-black col-span-2">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"                
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4 text-center text-sm">
            <div className="border border-black p-2">
              <p>
                We bought{" "}
                <b>
                  <u>10</u>
                </b>{" "}
                cakes. I{" "}
                <b>
                  <u>ate 7</u>
                </b>
                . How many cake have I got{" "}
                <b>
                  <u>left?</u>
                </b>
              </p>
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"
                maxLength="1"
              />
            </div>
            <div className="border border-black col-span-2">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"                
                maxLength="1"
              />
            </div>
          </div>
          <div className="grid grid-cols-4 text-center text-sm">
            <div className="border border-black p-2">
              <p>
                I got{" "}
                <b>
                  <u>8</u>
                </b>{" "}
                presents at my party. Grandma gave me{" "}
                <b>
                  <u>6 more</u>
                </b>
                . How many have I got now?
              </p>
            </div>
            <div className="border border-black">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"
                maxLength="1"
              />
            </div>
            <div className="border border-black col-span-2">
              <input
                type="text"
                className="outline-none w-full h-full text-center text-2xl"                
                maxLength="1"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/One/5">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/One/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA6;