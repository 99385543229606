import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const CCStandard = () => {
  return (
    <div>
      <div>
        <BannerTitle
          title={
            "What are the Common Core Statement Standards for English Language Arts?"
          }
        />
        <div className="flex px-8 py-4 md:px-20 lg:px-20">
          <ul className="list-disc font-bold">
            <li>
              The standards are a shared set of expectations for each grade
              level in the areas of reading, writing, speaking, listening and
              language. They define what students understand and be able to do.
              The standards are designed to be more rigorous and allow for
              students to justify their thinking. They reflect the knowledge
              that is necessary for success in college and beyond.
            </li>
            <li>
              As described in the Common Core State Standards, students who
              master the standards in reading, writing, speaking, and language
              as they advance through the grades will exhibit the following
              capabilities.
            </li>
          </ul>
        </div>
        <div className="flex px-8 py-4 md:px-20 lg:px-20 mb-20">
          <ul className="list-decimal font-bold">
            <li>They demonstrate independence.</li>
            <li>They build strong content knowledge.</li>
            <li>
              They respond to the varying demands of audience task, purpose, and
              discipline.
            </li>
            <li>They comprehend as well as critique.</li>
            <li>They value evidence.</li>
            <li>They use technology and digital strategically and capably.</li>
            <li>They come to understand other perspectives and cultures.</li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Four/1">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CCStandard;