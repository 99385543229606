import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E1 = () => {
  const speakData =
    "Casey woke up early. Mom and dad were asleep. She wanted to surprise them. Casey dressed herself. She combed her hair and went downstairs. Casey placed some sausages and eggs. She made toast with jam. Casey placed the food on a plate. She took it up the stairs to Mom and Dad. They were surprised to have breakfast in bed.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Casey’s Surprise"} />
        <div className="p-4">
          <p>Read the passage and answer the questions.</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.E1.CaseySuprise}
            alt="Casey’s Surprise"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Casey woke up early. Mom and dad were asleep. She wanted to surprise
            them. Casey dressed herself. She combed her hair and went
            downstairs.
          </p>
          <p>
            Casey placed some sausages and eggs. She made toast with jam. Casey
            placed the food on a plate. She took it up the stairs to Mom and
            Dad. They were surprised to have breakfast in bed.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/Two/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;