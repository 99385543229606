import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC12 = () => {
  const speakData = "Which one of these two kids should use more force? The boy needs to use more force because it is heavier. Heavy things are difficult to move. Light things are easy to move.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"More Force"} />
        <div className="p-4">
          <p>
            Which one of these two kids should use more force? The boy needs to
            use more force because it is heavier.
          </p>
        </div>
        <div className="p-4 md:p-12 lg:p-12 text-center">
          <img
            src={IMAGES.L1.SC12.HeavyMoreForce}
            alt="Froces"
            className="h-full w-full"
          />
          <p>Heavy things are difficult to move.</p>
        </div>
        <div className="p-4 md:p-12 lg:p-12 text-center mb-20">
          <img
            src={IMAGES.L1.SC12.LightForce}
            alt="Froces"
            className="h-full w-full"
          />
          <p>Light things are easy to move.</p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/One/11">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/One/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC12;
