import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC29 = () => {
  const speakData = "Vibrations make sound. Sound is energy. When we speak or sing, the vibrations travel from our throats into the air. People who are near us hear by turning the vibrations into sounds that their brains understand – like someone's words or a horn beeping. What’s interesting is, you can’t see sound coming, you can sometimes feel it, but how do we catch it? Let’s find out…";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Sound Is Energy"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Use tools and materials to design and build a device that uses light
            or sound to solve the problem of communicating over a distance.
          </p>
        </div>
        <div className="p-4">
          <p>Vibrations make sound. Sound is energy.</p>
        </div>
        <div className="p-4 mx-4 mt-4 bg-blue-300 text-white">
          <div>
            <p>
              When we speak or sing, the vibrations travel from our throats into
              the air.
            </p>
          </div>
          <div className="mt-4">
            <p>
              People who are near us hear by turning the vibrations into sounds
              that their brains understand – like someone's words or a horn
              beeping.
            </p>
          </div>
        </div>
        <div className="p-4">
          <p>
            What’s interesting is, you can’t see sound coming, you can sometimes
            feel it, but how do we catch it?
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.SoundisEnergy}
            alt="SoundisEnergy"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mx-4 mt-4 bg-orange-300 text-white">
          <div>
            <p>Let’s find out…</p>
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/27">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/One/30">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC29;
