import React from "react";
import { Link } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS14 = () => {
  const speakData =
    "Daddy finger, daddy finger, Where are you? Here I am, here I am. How do you do? Mommy finger, mommy finger, Where are you? Here I am, here I am. How do you do? Brother finger, brother finger, Where are you? Here I am, here I am. How do you do? Sister finger, sister finger, Where are you? Here I am, here I am. How do you do? Baby finger, baby finger, Where are you? Here I am, here I am. How do you do?";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Finger Family Song"} />
        <div className="flex p-8 md:p-12 lg:p-12 mb-20">
          <div className="break-all">
            <ul className="list-disc">
              <li>
                <p>Daddy finger, daddy finger,</p>
                <p>Where are you?</p>
                <p>Here I am, here I am.</p>
                <p> How do you do?</p>
              </li>
              <li>
                <p>Mommy finger, mommy finger,</p>
                <p>Where are you?</p>
                <p>Here I am, here I am.</p>
                <p>How do you do?</p>
              </li>
              <li>
                <p>Brother finger, brother finger,</p>
                <p>Where are you?</p>
                <p>Here I am, here I am.</p>
                <p>How do you do?</p>
              </li>
              <li>
                <p>Sister finger, sister finger,</p>
                <p>Where are you?</p>
                <p>Here I am, here I am.</p>
                <p>How do you do?</p>
              </li>
              <li>
                <p>Baby finger, baby finger,</p>
                <p>Where are you?</p>
                <p>Here I am, here I am.</p>
                <p>How do you do?</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/13">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/15">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS14;
