export const IMAGES = {
  L1: {
    E1: {
      SpotTheBarnDog: require("./L1/SpotTheBarnDog.png"),
    },
    E2: {
      Snail: require("./L1/Snail.png"),
    },
    E5: {
      LillyTheCat: require("./L1/LillyTheCat.png"),
    },
  },
  L2: {
    E1: {
      CaseySuprise: require("./L2/CaseySuprise.png"),
    },
    E3: {
      PlayingDressUp: require("./L2/PlayingDressUp.png"),
    },
    E5: {
      BillyGetLost: require("./L2/BillyGetLosts.png"),
    },
    E6: {
      Quiz: require("./L2/EggCow.png"),
    },
  },
  L3: {
    E1: {
      HideAndSeek: require("./L3/HideAndSeek.png"),
    },
    E3: {
      Hamsters: require("./L3/Hamsters.png"),
    },
    E5: {
      TheDrum: require("./L3/TheDrum.png"),
    },
  },
  L4: {
    E1: {
      Butterflies: require("./L4/Butterflies.png"),
    },
    E3: {
      LivingOnAFarm: require("./L4/LivingOnAFarm.png"),
    },
    E5: {
      GoingToDentist: require("./L4/GoingToDentist.png"),
    },
  },
};
