import React, { useState } from "react";
import { useDrop } from "react-dnd";
import ItemTypes from "./ItemType";
import Box from "./Box";

const data = [
  { id: 1, name: "Monday", type: ItemTypes.THING },
  { id: 2, name: "Mr.Kim", type: ItemTypes.PERSON },
  { id: 3, name: "America", type: ItemTypes.PLACE },
  { id: 4, name: "Judy", type: ItemTypes.PERSON },
  { id: 5, name: "Yangon", type: ItemTypes.PLACE },
  { id: 6, name: "November", type: ItemTypes.THING },
];

const Container = () => {
  const normalTarget = "flex flex-wrap bg-zmh-gray-500 p-2 m-2 text-white";
  const canDropTarget = "flex flex-wrap bg-green-500 p-2 m-2 text-white";

  const [items, setItems] = useState(data);
  const [target, setTarget] = useState([]);
  const [target2, setTarget2] = useState([]);
  const [target3, setTarget3] = useState([]);

  const [{ canDrop }, dropRef] = useDrop({
    accept: ItemTypes.PERSON,
    drop: (item) => addItemToBox(ItemTypes.PERSON, item.id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [{ canDrop2 }, dropRef2] = useDrop({
    accept: ItemTypes.PLACE,
    drop: (item) => addItemToBox(ItemTypes.PLACE, item.id),
    collect: (monitor) => ({
      isOver2: monitor.isOver(),
      canDrop2: monitor.canDrop(),
    }),
  });
  const [{ canDrop3 }, dropRef3] = useDrop({
    accept: ItemTypes.THING,
    drop: (item) => addItemToBox(ItemTypes.THING, item.id),
    collect: (monitor) => ({
      isOver3: monitor.isOver(),
      canDrop3: monitor.canDrop(),
    }),
  });

  const addItemToBox = (method, id) => {
    const itemList = items.filter((item) => id === item.id);    
    switch (method) {
      case "person":
        setTarget([...target, itemList[0]]);
        break;
      case "place":
        setTarget2([...target2, itemList[0]]);
        break;
      case "thing":
        setTarget3([...target3, itemList[0]]);
        break;
      default:
    }
    const newItemsList = items.filter((item) => id !== item.id);
    setItems(newItemsList);
  };

  return (
    <div>
      <div className="flex flex-wrap border-2 border-zmh-gray-500 p-2 m-2">
        {items.map((item) => (
          <Box
            draggable
            key={item.id}
            data={data}
            id={item.id}
            name={item.name}
            type={item.type}
          />
        ))}
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">PERSON</h6>
        <div className={canDrop ? canDropTarget : normalTarget} ref={dropRef}>
          {target.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">
            Person can be dropped here!
          </small>
        </div>
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">PLACE</h6>
        <div className={canDrop2 ? canDropTarget : normalTarget} ref={dropRef2}>
          {target2.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">
            Place can be dropped here!
          </small>
        </div>
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">THING</h6>
        <div className={canDrop3 ? canDropTarget : normalTarget} ref={dropRef3}>
          {target3.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">
            Thing can be dropped here!
          </small>
        </div>
      </div>
    </div>
  );
};

export default Container;
