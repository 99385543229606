import React from 'react';
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Hide and Seek"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. Draw a line from each family member to the object he or she
              lost.
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="to go to school" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="to make breakfast" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="to play with her friends" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">2. What did Jack want to do?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="Take a hide" />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="Play hide-and-seek" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="Fly a kite" />
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. Where did Jack find his mother’s tape?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="By the gate" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="On her desk" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Behind the drapes" />
            </div>
          </div>
        </div>       

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Three/1">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;