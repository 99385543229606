export const IMAGES = {
  L1: {
    WhatisSound: require("./L1/WhatisSound.png"),
    Vibration: require("./L1/Vibration.png"),
    Guitar: require("./L1/Guitar.png"),
    HearSound: require("./L1/HearSound.png"),
    SoundScientist: require("./L1/SoundScientist.png"),
    SoundScientist2: require("./L1/SoundScientist2.png"),
    Vibrations2: require("./L1/Vibrations2.png"),
    Vibrations3: require("./L1/Vibrations3.png"),
    Vibrations4: require("./L1/Vibrations4.png"),
    Quiz1: require("./L1/Quiz1.png"),
    LightSource: require("./L1/LightSource.png"),
    Shadows: require("./L1/Shadows.png"),
    TTO: require("./L1/TTO.png"),
    TTO2: require("./L1/TTO2.png"),
    Transparent: require("./L1/Transparent.png"),
    Translucent: require("./L1/Translucent.png"),
    Obaque: require("./L1/Obaque.png"),
    Thinking: require("./L1/Thinking.png"),
    Book: require("./L1/Book.png"),
    Glass: require("./L1/Glass.png"),
    TracingPaper: require("./L1/TracingPaper.png"),
    WhatDoYouThink: require("./L1/WhatDoYouThink.png"),
    Window: require("./L1/Window.png"),
    Sunglass: require("./L1/Sunglass.png"),
    Book2: require("./L1/Book2.png"),
    IceCubes: require("./L1/IceCubes.png"),
    GlassJar: require("./L1/GlassJar.png"),
    Coat: require("./L1/Coat.png"),
    SoundisEnergy: require("./L1/SoundisEnergy.png"),
    WhatYouNeed: require("./L1/WhatYouNeed.png"),
    WhatToDo1: require("./L1/WhatToDo1.png"),
    WhatToDo2: require("./L1/WhatToDo2.png"),
    WhatToDo3: require("./L1/WhatToDo3.png"),
    WhatToDo4: require("./L1/WhatToDo4.png"),
    WhatToDo5: require("./L1/WhatToDo5.png"),
    SomethingToThinkAbout: require("./L1/SomethingToThinkAbout.png"),
    Ear: require("./L1/Ear.png"),
    StringTelephone: require("./L1/StringTelephone.png"),
  },
  L2: {
    Bio1: require("./L2/Bio1.png"),
    Bio2: require("./L2/Bio2.png"),
    Bio3: require("./L2/Bio3.png"),
    Bio4: require("./L2/Bio4.png"),
    Bio5: require("./L2/Bio5.png"),
    Bio6: require("./L2/Bio6.png"),
    Pa1: require("./L2/Pa1.png"),
    Pa2: require("./L2/Pa2.png"),
    Pa3: require("./L2/Pa3.png"),
    Pa4: require("./L2/Pa4.png"),
    Pa5: require("./L2/Pa5.png"),
    Pa6: require("./L2/Pa6.png"),
    Pa7: require("./L2/Pa7.png"),
    Pa8: require("./L2/Pa8.png"),
    Pa9: require("./L2/Pa9.png"),
    Pa10: require("./L2/Pa10.png"),
    Pa11: require("./L2/Pa11.png"),
    Pa12: require("./L2/Pa12.png"),
    SF1: require("./L2/SF1.png"),
    SF2: require("./L2/SF2.png"),
    SF3: require("./L2/SF3.png"),
    SF4: require("./L2/SF4.png"),
    SF5: require("./L2/SF5.png"),
    SF6: require("./L2/SF6.png"),
    SF7: require("./L2/SF7.png"),
    SF8: require("./L2/SF8.png"),
    SF9: require("./L2/SF9.png"),
    SF10: require("./L2/SF10.png"),
    SF11: require("./L2/SF11.png"),
    SF12: require("./L2/SF12.png"),
    SF13: require("./L2/SF13.png"),
    SF14: require("./L2/SF14.png"),
    SF15: require("./L2/SF15.png"),
    SF16: require("./L2/SF16.png"),
    SF17: require("./L2/SF17.png"),
    SF18: require("./L2/SF18.png"),
    SF19: require("./L2/SF19.png"),
    SF20: require("./L2/SF20.png"),
    SF21: require("./L2/SF21.png"),
    SF22: require("./L2/SF22.png"),
    SF23: require("./L2/SF23.png"),
    SF24: require("./L2/SF24.png"),
    SF25: require("./L2/SF25.png"),
    SF26: require("./L2/SF26.png"),
    SF28: require("./L2/SF28.png"),
    SF29: require("./L2/SF29.png"),
    SF30: require("./L2/SF30.png"),
    SF31: require("./L2/SF31.png"),
    SF32: require("./L2/SF32.png"),
    SF33: require("./L2/SF33.png"),
    SF34: require("./L2/SF34.png"),
    SF35: require("./L2/SF35.png"),
    SF36: require("./L2/SF36.png"),
    Q1: require("./L2/Q1.png"),
    Q2: require("./L2/Q2.png"),
    Q3: require("./L2/Q3.png"),
    Q4: require("./L2/Q4.png"),
    Q5: require("./L2/Q5.png"),
    Q6: require("./L2/Q6.png"),
    Q7: require("./L2/Q7.png"),
  },
  L3: {
    L31: require("./L3/L31.png"),
    L32: require("./L3/L32.png"),
    L33: require("./L3/L33.png"),
    L34: require("./L3/L34.png"),
    L35: require("./L3/L35.png"),
    L36: require("./L3/L36.png"),
    L37: require("./L3/L37.png"),
    L38: require("./L3/L38.png"),
    L39: require("./L3/L39.png"),
    L310: require("./L3/L310.png"),
    L311: require("./L3/L311.png"),
    L312: require("./L3/L312.png"),
    L313: require("./L3/L313.png"),
    L314: require("./L3/L314.png"),
    L315: require("./L3/L315.png"),
    U1: require("./L3/U1.png"),
    U2: require("./L3/U2.png"),
    U3: require("./L3/U3.png"),
    U4: require("./L3/U4.png"),
    U5: require("./L3/U5.png"),
    U6: require("./L3/U6.png"),
    U7: require("./L3/U7.png"),
    U8: require("./L3/U8.png"),
    U9: require("./L3/U9.png"),
    U10: require("./L3/U10.png"),
    U11: require("./L3/U11.png"),
    U12: require("./L3/U12.png"),
    U13: require("./L3/U13.png"),
    U14: require("./L3/U14.png"),
    U15: require("./L3/U15.png"),
    UQ2: require("./L3/UQ2.png"),
    SolarSystem1: require("./L3/SolarSystem1.png"),
    SolarSystem2: require("./L3/SolarSystem2.png"),
    SolarSystem3: require("./L3/SolarSystem3.png"),
    SolarSystem4: require("./L3/SolarSystem4.png"),
    SolarSystem5: require("./L3/SolarSystem5.png"),
    SolarSystem6: require("./L3/SolarSystem6.png"),
    SolarSystem7: require("./L3/SolarSystem7.png"),
    SolarSystem8: require("./L3/SolarSystem8.png"),
    SolarSystem9: require("./L3/SolarSystem9.png"),
    SolarSystem10: require("./L3/SolarSystem10.png"),
    SolarSystem11: require("./L3/SolarSystem11.png"),
    SolarSystem12: require("./L3/SolarSystem12.png"),
    SolarSystem13: require("./L3/SolarSystem13.png"),
    SolarSystem14: require("./L3/SolarSystem14.png"),
    SolarSystem15: require("./L3/SolarSystem15.png"),
    SolarSystem16: require("./L3/SolarSystem16.png"),
    SolarSystem17: require("./L3/SolarSystem17.png"),
    SolarSystem18: require("./L3/SolarSystem18.png"),
    SolarSystem19: require("./L3/SolarSystem19.png"),
  },
  L4: {
    Design1: require("./L4/Design1.png"),
    Design2: require("./L4/Design2.png"),
    Design3: require("./L4/Design3.png"),
    Design4: require("./L4/Design4.png"),
    Design5: require("./L4/Design5.png"),
    Design6: require("./L4/Design6.png"),
  },
};