import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC1 = () => {
  const speakData =
    "Do you remember how plants and animals rely on the enviroment around them to provide them with everything they need? This means they have to live somewhere that has the right conditions to help them stay alive and well. Because different places around the world have different conditions, the plants and animals that live there are different too. These different animals plants all have speical ways to survive in their special habitats. We are going to find out about some of the different habitats around the world, and some of the different plants and animals that live there.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Biodiversity and Humans"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC1.WorldHabitats}
            alt="WorldHabitats"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.SC1.WH1} alt="WorldHabitats" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.SC1.WH2} alt="WorldHabitats" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.SC1.WH3} alt="WorldHabitats" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.SC1.WH4} alt="WorldHabitats" className="w-full" />
        </div>
        <div className="p-4 mb-24">
          <img src={IMAGES.L3.SC1.WH5} alt="WorldHabitats" className="w-full" />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC1;
