import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS1 = () => {

  return (
    <div>
      <div>
        <BannerTitle title={"Family"} />
        <div className="p-4">
          <p className="text-center font-bold underline">Table of Contents</p>
        </div>
        <div className="flex p-4">
          <div className="w-1/4">
            <p className="font-bold">Unit 1:</p>
          </div>
          <div className="w-3/4">
            <p className="font-bold">Learning about Family</p>
            <p>What is a family?</p>
            <p>A family tree</p>
            <p>Things to do with the family</p>
            <p>Every kinds of family</p>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/One/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1;
