import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC5 = () => {
  const speakData =
    "A toy is an item that entertains, comforts or interests its user. There are many different types of toys and they all have different uses and purposes. Teddy bears are cuddly toys that children like to hug and hold. They need to be fluffy and soft. They should not have small parts that can break off and be swallowed. They should not have sharp or pointed parts that children can hurt themselves on. Teddy bears should look friendly and not scary. Toy cars need to have moving wheels so they can be pushed along. They need to be decorated or designed to look like real cars, including being made to scale and have the same body shape and parts as a real car. Toy cars can have moving parts such as doors that open and close. A yo-yo must be round and smooth to allow it to move up and down in a smooth motion. It must have an axle to allow it to travel up and down the string. A yo-yo must be bright, colourful and visually appealing. It must have a strong string that does not weaken or bend.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Toys"} />
        <div className="p-4">
          <p>
            A toy is an item that entertains, comforts or interests its user.
            There are many different types of toys and they all have different
            uses and purposes.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Design3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <p className="text-center font-bold">Toy design</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Design4} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="p-8">
          <ul className="list-disc">
            <li>
              Teddy bears are cuddly toys that children like to hug and hold.
            </li>
            <li>They need to be fluffy and soft.</li>
            <li>
              They should not have small parts that can break off and be
              swallowed.
            </li>
            <li>
              They should not have sharp or pointed parts that children can hurt
              themselves on.
            </li>
            <li>Teddy bears should look friendly and not scary.</li>
          </ul>
        </div>
        <div className="p-4">
          <p className="text-center font-bold">Toy design</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Design5} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="p-8">
          <ul className="list-disc">
            <li>
              Toy cars need to have moving wheels so they can be pushed along.
            </li>
            <li>
              They need to be decorated or designed to look like real cars,
              including being made to scale and have the same body shape and
              parts as a real car.
            </li>
            <li>
              Toy cars can have moving parts such as doors that open and close.
            </li>
          </ul>
        </div>
        <div className="p-4">
          <p className="text-center font-bold">Toy design</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Design6} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="p-8">
          <ul className="list-disc">
            <li>
              A yo-yo must be round and smooth to allow it to move up and down
              in a smooth motion.
            </li>
            <li>
              It must have an axle to allow it to travel up and down the string.
            </li>
            <li>A yo-yo must be bright, colourful and visually appealing.</li>
            <li>It must have a strong string that does not weaken or bend.</li>
          </ul>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Four/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC5;
