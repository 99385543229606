import React from "react";

const UnitsButton = ({title}) => {
  return (
    <div>
      <button className="bg-zmh-lightblue-500 hover:text-zmh-gray-700 text-white font-bold w-full py-8 px-4 mt-4 rounded-xl shadow-xl">
        {title}
      </button>
    </div>
  );
};

export default UnitsButton;
