import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA13 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />

        <div className="p-4">
          <img src={IMAGES.L4.MA11.Bar3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <div>
            <p>1) How many books did John and Tom read together ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>2) How many books were read in total ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="2"
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>3) Who read the maximum number of books ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="4"
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>4) Who read the least number of books ?</p>
          </div>
          <div>
            <input
              type="text"
              className="outline-none w-full text-center text-2xl border-black border rounded-full"
              maxLength="6"
            />
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/12">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Four/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA13;