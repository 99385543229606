import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>
            Put an “X” in the box of the correct column to describe the traits
            as either: inherited, learned
          </p>
        </div>
        <div className="flex px-2 pt-4">
          <div className="w-3/5 p-2 border border-black"></div>
          <div className="w-1/5 p-2 text-center border border-black">
            Inherited Trait
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            Learned Trait
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">Blue eyes</div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Dogs having four legs
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">Speaking Spanish</div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Breaking your arm at recess
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">Being kind</div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Growing to be 6 feet tall
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Cat having long whiskers
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Getting a scar on your forehead
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Writing your full name on paper
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">Long curly hair</div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">Brown skin</div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">Spotted fur</div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Knowing how to walk
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            A chameleon's tail being cut off by a predator
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Dog knowing where his food bowl
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">
            Riding a skateboard
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="flex px-2">
          <div className="w-3/5 p-2 border border-black">Straight hair</div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
          <div className="w-1/5 p-2 text-center border border-black">
            <input
              className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full uppercase font-bold text-center"
              type="text"
              maxLength={1}
            />
          </div>
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Three/2">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Three/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;
