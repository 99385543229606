import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E12 = () => {
  const normalStyle =
    "w-full px-4 py-5 mb-2 bg-gray-200 hover:bg-gray-300 text-gray-800 text-lg font-medium rounded-2xl";
  const correctStyle =
    "w-full px-4 py-5 mb-2 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";
  const errorStyle =
    "w-full px-4 py-5 mb-2 bg-red-600 hover:bg-red-700 text-white text-lg font-medium rounded-2xl";
  const showCorrectSytle =
    "w-full px-4 py-5 mb-2 opacity-50 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "2":
        document.getElementById("1").disabled = true;
        document.getElementById("2").className = correctStyle;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "3":
        document.getElementById("1").disabled = true;
        document.getElementById("2").className = showCorrectSytle;
        document.getElementById("2").disabled = true;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        break;
      default:
      // code block
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Read each groups of words."} />

        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Choose the group that is a complete sentence.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">2.</Typography>
        </div>

        <div className="bg-white p-4 mb-20">
          <button onClick={checkAnswer} id="1" className={normalStyle}>
            go mom the to market
          </button>
          <button onClick={checkAnswer} id="2" className={normalStyle}>
            Have you seen my book ?
          </button>
          <button onClick={checkAnswer} id="3" className={normalStyle}>
            supermarket go
          </button>
        </div>
        <br />
        <br />
        <br />
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/11">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Two/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E12;
