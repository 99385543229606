import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS2 = () => {
  const speakData =
    "Cave Paintings. A very long time ago, cave paintings were used by many cultures, such as Australian Aborigines, to communicate with other tribes who visited their site. They used rocks and dirt mixed with water to make the paint and used their fingers or sticks to draw with.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Very Early Types of Communication"} />
        <div className="p-4">
          <p className="text-center font-bold text-orange-700">
            Cave Paintings
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.CavePainting}
            alt="CavePainting"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <p>
            A very long time ago, cave paintings were used by many cultures,
            such as Australian Aborigines, to communicate with other tribes who
            visited their site. They used rocks and dirt mixed with water to
            make the paint and used their fingers or sticks to draw with.
          </p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Four/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Four/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
