import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Scale the Synonym Slope"} />
        <div className="p-4">
          <p>
            <strong>Synonyms</strong> are words that have almost the same
            meaning. <strong>Tired</strong> and <strong>sleepy</strong> are
            synonyms. <strong>Talk</strong> and <strong>speak</strong> are
            synonyms.
          </p>
        </div>
        <div className="p-4">
          <p>
            <strong>Directions:</strong> Read the word. Find its synonym on the
            hill. Fill the synonym on the line.
          </p>
        </div>
        <div className="flex p-4 border-2 border-blue-500 rounded-lg justify-between m-4">
          <div className="">Wet</div>
          <div className="">Big</div>
          <div className="">Happy</div>
          <div className="">Over</div>
          <div className="">Small</div>
          <div className="">Start</div>
        </div>
        <div className="p-4 mb-20">
          <div className="flex mb-2">
            <div className="flex justify-center w-1/2 mx-2">
              <p>1. glad</p>
            </div>
            <div className="w-1/2 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="flex justify-center w-1/2 mx-2">
              <p>2. little</p>
            </div>
            <div className="w-1/2 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="flex justify-center w-1/2 mx-2">
              <p>3. begin</p>
            </div>
            <div className="w-1/2 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="flex justify-center w-1/2 mx-2">
              <p>4. above</p>
            </div>
            <div className="w-1/2 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="flex justify-center w-1/2 mx-2">
              <p>5. damp</p>
            </div>
            <div className="w-1/2 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="flex justify-center w-1/2 mx-2">
              <p>6. large</p>
            </div>
            <div className="w-1/2 mx-2">
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Two/5">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Two/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;