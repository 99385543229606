import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4">
          <p>
            Use words in the word bank to form a two-syllable compound word.
            Each word become a two-syllable compound word. Drag the words to
            make compound words.
          </p>
        </div>
        <div className="p-2 m-2 border-2 border-black">
          <div className="flex justify-between">
            <p>stick</p>
            <p>plane</p>
            <p>boy</p>
            <p>fish</p>
          </div>
          <div className="flex justify-between">
            <p>shell</p>
            <p>ball</p>
            <p>melon</p>
            <p>room</p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p>
              1. air {" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
          <div className="p-4">
            <p>
              2. basket{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
          <div className="p-4">
            <p>
              3. star{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
          <div className="p-4">
            <p>
              4. bath{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
          <div className="p-4">
            <p>
              5. cow{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
          <div className="p-4">
            <p>
              6. sea{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
          <div className="p-4">
            <p>
              7. drum{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
          <div className="p-4 mb-24">
            <p>
              8. water{" "}
              <input
                type="text"
                className="outline-none w-16 px-2 text-xl border-b-2 border-b-blue-500"
                maxLength={5}
              />
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Three/5">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E6;