import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA7 = () => {
  const speakData =
    "We call the long hand the minute hand and we call the short hand the hour hand. When the minute hand is pointing to the number 12 on the clock face we say o’clock . The short hand is pointing to tell us what hour we are in. So the time on the clock is 3 o’clock.This is called digital clock. The digit on the left will tell us hour. The digit on the right will tell us minute.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Tell and Write Time"} />
        <div className="p-4">
          <p>Tell and Write Time</p>
        </div>
        <div>
          <img src={IMAGES.L4.MA7.TIME} alt="" className="w-full" />
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg mt-10">
          <p>
            We call the long hand the minute hand and we call the short hand the
            hour hand. When the minute hand is pointing to the number 12 on the
            clock face we say o’clock . The short hand is pointing to tell us
            what hour we are in. So the time on the clock is 3 o’clock.This is
            called digital clock. The digit on the left will tell us hour. The
            digit on the right will tell us minute.
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/Four/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA7;