import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA11 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <div>
            <p>
              1) Emma has a straw which is 20 cm long. Harry has a straw which is 5
              cm shorter than Emma's straw. How long is Harry's straw?
            </p>
          </div>
          <div className="flex justify-center mt-6">
            <input
              type="text"
              className="outline-none w-full rounded-full text-center text-lg border-2 border-black"
              readOnly
              value={"20 - 5 = 15"}              
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>
              2) Robert has a chocolate bar which is 11 cm long. Mia has a chocolate bar which is 6
              cm longer than Robert's chocolate bar. How long is Mia's chocolate bar?
            </p>
          </div>
          <div className="flex justify-center mt-6">
            <input
              type="text"
              className="outline-none w-full rounded-full text-center text-lg border-2 border-black"                          
            />
          </div>
        </div>
        <div className="p-4">
          <div>
            <p>
              3) Adam has a ribbon which is 25 cm long. Rihanna has a ribbon which is 7 cm shorter than Adam's ribbon. How long is Rihanna's ribbon?
            </p>
          </div>
          <div className="flex justify-center mt-6">
            <input
              type="text"
              className="outline-none w-full rounded-full text-center text-lg border-2 border-black"                           
            />
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/10">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Three/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA11;
