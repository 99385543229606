import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS9 = () => {
  const speakData =
    "Radio. Radios send messages by radio waves in the air, instead of through wires. In the early 1920s, people spent hours listening to stations that were broadcasting news, music, sports, drama and variety shows.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Modern Communication with Machines"} />
        <div className="p-4">
          <p className="text-center font-bold text-purple-700">Radio</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Radio} alt="Radio" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Radios send messages by radio waves in the air, instead of through
            wires. In the early 1920s, people spent hours listening to stations
            that were broadcasting news, music, sports, drama and variety shows.
          </p>
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Four/8">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Four/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS9;
