import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS25 = () => {
  const normalStyle =
    "w-full px-4 py-5 mb-2 bg-gray-200 hover:bg-gray-300 text-gray-800 text-lg font-medium rounded-2xl";
  const correctStyle =
    "w-full px-4 py-5 mb-2 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";
  const errorStyle =
    "w-full px-4 py-5 mb-2 bg-red-600 hover:bg-red-700 text-white text-lg font-medium rounded-2xl";
  const showCorrectSytle =
    "w-full px-4 py-5 mb-2 opacity-50 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-2xl";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "2":        
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      case "3":
        document.getElementById("3").className = errorStyle;
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        document.getElementById("3").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Choose the correct answer."} />
        <div className="flex mt-3 p-3 text-2xl text-center justify-center">
          <strong>This is a </strong>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 justify-center">
          <div className="flex p-4 md:p-12 lg:p-12">
            <img
              src={IMAGES.L2.SS25.QDinningRoom}
              alt="Dinningroom"
              className="h-full w-full"
            />
          </div>
        </div>

        <div className="bg-white p-4 mb-20">
          <button onClick={checkAnswer} id="1" className={normalStyle}>
            Dinning Room
          </button>
          <button onClick={checkAnswer} id="2" className={normalStyle}>
            Bedroom
          </button>
          <button onClick={checkAnswer} id="3" className={normalStyle}>
            Bathroom
          </button>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/24">
            <BackButton />
          </Link>

          <Link to="/KG/SocialStudies/Units/Two/26">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS25;
