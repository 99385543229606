import React from "react";
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Living On A Farm"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. What is the main idea of the text?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Children play at the farm." />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="People love to go the farm." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="Children learn about the farm."
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. What can children do with animals on the farm?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Children can feed the goats." />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Children can milk a cow." />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="Children can collect the chicken eggs."
              />
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. What can children do with fruits and vegetables on the farm?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="Children can pick the fruits and vegetables."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="Children can eat the fruits and vegetables."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="Children can buy the fruits and vegetables on the farm."
              />
            </div>
          </div>
        </div>        

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/3">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Four/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E4;
