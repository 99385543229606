import React from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Container from "./DragNDrop_One/Container";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Drag the words into the correct column."} />
        <div className="flex p-6">Drag the words to the correct boxes</div>
      </div>
      <div className="mb-24">
        <DndProvider backend={TouchBackend}>
          {/* Here, render a component that uses DND inside it */}
          <Container />
        </DndProvider>
      </div>
      <div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Three/3">
            <BackButton />
          </Link>
          <Link to="/G2/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;