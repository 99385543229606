import React from 'react';
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Casey’s Surprise"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. Why did Casey wake up early?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="to go to school" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="to make breakfast" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="to play with her friends" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">2. What food did she bring?</Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA4" name="Q2" label="milk" />
            </div>
            <div className="flex-row">
              <Radio id="QA5" name="Q2" label="sausage" />
            </div>
            <div className="flex-row">
              <Radio id="QA6" name="Q2" label="banana" />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. Who is the main character?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="Casey" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="Mom" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="Dad" />
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              4. What is the setting, or where the story takes place?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA7" name="Q3" label="house" />
            </div>
            <div className="flex-row">
              <Radio id="QA8" name="Q3" label="school" />
            </div>
            <div className="flex-row">
              <Radio id="QA9" name="Q3" label="shop" />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/1">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Two/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E2;