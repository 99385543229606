import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA10 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("3").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("3").className = showCorrectSytle;
        document.getElementById("4").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("7").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("7").className = showCorrectSytle;
        document.getElementById("8").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("10").className = showCorrectSytle;
        document.getElementById("9").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("10").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "11":
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("11").className = errorStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":
        document.getElementById("12").className = correctStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "13":
        document.getElementById("13").className = correctStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        break;
      case "14":
        document.getElementById("13").className = showCorrectSytle;
        document.getElementById("14").className = errorStyle;
        document.getElementById("13").disabled = true;
        document.getElementById("14").disabled = true;
        break;
      case "15":
        document.getElementById("16").className = showCorrectSytle;
        document.getElementById("15").className = errorStyle;
        document.getElementById("15").disabled = true;
        document.getElementById("16").disabled = true;
        break;
      case "16":
        document.getElementById("16").className = correctStyle;
        document.getElementById("15").disabled = true;
        document.getElementById("16").disabled = true;
        break;
      case "17":
        document.getElementById("17").className = correctStyle;        
        document.getElementById("17").disabled = true;
        document.getElementById("18").disabled = true;
        break;
      case "18":
        document.getElementById("17").className = showCorrectSytle;
        document.getElementById("18").className = errorStyle;
        document.getElementById("17").disabled = true;
        document.getElementById("18").disabled = true;
        break;
      case "19":
        document.getElementById("20").className = showCorrectSytle;
        document.getElementById("19").className = errorStyle;
        document.getElementById("19").disabled = true;
        document.getElementById("20").disabled = true;
        break;
      case "20":
        document.getElementById("20").className = correctStyle;
        document.getElementById("19").disabled = true;
        document.getElementById("20").disabled = true;
        break;
      case "21":
        document.getElementById("21").className = correctStyle;
        document.getElementById("21").disabled = true;
        document.getElementById("22").disabled = true;
        break;
      case "22":
        document.getElementById("21").className = showCorrectSytle;
        document.getElementById("22").className = errorStyle;
        document.getElementById("21").disabled = true;
        document.getElementById("22").disabled = true;
        break;
      case "23":
        document.getElementById("24").className = showCorrectSytle;
        document.getElementById("23").className = errorStyle;
        document.getElementById("23").disabled = true;
        document.getElementById("24").disabled = true;
        break;
      case "24":
        document.getElementById("24").className = correctStyle;
        document.getElementById("23").disabled = true;
        document.getElementById("24").disabled = true;
        break;
      default:
    }
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Compare numbers"} />
        <div className="p-4">
          <p>1.Select the larger number.</p>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                9
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                6
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                2
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                1
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                8
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                0
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                7
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                5
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="9" className={normalStyle}>
                3
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="10" className={normalStyle}>
                13
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="11" className={normalStyle}>
                11
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="12" className={normalStyle}>
                13
              </button>
            </div>
          </div>
        </div>
        <div className="p-4">
          <p>2.Select the smaller number.</p>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="13" className={normalStyle}>
                7
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="14" className={normalStyle}>
                8
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="15" className={normalStyle}>
                3
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="16" className={normalStyle}>
                1
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="17" className={normalStyle}>
                4
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="18" className={normalStyle}>
                6
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="19" className={normalStyle}>
                17
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="20" className={normalStyle}>
                11
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="21" className={normalStyle}>
                10
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="22" className={normalStyle}>
                20
              </button>
            </div>
          </div>
          <div className="w-1/3 border-2 border-blue-700 rounded-2xl m-2">
            <div className="m-2">
              <button onClick={checkAnswer} id="23" className={normalStyle}>
                9
              </button>
            </div>
            <div className="m-2">
              <button onClick={checkAnswer} id="24" className={normalStyle}>
                1
              </button>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/9">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/One/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA10;
