import React, { useState } from "react";
import { Checkbox, Input } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E9 = () => {
  const [isDis, setIsDis] = useState(true);
  const changeNext = () => {
    setIsDis(!isDis);
  };
  return (
    <div>
      <div>
        <BannerTitle title={"The first letter in a sentence is a capital letter. Write each sentence correctly."} />
        <div className="p-10">
          <div>
            <p>1. will Wendy run in the race ?</p>
          </div>
          <div className="w-full mb-10">
            <Input variant="static" />
          </div>
          <div>2. he is drinking milk.</div>
          <div className="w-full mb-10">
            <Input variant="static" />
          </div>
          <div>3. my mom is a doctor.</div>
          <div className="w-full mb-10">
            <Input variant="static" />
          </div>
          <div>4. can you open the door</div>
          <div className="w-full mb-10">
            <Input variant="static" />
          </div>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can capitalize the first word in a sentence."
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/8">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Three/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E9;
