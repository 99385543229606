import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4 md:p-12 lg:p-12 text-center">
          <p className="text-2xl font-bold">Let's Eat!</p>
        </div>
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            <strong>Directions: </strong>Look at the diet of each animal.
            Determine whether the animal is a
            <strong> canivore, herbivore, or omnivore</strong>. Write the name
            of the animals in the correct circle.
          </p>
        </div>
        <div className="flex p-4">
          <div className="w-1/2">
            <img src={IMAGES.L3.SC6.EatQuiz1} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/2">
            <img src={IMAGES.L3.SC6.EatQuiz2} alt="Quiz" className="w-full" />
          </div>
        </div>
        <div className="mb-40">
          <div className="mb-10">
            <div className="w-60 h-60 bg-blue-500 rounded-full mx-auto p-8">
              <div>
                <p className="font-bold text-white text-xl text-center mb-4">
                  Canivore
                </p>
                <input
                  type="text"
                  className="bg-white w-full px-2 mb-4 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
                <input
                  type="text"
                  className="bg-white w-full px-2 mb-4 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
                <input
                  type="text"
                  className="bg-white w-full px-2 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="mb-10">
            <div className="w-60 h-60 bg-green-500 rounded-full mx-auto p-8">
              <div>
                <p className="font-bold text-white text-xl text-center mb-4">
                  Herbivore
                </p>
                <input
                  type="text"
                  className="bg-white w-full px-2 mb-4 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
                <input
                  type="text"
                  className="bg-white w-full px-2 mb-4 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
                <input
                  type="text"
                  className="bg-white w-full px-2 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="mb-10">
            <div className="w-60 h-60 bg-purple-500 rounded-full mx-auto p-8">
              <div>
                <p className="font-bold text-white text-xl text-center mb-4">
                  Omnivore
                </p>
                <input
                  type="text"
                  className="bg-white w-full px-2 mb-4 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
                <input
                  type="text"
                  className="bg-white w-full px-2 mb-4 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
                <input
                  type="text"
                  className="bg-white w-full px-2 rounded-md focus:outline-none focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Three/5">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/Three/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC6;
