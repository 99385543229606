import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { Typography, Radio } from "@material-tailwind/react";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS8 = () => {
  return (
    <div>
      <BannerTitle title={"Land form scene"} />
      <div className="p-4">
        <p>Choose the correct answers.</p>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            1. I am a very tall part of the earth. I am taller than a hill. I
            have lots of rocks or tree. I can have a pointed or round top.
          </Typography>
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.LFQ1} alt="Quiz" />
        </div>
        <div className="p-4">
          <p>What am I?</p>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA1" name="Q1" label="Mountain" />
          </div>
          <div className="flex-row">
            <Radio id="QA2" name="Q1" label="Valley" />
          </div>
          <div className="flex-row">
            <Radio id="QA3" name="Q1" label="Hill" />
          </div>
        </div>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            2. I am the area between mountains or hills. Sometimes the river
            flows on me.
          </Typography>
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.LFQ2} alt="Quiz" />
        </div>
        <div className="p-4">
          <p>What am I?</p>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA4" name="Q2" label="Hill" />
          </div>
          <div className="flex-row">
            <Radio id="QA5" name="Q2" label="Valley" />
          </div>
          <div className="flex-row">
            <Radio id="QA6" name="Q2" label="Mountain" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            3. I am the flat landform on the surface of the Earth. You can build
            a city or grow crops on me.
          </Typography>
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.LFQ3} alt="Quiz" />
        </div>
        <div className="p-4">
          <p>What am I?</p>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Hill" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Mountain" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Plains" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            4. I am the piece of land entirely surrounded by water. You can find
            me in the Ocean or sea.{" "}
          </Typography>
        </div>
        <div className="px-4">
          <img src={IMAGES.L3.LFQ4} alt="Quiz" />
        </div>
        <div className="px-4">
          <p>What am I?</p>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Island" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Hill" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Plains" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            5. I am very flat on the top, like a plate or table . I can be a
            mountain or a hill with flat top.
          </Typography>
        </div>
        <div className="px-4">
          <img src={IMAGES.L3.LFQ5} alt="Quiz" />
        </div>
        <div className="p-4">
          <p>What am I?</p>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Mountain" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Plateau" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Plains" />
          </div>
        </div>
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/Three/7">
          <BackButton />
        </Link>

        <Link to="/G1/SocialStudies/Units">
          <HomeButton />
        </Link>
      </div>
    </div>
  );
};

export default SS8;