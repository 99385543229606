import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA17 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div>
          <div className="p-4">
            <img src={IMAGES.L1.MA17.QQQ1} alt="" className="" />
          </div>
          <div className="grid grid-cols-5 gap-4 p-4 text-center">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"
                readOnly
                value={"5"}
                maxLength="2"
              />
            </div>
            <div className="font-bold text-2xl">
              <p>x</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"
                readOnly
                value={"2"}
                maxLength="2"
              />
            </div>
            <div className="font-bold text-3xl">
              <p>=</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"
                readOnly
                value={"10"}
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <img src={IMAGES.L1.MA17.QQQ2} alt="" className="" />
          </div>
          <div className="grid grid-cols-5 gap-4 p-4 text-center">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"                
                maxLength="2"
              />
            </div>
            <div className="font-bold text-2xl">
              <p>x</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"                
                maxLength="2"
              />
            </div>
            <div className="font-bold text-3xl">
              <p>=</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"                
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="p-4">
            <img src={IMAGES.L1.MA17.QQQ3} alt="" className="" />
          </div>
          <div className="grid grid-cols-5 gap-4 p-4 text-center">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"                
                maxLength="2"
              />
            </div>
            <div className="font-bold text-2xl">
              <p>x</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"                
                maxLength="2"
              />
            </div>
            <div className="font-bold text-3xl">
              <p>=</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-lg border-2 border-black"                
                maxLength="2"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/One/16">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA17;
