import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA3 = () => {
  const speakData =
    "The Front is called the face of the clock or the dial. minute and hour hand. the short hand points to the hour. the long hand points to the minutes. Count by 5's to find out the minute. eight hour fifteen minutes. two hour thirty minutes. five hour.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Work with time and money"} />        
        <div className="p-4">
          <img src={IMAGES.L4.MA3.Analog} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA3.Digital} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA3.Time1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA3.Time2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA3.Time3} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA3.Time4} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.MA3.Time5} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Four/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA3;