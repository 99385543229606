import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA12 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Marie baked cookies for her friends.</p>
          <p>Read the chart and answer the questions.</p>
        </div>
        <div className="p-4">
          <div>
            <img src={IMAGES.L4.MA12.QCookie} alt="" className="w-full" />
          </div>
          <div className="mb-6">
            <div>
              <p>1. How many cookies did Margie receive?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="1"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>2. Who received 5 cookies?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="5"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>3. Who received the most cookies?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="5"
              />
            </div>
          </div>
          <div className="mb-6">
            <div>
              <p>4. Who received the least cookies?</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border rounded-full"
                maxLength="5"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Four/11">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Four/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA12;
