import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Family4IMG from "../../assets/L1/Ana4.png";
import Family5IMG from "../../assets/L1/Ana5.png";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS10 = () => {
  const speakData =
    "My father has got a sister. Her name is Sarah and she is my Aunt. My mother has got two brothers. Their names are Richard and William, they are my Uncles. I also got my grandparents, my cousins Jason and Kattie. We have got a pat called Jackie, he is my favorite dog. I love my family and my pet.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"This is my family"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={Family4IMG} alt="Family" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            My father has got a sister. Her name is Sarah and she is my Aunt. My
            mother has got two brothers. Their names are Richard and William,
            they are my Uncles. I also got my grandparents, my cousins Jason and
            Kattie.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={Family5IMG} alt="Family" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            We have got a pat called Jackie, he is my favorite dog. I love my
            family and my pet.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS10;
