import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const CC = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Common Core State Standards"} />
        <div className="p-4">
          <p>
            In Grade 1, instructional time should focus on four critical areas:
            (1) developing understanding of addition, subtraction, and
            strategies for addition and subtraction within 20; (2) developing
            understanding of whole number relationships and place value,
            including grouping in tens and ones; (3) developing understanding of
            linear measurement and measuring lengths as iterating length units;
            and (4) reasoning about attributes of, and composing and decomposing
            geometric shapes.
          </p>
        </div>
        <div className="p-8 mb-20">
          <ul className="list-decimal">
            <li>
              Students develop strategies for adding and subtracting whole
              numbers based on their prior work with small numbers. They use a
              variety of models, including discrete objects and length-based
              models (e.g., cubes connected to form lengths), to model add-to,
              take-from, put-together, take-apart, and compare situations to
              develop meaning for the operations of addition and subtraction,
              and to develop strategies to solve arithmetic problems with these
              operations. Students understand connections between counting and
              addition and subtraction (e.g., adding two is the same as counting
              on two). They use properties of addition to add whole numbers and
              to create and use increasingly sophisticated strategies based on
              these properties (e.g., “making tens”) to solve addition and
              subtraction problems within 20. By comparing a variety of solution
              strategies, children build their understanding of the relationship
              between addition and subtraction.
            </li>
            <li>
              Students develop, discuss, and use efficient, accurate, and
              generalizable methods to add within 100 and subtract multiples of
              10. They compare whole numbers (at least to 100) to develop
              understanding of and solve problems involving their relative
              sizes. They think of whole numbers between 10 and 100 in terms of
              tens and ones (especially recognizing the numbers 11 to 19 as
              composed of a ten and some ones). Through activities that build
              number sense, they understand the order of the counting numbers
              and their relative magnitudes.
            </li>
            <li>
              Students develop an understanding of the meaning and processes of
              measurement, including underlying concepts such as iterating (the
              mental activity of building up the length of an object with
              equal-sized units) and the transitivity principle for indirect
              measurement.1
            </li>
            <li>
              Students compose and decompose plane or solid figures (e.g., put
              two triangles together to make a quadrilateral) and build
              understanding of part-whole relationships as well as the
              properties of the original and composite shapes. As they combine
              shapes, they recognize them from different perspectives and
              orientations, describe their geometric attributes, and determine
              how they are alike and different, to develop the background for
              measurement and for initial understandings of properties such as
              congruence and symmetry.
            </li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/One/1">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CC;