export const IMAGES = {
  L1: {
    Bag: require("./One/Bag.png"),
    Bear: require("./One/Bear.png"),
    Bell: require("./One/Bell.jpg"),
    Car: require("./One/Car.png"),
    Elephant: require("./One/Elephant.png"),
    Fan: require("./One/Fan.png"),
    GroceryStore: require("./One/GroceryStore.png"),
    Mouse: require("./One/Mouse.jpg"),
    Outopus: require("./One/Outopus.png"),
    Pan: require("./One/Pan.png"),
    Pig: require("./One/Pig.jpg"),
    Sock: require("./One/Sock.png"),
  },
  L2: {},
  L3: {},
  L4: {},
};
