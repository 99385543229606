import React, { useState } from "react";
import { PacmanLoader } from "react-spinners";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS11 = () => {
  const [loaded, setLoaded] = useState(false);
  const onLoad = () => {
    setLoaded(true);
  }

  return (
    <div>
      {!loaded && (
        <div className="flex h-screen justify-center items-center">
          <div className="-m-10">
            <PacmanLoader color="#46A5AE" />
          </div>
        </div>
      )}
      <div className={loaded ? "" : "hidden"}>
        <BannerTitle title={"Some people live in…"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="w-full mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Igloo</p>
            <img src={IMAGES.L2.SS11.iGloo} alt="iGloo" />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Mud House</p>
            <img
              src={IMAGES.L2.SS11.MudHouse}
              alt="MudHouse"
              className="w-full"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Farm House</p>
            <img
              src={IMAGES.L2.SS11.FarmHouse}
              alt="FarmHouse"
              className="w-full"
            />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <div className="mb-2 text-center shadow-2xl">
            <p className="w-full p-4 bg-zmh-gray-500 text-white">Caravan</p>
            <img
              onLoad={onLoad}
              src={IMAGES.L2.SS11.Caravan}
              alt="Caravan"
              className="w-full"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/10">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS11;
