import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC11 = () => {
  const speakData =
    "Plants make their own food. Herbivores eat the plants and Carnivores and omnivores eat them. When the animals die and decomposers break them down into soil. New plants use these nutrients in the soil to grow again.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"What is food chain?"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC11.WhatisFoodChain}
            alt="WhatisFoodChain"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <p>
            <strong>Plants</strong> make their
            <strong> own food. Herbivores</strong> eat the
            <strong> plants</strong> and <strong>Carnivores</strong> and
            <strong> omnivores</strong> eat them. When the animals die and
            decomposers break them down into <strong>soil</strong>.
            <strong> New plants </strong>
            use these nutrients in the soil to grow again.
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Three/10">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/Three/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC11;