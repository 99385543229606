import React from "react";

const NextButton = ({saveData}) => {
  return (
    <div>
      <button onClick={saveData} className="bg-zmh-lightblue-600 hover:bg-zmh-lightblue-900 p-4 rounded-full text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
          />
        </svg>
      </button>
    </div>
  );
};

export default NextButton;
