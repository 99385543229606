import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS5 = () => {
  const speakData =
    "Landforms Near Water. Maybe you have been to the beach before. That strip of land right next to the water is called a coastline. Coastlines are always changing because waves move sand and soil onto the coast and then back out to sea again. The water also carves out different shapes in the cliffs on the coastline. A landform that is completely surrounded by water is called an island. Islands can be big, like the continent of Australia and country Great Britain, or small like an island in a lake. Many islands are formed by volcanoes, like the Hawaiian Islands. When a landform is surrounded by water on only three sides, it is called a peninsula. If you take a look at a globe you can spot some peninsulas. One is part of the state of Florida, and another is part of the country of Italy. Which other peninsulas can you spot?";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Land Forms"} />
        <div className="p-4">
          <p className="font-bold text-center">Landforms Near Water</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF10} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Maybe you have been to the beach before. That strip of land right
            next to the water is called a coastline. Coastlines are always
            changing because waves move sand and soil onto the coast and then
            back out to sea again. The water also carves out different shapes in
            the cliffs on the coastline.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF11} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            A landform that is completely surrounded by water is called an
            island. Islands can be big, like the continent of Australia and
            country Great Britain, or small like an island in a lake. Many
            islands are formed by volcanoes, like the Hawaiian Islands.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF12} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            When a landform is surrounded by water on only three sides, it is
            called a <strong>peninsula</strong>. If you take a look at a globe
            you can spot some peninsulas. One is part of the state of Florida,
            and another is part of the country of Italy. Which other peninsulas
            can you spot?
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Three/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS5;
