import React from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC15 = () => {
  const normalStyle = "bg-gray-200 hover:bg-gray-200 px-4 rounded text-black m-2";
  const correctStyle = "bg-green-500 hover:bg-green-500 px-4 rounded text-white m-2";
  const errorStyle = "bg-red-500 hover:bg-red-600 px-4 rounded text-white m-2";
  const showCorrectSytle = "opacity-50 bg-green-500 hover:bg-green-600 px-4 rounded text-white m-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("3").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("6").className = showCorrectSytle;
        document.getElementById("5").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("6").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      default:
      case "7":
        document.getElementById("7").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("7").className = showCorrectSytle;
        document.getElementById("8").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("9").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("9").className = showCorrectSytle;
        document.getElementById("10").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "11":
        document.getElementById("12").className = showCorrectSytle;
        document.getElementById("11").className = errorStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
      case "12":
        document.getElementById("12").className = correctStyle;
        document.getElementById("11").disabled = true;
        document.getElementById("12").disabled = true;
        break;
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="flex px-4 pt-4">
          <div className="w-1/2 text-center bg-blue-500 text-white py-2">
            <div>Material</div>
          </div>
          <div className="w-1/2 text-center">
            <div>Will the change be reversible?</div>
          </div>
        </div>
        <div className="flex px-4">
          <div className="w-1/2 text-center border border-blue-500 py-2">
            <div>chocolate</div>
          </div>
          <div className="w-1/2 flex justify-center">
            <button onClick={checkAnswer} id="1" className={normalStyle}>
              Yes
            </button>
            <button onClick={checkAnswer} id="2" className={normalStyle}>
              No
            </button>
          </div>
        </div>
        <div className="flex px-4">
          <div className="w-1/2 text-center border border-blue-500 py-2">
            <div>wax</div>
          </div>
          <div className="w-1/2 flex justify-center">
            <button onClick={checkAnswer} id="3" className={normalStyle}>
              Yes
            </button>
            <button onClick={checkAnswer} id="4" className={normalStyle}>
              No
            </button>
          </div>
        </div>
        <div className="flex px-4">
          <div className="w-1/2 text-center border border-blue-500 py-2">
            <div>cheddar cheese</div>
          </div>
          <div className="w-1/2 flex justify-center">
            <button onClick={checkAnswer} id="5" className={normalStyle}>
              Yes
            </button>
            <button onClick={checkAnswer} id="6" className={normalStyle}>
              No
            </button>
          </div>
        </div>
        <div className="flex px-4">
          <div className="w-1/2 text-center border border-blue-500 py-2">
            <div>plasticine</div>
          </div>
          <div className="w-1/2 flex justify-center">
            <button onClick={checkAnswer} id="7" className={normalStyle}>
              Yes
            </button>
            <button onClick={checkAnswer} id="8" className={normalStyle}>
              No
            </button>
          </div>
        </div>
        <div className="flex px-4">
          <div className="w-1/2 text-center border border-blue-500 py-2">
            <div>butter</div>
          </div>
          <div className="w-1/2 flex justify-center">
            <button onClick={checkAnswer} id="9" className={normalStyle}>
              Yes
            </button>
            <button onClick={checkAnswer} id="10" className={normalStyle}>
              No
            </button>
          </div>
        </div>
        <div className="flex px-4">
          <div className="w-1/2 text-center border border-blue-500 py-2">
            <div>egg</div>
          </div>
          <div className="w-1/2 flex justify-center">
            <button onClick={checkAnswer} id="11" className={normalStyle}>
              Yes
            </button>
            <button onClick={checkAnswer} id="12" className={normalStyle}>
              No
            </button>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/14">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC15;
