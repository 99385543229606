import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA12 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <img src={IMAGES.L3.MA12.QMQ1} alt="" className="w-full" />
        </div>
        <div className="px-4 text-center">
          <p>Answer</p>
        </div>
        <div className="px-4 text-center">
          <input
            type="text"
            className="outline-none w-full text-center text-lg border-b-2 border-b-black"
          />
        </div>
        <div className="px-4 text-center">
          <input
            type="text"
            className="outline-none w-full text-center text-lg border-b-2 border-b-black"
          />
          <div className="px-4 text-center">
            <p>in.</p>
          </div>
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA12.QMQ2} alt="" className="w-full" />
        </div>
        <div className="px-4 text-center">
          <p>Answer</p>
        </div>
        <div className="px-4 text-center">
          <input
            type="text"
            className="outline-none w-full text-center text-lg border-b-2 border-b-black"
          />
        </div>
        <div className="px-4 text-center">
          <input
            type="text"
            className="outline-none w-full text-center text-lg border-b-2 border-b-black"
          />
          <div className="px-4 text-center">
            <p>in.</p>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/11">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA12;
