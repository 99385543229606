import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS4 = () => {
  const speakData =
    "Low Landforms. Some lower landforms are plains, valleys, and canyons. A plain is a flat, wide open space. Most big cities are built on plains since it's easier to build on flat land, and plains near rivers are excellent places to grow crops like corn and wheat. A valley is that low space between two mountains or hills. A valley formed by a river or stream makes a V-shape, meaning if you look at its steep sides and narrow bottom, it looks like a V. Other valleys were formed long ago by glaciers and are in a U-shape with a rounded bottom. Have you heard of the Grand Canyon? It is a large landform in the Southwestern United States. Canyons have deep, narrow valleys, steep cliffs, and layers and layers of rock. When you study the rock layers in a canyon, you can tell how the canyon was formed over millions of years.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Land Forms"} />
        <div className="p-4">
          <p className="font-bold text-center">Low Landforms</p>
        </div>
        <div className="p-4">
          <p>
            Some <strong>lower landforms</strong> are plains, valleys, and
            canyons.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF7} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            A plain is a flat, wide open space. Most big cities are built on
            plains since it's easier to build on flat land, and plains near
            rivers are excellent places to grow crops like corn and wheat.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF8} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            A valley is that low space between two mountains or hills. A valley
            formed by a river or stream makes a V-shape, meaning if you look at
            its steep sides and narrow bottom, it looks like a V. Other valleys
            were formed long ago by glaciers and are in a U-shape with a rounded
            bottom.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF9} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Have you heard of the Grand Canyon? It is a large landform in the
            Southwestern United States. Canyons have deep, narrow valleys, steep
            cliffs, and layers and layers of rock. When you study the rock
            layers in a canyon, you can tell how the canyon was formed over
            millions of years.
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Three/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Three/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS4;
