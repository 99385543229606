import React from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA11 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Addition and Subtraction"} />
        <div className="p-4">
          <p>Find the missing addend and write it out. Remember to count carefully.</p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">1.</p>
          <p className="w-3/4 text-2xl">
            {"2 + 3 = "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">2.</p>
          <p className="w-3/4 text-2xl">
            {"4 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
            {" = 5"}
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">3.</p>
          <p className="w-3/4 text-2xl">
            {"5 + 0 = "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">4.</p>
          <p className="w-3/4 text-2xl">
            {"1 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
            {" = 5"}
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">5.</p>
          <p className="w-3/4 text-2xl">
            {"0 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
            {" = 5"}
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">6.</p>
          <p className="w-3/4 text-2xl">
            {"3 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
            {" = 5"}
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">7.</p>
          <p className="w-3/4 text-2xl">
            {"5 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
            {" = 5"}
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">8.</p>
          <p className="w-3/4 text-2xl">
            {"4 + 1 = "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">9.</p>
          <p className="w-3/4 text-2xl">
            {"0 + 5 = "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
          </p>
        </div>
        <div className="p-4 flex">
          <p className="text-2xl w-1/4">10.</p>
          <p className="w-3/4 text-2xl">
            {"2 + "}
            <input
              type="text"
              className="outline-none w-8 text-center text-2xl border-black border-dotted border-b-2"
              maxLength="2"
            />
            {" = 5"}
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Two/10">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA11;