import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC4 = () => {
  const speakData =
    "The tropical rainforests are home to gigantic trees, colourful birds, millions of bright insects, and many different mammals. There are more trees in tropical rainforests than anywhere else in the world. These trees are home to lots of animals. Most of them live high in the branches where they can find food. Insects, small birds and frogs feed on the fruit, seeds and leaves, or other small creatures. Tree-living lizards, chameleons and snakes feed on smaller animals. Plant-eating mammals, such as flying squirrels, monkeys and sloths, live in the forest canopy. Carnivores, such as jaguars and leopards, hunt in the trees to catch prey.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Tropical Rainforests"} />
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Tropical Rainforests</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC4.TropicalRainforest}
            alt="TropicalRainforest"
            className="w-full"
          />
        </div>
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Animals of Tropical Rainforests</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SC4.AnimalsOfRainforest}
            alt="AnimalsOfRainforest"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC4;
