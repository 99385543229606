import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC10 = () => {
  const speakData =
    "Humans have studied Earth's moon for thousands of years. It is the most explored body in our Solar System apart from Earth, having been visited by numerous spacecraft. The first spacecraft to touch the surface of the Moon was the unmanned Soviet probe 'Luna 2' in 1959. It crashed into the surface as it was designed to in order to test whether the Moon's craters contained ice. After the success of Luna 2, the Soviet Space program sent many more spacecraft to the Moon. The first to successfully land intact on the surface was Luna 9 in 1966. The smae year, Luna 10 became the first man-made object to orbit the Moon. In 1968, Apollo 8 became the first manned spacecraft to orbit the moon. Astronauts Frank Borman, James Lovell and William Anders became the first humans to travel beyond Earth's orbit and see our home planet as a whole. During their mission, they were able to make observations of the Moon's surface and take photographs, including the very firt photograph of Earth from a distance. NASA spacecraft Apollo 11 arrived in orbit around the Moon on July 19th 1969 in preparation for landing on the Moon's surface. On board were three astronauts; Neil Armstrong, Edwin 'Buzz' Aldrin and Michael Collins. On July 24th, the lunar module successfully landed and Armstrong became the first man to walk on the Moon. While on the surface, Armstrong and Aldrin collected samples of moon rock, took photos and planted an American flag.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Moon Exploration"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC10.Moon1}
            alt="MoonExploration"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC10.Moon2}
            alt="MoonExploration"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC10.Moon3}
            alt="MoonExploration"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC10.Moon4}
            alt="MoonExploration"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC10.Moon5}
            alt="MoonExploration"
            className="w-full"
          />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/13">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC10;
