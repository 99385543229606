import React, { useState } from "react";
import { useDrop } from "react-dnd";
import ItemTypes from "./ItemType";
import Box from "./Box";

const data = [
  { id: 1, name: "apple", type: ItemTypes.THING },
  { id: 2, name: "love", type: ItemTypes.IDEA },
  { id: 3, name: "book", type: ItemTypes.THING },
  { id: 4, name: "table", type: ItemTypes.THING },
  { id: 5, name: "shoes", type: ItemTypes.THING },
  { id: 6, name: "peace", type: ItemTypes.IDEA },
  { id: 7, name: "hate", type: ItemTypes.IDEA },
  { id: 8, name: "beauty", type: ItemTypes.IDEA },
  { id: 9, name: "belt", type: ItemTypes.THING },
  { id: 10, name: "justice", type: ItemTypes.IDEA },
];

const Container = () => {
  const normalTarget = "flex flex-wrap bg-zmh-gray-500 p-2 m-2 text-white";
  const canDropTarget = "flex flex-wrap bg-green-500 p-2 m-2 text-white";

  const [items, setItems] = useState(data);
  const [target, setTarget] = useState([]);
  const [target2, setTarget2] = useState([]);

  const [{ canDrop }, dropRef] = useDrop({
    accept: ItemTypes.THING,
    drop: (item) => addItemToBox(ItemTypes.THING, item.id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [{ canDrop2 }, dropRef2] = useDrop({
    accept: ItemTypes.IDEA,
    drop: (item) => addItemToBox(ItemTypes.IDEA, item.id),
    collect: (monitor) => ({
      isOver2: monitor.isOver(),
      canDrop2: monitor.canDrop(),
    }),
  });

  const addItemToBox = (method, id) => {
    const itemList = items.filter((item) => id === item.id);
    if (method === "thing") {
      setTarget([...target, itemList[0]]);
    } else {
      setTarget2([...target2, itemList[0]]);
    }
    const newItemsList = items.filter((item) => id !== item.id);
    setItems(newItemsList);
  };

  return (
    <div>
      <div className="flex flex-wrap border-2 border-zmh-gray-500 p-2 m-2">
        {items.map((item) => (
          <Box
            draggable
            key={item.id}
            data={data}
            id={item.id}
            name={item.name}
            type={item.type}
          />
        ))}
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">THING</h6>
        <div className={canDrop ? canDropTarget : normalTarget} ref={dropRef}>
          {target.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">Thing can be dropped here!</small>
        </div>
      </div>
      <div className="p-2 m-2 bg-zmh-gray-500 text-white">
        <h6 className="">IDEA</h6>
        <div className={canDrop2 ? canDropTarget : normalTarget} ref={dropRef2}>
          {target2.map((target) => (
            <Box
              key={target.id}
              id={target.id}
              name={target.name}
              type={target.type}
            />
          ))}
        </div>
        <div>
          <small className="text-zmh-gray-100">Idea can be dropped here!</small>
        </div>
      </div>
    </div>
  );
};

export default Container;
