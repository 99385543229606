import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC11 = () => {
  const speakData =
    "Objects that give out light are called ‘sources’ of light.. Fire, Torch, Lamp, Laptop, Television, Light Bulb.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Light Sources"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Make observations to construct an evidence-based account that
            objects can be seen only when illuminated.
          </p>
        </div>
        <div className="p-4">
          <p>
            Plan and conduct an investigation to determine the effect of placing
            objects made with different materials in the path of a beam of
            light.
          </p>
        </div>
        <div className="p-4">
          <p>Objects that give out light are called ‘sources’ of light..</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.LightSource}
            alt="LightSource"
            className="w-full"
          />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/9">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/One/12">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC11;
