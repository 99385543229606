import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  const speakData = "What are some unit of length. inches. feet. centimeters. Eric found a really long stick. three foot and six inches. Jenny found a four foot and three inches long stick. How long would the two sticks be if put together.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Relate addition and  subtraction to length"} />
        <div className="p-4">
          <img src={IMAGES.L3.MA9.SL1} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA9.SL2} alt="" className="w-full" />
        </div>
        <div className="p-4">
          <img src={IMAGES.L3.MA9.SL3} alt="" className="w-full" />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/8">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Math/Units/Three/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;