import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS5 = () => {
  const speakData = "Some people live in a city. Cities are busy places. There are many shops, places to live, schools and things to see and do. There are often a lot of different types of transport.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"City"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.SS5.City} alt="City" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            Some people live in a city. Cities are busy places. There are many
            shops, places to live, schools and things to see and do. There are
            often a lot of different types of transport.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/4">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Two/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS5;
