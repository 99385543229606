import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Mixing Compounds"} />
        <div className="m-2 border border-black">
          <div className="flex p-2 justify-between">
            <div>sometimes</div>
            <div>downtown</div>
            <div>girlfriend</div>
            <div>everybody</div>
          </div>
          <div className="flex p-2 justify-between">
            <div>maybe</div>
            <div>myself</div>
            <div>lunchbox</div>
            <div>baseball</div>
            <div>outside</div>
            <div>today</div>
          </div>
        </div>
        <div className="p-4">
          <p>
            <strong>Directions:</strong> Write the correct compound word into
            the blank.{" "}
          </p>
        </div>
        <div className="p-4 mb-20">
          <div className="mb-2">
            <div>
              <p>1. Opposite of inside</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>2. Another word for me</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>3. A girl who is a friend</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>4. Not yesterday or tomorrow, but</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>5. All of the people</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>6. A sport</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>7. The main part of a town</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>8. Not always, just</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>9. A box for carrying your lunch</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-20">
            <div>
              <p>10. Perhaps or might</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Two/2">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Two/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;