import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Butterflies"} />
        <div>
          <div className="p-4">
            <p>Use details from the text to fill in the missing words.</p>
          </div>
          <div className="p-4">
            <p>
              1. The{" "}
              <input
                type="text"
                className="outline-none w-24 px-2 text-xl border-b-2 border-b-blue-500"                
              />{" "}
              will make the butterfly’s body.
            </p>
          </div>
          <div className="p-4">
            <p>
              2. The wings will be covered with{" "}
              <input
                type="text"
                className="outline-none w-24 px-2 text-xl border-b-2 border-b-blue-500"                
              />{" "}
              .
            </p>
          </div>
          <div className="p-4">
            <p>
              3. Butterflies have four{" "}
              <input
                type="text"
                className="outline-none w-24 px-2 text-xl border-b-2 border-b-blue-500"                
              />{" "}
              .
            </p>
          </div>
          <div className="p-4">
            <p>
              4. The antennae will be made from{" "}
              <input
                type="text"
                className="outline-none w-24 px-2 text-xl border-b-2 border-b-blue-500"                
              />{" "}
              .
            </p>
          </div>
          <div className="p-4 mb-20">
            <p>
              5. A butterfly has two{" "}
              <input
                type="text"
                className="outline-none w-24 px-2 text-xl border-b-2 border-b-blue-500"                
              />{" "}
              to see with.
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Four/1">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Four/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E2;
