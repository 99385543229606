import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E1 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Silent Letters"} />
        <div className="p-4">
          <p>
            Some words have letters you cannot hear at all, such as the{" "}
            <strong>gh</strong> in <strong>night</strong>, the{" "}
            <strong>w</strong> in <strong>wrong</strong>, the <strong>I</strong>{" "}
            in <strong>walk</strong>, the <strong>k</strong> in{" "}
            <strong>knee</strong>, the <strong>b</strong> in{" "}
            <strong>climb</strong>, and the <strong>t</strong> in{" "}
            <strong>listen</strong>.
          </p>
        </div>
        <div className="p-4">
          <p>
            <strong>Directions:</strong> look at the words in the word box.
            Write the word under its picture.
          </p>
        </div>
        <div className="p-2 m-2 border-2 border-black">
          <div className="flex justify-between">
            <p>knife</p>
            <p>light</p>
            <p>calf</p>
            <p>wrench</p>
            <p>lamb</p>
            <p>eight</p>
          </div>
          <div className="flex justify-between">
            <p>wrist</p>
            <p>whistle</p>
            <p>comb</p>
            <p>thumb</p>
            <p>knob</p>
            <p>knee</p>
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL1}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL2}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL3}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL4}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL5}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL6}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL7}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL8}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL9}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL10}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>
        <div className="flex p-4 mb-40">
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL11}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
          <div className="w-1/2 m-2">
            <img
              src={IMAGES.L2.E1.SL12}
              alt="SlientLetters"
              className="w-24 h-24 mx-auto"
            />
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Two/CommonCoreStandard">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Two/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E1;
