import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Types Animals By Food"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC4.AnimalsByFood}
            alt="AnimalsByFood"
            className="w-full"
          />
        </div>        
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Three/3">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/Three/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC4;
