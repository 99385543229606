import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC31 = () => {
  const speakData =
    "1. Using your sharp pencil, put a whole in the bottom of the cup. Be careful not to prick your finger! 2. Next, use your ruler and scissors to cut a length of string 60cm long. 3. Put one end of the string through the hole you have made in the cup. 4. Once you have this done, pull it through a little further – not all the way through or you’ll have to start over again! Tie a knot in the end. 5. Pull the end opposite the knot until the knot reaches the base of the cup. 6. Now repeat this all again with another cup using the same piece of string. 7. You should have two cups attached together by one piece of string. 8. Now, with your partner, decide who will be the listener and who will be the talker. 9. The listener will place the cup tight to their ear. The talker will pull the string tight and speak into the cup with their normal talking voice. 10. Could you hear what your partner said? Now switch roles!";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"What to do"} />
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            1. Using your sharp pencil, put a whole in the bottom of the cup. Be
            careful not to prick your finger!
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.WhatToDo1}
            alt="Whattodo"
            className="w-1/4 mx-auto"
          />
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            2. Next, use your ruler and scissors to cut a length of string 60cm
            long.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.WhatToDo2} alt="Whattodo" className="w-full" />
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            3. Put one end of the string through the hole you have made in the
            cup.
          </p>
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            4. Once you have this done, pull it through a little further – not
            all the way through or you’ll have to start over again! Tie a knot
            in the end.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.WhatToDo3}
            alt="Whattodo"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            5. Pull the end opposite the knot until the knot reaches the base of
            the cup.
          </p>
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            6. Now repeat this all again with another cup using the same piece
            of string.
          </p>
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            7. You should have two cups attached together by one piece of
            string.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.WhatToDo4}
            alt="Whattodo"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            8. Now, with your partner, decide who will be the listener and who
            will be the talker.
          </p>
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>
            9. The listener will place the cup tight to their ear. The talker
            will pull the string tight and speak into the cup with their normal
            talking voice.
          </p>
        </div>
        <div className="p-4 mx-4 mt-4 text-white bg-blue-300">
          <p>10. Could you hear what your partner said? Now switch roles!</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.WhatToDo5}
            alt="Whattodo"
            className="w-1/2 mx-auto"
          />
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/30">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/One/32">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC31;
