import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC1 = () => {
    const speakData = "Weather. Weather refers to short term atmospheric conditions while climate is the weather of a specific region averaged over a long period of time. ";
    const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Earth’s System"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC1.E1} alt="Earth" className="w-full" />
        </div>
        <div className="p-4 text-xl font-bold text-blue-500 text-center">
          <p>Weather</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC1.E2} alt="Weather" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Weather refers to short term atmospheric conditions while climate is
            the weather of a specific region averaged over a long period of
            time.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC1.E3} alt="Weather" className="w-full" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Four/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC1;
