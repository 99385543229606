import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA9 = () => {
  return (
    <div>
      <div className="bg-gray-100">
        <BannerTitle title={"Light or Heavy"} />
        <div className="p-4">
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <p>
              Compare the two objects. Write 'L' for light and 'H' for heavy.
            </p>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full">
                <div>
                  <img src={IMAGES.L3.MA9.LH1} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-cols-2">
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full">
                <div>
                  <img src={IMAGES.L3.MA9.LH2} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-cols-2">
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full">
                <div>
                  <img src={IMAGES.L3.MA9.LH3} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-cols-2">
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full">
                <div>
                  <img src={IMAGES.L3.MA9.LH4} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-cols-2">
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full">
                <div>
                  <img src={IMAGES.L3.MA9.LH5} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-cols-2">
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 mb-4 bg-white rounded-2xl shadow-lg">
            <div className="">
              <div className="w-full">
                <div>
                  <img src={IMAGES.L3.MA9.LH6} alt="" className="" />
                </div>
              </div>
              <div className="w-full grid gap-4 grid-cols-2">
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
                <div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-black rounded w-full text-center py-2 px-4 text-gray-700 uppercase leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    maxLength={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Three/8">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Three/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA9;