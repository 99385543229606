import React from 'react';
import { Checkbox, Typography } from "@material-tailwind/react";
import useSpeechSynthesis from 'react-speech-kit/dist/useSpeechSynthesis';
import { Link } from 'react-router-dom';
import W1 from '../../assets/Two/wolf1.png';
import W2 from '../../assets/Two/wolf2.png';
import W3 from '../../assets/Two/wolf3.png';
import W4 from '../../assets/Two/wolf4.png';
import W5 from '../../assets/Two/wolf5.png';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import PlayButton from '../../../../../components/PlayButton';
import StopButton from '../../../../../components/StopButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E1 = () => {
  const speakData =
    "One day, a boy was watching over his sheep. He was very bored. He wanted to play a trick. “ Help! Wolf!” cried the boy. The people of the town ran to help. There was no wolf. The boy laughed. The next day he did it again. “ Help! Wolf!” cried the boy. Again, the people of the town ran to help. Again, there was no wolf. Again, the boy laughed. Then a wolf really did come for the sheep. “ Help! Wolf!” cried the boy. No one came to help. The wolf chased all the boy’s sheep away. The boy learned a lesson. People don’t believe liars even when they tell the truth.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"The boy who cried ‘wolf’"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={W1} alt="Wolf" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            One day, a boy was watching over his sheep. He was very bored. He
            wanted to play a trick.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={W2} alt="Wolf" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            “ Help! Wolf!” cried the boy. The people of the town ran to help.
            There was no wolf. The boy laughed.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={W3} alt="Wolf" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            The next day he did it again. “ Help! Wolf!” cried the boy. Again,
            the people of the town ran to help. Again, there was no wolf. Again,
            the boy laughed.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={W4} alt="Wolf" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            Then a wolf really did come for the sheep. “ Help! Wolf!” cried the
            boy. No one came to help. The wolf chased all the boy’s sheep away.
          </Typography>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={W5} alt="Wolf" className="h-full w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">
            The boy learned a lesson. People don’t believe liars even when they
            tell the truth.
          </Typography>
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Checkbox color="blue" label="I can read a story." defaultChecked />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/English/Units/Two/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E1;