import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC3 = () => {
  const speakData =
    "Climate change refers to long-term changes. There are 3 climates. Polar Climate. Temperate Climate. Tropical Climate. Tropical climates are hot and humid all year. Countries near the equator are in the tropical climate zone. They don't have four seasons: they only have rainy and dry seasons. Temperate climates usually have winters that aren't very cold and summers that aren't very hot. Countries in the temperate climate zone have four seasons: winter, spring, summer and autumn. Polar climates are cold all year and there is always snow and ice. In winter the temperatures can be as low as -50°C and it's dark all the time. In summer there is sun nearly all day, but it's very weak, so it's still cold.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Climate"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC3.Climate} alt="Climate" className="w-full" />
        </div>
        <div className="px-8">
          <p>
            Climate change refers to long-term changes. There are 3 climates.
          </p>
          <ul className="list-decimal">
            <li>Polar Climate</li>
            <li>Temperate Climate</li>
            <li>Tropical Climate</li>
          </ul>
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.SC3.C1} alt="Climate" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            <strong className="text-green-500">Tropical climates</strong> are
            hot and humid all year. Countries near the equator are in the
            tropical climate zone. They don't have four seasons: they only have
            rainy and dry seasons.
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.SC3.C2} alt="Climate" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            <strong className="text-orange-500">Temperate climates</strong>{" "}
            usually have winters that aren't very cold and summers that aren't
            very hot. Countries in the temperate climate zone have four seasons:
            winter, spring, summer and autumn.
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.SC3.C3} alt="Climate" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            <strong className="text-blue-500">Polar climates</strong> are cold
            all year and there is always snow and ice. In winter the
            temperatures can be as low as -50°C and it's dark all the time. In
            summer there is sun nearly all day, but it's very weak, so it's
            still cold.
          </p>
        </div>
        <div className="p-4">
          <img src={IMAGES.L4.SC3.C4} alt="Climate" className="w-full" />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Four/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC3;
