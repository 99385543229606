export const IMAGES = {
  L1: {
    SC1: {
      1: require("./L1/1.png"),
      2: require("./L1/2.png"),
      3: require("./L1/3.png"),
      4: require("./L1/4.png"),
      5: require("./L1/5.png"),
      6: require("./L1/6.png"),
      7: require("./L1/7.png"),
      8: require("./L1/8.png"),
      9: require("./L1/9.png"),
      10: require("./L1/10.png"),
      11: require("./L1/11.png"),
      12: require("./L1/12.png"),
      13: require("./L1/13.png"),
      14: require("./L1/14.png"),
      15: require("./L1/15.png"),
    },
    SC2: {
      P1: require("./L1/Pattern1.png"),
      P2: require("./L1/Pattern2.png"),
      P3: require("./L1/Pattern3.png"),
      P4: require("./L1/Pattern4.png"),
      P5: require("./L1/Pattern5.png"),
      P6: require("./L1/Pattern6.png"),
      P7: require("./L1/Pattern7.png"),
      P8: require("./L1/Pattern8.png"),
      P9: require("./L1/Pattern9.png"),
      P10: require("./L1/Pattern10.png"),
      P11: require("./L1/Pattern11.png"),
      P12: require("./L1/Pattern12.png"),
      P13: require("./L1/Pattern13.png"),
      P14: require("./L1/Pattern14.png"),
      P15: require("./L1/Pattern15.png"),
      P16: require("./L1/Pattern16.png"),
      P17: require("./L1/Pattern17.png"),
      P18: require("./L1/Pattern18.png"),
      P19: require("./L1/Pattern19.png"),
      P20: require("./L1/Pattern20.png"),
      P21: require("./L1/Pattern21.png"),
      P22: require("./L1/Pattern22.png"),
    },
    SC3: {
      Q1: require("./L1/Quiz1.png"),
      Q2: require("./L1/Quiz2.png"),
    },
    SC4: {
      M1: require("./L1/M1.png"),
      M2: require("./L1/M2.png"),
      M3: require("./L1/M3.png"),
      M4: require("./L1/M4.png"),
      M5: require("./L1/M5.png"),
      M6: require("./L1/M6.png"),
      M7: require("./L1/M7.png"),
      M8: require("./L1/M8.png"),
      M9: require("./L1/M9.png"),
      M10: require("./L1/M10.png"),
      M11: require("./L1/M11.png"),
      M12: require("./L1/M12.png"),
      M13: require("./L1/M13.png"),
      M14: require("./L1/M14.png"),
      M15: require("./L1/M15.png"),
      M16: require("./L1/M16.png"),
      M17: require("./L1/M17.png"),
      M18: require("./L1/M18.png"),
      M19: require("./L1/M19.png"),
      M20: require("./L1/M20.png"),
      M21: require("./L1/M21.png"),
      M22: require("./L1/M22.png"),
      M23: require("./L1/M23.png"),
    },
    SC5: {
      Q1: require("./L1/Q1.png"),
      Q2: require("./L1/Q2.png"),
      Q3: require("./L1/Q3.png"),
    },
    SC16: {
      CanvasDraw1: require("./L1/CanvasDraw1.png"),
      CanvasDraw2: require("./L1/CanvasDraw2.png"),
    },
  },
  L2: {
    SC1: {
      Eco1: require("./L2/Eco1.png"),
      Eco2: require("./L2/Eco2.png"),
      Eco3: require("./L2/Eco3.png"),
      Eco4: require("./L2/Eco4.png"),
      Eco5: require("./L2/Eco5.png"),
      Eco6: require("./L2/Eco6.png"),
      Plant1: require("./L2/Plant1.png"),
      Plant2: require("./L2/Plant2.png"),
      Plant3: require("./L2/Plant3.png"),
      Plant4: require("./L2/Plant4.png"),
      Plant5: require("./L2/Plant5.png"),
      Plant6: require("./L2/Plant6.png"),
      Plant7: require("./L2/Plant7.png"),
      Plant8: require("./L2/Plant8.png"),
      Plant9: require("./L2/Plant9.png"),
    },
    SC2: {
      Soil: require("./L2/Soil.png"),
      Air: require("./L2/Air.png"),
      Water: require("./L2/Water.png"),
      Sun: require("./L2/Sun.png"),
    },
    SC3: {
      EDC1: require("./L2/EDC1.png"),
      EDC2: require("./L2/EDC2.png"),
      EDC3: require("./L2/EDC3.png"),
      EDC4: require("./L2/EDC4.png"),
      EDC5: require("./L2/EDC5.png"),
      EDC6: require("./L2/EDC6.png"),
      EDC7: require("./L2/EDC7.png"),
      EDC8: require("./L2/EDC8.png"),
      EDC9: require("./L2/EDC9.png"),
      EDC10: require("./L2/EDC10.png"),
      EDC11: require("./L2/EDC11.png"),
      EDC12: require("./L2/EDC12.png"),
      EDC13: require("./L2/EDC13.png"),
      EDC14: require("./L2/EDC14.png"),
    },
    SC4: {
      Quiz1: require("./L2/Quiz1.png"),
      Quiz2: require("./L2/Quiz2.png"),
    },
    SC5: {
      CE1: require("./L2/CE1.png"),
      CE2: require("./L2/CE2.png"),
    },
    SC6: {
      CanvasDraw1: require("./L2/CanvasDraw1.png"),
      CanvasDraw2: require("./L2/CanvasDraw2.png"),
    },
  },
  L3: {
    SC1: {
      WorldHabitats: require("./L3/WorldHabitats.png"),
      WH1: require("./L3/WH1.png"),
      WH2: require("./L3/WH2.png"),
      WH3: require("./L3/WH3.png"),
      WH4: require("./L3/WH4.png"),
      WH5: require("./L3/WH5.png"),
    },
    SC2: {
      Oceans: require("./L3/Oceans.png"),
      AnimalsOfOceans: require("./L3/AnimalsOfOceans.png"),
    },
    SC3: {
      Artic: require("./L3/Artic.png"),
      AnimalsOfArtic: require("./L3/AnimalsOfArtic.png"),
    },
    SC4: {
      TropicalRainforest: require("./L3/TropicalRainforest.png"),
      AnimalsOfRainforest: require("./L3/AnimalsOfRainforest.png"),
    },
    SC5: {
      Deserts: require("./L3/Deserts.png"),
      AnimalsOfDeserts: require("./L3/AnimalsOfDeserts.png"),
    },
    SC6: {
      ResearchingHabits: require("./L3/ResearchingHabits.png"),
      OceansListen: require("./L3/OceansListen.png"),
      ArticListen: require("./L3/ArticListen.png"),
      TropicalRainforestListen: require("./L3/TropicalRainforestListen.png"),
      DesertsListen: require("./L3/DesertsListen.png"),
    },
    SC7: {
      HowDoISurvive: require("./L3/HowDoISurvive.png"),
    },
    SC8: {
      YN1: require("./L3/YN1.png"),
      YN2: require("./L3/YN2.png"),
      YN3: require("./L3/YN3.png"),
    },
    SC8A: {
      CanvasDraw1: require("./L3/CanvasDraw1.png"),
    },
    SC9: {
      Earth1: require("./L3/Earth.png"),
      Earth2: require("./L3/Jupiter.png"),
      Earth3: require("./L3/Earth2.png"),
      Earth4: require("./L3/E1.png"),
      Earth5: require("./L3/E2.png"),
      Earth6: require("./L3/E3.png"),
      Earth7: require("./L3/Exploring.png"),
      Earth8: require("./L3/Earth3.png"),
      Earth9: require("./L3/E4.png"),
    },
    SC10: {
      Moon1: require("./L3/MoonExploration.png"),
      Moon2: require("./L3/MoonExploration2.png"),
      Moon3: require("./L3/MoonExploration3.png"),
      Moon4: require("./L3/MoonExploration4.png"),
      Moon5: require("./L3/PhotographsMoon.png"),
    },
    SC11: {
      CanvasDraw2: require("./L3/CanvasDraw2.png"),
    },
    SC13: {
      Stability: require("./L3/Stability.png"),
      Change: require("./L3/Change.png"),
      Stability1: require("./L3/Stability1.png"),
      Change1: require("./L3/Change1.png"),
      Predict: require("./L3/Predict.png"),
    },
    SC14: {
      EarthGIF: require("./L3/EarthGIF.gif"),
      BasketBallGIF: require("./L3/BasketBallGIF.gif"),
      IcedGIF: require("./L3/IcedGIF.png"),
      WaterMolecule: require("./L3/WaterMolecule.png"),
      Vinegar: require("./L3/Vinegar.png"),
      Baking: require("./L3/Baking.png"),
      Caterpillar: require("./L3/Caterpillar.png"),
    },
  },
  L4: {
    SC1: {
      E1: require("./L4/E1.png"),
      E2: require("./L4/E2.png"),
      E3: require("./L4/E3.png"),
    },
    SC2: {
      Q1: require("./L4/Q1.png"),
      Q2: require("./L4/Q2.png"),
      Q3: require("./L4/Q3.png"),
      Q4: require("./L4/Q4.png"),
      Q5: require("./L4/Q5.png"),
    },
    SC3: {
      Climate: require("./L4/Climate.png"),
      C1: require("./L4/C1.png"),
      C2: require("./L4/C2.png"),
      C3: require("./L4/C3.png"),
      C4: require("./L4/C4.png"),
    },
    SC4: {
      CanvasDraw1: require("./L4/CanvasDraw1.png"),
    },
    SC5: {
      Biogeology: require("./L4/Biogeology.png"),
      B1: require("./L4/B1.png"),
      B2: require("./L4/B2.png"),
      B3: require("./L4/B3.png"),
      B4: require("./L4/B4.png"),
      B5: require("./L4/B5.png"),
    },
    SC6: {
      CanvasDraw2: require("./L4/CanvasDraw2.png"),
    },
    SC7: {
      SD1: require("./L4/SD1.png"),
      SD2: require("./L4/SD2.png"),
      SD3: require("./L4/SD3.png"),
    },
    SC8: {
      CauseQ1: require("./L4/CauseQ1.png"),
      CauseQ2: require("./L4/CauseQ2.png"),
      CauseQ3: require("./L4/CauseQ3.png"),
      CauseQ4: require("./L4/CauseQ4.png"),
      CauseQ5: require("./L4/CauseQ5.png"),
      CauseQ6: require("./L4/CauseQ6.png"),
      CauseQ7: require("./L4/CauseQ7.png"),
    },
  },
};