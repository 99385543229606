import React from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BannerTitle from "../../../../../components/BannerTitle";

const SS6 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Which place do you live?"} />
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <div className="mb-2 text-center">
            {/* <p className="w-full p-4 bg-zmh-gray-500 text-white text-xl rounded-xl">
              What do you do with your family?
            </p> */}
            <img src={IMAGES.L2.SS6.Asking1} alt="Asking" className="w-full" />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/5">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS6;
