import React, {useState} from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Container from "./DragNDrop_Three/Container";
import { Link } from "react-router-dom";
import { Checkbox } from "@material-tailwind/react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E8 = () => {
    const [isDis, setIsDis] = useState(true);
    const changeNext = () => {
      setIsDis(!isDis);
    };
  return (
    <div>
      <BannerTitle title={"Drag the words on the cones in alphabetical order."} />
      <div>
        <DndProvider backend={TouchBackend}>
          {/* Here, render a component that uses DND inside it */}
          <Container />
        </DndProvider>
      </div>
      <div className="flex p-4 md:p-12 lg:p-12">
        <Checkbox
          onChange={changeNext}
          color="blue"
          label="I can notice the sounds in words."
        />
      </div>
      <div className="flex p-4 md:p-12 lg:p-12">
        <Checkbox
          onChange={changeNext}
          color="blue"
          label="I can put words in alphabetical order."
        />
      </div>
      <div className="flex p-4 md:p-12 lg:p-12 mb-20">
        <Checkbox
          onChange={changeNext}
          color="blue"
          label="I know the sounds of consonants."
        />
      </div>
      <div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/7">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Three/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E8;
