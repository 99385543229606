import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC8 = () => {
  const speakData =
    "Plants need water and light to live and grow. They make their own food. Their roots take water from soil and leaves take sunlight and carbon dioxide from air to make the food. This is called “Photosynthesis” .";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"How plants make their food?"} />
        <div className="p-4">
          Plants need <strong>water</strong> and <strong>light</strong> to live
          and grow. They make their own food. Their <strong>roots</strong> take
          <strong> water</strong> from soil and <strong>leaves</strong> take
          <strong> sunlight</strong> and <strong>carbon dioxide</strong> from
          air to make the food. This is called <strong>“Photosynthesis”</strong>
          .
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC8.Plants}
            alt="GrowingFlower"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Three/7">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/Three/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC8;