import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA13 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>Find the total numbers of blocks.</p>
        </div>
        <div className="mb-6">
          <div className=" grid grid-cols-3 gap-2">
            <div>
              <img src={IMAGES.L3.MA13.Block1} alt="" className="w-full" />
            </div>
            <div className=" col-span-2">
              <p className="text-lg">
                {"= "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" tens + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" ones = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className=" grid grid-cols-3 gap-2">
            <div>
              <img src={IMAGES.L3.MA13.Block2} alt="" className="w-full" />
            </div>
            <div className=" col-span-2">
              <p className="text-lg">
                {"= "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" tens + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" ones = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className=" grid grid-cols-3 gap-2">
            <div>
              <img src={IMAGES.L3.MA13.Block3} alt="" className="w-full" />
            </div>
            <div className=" col-span-2">
              <p className="text-lg">
                {"= "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" tens + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" ones = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className=" grid grid-cols-3 gap-2">
            <div>
              <img src={IMAGES.L3.MA13.Block4} alt="" className="w-full" />
            </div>
            <div className=" col-span-2">
              <p className="text-lg">
                {"= "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" tens + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" ones = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className=" grid grid-cols-3 gap-2">
            <div>
              <img src={IMAGES.L3.MA13.Block5} alt="" className="w-full" />
            </div>
            <div className=" col-span-2">
              <p className="text-lg">
                {"= "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" tens + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" ones = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className=" grid grid-cols-3 gap-2">
            <div>
              <img src={IMAGES.L3.MA13.Block6} alt="" className="w-full" />
            </div>
            <div className=" col-span-2">
              <p className="text-lg">
                {"= "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" tens + "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
                {" ones = "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-black border-b-2"
                  maxLength="2"
                />
              </p>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/12">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/14">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA13;