import React from "react";
import { Link } from "react-router-dom";
import { Typography, Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS6 = () => {
  return (
    <div>
      <BannerTitle title={"Choose the correct answers."} />
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            1. Most fast food gives our bodies a lot of _____.
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA1" name="Q1" label="Calories" />
          </div>
          <div className="flex-row">
            <Radio id="QA2" name="Q1" label="All answers are correct." />
          </div>
          <div className="flex-row">
            <Radio id="QA3" name="Q1" label="Salt" />
          </div>
          <div className="flex-row">
            <Radio id="QA3" name="Q1" label="Fat" />
          </div>
        </div>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            2. Which of the following is NOT one of the reasons that fast food
            is so popular today?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio
              id="QA4"
              name="Q2"
              label="Fast food tastes good to many people."
            />
          </div>
          <div className="flex-row">
            <Radio id="QA5" name="Q2" label="Fast food is nutritious." />
          </div>
          <div className="flex-row">
            <Radio id="QA6" name="Q2" label="Fast food doesn't cost a lot." />
          </div>
          <div className="flex-row">
            <Radio
              id="QA6"
              name="Q2"
              label="Fast food is convenient and easy to pick up."
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            3. The first fast food restaurant in America was _____.
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="McDonald’s" />
          </div>
          <div className="flex-row">
            <Radio
              id="QA8"
              name="Q3"
              label="White Castle, a fast food hamburger restaurant"
            />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Kentucky Fried Chicken" />
          </div>
          <div className="flex-row">
            <Radio
              id="QA9"
              name="Q3"
              label="White Castle, a street food stand"
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            4. Foods that have been changed from their natural form (like meat
            becoming a hotdog) are said to be _____.
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Healthy" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Fresh" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Nutritious" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Processed" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            5. A few centuries ago, fast food _____.
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Was very expensive" />
          </div>
          <div className="flex-row">
            <Radio
              id="QA8"
              name="Q3"
              label="Didn't really exist (except maybe as street food)"
            />
          </div>
          <div className="flex-row">
            <Radio
              id="QA9"
              name="Q3"
              label="Gave people the nutrition they needed"
            />
          </div>
          <div className="flex-row">
            <Radio
              id="QA9"
              name="Q3"
              label="Was only found at McDonald's and White Castle"
            />
          </div>
        </div>
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/Two/5">
          <BackButton />
        </Link>

        <Link to="/G1/SocialStudies/Units/Two/8">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default SS6;