import React from 'react';
import { Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { IMAGES } from '../../assets';
import BackButton from '../../../../../components/BackButton';
import NextButton from '../../../../../components/NextButton';
import BannerTitle from '../../../../../components/BannerTitle';

const E7 = () => {
    const normalStyle = "w-full mb-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 text-sm font-medium rounded-full";
    const correctStyle = "w-full mb-2 px-4 py-2 bg-green-500 hover:bg-green-600 text-white text-sm font-medium rounded-full";
    const errorStyle = "w-full mb-2 px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-full";
    const showCorrectSytle = "w-full mb-2 px-4 py-2 opacity-50 bg-green-500 hover:bg-green-600 text-white text-sm font-medium rounded-full";

    const checkAnswer = (e) => {
        if(e.currentTarget.id === '2'){
            document.getElementById(e.currentTarget.id).className = correctStyle;
        } else {
            document.getElementById(e.currentTarget.id).className = errorStyle;
            document.getElementById(2).className = showCorrectSytle;
        }
    }

  return (
    <div>
      <div>
        <BannerTitle
          title={
            "Say the name of each picture. Click the letter that makes the beginning sound."
          }
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <Typography variant="lead">4.</Typography>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.Pig} alt="Pig" className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white p-4 mb-20">
          <button onClick={checkAnswer} id={1} className={normalStyle}>
            B
          </button>
          <button onClick={checkAnswer} id={2} className={normalStyle}>
            P
          </button>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/One/6">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/One/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default E7;