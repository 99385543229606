import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E1 = () => {
  const speakData =
    "Not all inventions were meant to happen. In some cases, people made mistakes that ended up becoming useful or interesting inventions. The popsicle was the result of a mistake. In 1905, an eleven-year-old boy named Frank Epperson was trying to make a flavorful drink. He mixed soda pop powder with water and left it on his back porch overnight. It froze with the stir stick stuck in the middle. The next day he pulled it out and tasted the frozen drink. It was an instant hit. He called it the “European icicle” and cold them in his night neighborhood for five cents each. He later named the invention “popsicle”. A year before the invention of the popsicle the ice cream cone was invented. One very hot afternoon at the 1904 St. Louis World’s Fair, a young man was selling a lot of ice cream. Soon he ran out of paper ice cream cups. He was desperate to find something in which to serve his ice cream. Then he noticed an Arab vendor selling wafer- like biscuits sprinkled with sugar. He bought a stack of these wafers and put ice cream inside them. He sold his ice cream on the cone-like wafers. This new way to serve ice cream soon became very popular. Both these cases prove that the old expression, Necessity is the Mother of Invention, is certainly true. ";  
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Unexpected inventions"} />
        <div className="p-4">
          <p>
            Not all inventions were meant to happen. In some cases, people made
            mistakes that ended up becoming useful or interesting inventions.{" "}
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.E1.Unexpected}
            alt="Unexpected"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            The popsicle was the result of a mistake. In 1905, an
            eleven-year-old boy named Frank Epperson was trying to make a
            flavorful drink. He mixed soda pop powder with water and left it on
            his back porch overnight. It froze with the stir stick stuck in the
            middle. The next day he pulled it out and tasted the frozen drink.
            It was an instant hit. He called it the “European icicle” and cold
            them in his night neighborhood for five cents each. He later named
            the invention “popsicle”.
          </p>
          <p className="mt-4">
            A year before the invention of the popsicle the ice cream cone was
            invented. One very hot afternoon at the 1904 St. Louis World’s Fair,
            a young man was selling a lot of ice cream. Soon he ran out of paper
            ice cream cups. He was desperate to find something in which to serve
            his ice cream. Then he noticed an Arab vendor selling wafer- like
            biscuits sprinkled with sugar. He bought a stack of these wafers and
            put ice cream inside them. He sold his ice cream on the cone-like
            wafers. This new way to serve ice cream soon became very popular.
          </p>
          <p className="mt-4">
            Both these cases prove that the old expression, Necessity is the
            Mother of Invention, is certainly true.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Three/CommonCoreStandard">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/English/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E1;
