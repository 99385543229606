import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC7 = () => {
  const speakData =
    "People do things to live comfortably can affect the world to destroy. For Example : Plastic. The benefits of plastics are  durable material, low cost, water-resistant, lower energy.  And cons are: produces toxic fumes, low temperatures are embrittlement, causes CANCER. And animals can die by eating plastic if people throw them carelessly. And rubbish plastic can destroy the Earth’s surface and natural resources. People can reduce the usage of plastic and recycle the old plastic bag or box. How can we use plastic? Plastics are used to make bicycle helmets, child safety seats and airbags in automobiles. They're in the cell phones, televisions, computers and other electronic equipment that makes modern life possible. They're in the roofs, walls, flooring and insulation that make homes and buildings energy efficient.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Human impacts on Earth System"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SC7.SD1}
            alt="HumanImpacts"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4">
          <p>
            People do things to live comfortably can affect the world to
            destroy. For Example : Plastic. The benefits of plastics are 
            <strong>
              durable material, low cost, water-resistant, lower energy.
            </strong>{" "}
            And cons are: produces toxic fumes, low temperatures are
            embrittlement, causes CANCER. And animals can die by eating plastic
            if people throw them carelessly. And rubbish plastic can destroy the
            Earth’s surface and natural resources.
          </p>
          <p>
            People can reduce the usage of plastic and recycle the old plastic
            bag or box.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SC7.SD2}
            alt="HumanImpacts"
            className="w-1/2 mx-auto"
          />
        </div>

        <div className="p-4">
          <p>
            <strong>How can we use plastic?</strong>
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SC7.SD3} alt="HumanImpacts" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Plastics are used 
            <strong>
              to make bicycle helmets, child safety seats and airbags in
              automobiles.
            </strong>{" "}
            They're in the cell phones, televisions, computers and other
            electronic equipment that makes modern life possible. They're in the
            roofs, walls, flooring and insulation that make homes and buildings
            energy efficient.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Four/6">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Four/8">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC7;
