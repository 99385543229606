import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import HomeButton from "../../../../../components/HomeButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E8 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Punctuation"} />
        <div className="p-4">
          <p>
            Punctuation is
            <strong>
              the use of symbols such as full stops or periods, commas, or
              question marks to divide written words into sentences and clauses.
            </strong>
          </p>
          <p className="mt-4">Fill the punctuation into the sentence.</p>
          <p className="mt-4 font-bold text-3xl">. , ! ?</p>
        </div>
        <div>
          <div className="p-4">
            <p className="pl-6">
              1. How are you
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="pl-6">
              2. I love to swim
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />{" "}
              dance{" "}
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />{" "}
              and play{" "}
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="pl-6">
              3. What are you doing
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="pl-6">
              4. I am going to the beach
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
            </p>
          </div>
        </div>
        <div>
          <div className="p-4">
            <p className="pl-6">
              5. I bought a jar spoon plate and cup
              <input
                type="text"
                className="outline-none w-4 text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={1}
              />
            </p>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/7">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E8;