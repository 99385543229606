import React, { useState } from "react";
import { Checkbox } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E9 = () => {
  const bubbleStyle =
    "w-32 h-32 m-6 hover:outline-zmh-gray-700 outline outline-2 outline-zmh-lightblue-700 shadow-xl shadow-zmh-gray-400 rounded-full text-xl bg-zmh-lightblue-700 text-white";
  const correctStyle =
    "w-32 h-32 m-6 hover:outline-zmh-gray-700 outline outline-2 outline-zmh-lightblue-700 shadow-xl shadow-zmh-gray-400 rounded-full text-xl bg-green-500 text-white";
  const errorStyle =
    "w-32 h-32 m-6 hover:outline-zmh-gray-700 outline outline-2 outline-zmh-lightblue-700 shadow-xl shadow-zmh-gray-400 rounded-full text-xl bg-red-500 text-white";
  const bubbleData = [
    { bid: "1", name: "set" },
    { bid: "2", name: "fed" },
    { bid: "3", name: "get" },
    { bid: "4", name: "len" },
    { bid: "5", name: "net" },
    { bid: "6", name: "vet" },
  ];

  const bubbleAnswer = [
    { name: "set" },
    { name: "get" },
    { name: "net" },
    { name: "vet" },
    ];

  const [isDis, setIsDis] = useState(true);

  const checkAnswer = (e, params) => {
    const ans = bubbleAnswer.filter(({ name }) => {
      return name === params.name;
    });

    if (ans.length === 0) {
      document.getElementById(e.currentTarget.id).className = errorStyle;
    } else {
      document.getElementById(e.currentTarget.id).className = correctStyle;
    }
  };

  const changeNext = () => {
    setIsDis(!isDis);
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Read the words in each bubbles."} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <p>
            Click the bubble that rhyme with the word <strong>“let”.</strong>
          </p>
        </div>
        <div className="md:p-12 lg:p-12">
          {bubbleData.map(({ bid, name }) => (
            <button
              key={bid}
              id={bid}
              onClick={(e) => {
                checkAnswer(e, { bid, name });
              }}
              className={bubbleStyle}
            >
              {name}
            </button>
          ))}
        </div>
        <br />
        <div className="w-full px-3 mb-20">
          <div>
            <Checkbox
              onChange={changeNext}
              color="blue"
              label="I can rhyme words. I can understand sound in words.  "
            />
          </div>
        </div>
        <br />
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Two/8">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Two/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E9;
