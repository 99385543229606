import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS1 = () => {
  const speakData =
    "Using words, sounds, signs or behaviors to exchange information. Expressing your ideas, thoughts or feelings. Exchanging of information. Speaking, writing, or drawing to give a message. Sending or receiving information. ";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"What Is Communication?"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Com1} alt="Communication" className="w-full" />
        </div>
        <div className="p-8">
          <ul className="list-disc">
            <li>
              Using words, sounds, signs or behaviors to exchange information.
            </li>
            <li>Expressing your ideas, thoughts or feelings.</li>
            <li>Exchanging of information.</li>
            <li>Speaking, writing, or drawing to give a message.</li>
            <li>Sending or receiving information.</li>
          </ul>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.Com2} alt="Communication" className="w-full" />
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Four/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1;
