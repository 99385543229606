import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC9 = () => {
  const speakData =
    "Since the engines are consuming fuel and air and create heat in the combustion process, nitrogen from the air can be transformed into nitrogen oxides which are reddish brown gases that irritate the lungs and eyes. Pollutants emitted directly from vehicles are not the only cause for concern.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"How does the use of automobiles cause air pollution? "}
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SC9.AirPollution}
            alt="AirPollution"
            className="w-full"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Since the engines are consuming fuel and air and create heat in the
            combustion process, nitrogen from the air can be transformed into
            nitrogen oxides which are reddish brown gases that irritate the
            lungs and eyes. Pollutants emitted directly from vehicles are not
            the only cause for concern.
          </p>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Four/8">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/Four/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC9;
