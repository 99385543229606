import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA16 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />        
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ1} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                readOnly
                value={"2"}
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                readOnly
                value={"7"}
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
                readOnly
                value={"14"}
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ2} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ3} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ4} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ5} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ6} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ7} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ8} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ9} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ10} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ11} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>
        <div className="p-4 grid grid-cols-2">
          <div className="">
            <img src={IMAGES.L1.MA16.QQ12} alt="" className="" />
          </div>
          <div className="">
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" groups of "}
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
            </p>
            <p>
              <input
                type="text"
                className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                maxLength="2"
              />
              {" in total"}
            </p>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/One/15">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/One/17">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA16;
