import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import UndoButton from "../../../../../components/UndoButton";
import ResetButton from "../../../../../components/ResetButton";
import CanvasDaw from "react-canvas-draw";

const SC20 = () => {
  const firstCanvasRef = useRef(null);
  const secondCanvasRef = useRef(null);
  const thirdCanvasRef = useRef(null);

  const clear = () => {
    firstCanvasRef.current.clear();
  };
  const clear2 = () => {
    secondCanvasRef.current.clear();
  };
  const clear3 = () => {
    thirdCanvasRef.current.clear();
  };

  const undo = () => {
    firstCanvasRef.current.undo();
  };
  const undo2 = () => {
    secondCanvasRef.current.undo();
  };
  const undo3 = () => {
    thirdCanvasRef.current.undo();
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Quiz"} />
        <div>
          <div className="p-4 bg-yellow-200 rounded-lg border border-black m-2 text-sm">
            <p>
              When the matter is <strong>solid</strong>, molecules stay locked
              together in the same shape.
            </p>
            <p>
              When the matter is <strong>liquid</strong>, molecules stay locked
              together but can move around.
            </p>
            <p>
              When the matter is <strong>gas</strong>, molecules spread away
              from each other.
            </p>
          </div>
          <div className="p-4">
            <p>Write solid, liquid and gas on the line</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.SC5.Q1} alt="" className="w-full" />
          </div>
          <div className="flex ml-5">
            <div className="w-1/3">
              <input
                type="text"
                className="outline-none w-24 text-center text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={6}
              />
            </div>
            <div className="w-1/3">
              <input
                type="text"
                className="outline-none w-24 text-center text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={6}
              />
            </div>
            <div className="w-1/3">
              <input
                type="text"
                className="outline-none w-24 text-center text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={6}
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="p-4">
            <p>Label each picture solid, liquid or gas</p>
          </div>
          <div className="flex p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.SC5.Q2} alt="" className="w-full" />
          </div>
          <div className="flex ml-5">
            <div className="w-1/3">
              <input
                type="text"
                className="outline-none w-24 text-center text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={6}
              />
            </div>
            <div className="w-1/3">
              <input
                type="text"
                className="outline-none w-24 text-center text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={6}
              />
            </div>
            <div className="w-1/3">
              <input
                type="text"
                className="outline-none w-24 text-center text-lg border-b-blue-500 border-b-2 mx-1"
                maxLength={6}
              />
            </div>
          </div>
        </div>
        <div className="px-4 mt-10">
          <p>Draw one example of each state of matter.</p>
        </div>
        <div className="m-3 mt-10 border-2 border-black">
          <CanvasDaw
            canvasWidth={360}
            canvasHeight={240}
            ref={firstCanvasRef}
            brushRadius={3}
            lazyRadius={0}
          />
        </div>
        <div className="w-full flex text-center justify-center">
          <p>solid</p>
        </div>
        <div className="flex p-3 justify-center">
          <UndoButton type={undo} />
          <ResetButton type={clear} />
        </div>
        <div className="m-3 mt-10 border-2 border-black">
          <CanvasDaw
            canvasWidth={360}
            canvasHeight={240}
            ref={secondCanvasRef}
            brushRadius={3}
            lazyRadius={0}
          />
        </div>
        <div className="w-full flex text-center justify-center">
          <p>liquid</p>
        </div>
        <div className="flex p-3 justify-center">
          <UndoButton type={undo2} />
          <ResetButton type={clear2} />
        </div>
        <div className="m-3 mt-10 border-2 border-black">
          <CanvasDaw
            canvasWidth={360}
            canvasHeight={240}
            ref={thirdCanvasRef}
            brushRadius={3}
            lazyRadius={0}
          />
        </div>
        <div className="w-full flex text-center justify-center">
          <p>gas</p>
        </div>
        <div className="flex p-3 justify-center mb-20">
          <UndoButton type={undo3} />
          <ResetButton type={clear3} />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/One/19">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units/One/21">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC20;
