import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC2 = () => {
  const speakData =
    "Many kinds of plants grow in the ocean, including seaweed, grasses, algae and even flowers. The oceans contain all kinds of creatures, from giant whales to plankton so tiny that you need a microscope to see them. There are more than 21,000 species of fish in the ocean. Fish breathe underwater using special organs called gills. Many mammals live in the sea, such as seals, whales, and dolphins. These creatures need to return to the surface to breathe air, like we do. Somecreatures crawl over the bottom of the ocean or bburrow beneath it. They include lobsters, crabs, prawns and starfish.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Oceans"} />
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Oceans</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.SC2.Oceans} alt="Oceans" className="w-full" />
        </div>
        <div className="p-4 text-center text-xl text-green-500 font-bold">
          <p>Animals of the Oceans</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SC2.AnimalsOfOceans}
            alt="AnimalsOfOceans"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/Three/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/Science/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC2;
