import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS2 = () => {
  const speakData =
    "What Are Landforms? Landforms are naturally-formed features that can be found on Earth’s surface. Many of them are created by natural forces such as the sea or wind. Landforms often occur above sea level, but some can form under the ocean. Mountains, islands, and plains are all types of landforms. There are many different types of landforms. How many can you think of?";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Land Forms"} />
        <div className="p-4">
          <p className="text-center font-bold">What Are Landforms?</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.LF1} alt="LandForms" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Landforms are naturally-formed features that can be found on Earth’s
            surface. Many of them are created by natural forces such as the sea
            or wind.
          </p>
        </div>
        <div className="p-4">
          <p>
            Landforms often occur above sea level, but some can form under the
            ocean.
          </p>
        </div>
        <div className="p-4">
          <u className="text-center">
            Mountains, islands, and plains are all types of landforms.
          </u>
        </div>
        <div className="p-4 flex justify-between">
          <div>
            <img src={IMAGES.L3.LF2} alt="LandForms" className="w-full h-24" />
          </div>
          <div>
            <img src={IMAGES.L3.LF3} alt="LandForms" className="w-full h-24" />
          </div>
        </div>
        <div className="p-4">
          <p>
            There are many different types of landforms. How many can you think
            of?
          </p>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/SocialStudies/Units/Three/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/SocialStudies/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
