import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS15 = () => {
  const speakData =
    "Which of these is a fire truck from 100 years ago, and which is a modern fire truck?";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Fire Truck"} />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            Which of these is a fire truck from 100 years ago, and which is a
            modern fire truck?
          </p>
        </div>
        <div className="p-4 m-4 bg-zmh-lightblue-500 text-white text-center text-xl font-bold rounded-full">
          <p>Past</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SS15.FireTruck1}
            alt="FireTruck"
            className="w-full"
          />
        </div>
        <div className="p-4 m-4 bg-zmh-lightblue-500 text-white text-center text-xl font-bold rounded-full">
          <p>Present</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SS15.FireTruck2}
            alt="FireTruck"
            className="w-full"
          />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/14">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Three/16">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS15;
