import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC2 = () => {
  const speakData =
    "DID YOU KNOW WE USE BIOMIMICRY IN OUR DAILY LIFE? Designing a solution to a human problem is what biomimicry is.  It literally means mimicking life. Unit starts by asking students to look at the phenomena. What do these two pictures have in common? Students gather information about animal and plant parts that help them and then they are challenged to make something that solves a human problem by mimicking a plant or animal part. This one was mimicking a plant air sac-like water plants sometimes have.  He was making a thermometer that would float in the pool. This one is for grabbing scooping and it mimics a pelican’s bill. The fuzzy sock is supposed to keep this “person” warm like an animal’s fur.This one mimics a giant ear that a rabbit or deer would have to help a person hear better.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"Biomimicry"} />
        <div className="p-4">
          <p className="font-bold">Lesson Objective:</p>
        </div>
        <div className="p-4">
          <p>
            Use materials to design a solution to a human problem by mimicking
            how plants and/or animals use their external parts to help them
            survive, grow, and meet their needs.
          </p>
        </div>
        <div className="p-4">
          <p className="font-bold">
            DID YOU KNOW WE USE BIOMIMICRY IN OUR DAILY LIFE?
          </p>
        </div>
        <div className="p-4">
          <p className="font-bold">
            Designing a solution to a human problem is what biomimicry is.  It
            literally means mimicking life.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Bio1} alt="Biomimicry" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Unit starts by asking students to look at the 
            <strong className="text-pink-600">
              <u>phenomena</u>
            </strong>
            .  What do these two pictures have in common?
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Bio2} alt="Biomimicry" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            Students gather information about animal and plant parts that help
            them and then they are challenged to make something that solves a
            human problem by mimicking a plant or 
            <strong>
              <u>animal</u>
            </strong>
             part.  
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Bio3} alt="Biomimicry" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            This one was mimicking a plant air sac-like water plants sometimes
            have.  He was making a thermometer that would float in the pool.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Bio4} alt="Biomimicry" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            This one is for grabbing scooping and it mimics a pelican’s bill.  
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Bio5} alt="Biomimicry" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            The fuzzy sock is supposed to keep this “person” warm like an
            animal’s fur.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L2.Bio6} alt="Biomimicry" className="w-full" />
        </div>
        <div className="p-4">
          <p>
            This one mimics a giant ear that a rabbit or deer would have to help
            a person hear better.
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Two/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units/Two/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC2;
