import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA3 = () => {
  const speakData =
    "How many cups are there ? Up there we have 3 cups and down there we have 2 cups .We have to add 3+2.Soall the cups together would sum up 1,2,3,4, and 5. We have 5 cups. Subtraction . We subtract to know how many things we have left . Before there we 3 cookies . You have eaten 2 cookies . So if we take one away from 3.There will be 1 cookie left 3 minus 2 = 1.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"ADD AND SUBTRACT WITH EQUATIONS"} />
        <div className="p-4">
          <p>
            Add and subtract within 20,work with addition and subtraction
            equations
          </p>
        </div>
        <div>
          <img
            src={IMAGES.L3.MA1.AddSubtract}
            alt=""
            className="w-full"
          />
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg mt-10">
          <p>
            How many cups are there ? Up there we have 3 cups and down there we
            have 2 cups .We have to add 3+2.Soall the cups together would sum up
            1,2,3,4, and 5. We have 5 cups.
          </p>
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg mt-4">
          <p>
            Subtraction . We subtract to know how many things we have left .
            Before there we 3 cookies . You have eaten 2 cookies . So if we take
            one away from 3.There will be 1 cookie left 3-2=1.
          </p>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/Three/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA3;