import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { useSpeechSynthesis } from "react-speech-kit";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";

const SS3 = () => {
  const speakData =
    "One of the most typical street foods in Brazil is pão de queijo. It’s a ball of warm bread made with tapioca flour and cheese. Some street vendors also sell barbequed chicken or spicy prawns. If you are thirsty, try some acai. It’s a popular drink made from palm tree berries. For dessert you can fresh fruit or you can try some sweet coconut pastries. If you want a snack later, you can have popcorn with sugar. It’s a popular snack all over Brazil.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"POPULAR STREET FOOD IN THE WORLD"} />
        <div className="p-4">
          <p>
            One of the most typical street foods in Brazil is pão de queijo.
            It’s a ball of warm bread made with tapioca flour and cheese. Some
            street vendors also sell barbequed chicken or spicy prawns. If you
            are thirsty, try some acai. It’s a popular drink made from palm tree
            berries. For dessert you can fresh fruit or you can try some sweet
            coconut pastries. If you want a snack later, you can have popcorn
            with sugar. It’s a popular snack all over Brazil.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L4.SS1.StreetFood2}
            alt="StreetFood"
            className="w-full"
          />
        </div>

        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Four/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/SocialStudies/Units/Four/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;
