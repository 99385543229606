import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC9 = () => {
  const normalStyle =
    "bg-gray-200 hover:bg-gray-200 rounded-2xl text-black w-full py-2";
  const correctStyle =
    "bg-green-500 hover:bg-green-500 rounded-2xl text-white w-full py-2";
  const errorStyle =
    "bg-red-500 hover:bg-red-500 rounded-2xl text-white w-full py-2";
  const showCorrectSytle =
    "opacity-50 bg-green-500 hover:bg-gray-500 rounded-2xl text-white w-full py-2";

  const checkAnswer = (e) => {
    switch (e.currentTarget.id) {
      case "1":
        document.getElementById("1").className = correctStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "2":
        document.getElementById("1").className = showCorrectSytle;
        document.getElementById("2").className = errorStyle;
        document.getElementById("1").disabled = true;
        document.getElementById("2").disabled = true;
        break;
      case "3":
        document.getElementById("4").className = showCorrectSytle;
        document.getElementById("3").className = errorStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "4":
        document.getElementById("4").className = correctStyle;
        document.getElementById("3").disabled = true;
        document.getElementById("4").disabled = true;
        break;
      case "5":
        document.getElementById("5").className = correctStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "6":
        document.getElementById("5").className = showCorrectSytle;
        document.getElementById("6").className = errorStyle;
        document.getElementById("5").disabled = true;
        document.getElementById("6").disabled = true;
        break;
      case "7":
        document.getElementById("8").className = showCorrectSytle;
        document.getElementById("7").className = errorStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "8":
        document.getElementById("8").className = correctStyle;
        document.getElementById("7").disabled = true;
        document.getElementById("8").disabled = true;
        break;
      case "9":
        document.getElementById("9").className = correctStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      case "10":
        document.getElementById("9").className = showCorrectSytle;
        document.getElementById("10").className = errorStyle;
        document.getElementById("9").disabled = true;
        document.getElementById("10").disabled = true;
        break;
      default:
    }
  };
  return (
    <div>
      <div>
        <BannerTitle title={"Push or Pull"} />
        <div className="flex items-center">
          <div className="flex w-2/3 p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.SC9.Q1} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 mr-4">
            <div className="mb-2">
              <button onClick={checkAnswer} id="1" className={normalStyle}>
                Push
              </button>
            </div>
            <div className="mt-2">
              <button onClick={checkAnswer} id="2" className={normalStyle}>
                Pull
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex w-2/3 p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.SC9.Q2} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 mr-4">
            <div className="mb-2">
              <button onClick={checkAnswer} id="3" className={normalStyle}>
                Push
              </button>
            </div>
            <div className="mt-2">
              <button onClick={checkAnswer} id="4" className={normalStyle}>
                Pull
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex w-2/3 p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.SC9.Q3} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 mr-4">
            <div className="mb-2">
              <button onClick={checkAnswer} id="5" className={normalStyle}>
                Push
              </button>
            </div>
            <div className="mt-2">
              <button onClick={checkAnswer} id="6" className={normalStyle}>
                Pull
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex w-2/3 p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.SC9.Q4} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 mr-4">
            <div className="mb-2">
              <button onClick={checkAnswer} id="7" className={normalStyle}>
                Push
              </button>
            </div>
            <div className="mt-2">
              <button onClick={checkAnswer} id="8" className={normalStyle}>
                Pull
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex w-2/3 p-4 md:p-12 lg:p-12">
            <img src={IMAGES.L1.SC9.Q5} alt="Quiz" className="w-full" />
          </div>
          <div className="w-1/3 mr-4">
            <div className="mb-2">
              <button onClick={checkAnswer} id="9" className={normalStyle}>
                Push
              </button>
            </div>
            <div className="mt-2">
              <button onClick={checkAnswer} id="10" className={normalStyle}>
                Pull
              </button>
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/One/8">
            <BackButton />
          </Link>
          <Link to="/KG/Science/Units/One/10">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC9;
