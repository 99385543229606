import React from "react";
import { Link } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA4 = () => {
  const speakData = "ELEVEN,TWELVE,THIRTEEN,FOURTEEN,FIFTEEN,SIXTEEN,SEVENTEEN,EIGHTEEN,NINETEEN,TWENTY";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Number 11 to 20"} />
        <div className="flex p-4 text-center">
          <p className="w-1/3 text-green-500 text-6xl font-bold">11</p>
          <p className="w-1/3 text-blue-500 text-6xl font-bold">12</p>
          <p className="w-1/3 text-cyan-500 text-6xl font-bold">13</p>
        </div>
        <div className="flex p-4 text-center">
          <p className="w-1/3 font-bold">ELEVEN</p>
          <p className="w-1/3 font-bold">TWELVE</p>
          <p className="w-1/3 font-bold">THIRTEEN</p>
        </div>
        <div className="flex p-4 text-center">
          <p className="w-1/3 text-pink-500 text-6xl font-bold">14</p>
          <p className="w-1/3 text-yellow-700 text-6xl font-bold">15</p>
          <p className="w-1/3 text-purple-500 text-6xl font-bold">16</p>
        </div>
        <div className="flex p-4 text-center">
          <p className="w-1/3 font-bold">FOURTEEN</p>
          <p className="w-1/3 font-bold">FIFTEEN</p>
          <p className="w-1/3 font-bold">SIXTEEN</p>
        </div>
        <div className="flex p-4 text-center">
          <p className="w-1/3 text-blue-500 text-6xl font-bold">17</p>
          <p className="w-1/3 text-gray-500 text-6xl font-bold">18</p>
          <p className="w-1/3 text-amber-500 text-6xl font-bold">19</p>
        </div>
        <div className="flex p-4 text-center">
          <p className="w-1/3 font-bold">SEVENTEEN</p>
          <p className="w-1/3 font-bold">EIGHTEEN</p>
          <p className="w-1/3 font-bold">NINETEEN</p>
        </div>
        <div className="flex p-4 text-center">
          <p className="w-1/3 text-cyan-500 text-6xl font-bold">20</p>
        </div>
        <div className="flex p-4 text-center">
          <p className="w-1/3 font-bold">TWENTY</p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/One/3">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Math/Units/One/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA4;