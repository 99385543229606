import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SS6 = () => {
  const speakData =
    "Let's see what other maps we can find. Have you used a map before ? I used a little map when i went to the zoo. It showed me where to find the different animals and the cafe.";

  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"All about map"} />

        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L4.SS6.Map4} alt="Map" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img src={IMAGES.L4.SS6.M5} alt="Map" className="w-1/2" />
          <img src={IMAGES.L4.SS6.M6} alt="Map" className="w-1/2" />
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Four/5">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Four/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS6;
