import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Family1IMG from "../../assets/L1/AnaFamily.png";
import { useSpeechSynthesis } from "react-speech-kit";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS8 = () => {
  const speakData =
    "My name is Ana. I have a big family. We’re very happy because we do lots of fun things together. We live in the USA. I’m 5 years old. My favorite color is green and I like riding my bike. Every day, I get up early and have a shower. I have cereal for breakfast and then I walk to school with my dad. In the evenings, I dance at my dance class. At the weekends, I always go to the park with my family.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"This is my family"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={Family1IMG} alt="Family" className="h-full w-full" />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            My name is Ana. I have a big family. We’re very happy because we do
            lots of fun things together. We live in the USA. I’m 5 years old. My
            favorite color is green and I like riding my bike. Every day, I get
            up early and have a shower. I have cereal for breakfast and then I
            walk to school with my dad. In the evenings, I dance at my dance
            class. At the weekends, I always go to the park with my family.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/One/7_1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/One/9">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS8;
