import React from "react";
import { Typography, Radio } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Playing Dress-Up"} />
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              1. What is the main idea of this story?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio
                id="QA1"
                name="Q1"
                label="Sarah likes to dress up as a bride."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA2"
                name="Q1"
                label="Playing dress-up is a lot of fun."
              />
            </div>
            <div className="flex-row">
              <Radio
                id="QA3"
                name="Q1"
                label="Sarah likes to dress up as a clown."
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              2. What does Sarah like dressing up as best?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Mom" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="A dancer" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="A clown" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex px-4 py-4 md:px-12 lg:px-12">
            <Typography variant="lead">
              3. Where does Sarah shop for food?
            </Typography>
          </div>
          <div className="px-4 md:px-12 lg:px-12">
            <div className="flex-row">
              <Radio id="QA1" name="Q1" label="Bedroom" />
            </div>
            <div className="flex-row">
              <Radio id="QA2" name="Q1" label="Garden" />
            </div>
            <div className="flex-row">
              <Radio id="QA3" name="Q1" label="Kitchen" />
            </div>
          </div>
        </div>
        <div className="mb-40">
          <div className="p-4">
            <Typography variant="lead">
              4. Who is your favorite person to dress up as?
            </Typography>
          </div>
          <div className="m-4">
            <input
              type="text"
              className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/Two/3">
            <BackButton />
          </Link>
          <Link to="/G1/English/Units/Two/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E4;