import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC34 = () => {
  const speakData =
    "String Telephone. You can experiment with sound waves by making your own telephone. Connect two plastic cups with a length of string. Experiment with the length of string to see if you can still hear the sounds.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"An experiment"} />
        <div className="p-4">
          <p className="text-center font-bold">String Telephone</p>
        </div>
        <div className="p-4">
          <p>
            You can experiment with{" "}
            <strong className="text-pink-600">sound waves</strong> by making
            your own telephone.
          </p>
        </div>
        <div className="p-4 mx-4 mt-4 bg-green-600 text-white rounded-2xl">
          <p>Connect two plastic cups with a length of string.</p>
        </div>
        <div className="p-4">
          <p>
            Experiment with the length of string to see if you can still hear
            the sounds.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.StringTelephone}
            alt="StringTelephone"
            className="w-full"
          />
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/One/33">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Science/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC34;
