import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA4 = () => {
  return (
    <div>
      <div className="font-body ">
        <BannerTitle title={"Quiz"} />
        <div className="p-4">
          <p>
            Compare the numbers. Add {">"} or {"<"} or {"="}{" "}
          </p>
        </div>
        <div className="p-4">
          <div className="mx-4 px-4 bg-gray-500 text-white shadow-lg rounded-xl">
            <p>Example: 846 {">"} 239</p>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                1. 137{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  readOnly
                  value={"<"}
                  maxLength={1}
                />{" "}
                841
              </p>
            </div>
            <div>
              <p>
                2. 239{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                734
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                3. 245{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                109
              </p>
            </div>
            <div>
              <p>
                4. 343{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                808
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                5. 457{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                754
              </p>
            </div>
            <div>
              <p>
                6. 541{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                765
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                7. 137{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                841
              </p>
            </div>
            <div>
              <p>
                8. 666{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                959
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                9. 90{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                480
              </p>
            </div>
            <div>
              <p>
                10. 298{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                573
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                11. 736{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                774
              </p>
            </div>
            <div>
              <p>
                12. 215{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                869
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                13. 69{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                590
              </p>
            </div>
            <div>
              <p>
                14. 255{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                709
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                15. 474{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                70
              </p>
            </div>
            <div>
              <p>
                16. 701{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                301
              </p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <p>
                17. 912{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"                  
                  maxLength={1}
                />{" "}
                317
              </p>
            </div>
            <div>
              <p>
                18. 877{" "}
                <input
                  type="text"
                  className="outline-none w-8 text-center text-lg border-b-2 border-b-black"
                  maxLength={1}
                />{" "}
                600
              </p>
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Math/Units/Three/3">
            <BackButton />
          </Link>
          <Link to="/G2/Math/Units/Three/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA4;
