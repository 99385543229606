import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA2 = () => {

  return (
    <div>
      <div>
        <BannerTitle title={"Pumpkin Addition"} />
        <div className="w-full flex p-4">
          <div className="justify-center text-center w-1/2">
            <img
              src={IMAGES.L2.MA2.PumpkinQuiz1}
              alt="Number"
              className="w-full"
            />
          </div>
          <div className="w-1/2 pl-4">
            <div className="h-1/4 mt-10">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
            <div className="h-1/4">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
            <div className="h-1/4">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
            <div className="h-1/4">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex p-4 mb-20">
          <div className="justify-center text-center w-1/2">
            <img
              src={IMAGES.L2.MA2.PumpkinQuiz2}
              alt="Number"
              className="w-full"
            />
          </div>
          <div className="w-1/2 pl-4">
            <div className="h-1/4 mt-10">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
            <div className="h-1/4">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
            <div className="h-1/4">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
            <div className="h-1/4">
              <input
                type="text"
                className="outline-none w-full p-2 text-xl border-blue-500 border-2 rounded-lg"
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Math/Units/Two/1">
            <BackButton />
          </Link>
          <Link to="/KG/Math/Units/Two/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA2;
