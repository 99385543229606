import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E5 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Answer the questions"} />
        <div className="p-4 flex">
          <div className="w-1/2">
            <p className="text-center">1. Survival + ing =</p>
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            <p className="text-center">2. Believed + able =</p>
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>
        <div className="p-4 flex">
          <div className="w-1/2">
            <p className="text-center">3. Appears + ance =</p>
          </div>
          <div className="w-1/2">
            <input
              type="text"
              className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
            />
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Four/4">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Four/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E5;