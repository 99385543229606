import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA6 = () => {
  const speakData =
    "Katie’s bag has 4 pencils,5 notebooks,5 crayons, and 7 textbooks . How many items does Katie’s bag in total? To solve this we need to write addition. 4 pencils+5 notebooks+5 crayons+7 textbooks. We can simplify this by adding two numbers at a time.4 pencils+5 notebooks=9 items.5 crayons +7 textbooks =12 items . now we just add 9items + 12items =21 items. So Katie’s bag has 21 items.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle title={"ADDITION"} />
        <div className="p-4">
          <img src={IMAGES.L1.MA6.Addition} alt="" className="w-full" />
        </div>
        <div className="p-4 m-2 bg-gray-300 rounded-xl shadow-lg">
          <p>
            Katie’s bag has 4 pencils,5 notebooks,5 crayons, and 7 textbooks .
            How many items does Katie’s bag in total? To solve this we need to
            write addition. 4 pencils+5 notebooks+5 crayons+7 textbooks. We can
            simplify this by adding two numbers at a time.4 pencils+5
            notebooks=9 items.5 crayons +7 textbooks =12 items . now we just add
            9items + 12items =21 items. So Katie’s bag has 21 items.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/One/5">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/Math/Units/One/7">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA6;