import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E3 = () => {
  const speakData =
    "Germs are things we do not want to share. Germs can make people sick. Even though we cannot see germs, they get into the body in many ways. Germs can get into the body through the nose, mouth, eyes and cuts in the skin. We share germs when we drink from the same cups or eat off of the same plates. Here are some helpful tips to keep germs to yourself and to stay healthy: Wash your hands with soap. Cover your mouth when you cough or sneeze. Do not share food or drink. Get a little bit of sunshine and fresh air. Eat healthy meals. Get plenty of sleep. ";  
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Go Away Germs"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L1.E3.GoAwayGerms}
            alt="GoAwayGerms"
            className="w-1/2 mx-auto"
          />
        </div>
        <div className="p-4 mb-20">
          <p>
            Germs are things we do not want to share. Germs can make people
            sick. Even though we cannot see germs, they get into the body in
            many ways. Germs can get into the body through the nose, mouth, eyes
            and cuts in the skin. We share germs when we drink from the same
            cups or eat off of the same plates.
          </p>
          <p className="mt-4">
            Here are some helpful tips to keep germs to yourself and to stay
            healthy:
          </p>
          <ul className="list-decimal p-4">
            <li>Wash your hands with soap.</li>
            <li>Cover your mouth when you cough or sneeze.</li>
            <li>Do not share food or drink.</li>
            <li>Get a little bit of sunshine and fresh air. </li>
            <li>Eat healthy meals. </li>
            <li>Get plenty of sleep. </li>
          </ul>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/One/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G2/English/Units/One/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;
