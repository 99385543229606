import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { IMAGES } from "../../assets";

const SS2 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Complete the words"} />
        <div className="flex p-4">
          <div className="w-1/2 text-center">
            <div>
              <img src={IMAGES.L2.SS2.Q1} alt="Q" className="w-full" />
            </div>
            <div>
              <p className="font-bold">
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                t
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                t
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                e
              </p>
            </div>
          </div>
          <div className="w-1/2 h-48 text-center">
            <div>
              <img src={IMAGES.L2.SS2.Q2} alt="Q" className="w-full" />
            </div>
            <div>
              <p className="font-bold">
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                c
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                a
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />                
              </p>
            </div>
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 text-center">
            <div>
              <img src={IMAGES.L2.SS2.Q3} alt="Q" className="w-full" />
            </div>
            <div>
              <p className="font-bold">
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                s
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                a
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                d
              </p>
            </div>
          </div>
          <div className="w-1/2 h-48 text-center">
            <div>
              <img src={IMAGES.L2.SS2.Q4} alt="Q" className="w-full" />
            </div>
            <div>
              <p className="font-bold">
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                r
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                u
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                d                
              </p>
            </div>
          </div>
        </div>
        <div className="flex p-4">
          <div className="w-1/2 text-center">
            <div>
              <img src={IMAGES.L2.SS2.Q5} alt="Q" className="w-full" />
            </div>
            <div>
              <p className="font-bold">
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                o
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />
                s
                <input
                  className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-4 text-center"
                  type="text"
                  maxLength={1}
                />              
              </p>
            </div>
          </div>
          
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/Two/1">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units/Two/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS2;
