import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC2 = () => {
  const speakData =
    "Food is needed to live and grow for both animals and plants.";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"From Molecules to Organisms: Structure and Process"}
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC2.StructureAndProcess4}
            alt="StructureAndProcess"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <p>
            <strong>Food</strong> is needed to live and grow for both
            <strong> animals and plants</strong>.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SC2.StructureAndProcess5}
            alt="StructureAndProcess"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units/Three/1">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/Three/3">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC2;
