import React, { useEffect, useRef } from "react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import UndoButton from "../../../../../components/UndoButton";
import ResetButton from "../../../../../components/ResetButton";
import { Link } from "react-router-dom";
import CanvasDaw from "react-canvas-draw";
import BannerTitle from "../../../../../components/BannerTitle";

const SS22 = () => {
  const firstCanvasRef = useRef(null);

  useEffect(() => {
    const old_draw = JSON.parse(localStorage.getItem("draw_data"));
    if (old_draw) {
      firstCanvasRef.current.loadSaveData(old_draw);
    }
  }, []);

  const saveDraw = () => {
    let data = firstCanvasRef.current.getSaveData();
    localStorage.setItem("draw_data", JSON.stringify(data));
  };

  const clear = () => {
    firstCanvasRef.current.clear();
  };

  const undo = () => {
    firstCanvasRef.current.undo();
  };

  return (
    <div>
      <div>
        <BannerTitle title={"Response Activity 2"} />
        <div className="p-4 md:p-12 lg:p-12">
          <p>
            Draw the transportation that you found most interesting from the
            present?
          </p>
        </div>
        <div className="m-3 border-2 border-black">
          <CanvasDaw
            canvasWidth={360}
            ref={firstCanvasRef}
            brushRadius={1}
            lazyRadius={0}
          />
        </div>
        <div className="w-full flex text-center justify-center">
          <p>Present</p>
        </div>
        <div className="flex p-3 justify-center mb-20">
          <UndoButton type={undo} />
          <ResetButton type={clear} />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Three/21">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Three/23">
            <NextButton saveData={saveDraw} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS22;
