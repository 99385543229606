import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS14 = () => {
  const speakData =
    "My family live in the house. Our house is very nice. It is a two-story house. It has four bedrooms. We cook and eat in the kitchen. We watch TV in the living room. My favorite room is my bedroom. I like to read and do my homework in my bedroom.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"My House"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.SS14.MyHouse}
            alt="My House"
            className="h-full w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            My family live in the house. Our house is very nice. It is a
            two-story house. It has four bedrooms. We cook and eat in the
            kitchen. We watch TV in the living room. My favorite room is my
            bedroom. I like to read and do my homework in my bedroom.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/13">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Two/15">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS14;
