import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const SC1 = () => {
  const speakData =
    "What is matter? Matter is all of the living and nonliving things in that environment. Living things mean plants, animals, and organisms. Nonliving things are air, nutrients, and water. ";
  const { speak, speaking, cancel } = useSpeechSynthesis();
  return (
    <div>
      <div>
        <BannerTitle
          title={"From Molecules to Organisms: Structure and Process"}
        />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC1.StructureAndProcess1}
            alt="StructureAndProcess"
            className="w-full"
          />
        </div>
        <div className="p-4">
          <strong>What is matter?</strong>
          <p>
            -Matter is{" "}
            <strong>
              all of the living and nonliving things in that environment
            </strong>
            . Living things mean plants, animals, and organisms. Nonliving
            things are air, nutrients, and water.
          </p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L3.SC1.StructureAndProcess2}
            alt="StructureAndProcess"
            className="w-full"
          />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img
            src={IMAGES.L3.SC1.StructureAndProcess3}
            alt="StructureAndProcess"
            className="w-full"
          />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/Science/Units">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/Science/Units/Three/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC1;
