import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS1A = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Houses and Homes"} />
        <div className="p-4">
          <p className="font-bold">Learning objectives</p>
        </div>
        <div className="p-4">
          <p className="font-bold">Children will be able to</p>
        </div>
        <div className="px-8">
          <ul className="list-disc">
            <li>
              understand the places people live in and their familiar features
            </li>
            <li>recognise different houses people live</li>
            <li>
              practice naming and describing different rooms and some common
              household objects in their homes
            </li>
          </ul>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/1">
            <BackButton />
          </Link>
          <Link to="/KG/SocialStudies/Units/Two/1B">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1A;
