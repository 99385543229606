import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"The Universe and its Stars"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U1} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U2} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U3} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U4} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U5} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U6} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U7} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U8} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U9} alt="" className="w-full" />
        </div>
        <div className="p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U10} alt="" className="w-full" />
          <p className="text-center">Edwin Buzz Aldrin</p>
        </div>
        <div className="p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U11} alt="" className="w-full" />
          <p className="text-center">Neil Armstrong</p>
        </div>
        <div className="p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U12} alt="" className="w-1/2 mx-auto" />
          <p className="text-center">Buzz doll</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U13} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U14} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L3.U15} alt="" className="w-full" />
        </div>
        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Science/Units/Three/3">
            <BackButton />
          </Link>
          <Link to="/G1/Science/Units/Three/6">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC4;
