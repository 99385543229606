import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import { useSpeechSynthesis } from "react-speech-kit";

const E3 = () => {
  const speakData =
    "Sally Snail was sad. He wanted to run in the big race, but he was too slow. Robby Rat ran to Sally Snail. “Why are you so sad? “he asked. “I am too slow to be in the big race,” Sally Snail cried. “Sally snail, you are too slow!” Robby Rat laughed as he hopped down the road. Kami Kangaroo saw Sally Snail on her way to the race. “Why are you crying?” she asked. “I am too slow to be in the big race,” cried Sally Snail. “Don’t cry. I will help you,” Kami Kangaroo said. She picked up Sally Snail. She dropped him in her kangaroo pouch. Soon, it was time for the big race. Robby Rat and Kami Kangaroo raced together. As they hopped to the finish line, Kami Kangaroo took Sally Snail out of her pouch. She set him down across the finish line. Sally Snail won the big.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"The Race"} />
        <div className="p-4">
          <p>Read the passage and answer the questions.</p>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.E2.Snail} alt="Snail" className="w-1/2 mx-auto" />
        </div>
        <div className="p-4 mb-20">
          <p>
            Sally Snail was sad. He wanted to run in the big race, but he was
            too slow. Robby Rat ran to Sally Snail. “Why are you so sad? “he
            asked. “I am too slow to be in the big race,” Sally Snail cried.
            “Sally snail, you are too slow!” Robby Rat laughed as he hopped down
            the road. Kami Kangaroo saw Sally Snail on her way to the race. “Why
            are you crying?” she asked. “I am too slow to be in the big race,”
            cried Sally Snail. “Don’t cry. I will help you,” Kami Kangaroo said.
            She picked up Sally Snail. She dropped him in her kangaroo pouch.
            Soon, it was time for the big race. Robby Rat and Kami Kangaroo
            raced together. As they hopped to the finish line, Kami Kangaroo
            took Sally Snail out of her pouch. She set him down across the
            finish line. Sally Snail won the big.
          </p>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/English/Units/One/2">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/G1/English/Units/One/4">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E3;
