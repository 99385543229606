import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";
import BannerTitle from "../../../../../components/BannerTitle";

const E4 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Suffixes ful, less, ness, ly"} />        
        <div className="p-4">
          <p>
            <strong>Suffix</strong> is a word part that is added at the end of a
            base word to change the base word’s meaning. Look at the suffixes
            below.{" "}
          </p>
        </div>
        <div className="p-4">
          <p>
            <strong>Directions:</strong> Add the suffixes to the base words to
            make new words. Type the new word in the blank.
          </p>
        </div>
        <div className="p-4 mb-20">
          <div className="mb-2">
            <div>
              <p>1. care + ful =</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>2. pain + less = </p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>3. brave + ly</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <p>4. sad + ly = </p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
          <div className="mb-20">
            <div>
              <p>5. sick + ness</p>
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-lg border-b-blue-500 border-b-2 mx-1"
              />
            </div>
          </div>
        </div>

        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/English/Units/Two/3">
            <BackButton />
          </Link>
          <Link to="/G2/English/Units/Two/5">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E4;