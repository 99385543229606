import React from "react";
import { Link } from "react-router-dom";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const MA10 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"Write the missing numbers (1-50)"} />
        <div className="p-4">
          <div className="grid grid-cols-6">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"44"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"45"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"46"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"47"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"48"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"49"}
                readOnly
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-6">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"7"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"11"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-6">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"32"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"34"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"37"}
                readOnly
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-6">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"18"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"              
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"22"}
                readOnly
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-6">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"              
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"30"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"32"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                maxLength="2"
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-6">
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"25"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"                
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                value={"28"}
                readOnly
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"
                maxLength="2"
              />
            </div>
            <div>
              <input
                type="text"
                className="outline-none w-full text-center text-2xl border-black border-2"              
                maxLength="2"
              />
            </div>
          </div>
        </div>

        <div className="mb-24"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G1/Math/Units/Three/9">
            <BackButton />
          </Link>
          <Link to="/G1/Math/Units/Three/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MA10;