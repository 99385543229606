import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import UnitsButton from "../../../../components/UnitsButton";

const Units = () => {
  return (
    <div className="bg-white">
      <div>
        <div className="flex flex-col pt-8 pb-16 px-10 justify-center bg-gradient-to-t from-zmh-lightblue-down to-zmh-lightblue-top">
          <div className="font-bold text-8xl text-white">KG</div>
          <div className="text-4xl text-white">Kindergarden</div>
          <div className="text-white">(English)</div>
        </div>
      </div>
      <div className="bg-white rounded-t-3xl -mt-10">
        <div className="m-4 p-4 grid grid-cols-2 gap-4">
          <div>
            <Link to="/KG/English/Units/One/CommonCoreStandard">
              <UnitsButton title={"Level 1"} />
            </Link>
          </div>
          <div>
            <Link to="/KG/English/Units/Two/CommonCoreStandard">
              <UnitsButton title={"Level 2"} />
            </Link>
          </div>
          <div>
            <Link to="/KG/English/Units/Three/CommonCoreStandard">
              <UnitsButton title={"Level 3"} />
            </Link>
          </div>
          <div>
            <Link to="/KG/English/Units/Four/CommonCoreStandard">
              <UnitsButton title={"Level 4"} />
            </Link>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex w-full justify-center items-center p-4">
            <Link to="/KG">
              <BackButton />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Units;
