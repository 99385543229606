import React from "react";
import { Link } from "react-router-dom";
import { Typography, Radio } from "@material-tailwind/react";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS6 = () => {
  return (
    <div>
      <BannerTitle title={"Land Forms"} />
      <div className="p-4">
        <p>Choose the correct answers.</p>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            1. What do Florida and Italy have in common?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA1" name="Q1" label="They are both islands." />
          </div>
          <div className="flex-row">
            <Radio id="QA2" name="Q1" label="They both have peninsulas." />
          </div>
          <div className="flex-row">
            <Radio
              id="QA3"
              name="Q1"
              label="They are both in the United States."
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            2. Which type of landform is always completely surrounded by water
            on all sides?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA4" name="Q2" label="Plateau" />
          </div>
          <div className="flex-row">
            <Radio id="QA5" name="Q2" label="Peninsula" />
          </div>
          <div className="flex-row">
            <Radio id="QA6" name="Q2" label="Island" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            3. Which is the tallest type of landform?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Mountain" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Canyon" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Hill" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            4. Which types of landforms are always flat?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="Mountains and valleys" />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="Plateaus and plains" />
          </div>
          <div className="flex-row">
            <Radio id="QA9" name="Q3" label="Coastlines and islands" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex px-4 py-4 md:px-12 lg:px-12">
          <Typography variant="lead">
            5. How are a valley and a canyon alike?
          </Typography>
        </div>
        <div className="px-4 md:px-12 lg:px-12">
          <div className="flex-row">
            <Radio id="QA7" name="Q3" label="They are both low landforms." />
          </div>
          <div className="flex-row">
            <Radio id="QA8" name="Q3" label="They are both near the ocean." />
          </div>
          <div className="flex-row">
            <Radio
              id="QA9"
              name="Q3"
              label="They are both types of islands."
            />
          </div>
        </div>
      </div>

      <div className="mb-24"></div>

      <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
        <Link to="/G1/SocialStudies/Units/Three/5">
          <BackButton />
        </Link>

        <Link to="/G1/SocialStudies/Units/Three/7">
          <NextButton />
        </Link>
      </div>
    </div>
  );
};

export default SS6;