import React from "react";
import { Link } from "react-router-dom";
import HomeButton from "../../../../../components/HomeButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";
import { IMAGES } from "../../assets";

const SS3 = () => {
  return (
    <div>
      <div>
        <BannerTitle title={"FLYING MACHINES "} />
        <div className="p-4">
          <p>Write the correct answer under the picture.</p>
        </div>
        <div className="p-4 border border-blue-500 m-2">
          <div className="flex justify-between">
            <div>airship</div>
            <div>helicopter</div>
            <div>plane</div>
          </div>
          <div className="flex justify-between">
            <div>jet engine</div>
            <div>propeller</div>
            <div>hot-air balloon</div>
          </div>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <div className="p-4">
              <img
                src={IMAGES.L1.SS4.Plane}
                alt="QA"
                className="w-1/2 mx-auto"
              />
            </div>
            <div className="">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="p-4">
              <img
                src={IMAGES.L1.SS4.Propeller}
                alt="QA"
                className="w-1/2 mx-auto"
              />
            </div>
            <div className="">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="p-4">
              <img
                src={IMAGES.L1.SS4.AirBalloon}
                alt="QA"
                className="w-1/2 mx-auto"
              />
            </div>
            <div className="">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="p-4">
              <img
                src={IMAGES.L1.SS4.JetEngine}
                alt="QA"
                className="w-1/2 mx-auto"
              />
            </div>
            <div className="">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="p-4">
              <img
                src={IMAGES.L1.SS4.AIrship}
                alt="QA"
                className="w-1/2 mx-auto"
              />
            </div>
            <div className="">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="p-4">
              <img
                src={IMAGES.L1.SS4.Helicopter}
                alt="QA"
                className="w-1/2 mx-auto"
              />
            </div>
            <div className="">
              <input
                className="border-b-2 border-black focus:outline-0 focus:border-zmh-lightblue-500 w-full text-center"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="mb-20"></div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/SocialStudies/Units/One/2">
            <BackButton />
          </Link>

          <Link to="/G2/SocialStudies/Units">
            <HomeButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS3;
