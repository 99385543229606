import React from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import { useSpeechSynthesis } from "react-speech-kit";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import PlayButton from "../../../../../components/PlayButton";
import StopButton from "../../../../../components/StopButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SS1B = () => {
  const speakData =
    "Everyone needs to have somewhere they can sleep and feel safe, dry and warm. We all need somewhere to take shelter from the weather. People used to live in caves but over time they learned to build their own shelters, and then homes. People live in different types of places and homes.";
  const { speak, speaking, cancel } = useSpeechSynthesis();

  return (
    <div>
      <div>
        <BannerTitle title={"Houses and Homes"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img
            src={IMAGES.L2.SS1.HousesAndHomes}
            alt="HousesAndHomes"
            className="h-full w-full"
          />
        </div>

        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <Typography variant="lead">
            Everyone needs to have somewhere they can sleep and feel safe, dry
            and warm. We all need somewhere to take shelter from the weather.
            People used to live in caves but over time they learned to build
            their own shelters, and then homes. People live in different types
            of places and homes.
          </Typography>
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/SocialStudies/Units/Two/1A">
            <BackButton />
          </Link>
          {speaking ? (
            <StopButton id="stop" type={cancel} />
          ) : (
            <PlayButton
              id="speak"
              type={() => speak({ text: speakData, rate: 0.8 })}
            />
          )}
          <Link to="/KG/SocialStudies/Units/Two/2">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SS1B;
