import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import NextButton from "../../../../../components/NextButton";
import BackButton from "../../../../../components/BackButton";
import BannerTitle from "../../../../../components/BannerTitle";

const SC17 = () => {
  return (
    <div>
      <div>      
        <BannerTitle title={"Patterns"} />
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P1} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P2} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P3} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P4} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P5} alt="" className="w-full" />
        </div>
        <div className="flex p-4">
          <div className="w-1/2">
            <img src={IMAGES.L1.SC2.P6} alt="" className="w-full" />
          </div>
          <div className="w-1/2">
            <img src={IMAGES.L1.SC2.P7} alt="" className="w-full" />
          </div>
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P8} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P9} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P10} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P11} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P12} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P13} alt="" className="w-1/2 mx-auto" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P14} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P15} alt="" className="w-1/3" />
          <img src={IMAGES.L1.SC2.P16} alt="" className="w-1/3" />
          <img src={IMAGES.L1.SC2.P17} alt="" className="w-1/3" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P18} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P19} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P20} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12">
          <img src={IMAGES.L1.SC2.P21} alt="" className="w-full" />
        </div>
        <div className="flex p-4 md:p-12 lg:p-12 mb-20">
          <img src={IMAGES.L1.SC2.P22} alt="" className="w-full" />
        </div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/G2/Science/Units/One/16">
            <BackButton />
          </Link>
          <Link to="/G2/Science/Units/One/18">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SC17;