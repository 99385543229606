import React,{useState} from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Container from "./DragNDrop_Four/Container";
import { Link } from "react-router-dom";
import { Checkbox } from "@material-tailwind/react";
import BackButton from "../../../../../components/BackButton";
import NextButton from "../../../../../components/NextButton";

const E10 = () => {
    const [isDis, setIsDis] = useState(true);
    const changeNext = () => {
      setIsDis(!isDis);
    };
  return (
    <div>
      <div>
        <div className="flex bg-zmh-lightblue-600 text-white text-md font-bold justify-center items-center p-6">
          A plural noun is more than one person, place, thing or idea. You can
          make a noun plural by adding –s to the end of a noun.
        </div>
        <div className="flex p-6">Drag the words to the correct columns</div>
      </div>
      <div>
        <DndProvider backend={TouchBackend}>
          {/* Here, render a component that uses DND inside it */}
          <Container />
        </DndProvider>
      </div>
      <br />
      <br />
      <div className="w-full px-3 mb-20">
        <div>
          <Checkbox
            onChange={changeNext}
            color="blue"
            label="I can distinguish between singular and plural nouns."
          />
        </div>
      </div>
      <br />
      <div>
        <div className="flex bg-white fixed inset-x-0 bottom-0 p-4 md:p-12 lg:p-12 justify-between">
          <Link to="/KG/English/Units/Three/9">
            <BackButton />
          </Link>
          <Link to="/KG/English/Units/Three/11">
            <NextButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default E10;
